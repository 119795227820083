import React, { PureComponent } from "react";

import {
  getPodcast,
  getPodcastByCategoryId,
} from "../../../store/protected/podcast/action";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class PodcastCategoryNew extends PureComponent {
  responsive = {
    0: {
      items: 2,
    },
    450: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
  };

  componentDidMount() {
    if (this.props?.match?.params?.name)
      this.props.dispatchGetPodcastCategory({
        category_id: this.props.match.params.name,
        token: this.props.token,
      });
    this.props.dispatchPodcastCategory({
      token: this.props.token,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name != this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      this.props.dispatchGetPodcastCategory({
        category_id: this.props.match.params.name,
      });
    }
  }

  render() {
    const galleryItems = this.props?.podcastCategory?.data?.map(
      (list, index) => (
        <div className="item" key={index}>
          <Link
            to={`/podcast/${list.category_title_url}`}
            style={{
              background:
                "entrepreneurship" == list?.category_name.toLowerCase()
                  ? "#232075"
                  : "",
            }}
          >
            <img alt="img" src={list?.category_icon_url} />{" "}
            {list?.category_name}
          </Link>
        </div>
      )
    );

    return (
      <React.Fragment>
        <div className="container">
          <div className="podcast-cat-sld">
            <AliceCarousel
              items={galleryItems}
              responsive={this.responsive}
              buttonsDisabled={true}
            />
            <a
              href="https://podcasts.apple.com/us/podcast/aesthetic-entrepreneurs-get-stuff-done-podcast-richard/id1495236365"
              target="_blank"
              className="apple-pod"
            >
              <img alt="img" src="/assets/images/apple-btn.jpg" />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    podcastCategory: state.podcastReducer.podcasts,
    token: state.authReducer?.user?.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPodcastCategory: (data) => dispatch(getPodcast(data)),
    dispatchGetPodcastCategory: (data) =>
      dispatch(getPodcastByCategoryId(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PodcastCategoryNew));
