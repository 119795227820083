import React from "react";

import ReactPlayer from "react-player";

const ResponsivePlayer = ({ url, playing, refToSet, cbs }) => {
  console.log("light", url, "playing", playing);
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        playing={playing}
        width="100%"
        height="100%"
        controls={true}
        ref={refToSet}
        onContextMenu={(e) => e.preventDefault()}
        {...cbs}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      />
    </div>
  );
};
export default ResponsivePlayer;
