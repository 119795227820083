import {
  GET_COURSE_BANNER,
  GET_COURSE_BANNER_SUCCESS,
  GET_COURSE_BANNER_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: null,
  bannerList: null,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_BANNER:
      state = {
        error: null,
        loading: true,
        bannerList: null,
      };
      break;
    case GET_COURSE_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        bannerList: action.payload,
      };
      break;
    case GET_COURSE_BANNER_ERROR:
      state = {
        ...state,
        loading: false,
        bannerList: null,
        error: action.payload,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default bannerReducer;
