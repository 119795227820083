import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DataLoading from "../../../common/DataLoading";

const LatestBoxsets = (props) => {
    const [boxsetSlides, setBoxsetSlides] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [loading, setLoading] = useState(true);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
    };

    useEffect(() => {
        if (props?.courseList && props?.courseList.length > 0) {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        } else {
            setLoading(true)
        }
    }, [props?.courseList]);


    return (
        <section className="boxsets my-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 text-center mt-5">
                        <h2 className="mb-0">Latest Boxsets</h2>
                    </div>
                </div>
            </div>
            <div className="container mt-4 mb-5">
                <div className="card-slider-new">
                    {loading == false ?
                        <Slider {...settings}>
                            {props?.courseList && props?.courseList?.map((element, index) => (
                                <div className="col-lg-12 pr-5" key={index}>
                                    <div className="card w-100" style={{ position: "relative" }}>
                                        {/*<div class="wrapper">
                                    <input type="checkbox" class="heart-checkbox" id="heart-checkbox" />
                                     <label class="heart" for="heart-checkbox"></label>
                        </div>*/}
                                        {/*<button type="submit"><img className="card-img-sub" src={"./assets/new_layout/img/heart.png"} /></button>*/}

                                        <img className="card-img-top" src={element.thumbnail_url} alt="Card image cap" />
                                        <Link
                                            to={`/course/${element.course_category_url}/${element.id}/${element.course_title_url}`}
                                        >
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {element?.course_name?.length <= 50
                                                        ? element?.course_name
                                                        : element?.course_name?.substring(0, 50) +
                                                        "..."}
                                                </h5>
                                                <p>{element.no_of_chapters} Lessons</p>
                                                <a role="button" className="btn-free">
                                                    {element.course_type === 0 &&
                                                        (element.is_subscribed === true ||
                                                            element.is_subscribed === false) &&
                                                        (element.is_independent === true ||
                                                            element.is_independent === false)
                                                        ? "Free"
                                                        : element.course_type === 1 &&
                                                            element.is_independent === false
                                                            ? `£ ${element.monthly_fee}`
                                                            : element.course_type === 1 &&
                                                                element.is_independent === true
                                                                ? `£ ${element.course_fee}`
                                                                : "View Course"}
                                                </a>
                                                <p className="mt-4">
                                                    {element.rating && element.rating == 1 ?
                                                        <img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} />
                                                        : element.rating && element.rating == 2 ?
                                                            <>
                                                                <img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} />
                                                                <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                            </>
                                                            : element.rating && element.rating == 3 ?
                                                                <>
                                                                    <img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} />
                                                                    <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                    <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                </>
                                                                : element.rating && element.rating == 4 ?
                                                                    <>
                                                                        <img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} />
                                                                        <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                        <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                        <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                    </>
                                                                    : element.rating && element.rating == 5 ?
                                                                        <>
                                                                            <img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} />
                                                                            <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                            <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                            <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                            <span><img className="rating_image" src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                        </>
                                                                        :
                                                                        <></>

                                                    }
                                                    <small> ({element.rating})</small>
                                                    {/*<span className="reward-section"><img src={"./assets/new_layout/img/vector.png"} /> 116</span>*/}
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        :
                        <DataLoading />
                    }
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state?.dashboardReducer?.services?.courses,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LatestBoxsets));
