import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import Header from "../../../layout/gaust/Header";
import BlogBanner from "./BlogBanner";
import BlogCategory from "./BlogCategory";
import SubscriptionCheck from "./subscriptionCheck";
import { getBlogByCategoryId } from "../../../store/protected/blogs/action";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isLatestSuccess } from "../../../store/protected/courses/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class CategoryDetail extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },

    showLoginModel: false,
    blogUrl: "",
    tagName: "",
  };

  componentDidMount() {
    // document.title = "Blogs Category :: The Maetrix";
    this.props.dispatchGetBlogCategory({
      category_id: this.props.match.params.name,
      token: this.props.token,
    });
  }
  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  goBuySubscription = (tag) => {
    this.setState({
      tagName: tag,
    });
    window.$("#subsModel1").modal("show");
  };

  goLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };
  goBlogFree = (blog) => {
    this.props.dispatchLatestService({
      id: blog.id,
      category: "blog",
      token: this.props.token,
    });

    this.props.history.push(
      `/blog/${blog.category_title_url}/${blog.blog_title_url}`
    );
  };

  goBlog = (blog) => {
    this.props.dispatchLatestService({
      id: blog.id,
      category: "blog",
      token: this.props.token,
    });

    this.props.history.push(
      `/blog/${blog.category_title_url}/${blog.blog_title_url}`
    );
  };
  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    this.setState({
      blogUrl: `${window.location.origin}/blog/${url.category_title_url}/${url.blog_title_url}${qrstr}`,
    });
    window.$("#shareModel").modal("show");
  };

  render() {
    return (
      <div>
        <SharePopUpModel url={this.state.blogUrl} />
        {
          <SubscriptionCheck
            tag={this.state.tagName}
            history={this.props.history}
          />
        }
        <React.Fragment>
          {this.props?.token ? (
            <AuthHeader token={this.props.token} />
          ) : (
            <Header hitHome={this.openHeaderContentPopup} />
          )}
          <BlogBanner />
          <div className="all-blog page-height">
            <BlogCategory token={this.props.token} />
            <div className="blog-row">
              <div className="container">
                {this.props?.blogByCategoryId?.data.map(
                  (category, ind) =>
                    category?.blogs?.length > 0 && (
                      <React.Fragment key={ind}>
                        <div className="blog-row-header">
                          <h2>{category.category_name}</h2>
                        </div>
                        <div className="row">
                          {category?.blogs.map((blog, index) => (
                            <div className="col-md-4" key={index}>
                              <div className="blog-slider">
                                <div className="blog-list">
                                  <div className="item">
                                    <div className="blog-sld-box">
                                      <div className="blog-sld-img">
                                        <img alt="img" src={blog.banner_url} />
                                      </div>
                                      <div className="blog-sld-cnt">
                                        <div className="blog-sld-top">
                                          <button
                                            onClick={() => this.sharePop(blog)}
                                            type="button"
                                            className="share-blg"
                                          >
                                            <img src="/assets/images/share-gray-icon.png" />
                                          </button>
                                          <h2 className="title">
                                            {blog.blog_title}
                                          </h2>
                                        </div>
                                        <div className="blog-sld-footer">
                                          <img
                                            alt="img"
                                            src={
                                              blog.author_image !== null
                                                ? blog.author_image
                                                : "assets/images/course-slider-3.jpg"
                                            }
                                          />
                                          <h5>
                                            {blog.author_name !== null
                                              ? blog.author_name
                                              : "Richard"}
                                          </h5>
                                          <p>{blog.created_at}</p>
                                        </div>
                                        {blog.without_login === true ? (
                                          <button
                                            onClick={() =>
                                              this.goBlogFree(blog)
                                            }
                                            className="dlt-blg"
                                          >
                                            Read More
                                          </button>
                                        ) : !this.props.token ? (
                                          <button
                                            onClick={this.goLogin}
                                            className="dlt-blg"
                                          >
                                            Read More
                                          </button>
                                        ) : blog.is_subscribed == 1 ? (
                                          <button
                                            onClick={() => this.goBlog(blog)}
                                            className="dlt-blg"
                                          >
                                            Read More
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              this.goBuySubscription(
                                                blog.tag_name
                                              )
                                            }
                                            className="dlt-blg"
                                          >
                                            Read More
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    )
                )}
              </div>
            </div>
          </div>
          <Footer />
          {this.state.showLoginModel === true && <Login />}
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blogByCategoryId: state.blogReducer.blogByCategoryId,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetBlogCategory: (data) => dispatch(getBlogByCategoryId(data)),
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CategoryDetail));
//export default CategoryDetail
