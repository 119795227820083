import React, { Component } from "react";
import { BASE_URL } from "../../../helpers/Constants";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

class RatingPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stars: [],
      rating: 0,
      hovered: 0,
      selectedIcon: "★",
      deselectedIcon: "☆",
      description: "",
    };
    let outOf = props?.outOf ? props?.outOf : 5;

    for (var i = 0; i < outOf; i++) {
      this.state.stars.push(i + 1);
    }
  }

  changeRating(newRating) {
    this.setState({
      rating: newRating,
    });
  }

  submitRating = (e) => {
    e.preventDefault();
    const { rating } = this.state;
    let url = `${BASE_URL}api/rateService`;
    axios
      .post(
        url,
        {
          rating: rating,
          course_id: this.props.data.id,
        },
        {
          headers: { authorization: `Bearer ${this.props?.token}` },
        }
      )
      .then((res) => {
        window.$("#rating").modal("hide");
        this.setState({
          rating: 0,
          hovered: 0,
          description: "",
        });
        toast.success(
          res.data.message
            ? res.data.message
            : "Your Review Submit Successfully!!",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };
  render() {
    const { stars, rating, hovered, deselectedIcon, selectedIcon } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="rate-pops">
          <div className="modal fade" id="rating">
            <div className="modal-dialog" style={{ zIndex: 9999 }}>
              <div className="modal-content">
                <div className="modal-body">
                  <div className="rating-box">
                    <img
                      alt="course-thumbnail"
                      src={this.props?.data?.course_banner}
                      className="course-img"
                    />
                    <h2>{this.props?.data?.course_name}</h2>

                    <form onSubmit={this.submitRating}>
                      <div
                        className="rating"
                        style={{ fontSize: "3em", color: "#d73d29" }}
                      >
                        {stars.map((star, index) => {
                          return (
                            <span
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.changeRating(star);
                              }}
                            >
                              {rating < star
                                ? hovered < star
                                  ? deselectedIcon
                                  : selectedIcon
                                : selectedIcon}
                            </span>
                          );
                        })}
                      </div>
                      <button className="sbm">Submit</button>
                    </form>
                  </div>
                  <div className="close-btn">
                    <span data-dismiss="modal">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RatingPopUp;
