import React, { PureComponent } from "react";

import { getBanner } from "../../../store/protected/videos/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class VideoSlider extends PureComponent {
  componentDidMount() {
    this.props.dispatchVideoCategory({
      bannerCategory: "video",
      token: this.props.token,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="banner-slider">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              {this.props?.bannerList?.map((list, index) => (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                ></li>
              ))}
            </ol>
            <div className="carousel-inner">
              {this.props?.bannerList?.map((slide, ind) => (
                <React.Fragment key={ind}>
                  <div
                    className={
                      ind === 0 ? "carousel-item active" : "carousel-item"
                    }
                  >
                    {slide.link === null ? (
                      <>
                        {" "}
                        <img alt="img" src={slide?.banner_url} />{" "}
                        <div className="banner-overlay"></div>
                      </>
                    ) : (
                      <a href={slide.link}>
                        <img alt="img" src={slide?.banner_url} />
                        <div className="banner-overlay"></div>
                      </a>
                    )}
                    <div className="banner-caption">
                      {ind === 0 ? (
                        <h1>{slide.banner_heading} </h1>
                      ) : (
                        <h2>{slide.banner_heading} </h2>
                      )}
                      <p>{slide.banner_text}</p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="left-right-arrow">
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                {" "}
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                {" "}
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>{" "}
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bannerList: state.videosReducer.videoBanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchVideoCategory: (data) => dispatch(getBanner(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoSlider));
