import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  GET_MEMBER_DETAIL,
  GET_MEMBER_DETAIL_SUCCESS,
  GET_MEMBER_DETAIL_ERROR,
  GET_MEMBER_LOYALITY_DETAIL,
  GET_MEMBER_LOYALITY_DETAIL_SUCCESS,
  GET_MEMBER_LOYALITY_DETAIL_ERROR
} from "./actionType";

export const getMembers = (data) => {
  console.log("datadatadata", data);
  return {
    type: GET_MEMBERS,
    payload: data,
  };
};

export const getMembersSuccess = (data) => {
  return {
    type: GET_MEMBERS_SUCCESS,
    payload: data,
  };
};

export const getMembersError = (error) => {
  return {
    type: GET_MEMBERS_ERROR,
    payload: error,
  };
};

export const getMemberDetail = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_MEMBER_DETAIL,
    payload: data,
  };
};

export const getMemberDetailSuccess = (data) => {
  return {
    type: GET_MEMBER_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getMemberDetailError = (error) => {
  return {
    type: GET_MEMBER_DETAIL_ERROR,
    payload: error,
  };
};
export const getMemberLoyalityDetail = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_MEMBER_LOYALITY_DETAIL,
    payload: data,
  };
};

export const getMemberLoyalityDetailSuccess = (data) => {
  return {
    type: GET_MEMBER_LOYALITY_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getMemberLoyalityDetailError = (error) => {
  return {
    type: GET_MEMBER_LOYALITY_DETAIL_ERROR,
    payload: error,
  };
};
