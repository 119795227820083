import React, { PureComponent } from "react";

import Login from "../../gaust/login";
import SubscriptionCheck from "../blogs/subscriptionCheck";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class LetestBlogs extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    showSubscriptionModel: false,
    showLoginModel: false,
    tagName: "",
    blogUrl: "",
    SubscriptionName: "",
  };

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  goBlog = (blog) => {
    console.log("akshay kumar", blog);
    this.props.history.push(
      `/blog/${blog.blog_category_url}/${blog.blog_title_url}`
    );
  };

  goBuySubscription = (id) => {
    this.setState({
      tagName: id,
    });
    window.$("#subsModel1").modal("show");
  };

  goLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    this.setState(
      {
        blogUrl: `${window.location.origin}/blog/${url}`+qrstr,
      },
      () => {
        window.$("#shareModel").modal("show");
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props?.BlogList?.length > 0 && (
          <>
            {this.state.blogUrl !== "" && (
              <SharePopUpModel url={this.state.blogUrl} />
            )}

            <SubscriptionCheck
              tag={this.state.tagName}
              history={this.props.history}
              name={this.state.SubscriptionName}
            />

            <div className="blog-row">
              <div className="container">
                <div className="blog-row-header">
                  <h2>Latest Blogs</h2>
                  <Link to="/blogs" className="right-link">
                    View All{" "}
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>

                {this.props?.BlogList?.length > 0 && (
                  <React.Fragment>
                    <OwlCarousel
                      className="owl-theme"
                      margin={10}
                      items={3}
                      responsive={this.state.responsive}
                    >
                      {this.props?.BlogList?.map((blog, index) => (
                        <div className="blog-slider" key={index}>
                          <div className="blog-list">
                            <div className="item">
                              <div className="blog-sld-box">
                                <div className="blog-sld-img">
                                  <img alt="Banner" src={blog.banner_url} />
                                </div>
                                <div className="blog-sld-cnt">
                                  <div className="blog-sld-top">
                                    <h2 className="title">{blog.blog_title}</h2>
                                    <div className="blogDesc">
                                      {ReactHtmlParser(
                                        blog.preview_description
                                      )}
                                    </div>
                                  </div>
                                  <div className="blog-sld-footer">
                                    <img
                                      alt="img"
                                      src={
                                        blog.author_image !== null
                                          ? blog.author_image
                                          : "assets/images/course-slider-3.jpg"
                                      }
                                    />
                                    <h5>
                                      {blog.author_name !== null
                                        ? blog.author_name
                                        : "Richard"}
                                    </h5>
                                    <p>{blog.created_at}</p>
                                  </div>
                                  {blog.without_login === true ? (
                                    <button
                                      onClick={() =>
                                        this.goBlog(blog.blog_title_url)
                                      }
                                      className="dlt-blg"
                                    >
                                      Read More
                                    </button>
                                  ) : !this.props.token ? (
                                    <button
                                      onClick={this.goLogin}
                                      className="dlt-blg"
                                    >
                                      Read More
                                    </button>
                                  ) : blog.is_subscribed == 1 ? (
                                    <button
                                      onClick={() => this.goBlog(blog)}
                                      className="dlt-blg"
                                    >
                                      Read More
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.goBuySubscription(blog.tag_name)
                                      }
                                      className="dlt-blg"
                                    >
                                      Read More
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </React.Fragment>
                )}
              </div>
            </div>
            {this.state.showLoginModel === true && <Login />}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    BlogList: state?.dashboardReducer?.services?.blogs,
    user: state.authReducer?.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LetestBlogs));
