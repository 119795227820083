import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class CourseResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props?.courseList?.length > 0 && (
          <div className="course-row page-height">
            <div className="container">
              <div className="course-row-header">
                <h2>Courses Result</h2>
              </div>
              <div className="row">
                {this.props?.courseList?.map((course, id) => (
                  <div className="col-lg-3 col-md-4 col-sm-6" key={id}>
                    <div className="course-sld-box">
                      <div className="course-sld-img">
                        <img
                          alt="Course-Thumbnail"
                          src={course.thumbnail_url}
                        />
                      </div>
                      <Link
                        to={`/course/${course.course_category_url}/${course.id}/${course.course_title_url}`}
                      >
                        <div className="course-sld-cnt">
                          <h4>
                            {course?.course_name?.length <= 50
                              ? course?.course_name
                              : course?.course_name?.substring(0, 50) + "..."}
                          </h4>
                          <h5>Total Chapters-{course.no_of_chapters}</h5>
                          <h6>
                            <span>
                              {course.course_type == 0 &&
                              (course.is_subscribed === true ||
                                course.is_subscribed === false)
                                ? "Free"
                                : course.course_type == 1 &&
                                  course.is_subscribed === true
                                ? "View Course"
                                : `£ ${course.course_fee}`}
                            </span>
                          </h6>
                          <p>
                            <span>
                              {course.rating == 1 ? (
                                <>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : course.rating == 2 ? (
                                <>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </>
                              ) : course.rating == 3 ? (
                                <>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </>
                              ) : course.rating == 4 ? (
                                <>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </>
                              ) : (
                                <>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </>
                              )}
                            </span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseResult));
