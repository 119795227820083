import {
  SET_CART_ITEM,
  SET_CART_ITEM_SUCCESS,
  SET_CART_ITEM_ERROR,
  GET_CART_ITEM,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_ERROR,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_ERROR,
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_ERROR,
} from "./actionTypes";

export const setCartItem = (data) => {
  //console.log('datadatadata', data)
  return {
    type: SET_CART_ITEM,
    payload: data,
  };
};

export const setCartItemSuccess = (data) => {
  return {
    type: SET_CART_ITEM_SUCCESS,
    payload: data,
  };
};

export const setCartItemError = (error) => {
  return {
    type: SET_CART_ITEM_ERROR,
    payload: error,
  };
};

export const getCartItem = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_CART_ITEM,
    payload: data,
  };
};

export const getCartItemSuccess = (data) => {
  return {
    type: GET_CART_ITEM_SUCCESS,
    payload: data,
  };
};

export const getCartItemError = (error) => {
  return {
    type: GET_CART_ITEM_ERROR,
    payload: error,
  };
};

export const deleteCartItem = (data) => {
  //console.log('datadatadata', data)
  return {
    type: DELETE_CART_ITEM,
    payload: data,
  };
};

export const deleteCartItemSuccess = (data) => {
  return {
    type: DELETE_CART_ITEM_SUCCESS,
    payload: data,
  };
};

export const deleteCartItemError = (error) => {
  return {
    type: DELETE_CART_ITEM_ERROR,
    payload: error,
  };
};

export const checkoutPayment = (data) => {
  //console.log('datadatadata', data)
  return {
    type: CHECKOUT_PAYMENT,
    payload: data,
  };
};

export const checkoutPaymentSuccess = (data) => {
  return {
    type: CHECKOUT_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const checkoutPaymentError = (error) => {
  return {
    type: CHECKOUT_PAYMENT_ERROR,
    payload: error,
  };
};
