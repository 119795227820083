export const SET_CART_ITEM = "SET_CART_ITEM";
export const SET_CART_ITEM_SUCCESS = "SET_CART_ITEM_SUCCESS";
export const SET_CART_ITEM_ERROR = "SET_CART_ITEM_ERROR";

export const GET_CART_ITEM = "GET_CART_ITEM";
export const GET_CART_ITEM_SUCCESS = "GET_CART_ITEM_SUCCESS";
export const GET_CART_ITEM_ERROR = "GET_CART_ITEM_ERROR";

export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_ERROR = "DELETE_CART_ITEM_ERROR";

export const CHECKOUT_PAYMENT = "CHECKOUT_PAYMENT";
export const CHECKOUT_PAYMENT_SUCCESS = "CHECKOUT_PAYMENT_SUCCESS";
export const CHECKOUT_PAYMENT_ERROR = "CHECKOUT_PAYMENT_ERROR";
