import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import CourseCategory from "./courseCategory";
import Login from "../../../components/gaust/login";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { ToastContainer, toast } from "react-toastify";

class CoursesComponent extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    showLoginModel: false,
  };

  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
    // = "Courses :: Maetrix";

    this.props.dispatchCourse({
      token: this.props.token,
    });
    if (this.props.token) {
      if (localStorage.getItem("courses")) {
        this.setState(
          {
            ...this.state,
            localCart:
              localStorage.getItem("courses").length > 0
                ? JSON.parse(localStorage.getItem("courses")).map((cart) => {
                  return {
                    service_id: cart.id,
                    service_category: cart.service_category,
                  };
                })
                : [],
          },
          () => {
            this.props.dispatchAddToCart({
              token: this.props.token,
              data: this.state.localCart,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.getCourses != this.props?.getCourses &&
      this.props?.getCourses != null
    ) {
      this.setState({
        ...this.state,
        getCourseList: this.props?.getCourses?.data,
      });
    }
    if (
      prevProps?.isFavourite != this.props.isFavourite &&
      this.props.isFavourite != null
    ) {
      if (this.props.token) {
        this.props.dispatchCourse({
          token: this.props.token,
        });
        // window.location.reload();
      } else {
        this.props.dispatchCourse({});
      }
    }
  }

  isFavourite = (id) => {
    console.log(this.props);
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <div className="course-main page-height">
        <ToastContainer />
        <div className="container">
          <CourseCategory />
          {this.state.getCourseList &&
            this.state.getCourseList.map((list, index) => (
              <div className="all-course-slider" key={index}>
                {list?.courses?.length > 0 && (
                  <div>
                    <h2>{list.category_name}</h2>

                    <Link
                      to={`/course/${list.category_title_url}`}
                      className="right-link1"
                      style={{
                        float: "right",
                        textDecoration: "none",
                        marginTop: "-30px",
                        color: "#585858",
                      }}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>

                    {/* <a
                      href={`/course/${list.category_title_url}`}
                      className="right-link1"
                      style={{
                        float: "right",
                        textDecoration: "none",
                        marginTop: "-30px",
                        color: "#585858",
                      }}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </a> */}
                    <OwlCarousel
                      className="owl-theme"
                      margin={10}
                      items={4}
                      nav
                      responsive={this.state.responsive}
                    >
                      {list.courses.map((course, id) => (
                        <div className="item" key={id + course.id}>
                          <div className="course-sld-box">
                            <div className="course-sld-img">
                              <img alt="thubnail" src={course.thumbnail_url} />
                              {course.is_favourite}
                              <button
                                className={
                                  course.is_favourite ? "fav-btn1" : "fav-btn"
                                }
                                onClick={() => this.isFavourite(course?.id)}
                              >
                                <i
                                  className="fa fa-heart-o"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>

                            {this.props.token ? (
                              <Link
                                to={`/course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                              >
                                <div className="course-sld-cnt">
                                  <h3>
                                    {course?.course_name?.length <= 50
                                      ? course?.course_name
                                      : course?.course_name?.substring(0, 50) +
                                      "..."}
                                  </h3>
                                  <h4>
                                    Total Chapters-{course.no_of_chapters}
                                  </h4>
                                  <h5>
                                    <span>
                                      {/* {course.course_type == 0 &&
                                      (course.is_subscribed === true ||
                                        course.is_subscribed === false)
                                        ? "Free"
                                        : course.course_type == 1 &&
                                          course.is_subscribed === true
                                        ? "View Course"
                                        : `£ ${course.course_fee}`} */}

                                      {course.course_type === 0 &&
                                        (course.is_subscribed === true ||
                                          course.is_subscribed === false) &&
                                        (course.is_independent === true ||
                                          course.is_independent === false)
                                        ? "Free"
                                        : course.course_type === 1 &&
                                          course.is_independent === false &&
                                          course.is_subscribed === false
                                          ? `£ ${course.monthly_fee}`
                                          : course.course_type === 1 &&
                                            course.is_independent === true &&
                                            course.is_subscribed === false
                                            ? `£ ${course.course_fee}`
                                            : "View Course"}
                                    </span>
                                  </h5>
                                  <p>
                                    <span>
                                      {course.rating === 1 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                        </>
                                      ) : course.rating === 2 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : course.rating === 3 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : course.rating === 4 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                              >
                                <div className="course-sld-cnt">
                                  <h3>
                                    {course?.course_name?.length <= 50
                                      ? course?.course_name
                                      : course?.course_name?.substring(0, 50) +
                                      "..."}
                                  </h3>
                                  <h4>
                                    Total Chapters-{course.no_of_chapters}
                                  </h4>
                                  <h5>
                                    <span>
                                      {/* {course.course_type === 0 &&
                                      (course.is_subscribed === true ||
                                        course.is_subscribed === false)
                                        ? "Free"
                                        : course.course_type === 1
                                        ? "View Course"
                                        : ""} */}

                                      {course.course_type === 0 &&
                                        (course.is_subscribed === true ||
                                          course.is_subscribed === false) &&
                                        (course.is_independent === true ||
                                          course.is_independent === false)
                                        ? "Free"
                                        : course.course_type === 1 &&
                                          course.is_independent === false &&
                                          course.is_subscribed === false
                                          ? `£ ${course.monthly_fee}`
                                          : course.course_type === 1 &&
                                            course.is_independent === true &&
                                            course.is_subscribed === false
                                            ? `£ ${course.course_fee}`
                                            : "View Course"}
                                    </span>
                                  </h5>
                                  <p>
                                    <span>
                                      {course.rating === 1 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                        </>
                                      ) : course.rating === 2 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : course.rating === 3 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : course.rating === 4 ? (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star-o "
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      ) : (
                                        <>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                )}
              </div>
            ))}
        </div>
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCourses: state.coursesReducer.courses,
    isFavourite: state?.coursesReducer?.isFavourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoursesComponent));
