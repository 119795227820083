import {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	REGISTER,
	REGISTER_SUCCESS,
	REGISTER_ERROR,
	LOGOUT,
	LOGOUT_SUCCESS,
	LOGOUT_ERROR,
	USER_UPDATE,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_ERROR,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_ERROR,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	NEW_USER_DATA,
} from "./actionTypes"

export const loginUser = (data) => {
	return {
		type: LOGIN,
		payload: data,
	}
}

export const loginSuccess = (data) => {
	return {
		type: LOGIN_SUCCESS,
		payload: data,
	}
}

export const loginError = (data) => {
	return {
		type: LOGIN_ERROR,
		payload: data,
	}
}

export const registerUser = (data) => {
	return {
		type: REGISTER,
		payload: data,
	}
}

export const registerUserSuccess = (data) => {
	return {
		type: REGISTER_SUCCESS,
		payload: data,
	}
}

export const registerUserError = (data) => {
	return {
		type: REGISTER_ERROR,
		payload: data,
	}
}

export const logout = (data) => {
	return {
		type: LOGOUT,
		payload: data,
	}
}

export const logoutSuccess = (data) => {
	return {
		type: LOGOUT_SUCCESS,
		payload: data,
	}
}

export const logoutError = (data) => {
	return {
		type: LOGOUT_ERROR,
		payload: data,
	}
}

export const userProfileUpdate = (data) => {
	return {
		type: USER_UPDATE,
		payload: data,
	}
}

export const userProfileUpdateSuccess = (data) => {
	return {
		type: USER_UPDATE_SUCCESS,
		payload: data,
	}
}

export const userProfileUpdateError = (error) => {
	return {
		type: USER_UPDATE_ERROR,
		payload: error,
	}
}

export const forgotPassword = (data) => {
	return {
		type: FORGOT_PASSWORD,
		payload: data,
	}
}

export const forgotPasswordSuccess = (data) => {
	return {
		type: FORGOT_PASSWORD_SUCCESS,
		payload: data,
	}
}

export const forgotPasswordError = (data) => {
	return {
		type: FORGOT_PASSWORD_ERROR,
		payload: data,
	}
}

export const resetPassword = (data) => {
	return {
		type: RESET_PASSWORD,
		payload: data,
	}
}

export const resetPasswordSuccess = (data) => {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: data,
	}
}

export const resetPasswordError = (data) => {
	return {
		type: RESET_PASSWORD_ERROR,
		payload: data,
	}
}

export const newUserData = (data) => {
	return {
		type: NEW_USER_DATA,
		payload: data,
	}
}
