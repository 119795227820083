import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class EmbededPop extends PureComponent {
  
  render() {
    {console.log(this?.props)}
    return (
      <div className="embade-pops">
        <div className="modal fade" id="embededPupUp">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="embd-cnt">
                  <div className="pod-cnt">
                    <img alt="img" src={this.props?.data?.banner_url} />
                    <h3>{this?.props?.data?.podcast_title}</h3>
                  </div>
                  <div className="embd-code">
                    <p>
                      {`<audio controls>
                                             <source src=${this.props?.data?.podcast_url} type="audio/mpeg">
                                             </audio>`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getBlog: state.blogReducer.blogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmbededPop));
