import React, { Component } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Login from "../../../components/gaust/login";
import { BASE_URL } from "../../../helpers/Constants";
import Footer from "../../../layout/auth/Afooter";
import CourseResult from "./courseResult";
import VideosResult from "./videoResult";
import PodcastResult from "./podcastResult";
import BlogResult from "./blogResult";

import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginModel: false,
      searchResult: null,
      param: "",
    };
  }

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  async getResult(myParam) {
    const url = `${BASE_URL}api/globalSearch?search_text=${myParam}&token=${this.props.token}`;
    const response = await axios.get(url, {
      headers: {
        authorization: "Bearer" + this.props.token,
      },
    });

    this.setState({
      searchResult: response.data.result,
    });
  }
  componentDidMount() {
    // document.title = "Global Search :: The Maetrix";
    const myParam = this.props?.match?.params?.text;
    this.getResult(myParam);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.text != this.props?.match?.params?.text &&
      this.props?.match?.params?.text != null
    ) {
      this.getResult(this.props?.match?.params?.text);
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.props.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}

        {this.state?.searchResult && (
          <React.Fragment>
            <CourseResult courseList={this.state?.searchResult?.courses} />
            <VideosResult videoList={this.state?.searchResult?.videos} />
            <PodcastResult
              PodcastList={this.state?.searchResult?.podcasts}
              token={this.props.token}
            />
            <BlogResult
              BlogList={this.state?.searchResult?.blogs}
              token={this.props.token}
            />
          </React.Fragment>
        )}

        {this.state.showLoginModel === true && <Login />}
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
