import React, { Component } from "react";
import { setCartItem } from "../../../store/protected/cart/action";
import Register from "../login/register";
import ForgotPassword from "../login/forgotPassword";
import { loginUser } from "../../../store/actions";

import { ToastOptions } from "../../../helpers/Constants";
import { ToastContainer, toast } from "react-toastify";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import firebase from "firebase";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.getError != this.props.getError &&
      this.props.getError !== null
    ) {
      toast.error(
        this.props.getError ? this.props.getError : "Unable to Login",
        ToastOptions
      );
    }
  }

  componentWillUnmount() {
    window.$("#login-model").modal("hide");
  }

  handleUserInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  Toggle = () => {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      temp.type = "text";
    } else {
      temp.type = "password";
    }
  };

  handleLoginForm = async (event) => {
    event.preventDefault();
    let errors = {};

    if (!this.state.email) {
      errors.name = "Email Is Required";
    }

    if (!this.state.password) {
      errors.password = "Password Is Required";
    }

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };

      let ref = await firebase
        .auth()
        .signInWithEmailAndPassword(userData.email, userData.password)
        .catch((ex) => {
          /* handle exception */
        });
      let user = ref?.user;

      if (user) {
        let starCountRef = firebase.database().ref("/users/" + user.uid);
        starCountRef.on("value", async (snapshot) => {
          var user = firebase.auth().currentUser;

          if (user != null) {
            await firebase
              .database()
              .ref("/active/" + user.uid)
              .set({
                name: snapshot?.val()?.userName,
                profilePicture: snapshot?.val()?.profilePicture,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
              })
              .then(() => {
                console.log("Active user created");
              });
          }
        });
      }

      this.props.dispatchLogin(
        {
          email: userData.email,
          password: userData.password,
          history: this.props.history,
          url: this.props?.history?.location?.pathname,
          device_token: localStorage.getItem("device_token"),
        },
        () => {
          window.$("#login-model").modal("hide");
        }
      );
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <div className="login-popup">
          <div className="modal fade" id="login-model">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="login-cnt">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="login-left-bg">
                          {/* <div className="login-left-overlay"></div> */}
                          {/* <div className="login-left-cnt">
                            <img
                              alt="img"
                              src="/assets/new_layout/img/logo2.png"
                            ></img>
                            <h1>Welcome to Aesthetic Entrepreneurs</h1>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-login-sec">
                          <div className="login-form">
                            <h2>Welcome to MAETRIX by RCS</h2>
                            <h3>Please Login</h3>
                            <form onSubmit={this.handleLoginForm}>
                              <div className="form-group">
                                <input
                                  type="email"
                                  placeholder="Email"
                                  id="email"
                                  name="email"
                                  onChange={this.handleUserInput}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  onChange={this.handleUserInput}
                                  required
                                />
                                <span className="show-pass">
                                  <i
                                    id="pass-status"
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    onClick={this.Toggle}
                                  ></i>
                                </span>
                              </div>
                              <div className="form-group">
                                <ul>
                                  <li>
                                    <div className="form-check">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="exampleCheck1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="exampleCheck1"
                                      >
                                        Remember me
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <button type="button" id="forgotsec">
                                      Forgot Password
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div className="sbm-sec">
                                <button type="submit">Login</button>
                              </div>
                            </form>
                            <button type="button" id="regSec">
                              Create a new account
                            </button>
                            <br />
                            <Link to="/terms-and-conditions">
                              <p className="privacyPara">Terms & Conditions</p>
                            </Link>
                          </div>

                          {<Register />}

                          {<ForgotPassword />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    getError: state.authReducer.error,
    getLoginStatus: state?.authReducer?.user,
    currentLoginStatus: state?.authReducer?.login_Status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogin: (data) => dispatch(loginUser(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
