import React, {
  Component,
  PureComponent,
  useRef,
  useState,
  useEffect,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import EntrepreneurshipBox from "./EntrepreneurshipBox";
import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { ToastContainer, toast } from "react-toastify";

//const PopularTabs = (props) => {
class NewPopularTabs extends Component {
  //const [selectedTab, setSelectedTab] = useState(1);

  state = {
    settings: {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    showLoginModel: false,
    selectedTab: 1,
  };

  onSelectedTab = (value) => {
    //setSelectedTab(value)
    this.setState({
      ...this.state,
      selectedTab: value,
    });
  };

  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
    // = "Courses :: Maetrix";

    // this.props.dispatchCourse({
    //   token: this.props.token,
    // });
    if (this.props.token) {
      if (localStorage.getItem("courses")) {
        this.setState(
          {
            ...this.state,
            localCart:
              localStorage.getItem("courses").length > 0
                ? JSON.parse(localStorage.getItem("courses")).map((cart) => {
                    return {
                      service_id: cart.id,
                      service_category: cart.service_category,
                    };
                  })
                : [],
          },
          () => {
            this.props.dispatchAddToCart({
              token: this.props.token,
              data: this.state.localCart,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps?.getCourses != this.props?.getCourses &&
    //   this.props?.getCourses != null
    // ) {
    //   this.setState({
    //     ...this.state,
    //     getCourseList: this.props?.getCourses?.data,
    //   });
    // }
    // if (
    //   prevProps?.isFavourite != this.props.isFavourite &&
    //   this.props.isFavourite != null
    // ) {
    //   if (this.props.token) {
    //     this.props.dispatchCourse({
    //       token: this.props.token,
    //     });
    //   } else {
    //     this.props.dispatchCourse({});
    //   }
    // }
  }

  isFavourite = (id) => {
    console.log(this.props);
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    console.log("111111", this.props.categoryData);
    return (
      <div>
        <section className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <h2>
                  Podcasts to get you started{" "}
                  {/*<span> <a class="view-all-btn" href=""><i class="fa fa-long-arrow-left" aria-hidden="true" style={{ marginRight: "10px" }}></i> Back  </a></span>*/}
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    onClick={() => this.onSelectedTab(1)}
                    className={
                      this.state.selectedTab == "1"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected={
                      this.state.selectedTab == "1" ? "true" : "false"
                    }
                  >
                    Most Popular
                  </button>
                  <button
                    onClick={() => this.onSelectedTab(2)}
                    className={
                      this.state.selectedTab == "2"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected={
                      this.state.selectedTab == "2" ? "true" : "false"
                    }
                  >
                    New
                  </button>
                  <button
                    onClick={() => this.onSelectedTab(3)}
                    className={
                      this.state.selectedTab == "3"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected={
                      this.state.selectedTab == "3" ? "true" : "false"
                    }
                  >
                    Recommended
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                {this.state.selectedTab && this.state.selectedTab == "3" ? (
                  <div
                    className="tab-pane fade show active"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    {this.props.categoryData &&
                      this.props.categoryData &&
                      this.props.categoryData.map((category, index) => (
                        <div>
                          {index == 0 && (
                            <div className="container mt-4 mb-5">
                              <div className="card-slider-new">
                                <EntrepreneurshipBox
                                  categoryData={category?.podcasts}
                                  token={this.props.token}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ) : this.state.selectedTab && this.state.selectedTab == "2" ? (
                  <div
                    className="tab-pane fade show active"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {this.props.categoryData &&
                      this.props.categoryData &&
                      this.props.categoryData.map((category, index) => (
                        <div>
                          {index == 0 && (
                            <div className="container mt-4 mb-5">
                              <div className="card-slider-new">
                                <EntrepreneurshipBox
                                  categoryData={category?.podcasts}
                                  token={this.props.token}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {this.props.categoryData &&
                      this.props.categoryData &&
                      this.props.categoryData.map((category, index) => (
                        <div>
                          {index == 0 && (
                            <div className="container mt-4 mb-5">
                              <div className="card-slider-new">
                                <EntrepreneurshipBox
                                  categoryData={category?.podcasts}
                                  token={this.props.token}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    getCourses: state.coursesReducer.courses,
    isFavourite: state?.coursesReducer?.isFavourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewPopularTabs));
