import React, { PureComponent } from "react";

import Login from "../../gaust/login";
import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Header from "../../../layout/gaust/Header";
import PodcastBanner from "./PodcastBanner";
import PodcastCategory from "./PodcastCategory";
import PodcastSubscriptionCheck from "./PodcastSubscriptionCheck";
import EntrepreneurshipBoxCategory from "./EntrepreneurshipBoxCategory";
import EmbededPop from "./embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactHtmlParser from "react-html-parser";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { isLatestSuccess } from "../../../store/protected/courses/action";

class PodcastCategoryDetail extends PureComponent {
  state = {
    showLoginModel: false,
    playing: false,
    podcastUrl: "",
    podcastData: null,
    SubscriptionName: "",
  };
  responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  podcastCondition = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  buySubscription = () => {
    window.$("#subsModel").modal("show");
  };

  onPlay = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  onPlayLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  goToLogin = () => {
    console.log("not login & download");
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  download = () => {
    console.log("login & subscribed & download");
  };

  notSubscribed = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  embeded = (data) => {
    this.setState({
      podcastData: data,
    });
    window.$("#embededPupUp").modal("show");
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.category_title_url +
      "/" +
      url?.podcast_title_url+qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };
  playAudio = (service_id) => {
    console.log("play ausio", service_id);
    this.props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: this.props.token,
    });
  };
  componentDidMount() {
    // document.title = "Podcast Category Detail :: The Maetrix";
  }
  render() {
    return (
      <div>
        <EmbededPop data={this.state.podcastData} />
        <SharePopUpModel url={this.state.podcastUrl} />
        <PodcastSubscriptionCheck
          token={this.props.token}
          history={this.props.history}
          name={this.state?.SubscriptionName}
        />
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <PodcastBanner />
        <div className="all-podcast page-height">
          <PodcastCategory />

          <div className="podcast-row">
            <div className="container">
              {this.props?.podcastListByCategory?.data.map(
                (category, ind) =>
                  category?.podcasts?.length > 0 && (
                    <React.Fragment key={ind}>
                      <div className="podcast-row-header ">
                        <h2>{category.category_name}</h2>
                      </div>
                      <div className="row">
                        <EntrepreneurshipBoxCategory categoryData={category?.podcasts} token={this.props.token} />
                        {/* {category?.podcasts.map((podcast, index) => (
                          <div className="col-md-4" key={index}>
                            <div className="podcast-list">
                              <div className="item">
                                <div className="music-box">
                                  <img
                                    alt="img"
                                    className="audio-img"
                                    src={podcast?.banner_url}
                                  />
                                  {!this.props.token ? (
                                    <div
                                      onClick={this.podcastCondition}
                                      className="demoAudio"
                                    >
                                      <AudioPlayer
                                        src={podcast.podcast_url}
                                        playing={false}
                                        defaultDuration={podcast.duration}
                                        defaultCurrentTime="00:00"
                                        onPlayError={() => this.onPlayLogin()}
                                      />
                                    </div>
                                  ) : podcast.is_subscribed == 1 ? (
                                    <AudioPlayer
                                      src={podcast.podcast_url}
                                      onPlay={() => this.playAudio(podcast.id)}
                                      playing={false}
                                    />
                                  ) : (
                                    <div
                                      onClick={this.onPlay}
                                      className="demoAudio"
                                    >
                                      <AudioPlayer
                                        src={podcast.podcast_url}
                                        playing={false}
                                        defaultDuration={podcast.duration}
                                        defaultCurrentTime="00:00"
                                        onPlayError={() =>
                                          this.onPlay(podcast.tag_name)
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className="audio-cnt">
                                    <h2>{podcast?.podcast_title}</h2>

                                    <div className="hide-audio-cnt">
                                      {ReactHtmlParser(podcast?.description)}
                                    </div>
                                    <p className="btm-brd-hide">
                                      <span></span>
                                    </p>
                                  </div>
                                  <div className="audio-action">
                                    {!this.props.token ? (
                                      <button
                                        onClick={() => this.sharePop(podcast)}
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/download-icon.png"
                                        />
                                      </button>
                                    ) : podcast.is_subscribed == 1 ? (
                                      <a href={podcast.podcast_url} download>
                                        <button>
                                          <img
                                            alt="icon"
                                            src="/assets/images/download-icon.png"
                                          />
                                        </button>
                                      </a>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.notSubscribed(podcast.tag_name)
                                        }
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/download-icon.png"
                                        />
                                      </button>
                                    )}

                                    {!this.props.token ? (
                                      <button onClick={this.goToLogin}>
                                        <img
                                          alt="icon"
                                          src="/assets/images/share-icon.png"
                                        />
                                      </button>
                                    ) : podcast.is_subscribed == 1 ? (
                                      <button
                                        onClick={() => this.sharePop(podcast)}
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/share-icon.png"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => this.sharePop(podcast)}
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/share-icon.png"
                                        />
                                      </button>
                                    )}

                                    {!this.props.token ? (
                                      <button onClick={this.goToLogin}>
                                        <img
                                          alt="icon"
                                          src="/assets/images/coding-icon.png"
                                        />
                                      </button>
                                    ) : podcast.is_subscribed == 1 ? (
                                      <button
                                        onClick={() => this.embeded(podcast)}
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/coding-icon.png"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.notSubscribed(podcast.tag_name)
                                        }
                                      >
                                        <img
                                          alt="icon"
                                          src="/assets/images/coding-icon.png"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))} */}
                      </div>
                    </React.Fragment>
                  )
              )}
            </div>
          </div>
        </div>
        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    podcastListByCategory: state.podcastReducer?.podcastListByCategory,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PodcastCategoryDetail));
