import {
  GET_SUBSCRIPTIONS_LIST,
  GET_SUBSCRIPTIONS_LIST_SUCCESS,
  GET_SUBSCRIPTIONS_LIST_ERROR,
  SUBSCRIPTIONS_PAYMENT,
  SUBSCRIPTIONS_PAYMENT_SUCCESS,
  SUBSCRIPTIONS_PAYMENT_ERROR,
  ITEM_CHECKOUT,
  ITEM_CHECKOUT_SUCCESS,
  ITEM_CHECKOUT_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: null,
  subscriptionsList: [],
  payment_done: null,
  checkoutResponse: null,
};

const subscriptionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_LIST:
      state = {
        error: null,
        loading: true,
        subscriptionsList: [],
      };
      break;
    case GET_SUBSCRIPTIONS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        subscriptionsList: action.payload,
      };
      break;
    case GET_SUBSCRIPTIONS_LIST_ERROR:
      state = {
        ...state,
        loading: false,
        subscriptionsList: [],
        error: action.payload,
      };
      break;
    case SUBSCRIPTIONS_PAYMENT:
      state = {
        error: null,
        loading: true,
        payment_done: false,
      };
      break;
    case SUBSCRIPTIONS_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        payment_done: true,
      };
      break;
    case SUBSCRIPTIONS_PAYMENT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        payment_done: false,
      };
      break;
    case ITEM_CHECKOUT:
      state = {
        ...state,
        error: null,
        checkoutResponse: null,
      };
    case ITEM_CHECKOUT_SUCCESS:
      state = {
        ...state,
        checkoutResponse: action.payload,
        error: null,
      };
      break;
    case ITEM_CHECKOUT_ERROR:
      state = {
        ...state,
        checkoutResponse: null,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default subscriptionsListReducer;
