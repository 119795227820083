import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import { v4 } from "uuid";
import ReportModel from "./ReportModel";
import axios from "axios";
import { BASE_URL } from "../../../helpers/Constants";

export const GroupChat = ({ userDetail, token }) => {
  const [text, setText] = useState("");
  const [message, setMessage] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeUserFilterList, setActiveUserFilterList] = useState([]);
  const [activeArray, setActiveArray] = useState([]);
  const chatBoxRef = useRef();
  const [userInformation, setUserInformation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uId, setUID] = useState(null);
  const [userList, setUserList] = useState(false);
  const [filterListUser, setFilterListUser] = useState([]);
  const [allUserList, setAllUserList] = useState([]);

  const [tagProfile, setTagProfile] = useState([]);
  const [tagUserProfile, setTagUserProfile] = useState("");

  const messsageInputRef = useRef();

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    if (Number.isNaN(a.getDate())) return "";
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();

    var time = date + " " + month + " " + year;
    return time;
  }

  function time(x) {
    var aa = new Date(x);
    var hour = aa.getHours();
    if (hour.toString().length < 2) hour = "0" + hour;

    var min = aa.getMinutes();
    if (min.toString().length < 2) min = "0" + min;
    var times = hour + ":" + min;
    return times;
  }

  const getEmoji = (e) => {
    e.preventDefault();
    if (e.target.id === "imojidiv") {
    } else {
      var htmText = document.getElementById(e.target.id).innerHTML;
      setText(text.concat(htmText));
    }
  };
  const len = message.length;
  useEffect(() => {
    chatBoxRef.current &&
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight);
  }, [len]);

  useEffect(() => {
    const callBack = () => {
      firebase
        .database()
        .ref("active/" + sessionStorage.getItem("user"))
        .remove();
    };
    window.addEventListener("beforeunload", callBack);
  });

  useEffect(() => {
    userListGet();
  }, []);

  useEffect(() => {
    setFilterListUser(userInformation);
  }, [userInformation]);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then((token) => {
          return messaging.getToken();
        })
        .then((device_token) => {
          axios
            .post(
              `${BASE_URL}api/updateDeviceToken`,
              {
                device_token: device_token,
              },
              {
                headers: {
                  authorization: "Bearer " + token,
                },
              }
            )
            .catch((err) => {
              console.log("Error in update device token  ", err);
            });
        })
        .catch((error) => {
          console.log("Notification disable, please allow for update");
          // alert('Please Allow Notification To Receive The Updates')
        });
    }

    var starCountRef = firebase.database().ref("users/");
    starCountRef.orderByChild("userName").on("value", function (snapshot) {
      let uTemp = [];
      snapshot.forEach((childshot) => {
        var childdata = {
          id: childshot.key,
          data: childshot.val(),
        };
        uTemp.push(childdata);
      });
      setUserInformation(uTemp);
      // console.log("UserInformation_utemp", uTemp);
    });

    firebase.auth().onAuthStateChanged(function (user) {
      // console.log("userAuth", user);

      if (user != null) {
        sessionStorage.setItem("user", user.uid);
        // console.log("user-uid:", user.uid);
        var starCountRef = firebase
          .database()
          .ref("messeges/")
          .orderByChild("timestamp/");

        setLoading(true);
        starCountRef.on("value", function (snapshot) {
          let temp = [];
          snapshot.forEach((childshot, index) => {
            var childdata = {
              id: childshot.key,
              data: childshot.val(),
              timestamp: childshot.val().timestamp,
            };
            temp.push(childdata);
          });

          const newTemp =
            temp &&
            temp.map((item, index) => {
              const el = { ...item };
              // console.log("object_element", el);
              if (index > 0) {
                const currentDate = new Date(el.timestamp).getDate();
                const prevDate = new Date(temp[index - 1].timestamp).getDate();
                if (currentDate !== prevDate) el.showDateAbove = true;
              } else {
                el.showDateAbove = true;
              }
              return el;
            });
          setLoading(false);
          // console.log(newTemp);
          setMessage(newTemp);
        });

        let userCountRef = firebase.database().ref("/users/" + user.uid);
        userCountRef.on("value", function (snapshot) {
          var user = firebase.auth().currentUser;
          // console.log("userfire", user);
          // console.log("snapshot", snapshot);
          // console.log("snapshot val", snapshot?.val());

          if (user != null) {
            var activeRef = firebase.database().ref("active/" + user.uid);
            activeRef.once("value").then((aSnapshot) => {
              const exists = aSnapshot.exists();
              if (exists) {
                firebase
                  .database()
                  .ref("active/" + user.uid)
                  .set({
                    name: snapshot?.val()?.userName,
                    profilePicture: snapshot?.val()?.profilePicture,
                    email: snapshot?.val()?.userEmail,
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                  });
              } else {
                console.log("Reference does not exist");
              }
            });
          }
        });

        var starRef = firebase
          .database()
          .ref("active/")
          .orderByChild("/timestamp/");

        starRef.on("value", function (snap) {
          const x = snap.val();
          if (x) {
            let active = Object.keys(x);
            let demo = [];
            let userListFilter = [];
            snap.forEach((cs) => {
              var cd = {
                data: cs.val(),
              };
              demo.push(cd);
              if (cd?.data?.name && cd?.data?.name != "") {
                userListFilter.push(cd.data.name);
              }
              // if (cd?.data?.email && cd?.data?.email != '') {
              //   userListFilter.push(cd.data.email);
              // }
            });
            setActiveArray(active);
            // setActiveUsers(demo);
            // setActiveUserFilterList(userListFilter);
          }
        });
      } else {
        console.log("not auth user");
        console.log("user else", user);
      }
    });

    var allEmoji = "";
    for (var i = 128512; i <= 128580; i++) {
      allEmoji += `<a id="${i}" key={i} >&#${i};</a>`;
    }
    document.getElementById("imojidiv").innerHTML = allEmoji;
  }, [token]);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;

    if (value.match(/@$/gim) || value.match(/@\S+$/gim)) {
      let val = value.match(/@\S+$/gim);
      // console.log(val)

      setUserList(true);

      let temp;
      if (val != null) temp = val[0].match(/[#$%_+\-)^<>*(!a-z0-9]+/gim);

      setFilterListUser(
        userInformation.filter((user) =>
          user.data.userName
            ?.toLowerCase()
            ?.includes(temp != null ? temp[0].toLowerCase() : "")
        )
      );

      // setFilterListUser(userInformation);

      let textColor = document.getElementById("textmsg");
      if (temp !== undefined) {
        textColor.style.color = "red";
      } else {
        textColor.style.color = "black";
      }
    } else {
      setUserList(false);
    }
    setText(value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    // console.log(tagProfile);
    // console.log(tagProfile.pop());
    if (text) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user != null) {
          firebase
            .database()
            .ref("messeges/" + v4())
            .set({
              userId: user.uid,
              userMessage: text,
              userTagUUId: tagUserProfile,
              userTagId: tagUserProfile,
              timestamp: firebase.database.ServerValue.TIMESTAMP,
            });

          try {
            axios
              .post(
                `${BASE_URL}api/sendPushNotification`,
                {
                  uuids: tagProfile,
                  active_users: activeArray,
                  message: text,
                },
                {
                  headers: {
                    authorization: "Bearer " + token,
                  },
                }
              )
              .then((result) => {
                console.log("Result of tag profile", result);
              })
              .catch((err) => {
                console.log("Error in Tage Profile  ", err);
              });
            setTagProfile([]);
          } catch (err) {
            console.log("Error in Update ", err);
          }
        }
      });

      setText("");
    } else {
      alert("please enter message");
    }
  };

  const showEmojiPanel = () => {
    document.getElementById("imojidiv").removeAttribute("style");
  };

  const hideEmojiPanel = () => {
    document.getElementById("imojidiv").setAttribute("style", "display: none;");
  };
  const selectImage = (e) => {
    var file = document.querySelector("input[type=file]").files[0];
    var reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user != null) {
            firebase
              .database()
              .ref("messeges/" + v4())
              .set({
                userId: user.uid,
                userMessage: reader.result,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
              });
          }
        });

        setText("");
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const selectDoc = (e) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];

      const uploadDocs = firebase
        .storage()
        .ref()
        .child("/docs/" + file.name)
        .put(file);
      uploadDocs.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (error) => {
          console.log("Doc Error", error);
        },
        () => {
          firebase.auth().onAuthStateChanged(function (user) {
            if (user != null) {
              uploadDocs.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  firebase
                    .database()
                    .ref("messeges/" + v4())
                    .set({
                      userId: user.uid,
                      userMessage: downloadURL,
                      timestamp: firebase.database.ServerValue.TIMESTAMP,
                    });
                  setText("");
                });
            }
          });
        }
      );
    }
  };

  const getUser = (e, user) => {
    // console.log(user);
    e.preventDefault();
    if (e.target.id === "all") {
      let All = [];

      user.forEach((user) => {
        All.push(user.id);

        // console.log("All data", All);
        // console.log("users:", user);
      });

      setTagProfile(All);
      // setTagProfile([...tagProfile, All]);
      // console.log("user_tagAll", tagProfile);
      setTagUserProfile("");
      setText(text + "all");

      setUserList(false);
      messsageInputRef.current.focus();
    } else {
      e.preventDefault();

      // let uName = user.data.userName;
      // console.log(uName);
      setText("@" + user.data.userName.split(" ")[0] + " ");

      //  console.log("user_data:", user.data);

      setUserList(false);

      setTagProfile([...tagProfile, user.id]);
      setTagUserProfile(user.id);

      // console.log("user_tag", user);
      // console.log("user_tagid", user.id);

      messsageInputRef.current.focus();
    }
  };

  const userListGet = (event) => {
    axios
      .get(`${BASE_URL}api/userList`)
      .then((result) => {
        setAllUserList(result.data?.result);
      })
      .catch((err) => {
        console.log("Error in Member fetch ", err);
      });
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 col-md-12">
        <div className="chatboard-row">
          <div className="row">
            <div className="col-md-3">
              <div className="chat-user">
                <h2>Conversations</h2>
                <p>
                  <img src={"/assets/new_layout/img/green.png"} /> Online{" "}
                  <span style={{ marginLeft: "10px" }}>
                    <img src={"/assets/new_layout/img/red.png"} /> Offline
                  </span>
                </p>
                {activeUsers.map((list, index) => (
                  <div className="chat-user-row" key={index}>
                    <img
                      alt="UserImage"
                      src={list?.data?.profilePicture}
                      className="user-img"
                    />
                    <span className="active">
                      <i className="fa fa-circle" aria-hidden="true"></i>
                    </span>
                    <h3>{list?.data?.name}</h3>
                  </div>
                ))}
                {allUserList.map((list, index) =>
                  activeUserFilterList &&
                  !activeUserFilterList.includes(list?.name) ? (
                    // activeUserFilterList && !activeUserFilterList.includes(list?.email) ?
                    <div className="chat-user-row" key={index}>
                      {list.profile_image && list.profile_image != "" ? (
                        <img
                          alt="UserImage"
                          src={list.profile_image}
                          className="user-img"
                        />
                      ) : (
                        <img
                          alt="UserImage"
                          src="https://maetrix-code.s3.eu-west-2.amazonaws.com/profile1598441422.jpg"
                          className="user-img"
                        />
                      )}

                      <span className="active">
                        <i
                          style={{ color: "red" }}
                          className="fa fa-circle"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <h3>{list?.name}</h3>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="chat-box">
                <div ref={chatBoxRef} className="massege-box">
                  {loading === true ? (
                    <div className="loade-sec">
                      <div className="loader"></div>
                      <h5>Loading...</h5>
                    </div>
                  ) : (
                    message.length >= 0 &&
                    message.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.showDateAbove && (
                          <div className="dateSet">
                            {" "}
                            <span></span>
                            <h5>{timeConverter(item.timestamp)}</h5>
                          </div>
                        )}

                        {item?.data?.userId === userDetail?.uuid ? (
                          <div className="msg-row-rpl" key={index}>
                            {userInformation.length >= 0 &&
                              userInformation.map((user, i) => {
                                if (item.data?.userId === user.id) {
                                  return (
                                    <div className="newImg" key={i}>
                                      <img
                                        alt="userImage"
                                        key={i}
                                        src={user.data?.profilePicture}
                                        className="chat-prof"
                                      />
                                      {/*<span>Roman</span>*/}
                                    </div>
                                  );
                                }
                              })}

                            <div className="msg-txt">
                              <h6 className="time"> {time(item.timestamp)}</h6>
                              {item?.data?.userMessage.indexOf("base64") !==
                              -1 ? (
                                <img
                                  alt="ImageItem"
                                  src={item?.data?.userMessage}
                                  className="img-chat-rpl"
                                />
                              ) : item.data?.userMessage.indexOf(
                                  "https://firebasestorage.googleapis.com/"
                                ) !== -1 ? (
                                <a
                                  href={item?.data?.userMessage}
                                  style={{
                                    color: "white",
                                    textDecoration: "none",
                                  }}
                                >
                                  <i
                                    className="fa fa-file"
                                    style={{ fontSize: "40px" }}
                                  ></i>{" "}
                                  Click Here To Preview
                                </a>
                              ) : item?.data?.userMessage.indexOf("http://") !==
                                  -1 ||
                                item?.data?.userMessage.indexOf("https://") !==
                                  -1 ||
                                item?.data?.userMessage.indexOf("www.") !==
                                  -1 ? (
                                <a
                                  href={
                                    item?.data?.userMessage.indexOf(
                                      "https://"
                                    ) !== -1
                                      ? item?.data?.userMessage
                                      : "https://" + item?.data?.userMessage
                                  }
                                  target="_blank"
                                >
                                  {item?.data?.userMessage}
                                </a>
                              ) : item?.data?.userTagUUId &&
                                item?.data?.userMessage.indexOf("@") !== -1 ? (
                                <>
                                  {allUserList &&
                                    allUserList
                                      .filter(
                                        (user) =>
                                          user.uuid === item?.data?.userTagUUId
                                      )
                                      .map((user, index) => (
                                        <p key={index}>
                                          {item?.data?.userMessage ? (
                                            <span>
                                              <a
                                                style={{
                                                  color: "#FFF6F5",
                                                  fontWeight: "700",
                                                }}
                                                href={`/member/${
                                                  user.id
                                                }/${user.name
                                                  .toLowerCase()
                                                  .replace(" ", "_")}`}
                                              >
                                                {item?.data?.userMessage
                                                  .replace("@", "")
                                                  .split(" ")
                                                  .shift() + " "}
                                              </a>
                                              {item?.data?.userMessage.indexOf(
                                                " "
                                              ) > "1"
                                                ? item?.data?.userMessage.substr(
                                                    item?.data?.userMessage.indexOf(
                                                      " "
                                                    ) + 1
                                                  )
                                                : ""}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      ))}
                                </>
                              ) : (
                                <p data-title={item?.data?.userMessage}>
                                  {item?.data?.userMessage
                                    ? item?.data?.userMessage
                                    : ""}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="msg-row" key={index}>
                            {userInformation.length > 0 &&
                              userInformation.map((user, i) => {
                                if (item.data?.userId === user.id) {
                                  return (
                                    <div className="newImg" key={index}>
                                      <img
                                        key={i}
                                        alt="UserImage"
                                        src={
                                          item.data?.userId === user.id
                                            ? user.data?.profilePicture
                                            : "assets/images/profile-img.jpgassets/images/profile-img.jpg"
                                        }
                                        className="chat-prof"
                                      />

                                      {/*<span>Roman</span>*/}
                                    </div>
                                  );
                                }
                              })}

                            <div className="msg-txt">
                              {allUserList.length > 0 &&
                                allUserList.map((user, i) => {
                                  if (item.data?.userId === user.uuid) {
                                    return (
                                      <React.Fragment key={i}>
                                        <a
                                          key={i}
                                          href={`/member/${user.id}/${user.name
                                            .toLowerCase()
                                            .replace(" ", "_")}`}
                                        >
                                          <h5>{user.name}</h5>
                                        </a>
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              <h6 className="time">{time(item.timestamp)}</h6>

                              {item?.data?.userMessage.indexOf("base64") !==
                              -1 ? (
                                <img
                                  alt="img"
                                  src={item?.data?.userMessage}
                                  className="img-chat-rpl"
                                />
                              ) : item.data?.userMessage.indexOf(
                                  "https://firebasestorage.googleapis.com/"
                                ) !== -1 ? (
                                <a
                                  href={item?.data?.userMessage}
                                  target="_blank"
                                  style={{
                                    color: "white",
                                    textDecoration: "none",
                                  }}
                                >
                                  <i
                                    className="fa fa-file"
                                    style={{ fontSize: "40px" }}
                                  ></i>{" "}
                                  Click Here To Preview
                                </a>
                              ) : item?.data?.userMessage.indexOf("http://") !==
                                  -1 ||
                                item?.data?.userMessage.indexOf("https://") !==
                                  -1 ||
                                item?.data?.userMessage.indexOf("www.") !==
                                  -1 ? (
                                <a
                                  href={
                                    item?.data?.userMessage.indexOf(
                                      "https://"
                                    ) !== -1
                                      ? item?.data?.userMessage
                                      : "https://" + item?.data?.userMessage
                                  }
                                  target="_blank"
                                >
                                  {item?.data?.userMessage}
                                </a>
                              ) : item?.data?.userTagUUId &&
                                item?.data?.userMessage.indexOf("@") !== -1 ? (
                                <>
                                  {allUserList &&
                                    allUserList
                                      .filter(
                                        (user) =>
                                          user.uuid === item?.data?.userTagUUId
                                      )
                                      .map((user, index) => (
                                        <p key={index}>
                                          {item?.data?.userMessage ? (
                                            <span>
                                              <a
                                                style={{
                                                  color: "#FFF6F5",
                                                  fontWeight: "700",
                                                }}
                                                href={`/member/${
                                                  user.id
                                                }/${user.name
                                                  .toLowerCase()
                                                  .replace(" ", "_")}`}
                                              >
                                                {item?.data?.userMessage
                                                  .replace("@", "")
                                                  .split(" ")
                                                  .shift() + " "}
                                              </a>
                                              {item?.data?.userMessage.indexOf(
                                                " "
                                              ) > "1"
                                                ? item?.data?.userMessage.substr(
                                                    item?.data?.userMessage.indexOf(
                                                      " "
                                                    ) + 1
                                                  )
                                                : ""}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      ))}
                                </>
                              ) : (
                                <p data-title={item?.data?.userMessage}>
                                  {item?.data?.userMessage
                                    ? item?.data?.userMessage
                                    : ""}
                                </p>
                              )}
                            </div>
                            <div
                              className="reportIcon"
                              type="button"
                              data-toggle="modal"
                              data-target="#reportPop"
                              onClick={() => setUID(item)}
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </div>
                <div
                  id="imojidiv"
                  onClick={getEmoji}
                  style={{ display: "none" }}
                ></div>
                <div className="send-msg">
                  <form onSubmit={handleClick}>
                    {userList && (
                      <div className="users tag">
                        <>
                          <p
                            key="12UHuei"
                            id="all"
                            onClick={(e) => getUser(e, userInformation)}
                          >
                            All
                          </p>

                          {filterListUser &&
                            filterListUser.map((user, i) => (
                              <p
                                id="single"
                                key={i}
                                onClick={(e) => getUser(e, user)}
                              >
                                {user.data.userName}
                              </p>
                            ))}
                        </>
                      </div>
                    )}

                    <div className="emoji" style={{ cursor: "pointer" }}>
                      <span className="emojispan" onClick={showEmojiPanel}>
                        <i className="fa fa-smile-o" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="file-msg">
                      <span>
                        <i className="fa fa-camera" aria-hidden="true"></i>
                      </span>
                      <input
                        id="imgFile"
                        type="file"
                        name=""
                        accept="image/*"
                        onChange={selectImage}
                      />
                    </div>
                    <div className="file-doc">
                      <span>
                        <i className="fa fa-paperclip" aria-hidden="true"></i>
                      </span>
                      <input
                        onChange={selectDoc}
                        type="file"
                        name=""
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                      />
                    </div>

                    <div className="text-msg">
                      <input
                        id="textmsg"
                        ref={messsageInputRef}
                        type="text"
                        placeholder="Write your message here..."
                        autoComplete="off"
                        name="msg"
                        onFocus={hideEmojiPanel}
                        value={text}
                        onChange={handleChange}
                      />
                      <button type="submit">
                        <img src={"/assets/new_layout/img/send.png"} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportModel value={uId} token={token} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userName: state.authReducer?.user?.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupChat));
