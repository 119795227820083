import React, { Component } from "react";
import { registerUser, loginUser } from "../../../store/actions";

import { ToastOptions, BASE_URL } from "../../../helpers/Constants";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import firebase from "firebase";
import axios from "axios";
import "./referral.css";
class Login extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    referral: "",
    default_referral: "",
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.getError != this.props.getError &&
      this.props.getError !== null
    ) {
      toast.error(
        this.props.getError ? this.props.getError : "Unable to Login",
        ToastOptions
      );
    }
  }
  componentDidMount() {
    window.$(".reg-form").show();
    window.$(".login-form").hide();
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code && code != "") {
      this.setState({ default_referral: code, referral: code });
    }
    const url = `${BASE_URL}api/referral/click`;
    axios
      .post(url, { referral_code: code })
      .then((response) => {})
      .catch((error) => {
        console.log("error", error);
      });
  }
  handleUserInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleUserInputReg = (event) => {
    if (event.target.name === "name") {
      if (
        /^[a-zA-Z -]+$/.test(event.target.value) !== true &&
        event.target.value
      ) {
        return false;
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleRegPassword = () => {
    let temp = document.getElementById("password-field-reg");
    let icon = document.getElementById("pass-reg");
    if (temp.className == "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  toggleRegPassword2 = () => {
    let temp = document.getElementById("password-field-reg1");
    let icon = document.getElementById("pass-reg1");
    if (temp.className == "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };
  toggleLoginPassword = () => {
    var temp = document.getElementById("password");
    let icon = document.getElementById("pass-status");
    if (temp.type === "password") {
      temp.type = "text";
      icon.className = "fa fa-eye";
    } else {
      temp.type = "password";
      icon.className = "fa fa-eye-slash";
    }
  };
  login = () => {
    window.$(".reg-form").hide();
    window.$(".login-form").show();
  };
  handleLoginForm = async (event) => {
    event.preventDefault();
    let errors = {};

    if (!this.state.email) {
      errors.name = "Email Is Required";
    }

    if (!this.state.password) {
      errors.password = "Password Is Required";
    }

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };

      let ref = await firebase
        .auth()
        .signInWithEmailAndPassword(userData.email, userData.password)
        .catch((ex) => {
          /* handle exception */
        });
      let user = ref?.user;

      if (user) {
        let starCountRef = firebase.database().ref("/users/" + user.uid);
        starCountRef.on("value", async (snapshot) => {
          var user = firebase.auth().currentUser;

          if (user != null) {
            await firebase
              .database()
              .ref("/active/" + user.uid)
              .set({
                name: snapshot?.val()?.userName,
                profilePicture: snapshot?.val()?.profilePicture,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
              })
              .then(() => {
                console.log("Active user created");
              });
          }
        });
      }

      this.props.dispatchLogin({
        email: userData.email,
        password: userData.password,
        history: this.props.history,
        url: this.props?.history?.location?.pathname,
        device_token: localStorage.getItem("device_token"),
      });
    }
    window.location.href = "/";
  };
  handleRegForm = (event) => {
    //console.log('click button');
    event.preventDefault();
    let errors = {};
    if (!this.state.name) {
    }
    if (!this.state.email) {
      errors.name = "Email Is Required";
    }

    if (this.state.password != this.state.confirm_password) {
      errors.password = "Password And Confirm Password Does Not Match";
      toast.error("Password And Confirm Password Does Not Match", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const userData = {
        name: this.state.name,
        email: this.state.email,
        referral_code: this.state.referral,
      };
      this.props.dispatchRegister({
        name: userData.name,
        email: userData.email,
        referral_code: userData.referral_code,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
      });
    }
  };
  render() {
    console.log(this.state.default_referral);
    return (
      <div>
        <ToastContainer />

        <div className="referral">
          <div className="row">
            <div className="col-md-6 welcome">
              <p className="uppercase text-head">Welcome</p>
              <p className="uppercase text-head2 mt-20">
                "what you offer has no value,
              </p>
              <p className="uppercase text-head2">
                unless you can put it in the
              </p>
              <p className="uppercase text-head2">mind of your client"</p>
              <p className="text-head3 mt-20">
                You now get 50% OFF your first month subscription!{" "}
              </p>
              <p className="text-head3 mt-10">Let`s get cracking!</p>
              <p className="mt-20 text-head4">
                The Programme was a lifeline at a time when I was swamped in my
                business.
              </p>
              <p className="text-head4">
                Thanks to Richard`s guidance, I`ve streamlined my operations an
                now enjoy a newfound sense of control and confidence
              </p>
            </div>
            <div className="col-md-6">
              <div className="main-login-sec">
                <div className="login-form">
                  <h2>Maetrix</h2>
                  <h3>Please Login</h3>
                  <form onSubmit={this.handleLoginForm}>
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                        onChange={this.handleUserInput}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleUserInput}
                        required
                      />
                      <span className="show-pass">
                        <i
                          id="pass-status"
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={this.toggleLoginPassword}
                        ></i>
                      </span>
                    </div>
                    <div className="sbm-sec">
                      <button type="submit">Login</button>
                    </div>
                  </form>
                  <button type="button" id="regSec">
                    Create a new account
                  </button>
                  <br />
                  <Link to="/terms-and-conditions">
                    <p className="privacyPara">Terms & Conditions</p>
                  </Link>
                </div>
                <div className="reg-form">
                  <h2>Maetrix</h2>
                  <h3>Please Register</h3>
                  <form onSubmit={this.handleRegForm} id="regForm">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleUserInputReg}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleUserInputReg}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="active"
                        id="password-field-reg"
                        name="password"
                        value={this.state.password}
                        placeholder="Password"
                        onChange={this.handleUserInputReg}
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                      />
                      <span className="show-pass">
                        <i
                          id="pass-reg"
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.toggleRegPassword}
                        ></i>
                      </span>
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="active"
                        id="password-field-reg1"
                        name="confirm_password"
                        value={this.state.confirm_password}
                        placeholder="Confirm Password"
                        onChange={this.handleUserInputReg}
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                      />
                      <span className="show-pass">
                        <i
                          id="pass-reg1"
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.toggleRegPassword2}
                        ></i>
                      </span>
                    </div>

                    <div className="sbm-sec">
                      <button type="submit">Register</button>
                    </div>
                  </form>
                  <button type="button" onClick={this.login}>
                    Log In
                  </button>
                  <Link to="/terms-and-conditions">
                    <p className="privacyPara">Terms & Conditions</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getError: state.authReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogin: (data) => dispatch(loginUser(data)),
    dispatchRegister: (data) => dispatch(registerUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
