import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Login from "../../../components/gaust/login";
import Footer from "../../../layout/gaust/Footer";
import axios from "axios";
import { BASE_URL } from "../../../helpers/Constants";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

export class Landing extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
    btnClicked: false,
    showLoginModel: false,
    name: "",
    email: "",
    message: "",
    show: false,
  };

  handleChange = (event) => {
    if (event.target.name === "name") {
      this.setState({
        name: event.target.value,
      });
    }
    if (event.target.name === "email") {
      this.setState({
        email: event.target.value,
      });
    }
    if (event.target.name === "message") {
      this.setState({
        message: event.target.value,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let url = `${BASE_URL}api/sendEnquiry`;
    axios
      .post(url, {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      })
      .then((response) => {
        this.setState({
          show: true,
          name: "",
          email: "",
          message: "",
        });
      });
  };

  componentDidMount() {
    // document.title = "Landing Page :: The Maetrix";
  }

  myFunction = (value) => {
    console.log("btn click");
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  goToHome = () => {
    window.location.href = "/";
  };
  // loginBtnClicked = (event) => {
  //   //console.log('login button clicked in header.js');
  //   this.setState(
  //     {
  //       btnClicked: true,
  //     },
  //     () => {
  //       //console.log('setstate callback', this.state)
  //       if (this.props) {
  //         this.props.hitHome(this.state.btnClicked);
  //       }
  //     }
  //   );
  // };

  render() {
    return (
      <React.Fragment>
        <div className="lndng-head-row page-height">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="lndng-logo">
                  <a href="/">
                    <img alt="logo" src="/assets/new_layout/img/logo.png" />
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="signup-btn">
                  {this.props?.token ? (
                    <button type="button" onClick={() => this.goToHome()}>
                      Go To Dashboard
                    </button>
                  ) : (
                    <button type="button" onClick={() => this.myFunction(true)}>
                      Log In / Register
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="lndng-top">
              <div className="row">
                <div className="col-md-6">
                  <div className="lndng-top-left">
                    <h1>Why Maetrix?</h1>
                    <p>
                      The MAETRIX is the Aesthetic Entrepreneurs proprietary
                      content and learning management system, the 2nd brain of
                      the organisation. Designed to have everything that an
                      aesthetic entrepreneur needs to grow and develop in one
                      place, Maetrix is the one stop shop for high quality
                      education, insight, coaching and inspiration to enable our
                      clients to realise their full potential. It's a platform
                      like no other, that combines learning management,
                      community and content together to provide a unique and
                      highly effective environment for learning and skills
                      development.
                    </p>
                    <a href="/home">Know More</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lndng-top-right">
                    {/* <img alt="img" src="assets/images/lndng-right-frame.png" /> */}
                    <div className="intro-vdo">
                      <div className="frame-bottom">
                        <img alt="img" src="assets/images/frame-stand.png" />
                      </div>
                      <video
                        src="https://maetrix-code.s3.eu-west-2.amazonaws.com/1618474996.mp4"
                        type="video/mp4"
                        poster="assets/images/themaetrixthumbnail.png"
                        controls
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lndng-hd-top-icon">
            <img alt="img" src="assets/images/lndng-hd-top-icon.png" />
          </div>
          <div className="lndng-hd-btm-icon">
            <img alt="img" src="assets/images/lndng-hd-btm-icon.png" />
          </div>
        </div>
        {/* <!-- Landing head box end -->
    <!-- Landing feature section start --> */}
        <div className="feature-row">
          <div className="container">
            <div className="feature-hdng">
              <h2>Where did the MAETRIX come from?</h2>
              <p>
                The MAETRIX was conceived and designed by the Founder of
                Aesthetic Entrepreneurs, as a key part of the AE business
                strategy. "We delivered most of our training and coaching either
                face to face, or through Zoom and Social Media. I knew we needed
                to take Aesthetic Entrepreneurs more down a digital delivery
                route, and I found the combination of a defined structure that a
                LMS (learning Management System), and social media community
                very effective. I could couldn't find a solution that
                effectively harnessed both of these in one place, and people
                like to absorb content in different ways, so I wanted to add
                additional content forms such as Podcasting and blogs. Also, the
                risk of holding all of that IP on Facebook servers was just too
                high, so I needed to develop our own platform, and MAETRIX was
                born". MAETRIX stands for "MultiFunctional Aesthetic
                Entrepreneurs TRaining and Information eXchange, and was named
                by one of our clients, Dr Louise Hallam.
              </p>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Courses</h2>
                    <p>
                      The MAETRIX is, in it's purest form, as content delivery
                      and learning management system. The courses modules enable
                      us to deliver structured learning that is easily
                      digestible and simple to use. The LMS module can be
                      monetised using Stripe, and is comparable in quality to
                      many other systems, such as Kajabi, Thinkific, but MAETRIX
                      is much more than just LMS.
                    </p>
                    <a href="/courses">Know More</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/courses-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Blogs</h2>
                    <p>
                      One of the most effective ways to build brand awareness,
                      while providing relevant and useful content to your target
                      audience is through consistent blogging. Blogging is an
                      inexpensive way for small businesses to drive traffic to
                      their site, enhance inbound marketing efforts and attract
                      more perspective customers. MAETRIX has an in built
                      blogging platform to capture this powerful medium 
                    </p>
                    <a href="/blogs">Know More</a>
                  </div>
                </div>
                <div className="col-md-6 order-md-first">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/blog-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Videos</h2>
                    <p>
                      Anyone who has spent 30 seconds in our community will know
                      that Video is at the core of our content strategy. We
                      repurpose all of our content, and have over 1,000 hrs of
                      video lessons and workshops available on demand.
                    </p>
                    <a href="/videos">Know More</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/video-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Podcasts</h2>
                    <p>
                      Podcasts are easily accessible, portable, entertaining, a
                      purposeful way to kill dead time and a quick, memorable
                      way to learn something. Podcasters are people who
                      generally really care about their topics, want to share
                      their knowledge and passion, educate and/or create a
                      conversation. MAETRIX has a RSS feed so you can host your
                      own podcast and publish to Apple, Spotify and many other
                      distribution channels.
                    </p>
                    <a href="/podcasts">Know More</a>
                  </div>
                </div>
                <div className="col-md-6 order-md-first">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/podcast-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Landing feature section end -->
    <!-- Landing feature section start --> */}
        <div className="feature-row-dark">
          <div className="container">
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Maetrix LIVE</h2>
                    <p>
                      Maetrix Live - The jewel in the crown. Live stream video
                      directly into the Maetrix platform. Perfect for product
                      launches, educational seminars and workshops.
                    </p>
                    <a href="/maetrixlive">Know More</a>
                  </div>
                </div>
                <div className="col-md-6 order-md-first">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/live-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Community</h2>
                    <p>
                      At the heart of MAETRIX is community. We know that a loyal
                      group of 100 clients is more powerful than 10,000 likes,
                      and the MAETRIX enables you to grow, and nurture your
                      community without relying on a 3rd party social media
                      platform. The group chat enables you to connect with
                      users, highlight unique content and communicate with a
                      single user, or all! Profiles
                    </p>
                    <a href="/dashboard">Know More</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/chat-frame-img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="feature-bx-lft">
                    <h2>Showcase your clients with profiles</h2>
                    <p>
                      Your users can create a high quality profile page that
                      holds links to ALL of their digital assets in one place.
                      They can benefit from your SEO power, and their customers
                      can search and gorge all the meaningful content and then
                      book from a single page. Profiles is an incredibly
                      powerful partnership tool.
                    </p>
                    <a href="#">Coming Soon</a>
                  </div>
                </div>
                <div className="col-md-6 order-md-first">
                  <div className="feature-bx-rght">
                    <img alt="img" src="assets/images/AE Mockup.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Landing feature section end -->
    <!-- Testimonial section start --> */}
        <div className="tstmnl-row">
          <div className="container">
            <div className="tstmnl-hdng">
              <h2>What our clients say ?</h2>
            </div>
            <div className="tstmnl-slider">
              <OwlCarousel
                className="owl-theme"
                margin={10}
                items={2}
                dots={false}
                nav={true}
                responsive={this.state.responsive}
              >
                <div className="item">
                  <div className="tstmnl-sld-box">
                    <div className="tstmnl-sld-head">
                      <img alt="img" src="assets/images/testimonial-1.png" />
                      <h3>Kelly Mccready</h3>
                    </div>
                    <div className="tstmnl-sld-cnt">
                      <img
                        alt="img"
                        src="assets/images/quote-left.svg"
                        className="quote"
                      />
                      <p>
                        Thank you Richard, Thank you for everything you have
                        done for me, for the repetitiveand for belief in
                        me...For always give me a kick off when I need it the
                        most.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tstmnl-sld-box">
                    <div className="tstmnl-sld-head">
                      <img alt="img" src="assets/images/testimonial-2.png" />
                      <h3>Hayley Jones</h3>
                    </div>
                    <div className="tstmnl-sld-cnt">
                      <img
                        alt="img"
                        src="assets/images/quote-left.svg"
                        className="quote"
                      />
                      <p>
                        Richard has help me so much in crystallizing my thoughts
                        and strategies. He gives incredibly good practical
                        advice on things like identifying the target audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tstmnl-sld-box">
                    <div className="tstmnl-sld-head">
                      <img alt="img" src="assets/images/testimonial-3.png" />
                      <h3>Sunny Dhesi</h3>
                    </div>
                    <div className="tstmnl-sld-cnt">
                      <img
                        alt="img"
                        src="assets/images/quote-left.svg"
                        className="quote"
                      />
                      <p>
                        Aesthetic Entrepreneurs has helped meto gain more focus
                        on what my business needs. passion and a drive to do
                        better aree something that i have always had but AE
                        helped me to make that passion more consistent.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tstmnl-sld-box">
                    <div className="tstmnl-sld-head">
                      <img alt="img" src="assets/images/kerry.jpg" />
                      <h3>Kerry Collins</h3>
                    </div>
                    <div className="tstmnl-sld-cnt">
                      <img
                        alt="img"
                        src="assets/images/quote-left.svg"
                        className="quote"
                      />
                      <p>
                        I would not be where i am today without Aesthetic
                        Entrepreneurs and Accelerators, So much support and
                        guidance form Richard and his team.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tstmnl-sld-box">
                    <div className="tstmnl-sld-head">
                      <img alt="img" src="assets/images/leisa.jpg" />
                      <h3>Kelly Mccready</h3>
                    </div>
                    <div className="tstmnl-sld-cnt">
                      <img
                        alt="img"
                        src="assets/images/quote-left.svg"
                        className="quote"
                      />
                      <p>
                        I have learnt more in 3 weeks of being part of Aesthetic
                        Entrepreneurs Launchpad than in the entire 12 years I've
                        been in Aesthetics.
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* <!-- Testimonial section end -->
    <!-- Landing footer section start --> */}
        <div className="lndng-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="lndng-ftr-form">
                  <h2>
                    Contact the Team<span>Start a conversation</span>
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder="Type your message here..."
                        required
                      ></textarea>
                    </div>
                    <div className="sbm-btn">
                      <button type="submit" value="Submit">
                        Send
                      </button>
                    </div>
                    {this.state.show === true && (
                      <div className="sbm-msg">
                        <p> Message Submit Successfully</p>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="lndng-ftr-img">
                  <img alt="img" src="assets/images/landing-reacherd-img.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Landing));
// export default Landing;
