import {
  CREATE_USER_AI_CONTENT,
  CREATE_USER_AI_CONTENT_SUCCESS,
  CREATE_USER_AI_CONTENT_ERROR,
  SAVE_AI_CONTENT,
  SAVE_AI_CONTENT_SUCCESS,
  SAVE_AI_CONTENT_ERROR,
  GET_USER_AI_CONTENT,
  GET_USER_AI_CONTENT_SUCCESS,
  GET_USER_AI_CONTENT_ERROR,
  DELETE_USER_AI_CONTENT,
  DELETE_USER_AI_CONTENT_SUCCESS,
  DELETE_USER_AI_CONTENT_ERROR,
  ADD_FEEDBACK_AI_CONTENT,
  ADD_FEEDBACK_AI_CONTENT_SUCCESS,
  ADD_FEEDBACK_AI_CONTENT_ERROR,
} from "./actionTypes";

export const createAIContent = (data) => {
  return {
    type: CREATE_USER_AI_CONTENT,
    payload: data,
  };
};

export const createAIContentSuccess = (data) => {
  return {
    type: CREATE_USER_AI_CONTENT_SUCCESS,
    payload: data,
  };
};

export const createAIContentError = (error) => {
  return {
    type: CREATE_USER_AI_CONTENT_ERROR,
    payload: error,
  };
};
export const saveAIContent = (data) => {
  return {
    type: SAVE_AI_CONTENT,
    payload: data,
  };
};

export const saveAIContentSuccess = (data) => {
  return {
    type: SAVE_AI_CONTENT_SUCCESS,
    payload: data,
  };
};

export const saveAIContentError = (error) => {
  return {
    type: SAVE_AI_CONTENT_ERROR,
    payload: error,
  };
};

export const getAIContent = (data) => {
  return {
    type: GET_USER_AI_CONTENT,
    payload: data,
  };
};

export const getAIContentSuccess = (data) => {
  return {
    type: GET_USER_AI_CONTENT_SUCCESS,
    payload: data,
  };
};

export const getAIContentError = (error) => {
  return {
    type: GET_USER_AI_CONTENT_ERROR,
    payload: error,
  };
};
export const deleteAIContent = (data) => {
  return {
    type: DELETE_USER_AI_CONTENT,
    payload: data,
  };
};

export const deleteAIContentSuccess = (data) => {
  return {
    type: DELETE_USER_AI_CONTENT_SUCCESS,
    payload: data,
  };
};

export const deleteAIContentError = (error) => {
  return {
    type: DELETE_USER_AI_CONTENT_ERROR,
    payload: error,
  };
};
export const addAIContentFeedback = (data) => {
  return {
    type: ADD_FEEDBACK_AI_CONTENT,
    payload: data,
  };
};

export const addAIContentFeedbackSuccess = (data) => {
  return {
    type: ADD_FEEDBACK_AI_CONTENT_SUCCESS,
    payload: data,
  };
};

export const addAIContentFeedbackError = (error) => {
  return {
    type: ADD_FEEDBACK_AI_CONTENT_ERROR,
    payload: error,
  };
};
