import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../../helpers/Constants";
import { withRouter } from "react-router-dom";
import DataLoading from "../../../common/DataLoading";

class NewsFeeds extends Component {
  state = {
    feed: [],
    loading: false,
  };
  componentDidMount() {
    this.setState({
      loading: true,
    });
    axios
      //.get(`${BASE_URL}api/socialMediaData`)
      .get(`https://admin.themaetrix.com/api/socialMediaData`)
      .then((result) => {
        this.setState({
          feed: result?.data?.result,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("Error in Member fetch ", err);
        this.setState({
          loading: false,
        });
      });
  }
  componentDidMount() {
    // var elem = document.getElementById("calendar");
    // elem.addEventListener("onload", this.myFunc());
  }

  myFunc(e) {
    e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = "https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js";
    e.className = "ae-emd-script";
    document.getElementsByTagName("body")[0].appendChild(e);
  }

  render() {
    return (
      <section className="newsfeed my-5">
        {/* <div class="container">
                    <div class="inner-section">

                        <div class="force-overflow">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <h2>NewsFeed</h2>
                                </div>
                                <hr></hr>
                                {this?.state?.loading == false ?
                                    this?.state?.feed?.map((feeds, index) =>
                                        feeds.type === "event" ? (
                                            <div className="news-feed-box" key={index}>
                                                <a
                                                    href={feeds.event_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {feeds.image_url !== null && (
                                                        <img alt="img" src={feeds.image_url} className="mic-icon" />
                                                    )}
                                                    <h3>
                                                        <img alt="img" src="assets/images/fb-icon.png" />
                                                        {feeds.user ? feeds.user : "User"} added an{" "}
                                                        <strong>event</strong>
                                                    </h3>
                                                    <h3>{feeds.event_title}</h3>
                                                    <h6>{feeds.time}</h6>
                                                    <p>{feeds.event_description}...</p>
                                                </a>
                                            </div>
                                        ) : feeds?.type === "feed" ? (
                                            <div className="news-feed-box" key={index}>
                                                <a
                                                    href={feeds.feed_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {feeds.image_url !== null && (
                                                        <img alt="img" src={feeds.image_url} className="mic-icon" />
                                                    )}
                                                    <h3>
                                                        <img alt="img" src="assets/images/fb-icon.png" />
                                                        {feeds.user ? feeds.user : "User"} added a{" "}
                                                        <strong>post</strong>
                                                    </h3>
                                                    <p>
                                                        {feeds.feed_description}...<strong>View more</strong>
                                                    </p>
                                                    <h6>{feeds.time}</h6>
                                                </a>
                                            </div>
                                        ) : (
                                            <div class="col-md-12" style={{ "marginBottom": "10px" }} key={index}>
                                                <a
                                                    href={feeds.feed_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <div class="row">
                                                        <div class="col-12 col-md-3">
                                                            {(feeds.image_url && feeds.image_url.search(/.mp4/i) > 1) ?
                                                                <video width="100%" height="150px" style={{ height: "150px", width: "100%" }} controls preload="auto">
                                                                    <source src={feeds.image_url} type="video/mp4" />
                                                                    <source src={feeds.image_url} type="video/ogg" />
                                                                </video>
                                                                :
                                                                <img alt="img" width="100%" height="150px" style={{ height: "150px", width: "100%" }} src={feeds.image_url} className="img-fluid" />
                                                            }
                                                        </div>
                                                        <div class="col-12 col-md-9">
                                                            <a href={feeds.insta_feed_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"><strong>The Aesthetic Entrepreneurs</strong> added a post</a>
                                                            <p class="pt-2">{feeds.insta_feed_title}….</p>
                                                            <p class="new-txt pt-2">{feeds.time}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    )
                                    :
                                    <DataLoading />
                                }

                            </div>
                        </div>
                    </div>
                </div> */}
        {/* <div
          id="calendar"
          className="ae-emd-cal"
          data-calendar="sR520112"
          data-configure="false"
        >
          calendar
        </div> */}
        {/* <script type="text/javascript">()
                </script> */}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news_feeds: state?.dashboardReducer?.services?.news_feeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsFeeds));
