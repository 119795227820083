import React, { useState, useEffect, useRef } from "react";

import { BASE_URL } from "../../../helpers/Constants";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import { v4 } from "uuid";
import axios from "axios";

export const LiveStreamingChat = ({ userDetail, token, response }) => {
  const [text, setText] = useState("");
  const [message, setMessage] = useState([]);
  const chatBoxRef = useRef();
  const [userInformaion, setUserInformation] = useState([]);
  let [loading, setLoading] = useState(false);
  let [imoji, setImoji] = useState(false);
  const [userList, setUserList] = useState(false);
  const [filterListUser, setFilterListUser] = useState([]);

  const messsageInputRef = useRef();

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    if (Number.isNaN(a.getDate())) return "";
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();

    var time = date + " " + month + " " + year;
    return time;
  }

  function time(x) {
    var aa = new Date(x);
    var hour = aa.getHours();
    if (hour.toString().length < 2) hour = "0" + hour;

    var min = aa.getMinutes();
    if (min.toString().length < 2) min = "0" + min;
    var times = hour + ":" + min;
    return times;
  }

  const getEmoji = (e) => {
    e.preventDefault();
    if (e.target.id === "imojidiv") {
    } else {
      var htmText = document.getElementById(e.target.id).innerHTML;
      setText(text.concat(htmText));
    }
  };

  const len = message.length;

  useEffect(() => {
    chatBoxRef.current &&
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight);
  }, [len]);

  useEffect(() => {
    setFilterListUser(userInformaion);
  }, [userInformaion]);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then((token) => {
          return messaging.getToken();
        })
        .then((device_token) => {
          axios
            .post(
              `${BASE_URL}api/updateDeviceToken`,
              {
                device_token: device_token,
              },
              {
                headers: {
                  authorization: "Bearer " + token,
                },
              }
            )
            .catch((err) => {
              console.log("Error in Tage Profile  ", err);
            });
        })
        .catch((error) => {
          alert("Please Allow Notification To Receive The Updates");
        });
    }

    var starCountRef = firebase.database().ref("users/");
    starCountRef.on("value", function (snapshot) {
      let uTemp = [];
      snapshot.forEach((childshot) => {
        var childdata = {
          id: childshot.key,
          data: childshot.val(),
        };
        uTemp.push(childdata);
      });
      setUserInformation(uTemp);
    });

    firebase.auth().onAuthStateChanged(function (user) {
      if (user != null) {
        sessionStorage.setItem("user", user.uid);
        var starCountRef = firebase
          .database()
          .ref("streamingChat/")
          .orderByChild("timestamp/");

        setLoading(true);
        starCountRef.on("value", function (snapshot) {
          let temp = [];
          snapshot.forEach((childshot, index) => {
            var childdata = {
              id: childshot.key,
              data: childshot.val(),
              timestamp: childshot.val().timestamp,
            };
            temp.push(childdata);
          });

          const newTemp =
            temp &&
            temp.map((item, index) => {
              const el = { ...item };
              if (index > 0) {
                const currentDate = new Date(el.timestamp).getDate();
                const prevDate = new Date(temp[index - 1].timestamp).getDate();
                if (currentDate !== prevDate) el.showDateAbove = true;
              } else {
                el.showDateAbove = true;
              }
              return el;
            });
          setLoading(false);
          setMessage(newTemp);
        });
      } else {
        console.log("not auth user");
        console.log("user else", user);
      }
    });

    var allEmoji = "";
    for (var i = 128512; i <= 128580; i++) {
      allEmoji += `<a id="${i}" key={i} >&#${i};</a>`;
    }
    document.getElementById("imojidiv").innerHTML = allEmoji;
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setText(value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    document.getElementById("imojidiv").setAttribute("style", "display: none;");
    if (text) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user != null) {
          firebase
            .database()
            .ref("streamingChat/" + v4())
            .set({
              userId: user.uid,
              userMessage: text,
              timestamp: firebase.database.ServerValue.TIMESTAMP,
            });
        }
      });

      setText("");
    } else {
      alert("please enter message");
    }
  };

  const showEmojiPanel = () => {
    setImoji((imoji = !imoji));
    imoji === true
      ? document.getElementById("imojidiv").removeAttribute("style")
      : document
          .getElementById("imojidiv")
          .setAttribute("style", "display: none;");
  };

  const hideEmojiPanel = () => {
    document.getElementById("imojidiv").setAttribute("style", "display: none;");
  };

  return (
    <div className="col-lg-4 col-md-5">
      <div className="strm-chat">
        <div className="strm-cht-head">
          <h2>Latest Comments</h2>
        </div>
        <div ref={chatBoxRef} className="strm-cht-box">
          {response?.enable_comments !== true ? (
            <div className="loade-sec1">
              <h5>Comments Disable</h5>
            </div>
          ) : loading === true ? (
            <div className="loade-sec">
              <div className="loader"></div>
              <h5>Loading...</h5>
            </div>
          ) : (
            message.length >= 0 &&
            message.map((item, index) => (
              <React.Fragment key={index}>
                {item.showDateAbove && (
                  <div className="dateSet">
                    {" "}
                    <span></span>
                    <h5>{timeConverter(item.timestamp)}</h5>
                  </div>
                )}

                <div className="strm-cht-usr">
                  {userInformaion.length > 0 &&
                    userInformaion.map((user, i) => {
                      if (item.data?.userId == user.id) {
                        return (
                          <img
                            key={i}
                            alt="UserImage"
                            src={
                              item.data?.userId == user.id
                                ? user.data?.profilePicture
                                : "assets/images/profile-img.jpg"
                            }
                            className="usr-icn"
                          />
                        );
                      }
                    })}
                  {userInformaion.length > 0 &&
                    userInformaion.map((user, i) => {
                      if (item.data?.userId == user.id) {
                        return (
                          <React.Fragment key={i}>
                            <h4 key={i}>{user.data.userName}</h4>
                            <span className="timeSec">
                              {time(item.timestamp)}
                            </span>
                          </React.Fragment>
                        );
                      }
                    })}

                  <p>{item?.data?.userMessage}</p>
                </div>
              </React.Fragment>
            ))
          )}
        </div>
        <div className="snd-msg">
          <form onSubmit={handleClick}>
            <div
              id="imojidiv"
              onClick={getEmoji}
              style={{ display: "none" }}
            ></div>
            <div className="snd-sec">
              {response?.enable_comments !== true ? (
                <>
                  <span className="emoji">
                    <i className="fa fa-smile-o" aria-hidden="true"></i>
                  </span>
                  <input
                    id="textmsg"
                    placeholder="Comment disable"
                    type="text"
                    autoComplete="off"
                    name="msg"
                    defaultValue={text}
                  />
                  <button className="snd-btn">Send</button>{" "}
                </>
              ) : (
                <>
                  <span className="emoji" onClick={showEmojiPanel}>
                    <i className="fa fa-smile-o" aria-hidden="true"></i>
                  </span>
                  <input
                    id="textmsg"
                    ref={messsageInputRef}
                    type="text"
                    autoComplete="off"
                    name="msg"
                    onFocus={hideEmojiPanel}
                    value={text}
                    onChange={handleChange}
                  />
                  <button className="snd-btn" type="submit">
                    Send
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userName: state.authReducer?.user?.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LiveStreamingChat));
