import {
  GET_ROADMAP_ASSIGNS,
  GET_ROADMAP_ASSIGNS_SUCCESS,
  GET_ROADMAP_ASSIGNS_ERROR,
  UPDATE_ROADMAP_ASSIGNS,
  UPDATE_ROADMAP_ASSIGNS_SUCCESS,
  UPDATE_ROADMAP_ASSIGNS_ERROR,
  GET_ROADMAP_LESSONS,
  GET_ROADMAP_LESSONS_SUCCESS,
  GET_ROADMAP_LESSONS_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  assigns: [],
  lessons: [],
};

const roadmapReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROADMAP_ASSIGNS:
      state = {
        ...state,
        error: null,
        loading: true,
      };
      break;
    case GET_ROADMAP_ASSIGNS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        assigns: action.payload,
      };
      break;

    case GET_ROADMAP_ASSIGNS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        assigns: [],
      };
      break;
    case UPDATE_ROADMAP_ASSIGNS:
      state = {
        ...state,
        error: null,
        loading: true,
      };
      break;
    case UPDATE_ROADMAP_ASSIGNS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        roadmap: action.payload,
      };
      break;

    case UPDATE_ROADMAP_ASSIGNS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        roadmap: [],
      };
      break;
    case GET_ROADMAP_LESSONS:
      state = {
        ...state,
        error: null,
        loading: true,
      };
      break;
    case GET_ROADMAP_LESSONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        lessons: action.payload.lessons,
        assigns: action.payload.assigns,
      };
      break;

    case GET_ROADMAP_LESSONS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        lessons: [],
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default roadmapReducer;
