import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-loop";

import GroupChat from "../../../components/auth/dashboard/GroupChat";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Footer = (props) => {
    console.log(props)
    
    return (
        
        <div className="container-fluid mt-5" style={{ backgroundColor: "#333333" }}>
            
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
                <div className="col-md-4 d-flex align-items-center">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <svg className="bi" width="30" height="24"></svg>
                    </a>
                    <span className="text-white">Copyright © 2024 RCS Rocks Ltd</span>
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="text-white">Follow Us</li>
                    <li className="ms-3 mx-2"><a className="text-muted" href="#"><img src={"./assets/new_layout/img/facebook (1).png"} style={{ wiidth: "30px", height: "30px" }} /></a></li>
                    <li className="ms-3"><a className="text-muted" href="#"><img src={"./assets/new_layout/img/instagram-sketched.png"} style={{ wiidth: "30px", height: "30px" }} /></a></li>
                </ul>
            </footer>
           
            
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        

                        <div class="modal-body">
                        {(props.userName && props?.token) ?
                            <GroupChat userDetail={props.userName} token={props?.token}/> 
                            :
                            <p></p>
                        }
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        
    userName: state.authReducer?.user?.user,
        services: state.dashboardReducer.services,
    };
};

const mapDispatchToProps = (dispatch) => {
    
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Footer));
