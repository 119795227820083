import React, { PureComponent } from "react";

import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import {
  getUserDashboardAI,
  deleteUserDashboardAI,
} from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DataLoading from "../../common/DataLoading";
import DateFormat from "../../../helpers/DateHelper";
import ReactMarkdown from "react-markdown";

class AIDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.props.dispatchGetAIAnswers({
      token: this.props.token,
    });
  }
  deleteAI = async (item) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation)
      this.props.dispatchDeleteAIAnswer({
        id: item.id,
        token: this.props.token,
      });
  };

  render() {
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <ToastContainer />
        <div className="container page-height">
          <h2>I asked Maetrix </h2>
          <div className="my-questions-row">
            {/* <h3>Question and Answers</h3> */}
            {this.props.loadingAIAnswers == false ? (
              <>
                <div id="content" className="ai-qa-list">
                  {this.props.aiAnswers &&
                    this.props.aiAnswers.map((q, index) => (
                      <div key={index} className="qa-row">
                        <i
                          class="delete fa fa-times"
                          aria-hidden="true"
                          onClick={(e) => this.deleteAI(q)}
                        ></i>
                        <h3>{q.lesson_name}</h3>
                        <div className="question">
                          <h4>
                            <i
                              className="fa fa-align-left"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            {q.question}
                          </h4>
                          <p>
                            <i className="fa fa-calendar"></i>
                            {DateFormat(q.created_at)}
                          </p>
                        </div>
                        <ReactMarkdown>{q.answer}</ReactMarkdown>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <DataLoading />
            )}
          </div>
        </div>

        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aiAnswers: state.dashboardReducer.aiAnswers,
    loadingAIAnswers: state.dashboardReducer.loadingAI,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetAIAnswers: (data) => dispatch(getUserDashboardAI(data)),
    dispatchDeleteAIAnswer: (data) => dispatch(deleteUserDashboardAI(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AIDashboard));
