import { BASE_URL } from "../../../helpers/Constants";
import queryString from "query-string";
import {
  GET_VIDEO,
  GET_BANNER,
  GET_VIDEO_DETAIL,
  GET_VIDEOS_BY_CATEGORY_ID,
  SET_PAUSE_TIME,
  GET_PAUSE_TIME,
} from "./actionType";

import {
  getVideoSuccess,
  getVideoError,
  getBannerSuccess,
  getBannerError,
  getVideoDetailSuccess,
  getVideoDetailError,
  getVideosByCategoryIdSuccess,
  getVideosByCategoryIdError,
  savePauseTimeSuccess,
  savePauseTimeError,
  getPauseTimeSuccess,
  getPauseTimeError,
} from "./action";

import { takeEvery, fork, put, all, takeLatest } from "redux-saga/effects";

function stringifyQueryParams(obj) {
  let queryStr = "";
  Object.keys(obj).map((key) => {
    let objKey = obj[key];
    if (Array.isArray(objKey)) {
      objKey = objKey.join(",");
    }
    if (objKey !== false && objKey !== 0 && !objKey) {
      return false;
    } // return if ObjectKey is falsy
    try {
      const decodeUri = decodeURIComponent(objKey);
      queryStr += `${key}=${encodeURIComponent(decodeUri)}&`;
    } catch (e) {
      queryStr += `${key}=${objKey}&`;
    }
    return queryStr;
  });
  return queryStr.length ? `?${queryStr.substr(0, queryStr.length - 1)}` : "";
}

const addParamToUrl = (relativeUrl, queryParam, isFetch) => {
  const kvp = relativeUrl.split("?");
  let existing = {};
  if (kvp.length > 1) {
    // decoder option for parse to override decoding of properties and values
    existing = queryString.parse(kvp[1]);
  }
  if (isFetch) {
    existing = { ...queryParam, ...existing };
  } else {
    existing = { ...existing, ...queryParam };
  }
  return `${kvp[0]}${stringifyQueryParams(existing)}`;
};

function* getAllVideos(data) {
  try {
    const response = yield fetch(
      data.payload.token !== null && data.payload.token !== undefined
        ? BASE_URL + "api/videoListByCategory?token=" + data?.payload?.token
        : BASE_URL + "api/videoListByCategory",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    // else {
    //     response = yield fetch(BASE_URL + "api/videoListByCategory",
    //         {
    //             method: 'GET',
    //             headers: {
    //                 "Accept": "application/json",
    //             },
    //         }).then(res => res.json())
    //         .catch(error => ({
    //             status: "error",
    //             errors: { message: "Server not responding" }
    //         }));
    // }

    // {"status":false,"result":null,"message":"Invalid Crediantials"}

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getVideoSuccess(response.result));
    } else {
      yield put(getVideoError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getVideoError(error.message));
  }
}

function* getVideoBanner(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(
      BASE_URL +
        "api/bannerList?banner_category=" +
        data.payload.bannerCategory,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // "Authorization": "Bearer " + data.payload.token
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getBannerSuccess(response.result));
    } else {
      yield put(getBannerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getBannerError(error.message));
  }
}

function* getVideoDetailById(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      video_title: data?.payload?.video_title_url,
      token: data?.payload?.token,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/videoDetail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('responedadadadadadad', response);
      yield put(getVideoDetailSuccess(response.result));
    } else {
      yield put(getVideoDetailError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getVideoDetailError(error.message));
  }
}
function* getVideoByCategory(data) {
  try {
    const response = yield fetch(
      data.payload.token !== null && data.payload.token !== undefined
        ? BASE_URL +
            "api/videoListByCategory?category_name=" +
            data.payload.category_name +
            "&token=" +
            data.payload.token
        : BASE_URL +
            "api/videoListByCategory?category_name=" +
            data.payload.category_name,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getVideosByCategoryIdSuccess(response.result));
    } else {
      yield put(getVideosByCategoryIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getVideosByCategoryIdError(error.message));
  }
}

function* savePauseTime(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      service_category: data?.payload?.service_category,
      service_id: data?.payload?.service_id,
      playble_item_id: data?.payload?.playble_item_id,
      resume_at: data?.payload?.resume_at,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/addOrUpdatePlaylist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(savePauseTimeSuccess(response.result));
      //data.payload.history.push('/course/course-description/' + data.payload.id + '/course-detail');
    } else {
      yield put(savePauseTimeError(response.message));
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(savePauseTimeError(error.message));
  }
}

function* getPauseTimeById(data) {
  try {
    const url = addParamToUrl(BASE_URL + "api/getResumeTime", {
      ...data.payload.qs,
    });
    const response = yield fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getPauseTimeSuccess(response.result));
    } else {
      yield put(getPauseTimeError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getPauseTimeError(error.message));
  }
}

export function* watchgetBanner() {
  yield takeLatest(GET_BANNER, getVideoBanner);
}
export function* watchgetVideos() {
  yield takeEvery(GET_VIDEO, getAllVideos);
}
export function* watchgetVideoDetail() {
  yield takeEvery(GET_VIDEO_DETAIL, getVideoDetailById);
}
export function* watchgetVideoByCategoryId() {
  yield takeEvery(GET_VIDEOS_BY_CATEGORY_ID, getVideoByCategory);
}
export function* watchgetSetPauseTime() {
  yield takeEvery(SET_PAUSE_TIME, savePauseTime);
}
export function* watchgetGetPauseTime() {
  yield takeEvery(GET_PAUSE_TIME, getPauseTimeById);
}
function* videosSaga() {
  yield all([
    fork(watchgetBanner),
    fork(watchgetVideos),
    fork(watchgetVideoDetail),
    fork(watchgetVideoByCategoryId),
    fork(watchgetSetPauseTime),
    fork(watchgetGetPauseTime),
  ]);
}

export default videosSaga;
