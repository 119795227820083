import React, { PureComponent, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Plan = (props) => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 4,
        speed: 500
    };

    return (
        <section className="goal my-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <h2>Plan </h2>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    {[1, 2, 3]?.map((element, index) => (
                        <div className="col-lg-3 pr-5">
                            <div className="card w-100">
                                <img className="card-img-top" src={"./assets/new_layout/img/Image 189.png"} alt="Card image cap" />
                                <div className="card-body">
                                    <h5 className="card-title">LaunchPad</h5>
                                    <p>12 Lessons</p>
                                    <a href="" className="btn-free">Free</a>
                                    <p className="mt-4">
                                        <img src={"./assets/new_layout/img/Path 9.png"} />
                                        <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                        <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                        <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                        <small>(4.0)</small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        services: state.dashboardReducer.services,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Plan));
