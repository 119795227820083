import React, { PureComponent } from "react";
import { getAllActivity } from "../../../store/protected/my-activity/action";
import { Link, withRouter } from "react-router-dom";
import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";

class FavouriteActivity extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  isFavourite = (id) => {
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
      this.props.dispatchActivity({
        token: this.props.token,
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };


  render() {
    // console.log('cources letest', this.props?.favouriteList);
    return (
      <React.Fragment>
        {this.props?.favouriteList?.length > 0 && (
          <div className="course-row">
            <div className="course-row-header">
              <h2>Boxsets</h2>
            </div>
            <div className="all-course-slider">
              {this.props?.favouriteList?.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  items={4}
                  nav
                  responsive={this.state.responsive}
                >
                  {this.props?.favouriteList?.map((course, id) => (
                    <div className="item" key={id}>
                      <div className="course-sld-box">
                        <div className="course-sld-img">
                          <img alt="img" src={course.thumbnail_url} />
                          <button
                            className={
                              course.is_favourite ? "fav-btn1" : "fav-btn"
                            }
                            onClick={() => this.isFavourite(course?.id)}
                          >
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <Link
                          to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                        >
                          <div className="course-sld-cnt">
                            <h4>
                              {course?.course_name?.length <= 50
                                ? course?.course_name
                                : course?.course_name?.substring(0, 50) + "..."}
                            </h4>
                            <h5>Total Chapters-{course.no_of_chapters}</h5>
                            <h6>
                              <span>
                                {course.course_type == 0 ? "Free" : "Paid"}
                              </span>
                            </h6>
                            <p>
                              <span>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    favouriteList: state?.activityReducer?.allActivity?.favourites,
    token: state.authReducer?.user?.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
    dispatchActivity: (data) => dispatch(getAllActivity(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FavouriteActivity));
