import React, { PureComponent } from "react";
import { getDashboardBanner } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DashboardBanner extends PureComponent {
  componentDidMount() {
    this.props.dispatchBannerCategory({
      bannerCategory: "dashboard",
      token: this.props.token,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="banner-slider">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {this.props?.dashboardBanner?.map((slide, ind) => (
                <div
                  className={
                    ind === 0 ? "carousel-item active" : "carousel-item"
                  }
                  key={ind}
                >
                  {slide.link === null ? (
                    <>
                      <img
                        alt={
                          slide?.banner_alt_text
                            ? slide?.banner_alt_text
                            : "Banner image"
                        }
                        src={slide?.banner_url}
                      />
                      <div className="banner-overlay"></div>
                    </>
                  ) : (
                    <a href={slide.link}>
                      <img
                        alt={
                          slide?.banner_alt_text
                            ? slide?.banner_alt_text
                            : "Banner image"
                        }
                        src={slide?.banner_url}
                      />
                      <div className="banner-overlay"></div>
                    </a>
                  )}
                  <div className="banner-caption">
                    {ind === 0 && slide.banner_heading !== null ? (
                      <h1>{slide.banner_heading}</h1>
                    ) : (
                      <h2>{slide.banner_heading}</h2>
                    )}
                    <p>{slide.banner_text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="left-right-arrow">
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                {" "}
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                {" "}
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>{" "}
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dashboardBanner: state.dashboardReducer.dashboardBanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchBannerCategory: (data) => dispatch(getDashboardBanner(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardBanner));
