import React, { PureComponent } from "react";

import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class BlogActivity extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  goBlog = (id) => {
    this.props.history.push(`/blogs/${id}`);
  };
  sharePop = () => {
    window.$("#shareModel").modal("show");
  };
  render() {
    return (
      <React.Fragment>
        <SharePopUpModel />
        {this.props?.BlogList?.length > 0 && (
          <div className="blog-row">
            <div className="blog-row-header">
              <h2>Blogs</h2>
            </div>
            {this.props?.BlogList?.length > 0 && (
              <React.Fragment>
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  items={3}
                  nav
                  responsive={this.state.responsive}
                >
                  {this.props?.BlogList?.map((blog, index) => (
                    <div className="blog-slider" key={index}>
                      <div className="blog-list">
                        <div className="item">
                          <div className="blog-sld-box">
                            <div className="blog-sld-img">
                              <img alt="img" src={blog.banner_url} />
                            </div>
                            <div className="blog-sld-cnt">
                              <div className="blog-sld-top">
                                <button
                                  onClick={this.sharePop}
                                  type="button"
                                  className="share-blg"
                                >
                                  <img src="/assets/images/share-gray-icon.png" />
                                </button>
                                <h2 className="title">{blog.blog_title}</h2>
                                <div className="blogDesc">
                                  {ReactHtmlParser(blog.preview_description)}
                                </div>
                              </div>
                              <div className="blog-sld-footer">
                                <img
                                  alt="img"
                                  src="assets/images/course-slider-3.jpg"
                                />
                                <h5>Richard</h5>
                                <p>{blog.created_at}</p>
                              </div>
                              <button
                                onClick={() => this.goBlog(blog.id)}
                                className="dlt-blg"
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    BlogList: state?.activityReducer?.allActivity?.blogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlogActivity));
