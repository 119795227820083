import React, { Component } from "react";
import NewsFeed from "./NewsFeeds";
import LetestVideos from "./LetestVideos";

class FeedVideo extends Component {
  render() {
    return (
      <div className="dashboard-btm">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <NewsFeed />
            </div>
            <div className="col-lg-8 col-md-7">
              <div className="video-row">
                <div className="video-row-header">
                  <LetestVideos />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeedVideo;
