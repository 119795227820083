import React, { PureComponent } from "react";

import {
  getBlog,
  getBlogByCategoryId,
} from "../../../store/protected/blogs/action";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class BlogCategory extends PureComponent {
  
  responsive = {
    0: {
      items: 2,
    },
    450: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
  };

  componentDidMount() {
    console.log("componentDidMount");
    this.props.dispatchGetBlogCategory({
      category_id: this.props.match.params.name,
      token: this.props.token,
    });

    this.props.dispatchBlogCategory({
      token: this.props.token,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name != this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      console.log("param change");
      this.props.dispatchGetBlogCategory({
        category_id: this.props.match.params.name,
      });
    }
  }

  render() {
    const galleryItems = this.props?.getBlog?.data?.map((list, index) => (
      <div className="item" key={index}>
        <Link to={`/blog/${list.category_title_url}`}>
          <img alt="icon" src={list?.category_icon_url} /> {list?.category_name}
        </Link>
      </div>
    ));
    return (
      <React.Fragment>
        <div className="container">
          <div className="blog-cat-sld">
            <AliceCarousel
              items={galleryItems}
              responsive={this.responsive}
              buttonsDisabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getBlog: state.blogReducer.blogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchBlogCategory: (data) => dispatch(getBlog(data)),
    dispatchGetBlogCategory: (data) => dispatch(getBlogByCategoryId(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlogCategory));
//export default BlogCategory
