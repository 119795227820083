import React, { Component } from "react";

import Banner from "../../../layout/auth/Abanner";
import AuthHeader from "../../../layout/auth/Aheader";
import AuthFooter from "../../../layout/auth/Afooter";
import Header from "../../../layout/gaust/Header";
import CourseCategory from "./courseCategory";
import Login from "../../gaust/login";
import { getCoursesByCategoryId } from "../../../store/protected/courses/action";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";


class CoursesByCategoryId extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    // document.title = "Course-Category :: Maetrix";
    this.props.dispatchCoursesByCategoryId({
      id: this.props.match.params.name,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name !== this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      this.props.dispatchCoursesByCategoryId({
        id: this.props.match.params.name,
      });
    }
  }
  render() {
    return (
      <div>
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <Banner />

        <div className="course-main page-height ">
          <div className="container">
            <CourseCategory />
            <div className="cat-video-row">
              <div className="container">
                <div className="cat-video-head">
                  <h2>
                    <img
                      alt="img"
                      src={this.props?.coursesList?.category_icon_url}
                    />
                    {this.props?.coursesList?.category_name}
                  </h2>
                </div>
                <div className="row">
                  {this.props?.coursesList?.courses?.map((course, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                      <div className="cat-video-box">
                        <div className="cat-video-img">
                          {/* <img alt="img" src={course?.course_banner} /> */}
                          <img alt="img" src={course?.thumbnail_url} />
                          <button className="fav-btn">
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        {
                          <Link
                            to={`/course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                          >
                            <div className="cat-video-cnt">
                              <h3>{course?.course_name}</h3>
                              <h4>Total Lessons- {course?.no_of_lessons}</h4>
                              <h5>
                                <span>
                                 

                                  {course.course_type === 0 &&
                                  (course.is_subscribed === true ||
                                    course.is_subscribed === false) &&
                                  (course.is_independent === true ||
                                    course.is_independent === false)
                                    ? "Free"
                                    : course.course_type === 1 &&
                                      course.is_independent === false &&
                                      course.is_subscribed === false
                                    ? `£ ${course.monthly_fee}`
                                    : course.course_type === 1 &&
                                      course.is_independent === true &&
                                      course.is_subscribed === false
                                    ? `£ ${course.course_fee}`
                                    : "View Course"}
                                </span>
                              </h5>

                              <p>
                                <span>
                                  {course.rating === 1 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      =
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                    </>
                                  ) : course.rating === 2 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : course.rating === 3 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : course.rating === 4 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  )}
                                </span>
                              </p>
                            </div>
                          </Link>
                        }
                      </div>
                    </div>
                  ))}

                  {this.state.showLoginModel === true && <Login />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AuthFooter token={this.props.token} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    coursesList: state.coursesReducer?.coursesList?.data[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCoursesByCategoryId: (data) =>
      dispatch(getCoursesByCategoryId(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoursesByCategoryId));
