import React, { Component } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import AuthHeader from "../../../layout/auth/Aheader";
import AuthFooter from "../../../layout/auth/Afooter";
import SubscriptionPop from "./subscription-popup";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import axios from "axios";
import Header2 from "../../../layout/gaust/newLayout/Header2";

import { getSubnscriptionList } from "../../../store/protected/subscription/action";
const currencySymbols = {
  usd: "$",
  aud: "A$",
  gbp: "£",
  nzd: "NZ$",
};
class SubscriptionList extends Component {
  componentDidMount() {
    // document.title = "Maetrix Subscription :: The Maetrix";
    this.props.dispatchSubscription({
      token: this.props.token,
    });
  }

  handleClick = async (list) => {
    const stripe = await stripePromise;
    let services = [];
    let service = {
      service_id: list?.id,
      service_name: list?.tag_name,
      service_category: list?.service_category,
    };
    services = services.concat(service);
    // const url = `${BASE_URL}api/subscriptionPaymentTest`;
    const url = `${BASE_URL}api/subscriptionPayment`;
    const response = await axios.post(
      url,
      { services },
      {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      }
    );
    const apiResult = await response.data.result;
    localStorage.setItem("Checkout_Response", JSON.stringify(apiResult), () => {
      console.log("locaalstorage", localStorage.getItem("Checkout_Response"));
    });
    const result = await stripe.redirectToCheckout({
      sessionId: apiResult?.session?.id,
    });
    if (result.error) {
      console.log("error", result.error.message);
    }
  };

  go = () => {
    console.log("hello user");
    this.props.history.push("/");
  };

  render() {
    return (
      <div>
        <AuthHeader token={this.props.token} />
        <section className="subscription my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 text-center">
                <h2>Subscription</h2>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4 my-5">
              <div className="col-md-12 text-center">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                  style={{ "justifyContent": "center" }}
                >
                  {/*<li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">One time <br/> <span>Save 10% total</span></button>
                        </li> */}
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link  active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Recurrent <br /> <span>(Monthly)</span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade  show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row row-cols-1 row-cols-md-4 g-4 my-5">
                      {this.props?.subscriptionsList?.map((list, index) => (
                        <div className="col" key={index}>
                          <div className="card h-100">
                            <div className="card-body pb-5">
                              <h4 className="card-title pt-4">
                                {list?.tag_name}
                                <br /> {/*<span>Starter</span>*/}
                              </h4>
                              {list.tag_description.map((item, ind) => (
                                <p className="card-text pt-2" key={ind}>
                                  <img
                                    src={
                                      "./assets/new_layout/img/Path 3167.png"
                                    }
                                  />{" "}
                                  {item}
                                </p>
                              ))}
                            </div>
                            <div className="card-footer pt-3 pb-5">
                              <p>
                                {/* € {list?.fee} <span>/month</span> */}
                                {list?.price && parseInt(list?.price) > 0
                                  ? `${currencySymbols[list.currency]}  ${list.price
                                  }`
                                  : `£ ${list.fee}`}
                                <span>/month</span>
                              </p>
                              <button type="button" className="choose-btn">
                                {list.fee == 0 && list?.is_default ? (
                                  <span onClick={() => this.go()}>
                                    Activated & Explore Now
                                  </span>
                                ) : list.is_active === true ? (
                                  <span>Active Subscription</span>
                                ) : (
                                  <span onClick={() => this.handleClick(list)}>
                                    CHOOSE
                                  </span>
                                )}
                              </button>
                              {list.is_expired == true && (
                                <button
                                  type="button"
                                  style={{
                                    bottom: "52px",
                                    background: "red",
                                    color: "white",
                                    border: "2px solid white",
                                  }}
                                >
                                  Subscription Expired
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <AuthFooter userDetail={this.props.userName} token={this.props.token} />
        {
          <SubscriptionPop
            selectedPlan={this.state?.selectedPlan}
            token={this.props.token}
            history={this.props.history}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptionsList: state.subscriptionsListReducer.subscriptionsList,
    userName: state.authReducer?.user?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSubscription: (data) => dispatch(getSubnscriptionList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionList));
