import React, { PureComponent, useRef, useState, useEffect } from "react";

const DataLoadingNew = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="loade-sec">
                    <div className="loader"></div>
                    <h5>Loading...</h5>
                </div>
            </div>
        </div>
    )
}
export default DataLoadingNew;
