import {
  GET_BLOG,
  GET_BLOG_SUCCESS,
  GET_BLOG_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_BLOG_DETAIL,
  GET_BLOG_DETAIL_SUCCESS,
  GET_BLOG_DETAIL_ERROR,
  GET_BLOG_BY_CATEGORY_ID,
  GET_BLOG_BY_CATEGORY_ID_SUCCESS,
  GET_BLOG_BY_CATEGORY_ID_ERROR,
} from "./actionType";
const initialState = {
  error: null,
  loading: null,
  blogs: null,
  blogDetail: null,
  blogList: null,
  blogBanner: null,
  blogDetailById: null,
  blogByCategoryId: null,
};
const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG:
      state = {
        error: null,
        loading: true,
        blogs: null,
      };
      break;
    case GET_BLOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        blogs: action.payload,
      };
      break;
    case GET_BLOG_ERROR:
      state = {
        ...state,
        loading: false,
        blogs: null,
        error: action.payload,
      };
      break;
    case GET_BANNER:
      state = {
        error: null,
        loading: true,
        podcastBanner: null,
      };
      break;
    case GET_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        podcastBanner: action.payload,
      };
      break;
    case GET_BANNER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case GET_BLOG_DETAIL:
      state = {
        error: null,
        loading: true,
        blogDetailById: null,
      };
      break;
    case GET_BLOG_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        blogDetailById: action.payload,
      };
      break;
    case GET_BLOG_DETAIL_ERROR:
      state = {
        ...state,
        loading: false,
        blogDetailById: null,
        error: action.payload,
      };
      break;
    case GET_BLOG_BY_CATEGORY_ID:
      state = {
        ...state,
        error: null,
        loading: true,
        blogByCategoryId: null,
      };
      break;
    case GET_BLOG_BY_CATEGORY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        blogByCategoryId: action.payload,
      };
      break;
    case GET_BLOG_BY_CATEGORY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        blogByCategoryId: null,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default blogReducer;
