import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {
    Year: null,
  };
  componentDidMount() {
    const date = new Date();
    let year = date.getFullYear();
    this.setState({
      Year: year,
    });
  }
  render() {
    return (
      <div className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <p>Copyright © 2024 RCS Rocks Ltd</p>
            </div>
            <div className="col-md-5">
              <ul className="social-nav">
                <li>
                  <Link style={{ color: "white" }} to="/terms-and-conditions">
                    Terms & Condition
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "white" }} to="/privacy-policy">
                    Privacy & Policy
                  </Link>
                </li>
                <li>Follow us</li>
                <li>
                  <a
                    href="https://www.facebook.com/aestheticentrepreneurs"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="fb-icon" src="/assets/images/fb-icon.png"></img>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/theaestheticentrepreneurs/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      alt="insta-icon"
                      src="/assets/images/insta-icon.png"
                    ></img>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
