export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR'

export const GET_MEMBER_DETAIL = 'GET_MEMBER_DETAIL';
export const GET_MEMBER_DETAIL_SUCCESS = 'GET_MEMBER_DETAIL_SUCCESS';
export const GET_MEMBER_DETAIL_ERROR = 'GET_MEMBER_DETAIL_ERROR'

export const GET_MEMBER_LOYALITY_DETAIL = 'GET_MEMBER_LOYALITY_DETAIL';
export const GET_MEMBER_LOYALITY_DETAIL_SUCCESS = 'GET_MEMBER_LOYALITY_DETAIL_SUCCESS';
export const GET_MEMBER_LOYALITY_DETAIL_ERROR = 'GET_MEMBER_LOYALITY_DETAIL_ERROR'