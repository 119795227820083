import React, { useEffect, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import DataLoading from "../../common/DataLoading";
import DateFormat from "../../../helpers/DateHelper";
import { getDashboardUserGoal } from "../../../store/protected/dashboard/action";
import "./weekly-tracker.css";
// const WeeklyTracker = (props) => {
class WeeklyTracker extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.dispatchGetDashboardUserGoal({
      token: this.props.token,
    });
  }
  getWeekNumber = (date) => {
    date = new Date(date);

    // Set the date to the nearest Thursday (the day the ISO week starts on)
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));

    // Get the year of the week
    var year = date.getFullYear();

    // Calculate the week number
    var weekNumber = Math.ceil(
      ((date - new Date(year, 0, 1)) / 86400000 + 1) / 7
    );

    return weekNumber;
  };
  render() {
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <div className="container page-height weekly-tracker">
          {this.props.updatingGoal ? (
            <DataLoading />
          ) : (
            <>
              <div className="row-names">
                <div>
                  <label>Date</label>
                </div>
                <div>
                  <label>Goal</label>
                </div>
                <div>
                  <label>Focus</label>
                </div>
                <div>
                  <label>Task</label>
                </div>
                <div>
                  <label>Success</label>
                </div>
              </div>
              <div className="row-body">
                {this.props.userGoal &&
                  this.props.userGoal.length > 0 &&
                  this.props.userGoal.map((goal, index) => (
                    <div className="goal-row" key={index}>
                      <div className="centered-text">
                        <div>
                          <p>
                            Week-{this.getWeekNumber(goal.created_at)}(
                            {DateFormat(goal.created_at)})
                          </p>
                        </div>
                      </div>
                      <div className="centered-text">
                        <div>
                          {goal.goal.split(",").map((g, g_index) => (
                            <p key={`focus_${g_index}`}>{g}</p>
                          ))}
                        </div>
                      </div>
                      <div className="focus centered-text">
                        <div>
                          {goal.focus.split(",").map((f, f_index) => (
                            <p key={`focus_${f_index}`}>{f}</p>
                          ))}
                        </div>
                      </div>
                      <div className="centered-text">
                        <div>
                          {goal.task.split(",").map((t, t_index) => (
                            <p key={`task_${t_index}`}>{t}</p>
                          ))}
                        </div>
                      </div>
                      <div className="centered-text">
                        <div>
                          {goal.success.split(",").map((s, s_index) => (
                            <p key={`success_${s_index}`}>{s}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    updatingGoal: state.dashboardReducer.updatingGoal,
    userGoal: state.dashboardReducer.goal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetDashboardUserGoal: (data) =>
      dispatch(getDashboardUserGoal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WeeklyTracker));
