import React, { useState } from "react";
import { BASE_URL } from "../../../helpers/Constants";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function ReportModel({ value, token }) {
  const [radioButton, setRadioButton] = useState({
    spam: "",
    nudity: "",
    exploitation: "",
    harassment: "",
    other: "",
  });
  const [description, setDescription] = useState("");

  const handleResetForm = (event) => {
    setRadioButton({
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `${BASE_URL}api/reportChat`;
    axios
      .post(
        url,
        {
          suspect_uuid: value.data.userId,
          report_type: radioButton.spam
            ? radioButton.spam
            : radioButton.nudity
            ? radioButton.nudity
            : radioButton.exploitation
            ? radioButton.exploitatio
            : radioButton.harassment
            ? radioButton.harassmen
            : radioButton.other
            ? radioButton.other
            : radioButton.other,
          message: value?.data?.userMessage,
          description: description,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setDescription("");
        setRadioButton({
          spam: "",
          nudity: "",
          exploitation: "",
          harassment: "",
          other: "",
        });
        window.$("#reportPop").modal("hide");
        toast.success(
          res.data.message
            ? res.data.message
            : "Your Request Submit Successfully!!",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };
  return (
    <div className="report-popup">
      <ToastContainer />
      <div className="modal fade" id="reportPop">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} autoComplete="off">
                <h3>Report This Message?</h3>
                <div className="report-radio">
                  <label>
                    <input
                      type="radio"
                      onChange={handleResetForm}
                      name="spam"
                      value="Spam"
                    />
                    <span className="label-text">Spam</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      onChange={handleResetForm}
                      name="spam"
                      value="Contains nudity or pornography"
                    />
                    <span className="label-text">
                      Contains nudity or pornography
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      onChange={handleResetForm}
                      name="spam"
                      value="Child endangerment (exploitation)"
                    />
                    <span className="label-text">
                      Child endangerment (exploitation)
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      onChange={handleResetForm}
                      name="spam"
                      value="Harassment or treats"
                    />
                    <span className="label-text">Harassment or treats</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      onChange={handleResetForm}
                      name="spam"
                      value="Other"
                    />
                    <span className="label-text">Others</span>
                  </label>
                </div>
                <div className="form-group">
                  <textarea
                    onChange={(event) => setDescription(event.target.value)}
                    placeholder="Write here your report..."
                    name="description"
                    value={description}
                  ></textarea>
                </div>
                <div className="sbm-btn">
                  <button type="submit">Report</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportModel;
