import React from "react";

import { Redirect } from "react-router-dom";

// import { Route, Redirect } from "react-router";

import BoxsetsMain from "../components/auth/boxsets/index";
import CourseMain from "../components/auth/courses/MainCoursePage";
import CourseDescription from "../components/auth/courses/course-description";
import Videos from "../components/auth/videos";
import Podcast from "../components/auth/podcasts";
import Blog from "../components/auth/blogs";
//import CategoryDetail from "../components/auth/courses/categorydetail"
import CategoryDetail from "../components/auth/boxsets/categorydetail";
import Cart from "../components/auth/cart";
import VideoDetail from "../components/auth/videos/VideoDetail";
import SupportFeedback from "../components/auth/support-feedback";
import ResetPassword from "../components/gaust/login/resetPassword";
import CategoryDetails from "../components/auth/videos/categoryDetails";
import PodcastCategoryDetail from "../components/auth/podcasts/PodcastCategoryDetail";
import BlogCategoryDetail from "../components/auth/blogs/CategoryDetail";
import Home from "../components/gaust/homePage/index";
import Search from "../components/auth/search/search";
import BlogDetail from "../components/auth/blogs/BlogDetail";
import TermCondition from "../components/auth/term&condition/Term&Condition";
import PrivacyPolicy from "../components/auth/term&condition/PrivacyPolicy";
import LiveStreaming from "../components/auth/streaming/Main";
import PodcastDetail from "../components/auth/podcasts/PodcastDetail";
import LandingPage from "../components/gaust/LandingPage/Landing";
import MemberDetail from "../components/auth/members/MemberDetail";
import DirectPayment from "../components/auth/directPayment";
import ActivateAccount from "../components/gaust/Activate/activateAccount";

import NewUserSuccess from "../components/auth/paymentStatus/NewUserSuccess";
import NewUserFail from "../components/auth/paymentStatus/NewUserFail";
import NewUser from "../components/auth/directPayment/NewUser";
import NewHomePage from "../components/gaust/newHome/index";
import ReferralPage from "../components/gaust/referral/referralPage";

// import NotFound from "../components/NotFound/NotFound";
import Error404 from "../components/NotFound/NotFound";
import Calendar from "../components/auth/calendar";

// const routes = window.location.pathname;
// console.log(routes);

// history.replace(`${/courses}`);
// setTimeout(() => {
//   history.replace(routes);
// }, 0);

const publicRoutes = [
  { path: "/error", exact: true, component: { Error404 } },
  /*{{ path: "/", exact: true, component: Home },}*/
  { path: "/", exact: true, component: NewHomePage },
  { path: "/referral", exact: true, component: ReferralPage },
  { path: "/calendar", exact: true, component: Calendar },
  { path: "/dashboard", exact: true, component: () => <Redirect to="/" /> },
  { path: "/lp", exact: true, component: LandingPage },
  { path: "/videos", exact: true, component: Videos },
  { path: "/podcasts", exact: true, component: Podcast },
  {
    path: "/course/:categoryName/:id/:name",
    exact: true,
    component: CourseDescription,
  },
  {
    path: "/course/:name",
    exact: true,
    component: CategoryDetail,
    ignore_auth: true,
  },
  { path: "/support", exact: true, component: SupportFeedback },
  {
    path: "/courses-1",
    exact: true,
    component: BoxsetsMain,
    ignore_auth: true,
  },
  {
    path: "/courses",
    exact: true,
    component: BoxsetsMain,
    ignore_auth: true,
  },
  { path: "/blogs", exact: true, component: Blog },
  { path: "/resetpassword", component: ResetPassword },
  { path: "/member/:id/:name", exact: true, component: MemberDetail },
  { path: "/cart", exact: true, component: Cart },
  {
    path: "/video/:categoryName/:name",
    exact: true,
    component: VideoDetail,
  },
  { path: "/video/:name", exact: true, component: CategoryDetails },
  { path: "/podcast/:name", exact: true, component: PodcastCategoryDetail },
  { path: "/blog/:name", exact: true, component: BlogCategoryDetail },
  { path: "/blog/:name", exact: true, component: BlogDetail },
  { path: "/blog/:categoryName/:name", exact: true, component: BlogDetail },
  { path: "/search/:text", exact: true, component: Search },
  { path: "/terms-and-conditions", exact: true, component: TermCondition },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
  { path: "/maetrixlive", exact: true, component: LiveStreaming },
  {
    path: "/podcast/:categoryName/:name",
    exact: true,
    component: PodcastDetail,
  },
  { path: "/direct-payment", exact: true, component: DirectPayment },
  { path: "/account/activation", exact: true, component: ActivateAccount },
  {
    path: "/new-user/payment-status/payment-success",
    exact: true,
    component: NewUserSuccess,
  },
  {
    path: "/new-user/payment-status/payment-failed",
    exact: true,
    component: NewUserFail,
  },
  { path: "/new-user", exact: true, component: NewUser },
];

export { publicRoutes };
