import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import VideoSlider from "./VideoSlider";
import VideoCategory from "./VideoCategory";
import SharePopUp from "./../sharePopUp";
import { getVideo } from "../../../store/protected/videos/action";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Videos extends PureComponent {
  state = {
    showLoginModel: false,
    showSharePopUp: false,
  };
  responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  componentDidMount() {
    // document.title = "Videos :: The Maetrix";
    this?.props?.token !== null && this?.props?.token !== undefined
      ? this.props.dispatchVideoList({
          token: this?.props?.token,
        })
      : this.props.dispatchVideoList({});
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <div>
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <VideoSlider />
        <div className="all-videos page-height">
          <VideoCategory />

          {this.props?.videosList?.data?.map(
            (vidList, vidIndex) =>
              vidList?.videos?.length > 0 && (
                <React.Fragment key={vidIndex}>
                  <div className="video-row">
                    <div className="container">
                      <div className="video-row-header">
                        <h2>{vidList.category_name}</h2>
                        <Link
                          to={`/video/${vidList.category_title_url}`}
                          className="right-link"
                        >
                          View All{" "}
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                      <div className="video-slider">
                        <OwlCarousel
                          className="owl-theme"
                          margin={10}
                          items={4}
                          nav={true}
                          responsive={this.responsive}
                        >
                          {vidList?.videos?.map((videos, vList) => (
                            <div className="item" key={vList}>
                              <div className="video-sld-box">
                                <div className="video-sld-img">
                                  <img alt="img" src={videos.banner_url} />
                                </div>
                                <div className="video-sld-cnt">
                                  {
                                    <Link
                                      to={`/video/${videos.category_title_url}/${videos.video_title_url}`}
                                    >
                                      <p className="right-date">
                                        {videos.created_at}
                                      </p>
                                      <div className="video-sld-cnt-mid">
                                        <img
                                          alt="img"
                                          src="/assets/images/Play-btn.png"
                                        />
                                        <h3>
                                          <span>
                                            {videos.video_type === 0 &&
                                            (videos.is_subscribed === true ||
                                              videos.is_subscribed === false)
                                              ? "Free"
                                              : videos.video_type === 1 &&
                                                videos.is_subscribed === true
                                              ? "View Video"
                                              : `£ ${videos.fee}`}
                                          </span>
                                        </h3>
                                        <div className="video-sld-left">
                                          <h4>{videos.video_title}</h4>
                                        </div>
                                        <div className="video-sld-right">
                                          <p>{videos.duration}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  }
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
        </div>
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
        {this.state.showSharePopUp === true && <SharePopUp />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videosList: state.videosReducer?.videos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchVideoList: (data) => dispatch(getVideo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Videos));
