import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/newLayout/Header";
import Footer from "../../../layout/gaust/newLayout/FooterHome";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Calendar extends PureComponent {
  componentDidMount() {
    var elem = document.getElementById("calendar");
    elem.addEventListener("onload", this.myFunc());
  }

  myFunc(e) {
    e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = "https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js";
    e.className = "ae-emd-script";
    document.getElementsByTagName("body")[0].appendChild(e);
  }
  myFunction = (value) => {
    //console.log('value', value);
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="matrix_new_layout">
          <>
            {this.props.token ? (
              <AuthHeader
                token={this.props.token}
                history={this.props.history}
              />
            ) : (
              <Header hitHome={this.myFunction} />
            )}
            <div
              id="calendar"
              className="ae-emd-cal"
              data-calendar="sR520112"
              data-configure="false"
            ></div>
          </>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Calendar));
