import React, { Component } from "react"

import { BASE_URL, stripePromise } from "../../../helpers/Constants"
import Login from "../../gaust/login"
import axios from "axios"
import NewUser from "./NewUser"

export class index extends Component {
	state = {
		service_id: "",
		service_category: "",
		service_name: "",
		user: "",
	}

	checkoutPopUp = async () => {
		const stripe = await stripePromise
		//const url = `${BASE_URL}api/createSessionForPaymentV2Test`;
		const url = `${BASE_URL}api/createSessionForPaymentV2`
		const response = await axios.post(
			url,
			{
				service_id: this.state?.service_id,
				service_category: this.state?.service_category,
				service_name: this.state?.service_name,
			},
			{
				headers: {
					authorization: "Bearer" + this.props.token,
				},
			}
		)
		const apiResult = await response.data.result
		localStorage.setItem("cart_Checkout", JSON.stringify(apiResult))
		const result = await stripe.redirectToCheckout({
			sessionId: apiResult?.session?.id,
		})
		if (result.error) {
			console.log("error", result.error.message)
		}
	}

	goToLogin = () => {
		window.$("#login-model").modal("show")
	}

	gotToRegister = () => {}

	handleClick = async (list) => {
		const stripe = await stripePromise
		const url = `${BASE_URL}api/subscriptionPaymentV2`
		//const url = `${BASE_URL}api/subscriptionPaymentV2Test`;
		const response = await axios.post(
			url,
			{
				service_id: this.state?.service_id,
				service_category: this.state?.service_category,
				service_name: this.state?.service_name,
			},
			{
				headers: {
					authorization: "Bearer" + this.props.token,
				},
			}
		)
		const apiResult = await response.data.result
		localStorage.setItem("Checkout_Response", JSON.stringify(apiResult))
		const result = await stripe.redirectToCheckout({
			sessionId: apiResult?.session?.id,
		})
		if (result.error) {
			console.log("error", result.error.message)
		}
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search)

		if (urlParams) {
			this.setState(
				{
					service_id: urlParams.get("service_id"),
					service_name: urlParams.get("service_name"),
					service_category: urlParams.get("service_category"),
					user: urlParams.get("user"),
				},
				() => {
					console.log(
						"1",
						this.state.service_id,
						"2",
						this.state.service_name,
						"3",
						this.state.service_category
					)
					if (urlParams.get("user") == "new") {
						this.gotToRegister()
						localStorage.setItem("new-user", "new")
					} else {
						if (this.props.token) {
							if (this.state?.service_category === "subscription") {
								this.handleClick()
							} else {
								this.checkoutPopUp()
							}
						} else {
							localStorage.setItem(
								"url_params",
								`service_category=${this.state.service_category}&service_id=${this.state.service_id}&service_name=${this.state.service_name}`
							)
							this.goToLogin()
						}
					}
				}
			)
		}
	}
	render() {
		return (
			<div>
				{this.state.user == "new" && <NewUser {...this.state} />}
				<Login />
			</div>
		)
	}
}

export default index
