import React from "react";
import "./AiWizardDrawer.css";
import ReactMarkdown from "react-markdown";
export const AiWizardDrawer = (props) => {
  const {
    open,
    type,
    onClose,
    onSelectType,
    title,
    loadingAIConent,
    aiContent,
    onClickUse,
    onClickCopy,
    onClickRegenerate,
  } = props;
  return (
    <div className="ai-wizard">
      <div
        className={`overlay ${open ? "overlayOpen" : "overlayHidden"} `}
        onClick={onClose}
        aria-hidden="true"
      />
      <div
        tabIndex="-1"
        className={`drawer right ${open ? "animate" : "hidden"}`}
      >
        <div className="header">
          {type != null && (
            <i
              onClick={() => onSelectType(null)}
              className="fa fa-arrow-left"
              aria-hidden="true"
            ></i>
          )}
          <h5>{title}</h5>
          {}
        </div>
        <div className="wizard-content">
          {type == null && (
            <>
              <div className="type" onClick={() => onSelectType("Campaign")}>
                <i className="fa fa-columns" aria-hidden="true"></i>
                &nbsp; Create Campaign
              </div>
              <div className="type" onClick={() => onSelectType("Content")}>
                <i className="fa fa-book" aria-hidden="true"></i>
                &nbsp;Create Content
              </div>
              <div className="type" onClick={() => onSelectType("Plan")}>
                <i className="fa fa-magic" aria-hidden="true"></i> &nbsp;Create
                Treatment Plan
              </div>
            </>
          )}
          {type != null && loadingAIConent && (
            <div className="skeleton-loading py-12">
              <div className="flex items-center">
                {/* <Spinner name="line-scale" /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="circle-half-stroke"
                  className="svg-inline--fa fa-circle-half-stroke fa-fw animate-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256c0-114.9-93.1-208-208-208V464c114.9 0 208-93.1 208-208zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                  ></path>
                </svg>
                &nbsp;<h6 style={{ margin: 0 }}>Answer</h6>
              </div>
              <div className="skeleton-placeholder" />
              <div className="skeleton-placeholder" />
            </div>
          )}
          {type != null && !loadingAIConent && aiContent && (
            <div className="ai-content">
              <div className="ai-content-header">
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  onClick={onClickRegenerate}
                >
                  &nbsp; Regenerate
                </i>
                <i
                  className="fa fa-thumbs-o-up"
                  aria-hidden="true"
                  onClick={onClickUse}
                >
                  &nbsp; Use this
                </i>
                <i
                  className="fa fa-files-o"
                  aria-hidden="true"
                  onClick={onClickCopy}
                >
                  &nbsp; Copy
                </i>
              </div>
              <div className="px-4 py-3">
                <ReactMarkdown>{aiContent}</ReactMarkdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
