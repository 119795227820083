import React, { Component } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Login from "../../gaust/login";
import Footer from "../../../layout/auth/Afooter";

class TermCondition extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  componentDidMount() {
    // document.title = "Term And Condition :: The Maetrix";
  }

  render() {
    return (
      <React.Fragment>
        {this.props.token ? (
          <AuthHeader token={this.props.token} history={this.props.history} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="termCondition page-height">
          <div className="container">
            <h3>1. INTRODUCTION</h3>
            <p>
              esthetic Entrepreneurs Ltd understands that your privacy is
              important to you and that you care about how your personal data is
              used. We respect and value the privacy of all of our clients and
              members and will only collect and use personal data in ways that
              are described here, and in a way that is consistent with our
              obligations and your rights under the law.
            </p>
            <p>Information About Us</p>
            <p>
              Aesthetic Entrepreneurs Ltd a limited company registered in
              England under company number 07768723.
            </p>
            <p>Registered address: 55A The Street, Farnham GU104QS</p>
            <p>VAT number: GB298985996</p>
            <p>We are registered with the Information Commissioners Office</p>
            <p>
              If you are not happy with any aspect of how we collect and use
              your data, you have the right to complain to the Information
              Commissioner’s Office (ICO), the UK supervisory authority for data
              protection issues (
              <a href="https://www.ico.org.uk">www.ico.org.uk</a>). We should be
              grateful if you would contact us first if you do have a complaint
              so that we can try to resolve it for you.
            </p>
            <p>
              It is very important that the information we hold about you is
              accurate and up to date. Please let us know if at any time your
              personal information changes by emailing us at
              hello@aesthetic-entrepreneurs.com
            </p>
            <p>Sensitive Data</p>
            <p>
              We do not collect any Sensitive Data about you. Sensitive data
              refers to data that includes details about your race or ethnicity,
              religious or philosophical beliefs, sex life, sexual orientation,
              political opinions, trade union membership, information about your
              health and genetic and biometric data. We do not collect any
              information about criminal convictions and offences.
            </p>
            <p>
              IF YOU DO COLLECT SENSITIVE DATA, DELETE THE ABOVE PARAGRAPH AND
              INSERT THE FOLLOWING:
            </p>
            <p>
              We need to collect data about you in order to provide access to
              our services
            </p>
            <h3>2. HOW WE USE YOUR PERSONAL DATA</h3>
            <p>
              We will only use your personal data when legally permitted. The
              most common uses of your personal data are:
            </p>
            <p>Where we need to perform the contract between us.</p>
            <p>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </p>
            <p>
              Where we need to comply with a legal or regulatory obligation.
            </p>
            <p>
              Generally, we do not rely on consent as a legal ground for
              processing your personal data, other than in relation to sending
              marketing communications to you via email or text message. You
              have the right to withdraw consent to marketing at any time by
              emailing us
            </p>
            <p>Purposes for processing your personal data</p>
            <p>
              Set out below is a description of the ways we intend to use your
              personal data and the legal grounds on which we will process such
              data. We have also explained what our legitimate interests are
              where relevant.
            </p>
            <p>Marketing communications</p>
            <p>
              You will receive marketing communications from us if you have:
            </p>
            <p>
              requested information from us or purchased goods or services from
              us; or if you provided us with your details and ticked the box at
              the point of entry of your details for us to send you marketing
              communications; and
            </p>
            <p>
              in each case, you have not opted out of receiving that marketing.
            </p>
            <p>
              We will get your express opt-in consent before we share your
              personal data with any third party for marketing purposes.
            </p>
            <p>
              You can ask us or third parties to stop sending you marketing
              messages at any time by emailing us Where you opt out of receiving
              our marketing communications, this will not apply to personal data
              provided to us as a result of a product/service purchase, warranty
              registration, product/service experience or other transactions.
            </p>
            <h3>3. DISCLOSURES OF YOUR PERSONAL DATA</h3>
            <p>We don’t share your personal data with any 3rd parties</p>
            <h3>7. DATA SECURITY</h3>
            <p>
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know such data. They will only process your personal data on our
              instructions and they are subject to a duty of confidentiality.
            </p>
            <p>
              We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
            <h3>8. DATA RETENTION</h3>
            <p>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature, and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements.
            </p>
            <p>
              By law we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              six years after they cease being customers for tax purposes.
            </p>
            <p>
              In some circumstances you can ask us to delete your data: see
              below for further information.
            </p>
            <p>
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes in which case we may use this information
              indefinitely without further notice to you.
            </p>
            <h3>9. YOUR LEGAL RIGHTS</h3>
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. These include the right
              to:
            </p>
            <p>Request access to your personal data.</p>
            <p> Request correction of your personal data. </p>
            <p>Request erasure of your personal data. </p>
            <p>Object to processing of your personal data. </p>
            <p>Request restriction of processing your personal data. </p>
            <p>Request transfer of your personal data.</p>
            <p> Right to withdraw consent. </p>
            <p>You can see more about these rights at:</p>
            <p>
              <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
                https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
              </a>
            </p>
            <p>
              If you wish to exercise any of the rights set out above, please
              email us
            </p>
            <p>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or
              excessive. Alternatively, we may refuse to comply with your
              request in these circumstances.
            </p>
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
            <p>
              We try to respond to all legitimate requests within one month.
              Occasionally it may take us longer than a month if your request is
              particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
          </div>
        </div>
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}

export default TermCondition;
