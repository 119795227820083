import React, { Component } from "react";

class LiveStreamingVideo extends Component {
  render() {
    let url = this.props?.response?.stream_url;
    return (
      <div className="col-lg-8 col-md-7">
        <div className="strm-vdo">
          <div className="strm-ttl">
            <h2>{this.props?.response?.stream_title}</h2>
            <p>{this.props?.response?.stream_description}</p>
          </div>
          <iframe
            title="Live Streaming"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default LiveStreamingVideo;
