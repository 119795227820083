import React, {
  Component,
  createRef,
  PureComponent,
  useRef,
  useState,
  useEffect,
} from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Header extends Component {
  //const [stickyHeader, setStickyHeader] = useState({ isSticky: false, offset: 0 });
  // const [btnClicked, setBtnClicked] = useState(false);
  // const headerRef = useRef(null);

  constructor(props) {
    super(props);
    this.state = {
      btnClicked: false,
      searchText: "",
      sticky: { isSticky: false, offset: 0 },
    };

    this.headerRef = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  // handle scroll event
  handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      this.setState({ sticky: { isSticky: true, offset: elHeight } });
    } else {
      this.setState({ sticky: { isSticky: false, offset: 0 } });
    }
  };

  handleChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSubmit = (e) => {
    if (this.state?.searchText == null || this.state?.searchText === "") {
      alert("Empty Search");
    } else {
      e.preventDefault();

      this.props.history.push(`/search/${this.state.searchText}`);
    }
  };

  loginBtnClicked = (event) => {
    //console.log('login button clicked in header.js');
    this.setState(
      {
        btnClicked: true,
      },
      () => {
        //console.log('setstate callback', this.state)
        if (this.props) {
          this.props.hitHome(this.state.btnClicked);
        }
      }
    );
  };

  componentDidMount() {
    var header = this.headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      this.handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }

  backword = () => {
    window.location.pathname !== "/" && this.props.history.goBack();
  };
  /*
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height);
        };

        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > elTopOffset + elHeight) {
            setStickyHeader({ sticky: { isSticky: true, offset: elHeight } });
        } else {
            setStickyHeader({ sticky: { isSticky: false, offset: 0 } });
        }
    };

    const loginBtnClicked = (event) => {
        //console.log('login button clicked in header.js');
        setBtnClicked(true);
        props.hitHome(true);
        /*
        this.setState(
          {
            btnClicked: true,
          },
          () => {
            if (this.props) {
              this.props.hitHome(this.state.btnClicked);
            }
          }
        );
        */
  // }; */
  render() {
    // console.log('props', this.props.hitHome);

    return (
      <div className="top-header-fixed">
        <div
          id="sticky-header"
          className={`${
            this.state.sticky.isSticky
              ? "header-sec header-sticky"
              : "header-sec header-sticky"
          }`}
          ref={this.headerRef}
        >
          <nav className="navbar navbar-expand-lg" id="navbar1">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand">
                <img
                  src={
                    window.location.pathname.includes("/course/")
                      ? "/assets/new_layout/img/logo.png"
                      : "/assets/new_layout/img/logo.png"
                  }
                  className="img-fluid"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse me-auto"
                id="navbarSupportedContent"
              >
                <form className="d-flex" onSubmit={this.handleSubmit}>
                  <img
                    src={
                      window.location.pathname.includes("/course/")
                        ? "/assets/new_layout/img/search.png"
                        : "/assets/new_layout/img/search.png"
                    }
                    className="mt-2 pr-1"
                  />
                  <input
                    onChange={this.handleChange}
                    value={this.state.searchText}
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
                <ul className="navbar-nav mb-2 mb-lg-0">
                  {/*
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="#"><img src={"./assets/new_layout/img/notification.png"} /></a>
                                </li>*/}
                  <li className="nav-item">
                    <Link to="/cart" className="nav-link">
                      <img
                        src={
                          window.location.pathname.includes("/course/")
                            ? "/assets/new_layout/img/Cart.png"
                            : "/assets/new_layout/img/Cart.png"
                        }
                      />
                    </Link>
                  </li>
                  {/*<li className="nav-item">
                                    <a className="nav-link" href="#"><img src={"./assets/new_layout/img/profile.png"} /></a>
                                </li>*/}

                  <li>
                    {/* <button type="button" data-toggle="modal" data-target="#login-model">Register Now</button> */}
                    <button
                      type="button"
                      onClick={this.loginBtnClicked}
                      className="loginbutton"
                    >
                      Log In / Register
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className={
                        window.location.pathname === "/"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      aria-current="page"
                      to={"/"}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mx-4">
                    <Link
                      className={
                        window.location.pathname.includes("courses") ||
                        window.location.pathname.includes("/course/")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={"/courses"}
                    >
                      Programme
                    </Link>
                  </li>
                  {/*
                                <li className="nav-item">
                                    <Link  className={ window.location.pathname.includes("member") ||  window.location.pathname.includes("/member/")
                          ? "nav-link active" : "nav-link" } onClick={this.loginBtnClicked} to={"/members"}>Members</Link>
                              </li> */}

                  <li className="nav-item mx-4">
                    <Link className="nav-link" to={"/videos"}>
                      AE Shorts
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/podcasts"}>
                      Podcasts
                    </Link>
                  </li>
                  <li className="nav-item mx-4">
                    <Link className="nav-link" to={"/blogs"}>
                      Blogs
                    </Link>
                  </li>
                  {/*
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/support"}>Support & Feedback</Link>
                                </li>
                                */}
                  {/* <li className="nav-item ml-4">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={this.loginBtnClicked}
                    >
                      Subscription
                    </a>
                  </li> */}
                  <li className="nav-item ml-4">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={this.loginBtnClicked}
                    >
                      Members
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
