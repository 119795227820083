import {
  GET_AI_WORKBOOK,
  GET_AI_WORKBOOK_SUCCESS,
  GET_AI_WORKBOOK_ERROR,
  DELETE_AI_WORKBOOK,
  DELETE_AI_WORKBOOK_SUCCESS,
  DELETE_AI_WORKBOOK_ERROR,
} from "./actionType";

export const getAIWorkBook = (data) => {
  return {
    type: GET_AI_WORKBOOK,
    payload: data,
  };
};

export const getAIWorkBookSuccess = (data) => {
  return {
    type: GET_AI_WORKBOOK_SUCCESS,
    payload: data,
  };
};

export const getAIWorkBookError = (error) => {
  return {
    type: GET_AI_WORKBOOK_ERROR,
    payload: error,
  };
};
export const deleteAIWorkBook = (data) => {
  return {
    type: DELETE_AI_WORKBOOK,
    payload: data,
  };
};

export const deleteAIWorkBookSuccess = (data) => {
  return {
    type: DELETE_AI_WORKBOOK_SUCCESS,
    payload: data,
  };
};

export const deleteAIWorkBookError = (error) => {
  return {
    type: DELETE_AI_WORKBOOK_ERROR,
    payload: error,
  };
};
