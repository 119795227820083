export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_ERROR = "GET_COURSE_ERROR";

export const GET_COURSE_BY_ID = "GET_COURSE_BY_ID";
export const GET_COURSE_BY_ID_SUCCESS = "GET_COURSE_BY_ID_SUCCESS";
export const GET_COURSE_BY_ID_ERROR = "GET_COURSE_BY_ID_ERROR";

export const GET_COURSES_BY_CATEGORY_ID = "GET_COURSES_BY_CATEGORY_ID";
export const GET_COURSES_BY_CATEGORY_ID_SUCCESS =
  "GET_COURSES_BY_CATEGORY_ID_SUCCESS";
export const GET_COURSES_BY_CATEGORY_ID_ERROR =
  "GET_COURSES_BY_CATEGORY_ID_ERROR";

export const ENROLL_TO_SERVICE = "ENROLL_TO_SERVICE";
export const ENROLL_TO_SERVICE_SUCCESS = "ENROLL_TO_SERVICE_SUCCESS";
export const ENROLL_TO_SERVICE_ERROR = "ENROLL_TO_SERVICE_ERROR";

export const SAVE_PAUSE_TIME = "SAVE_PAUSE_TIME";
export const SAVE_PAUSE_TIME_SUCCESS = "SAVE_PAUSE_TIME_SUCCESS";
export const SAVE_PAUSE_TIME_ERROR = "SAVE_PAUSE_TIME_ERROR";

export const GET_PAUSE_TIME_BY_LESSION_ID = "GET_PAUSE_TIME_BY_LESSION_ID";
export const GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS =
  "GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS";
export const GET_PAUSE_TIME_BY_LESSION_ID_ERROR =
  "GET_PAUSE_TIME_BY_LESSION_ID_ERROR";

export const GET_PROGRESS_BY_ID = "GET_PROGRESS_BY_ID";
export const GET_PROGRESS_BY_ID_SUCCESS = "GET_PROGRESS_BY_ID_SUCCESS";
export const GET_PROGRESS_BY_ID_ERROR = "GET_PROGRESS_BY_ID_ERROR";

export const ADD_TO_FAVOURITE = "ADD_TO_FAVOURITE";
export const ADD_TO_FAVOURITE_SUCCESS = "ADD_TO_FAVOURITE_SUCCESS";
export const ADD_TO_FAVOURITE_ERROR = "ADD_TO_FAVOURITE_ERROR";

export const MARK_AS_COMPLETE = "MARK_AS_COMPLETE";
export const MARK_AS_COMPLETE_SUCCESS = "MARK_AS_COMPLETE_SUCCESS";
export const MARK_AS_COMPLETE_ERROR = "MARK_AS_COMPLETE_ERROR";

export const IS_LIKED = "IS_LIKED";
export const IS_LIKED_SUCCESS = "IS_LIKED_SUCCESS";
export const IS_LIKED_ERROR = "IS_LIKED_ERROR";

export const LATEST_SERVICE = "LATEST_SERVICE";
export const LATEST_SERVICE_SUCCESS = "LATEST_SERVICE_SUCCESS";
export const LATEST_SERVICE_ERROR = "LATEST_SERVICE_ERROR";

export const GET_COURSE_AI_ANSWER = "GET_COURSE_AI_ANSWER";
export const GET_COURSE_AI_ANSWER_SUCCESS = "GET_COURSE_AI_ANSWER_SUCCESS";
export const GET_COURSE_AI_ANSWER_ERROR = "GET_COURSE_AI_ANSWER_ERROR";

export const SAVE_COURSE_AI_ANSWER = "SAVE_COURSE_AI_ANSWER";
export const SAVE_COURSE_AI_ANSWER_SUCCESS = "SAVE_COURSE_AI_ANSWER_SUCCESS";
export const SAVE_COURSE_AI_ANSWER_ERROR = "SAVE_COURSE_AI_ANSWER_ERROR";

export const CLEAR_COURSE_AI_ANSWER = "CLEAR_COURSE_AI_ANSWER";
