import React, {
  Component,
  PureComponent,
  useRef,
  useState,
  useEffect,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { ToastContainer, toast } from "react-toastify";

//const PopularTabs = (props) => {
class PopularTabs extends Component {
  //const [selectedTab, setSelectedTab] = useState(1);

  state = {
    settings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    },
    showLoginModel: false,
    selectedTab: 1,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  onSelectedTab = (value) => {
    //setSelectedTab(value)
    this.setState({
      ...this.state,
      selectedTab: value,
    });
  };

  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
    // = "Courses :: Maetrix";

    this.props.dispatchCourse({
      token: this.props.token,
    });
    if (this.props.token) {
      if (localStorage.getItem("courses")) {
        this.setState(
          {
            ...this.state,
            localCart:
              localStorage.getItem("courses").length > 0
                ? JSON.parse(localStorage.getItem("courses")).map((cart) => {
                    return {
                      service_id: cart.id,
                      service_category: cart.service_category,
                    };
                  })
                : [],
          },
          () => {
            this.props.dispatchAddToCart({
              token: this.props.token,
              data: this.state.localCart,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.getCourses != this.props?.getCourses &&
      this.props?.getCourses != null
    ) {
      this.setState({
        ...this.state,
        getCourseList: this.props?.getCourses?.data,
      });
    }
    if (
      prevProps?.isFavourite != this.props.isFavourite &&
      this.props.isFavourite != null
    ) {
      if (this.props.token) {
        this.props.dispatchCourse({
          token: this.props.token,
        });
        // window.location.reload();
      } else {
        this.props.dispatchCourse({});
      }
    }
  }

  isFavourite = (id) => {
    console.log(this.props);
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <div>
        <section className="boxsets-page my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <h2>Courses to get you started</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    onClick={() => this.onSelectedTab(1)}
                    className={
                      this.state.selectedTab == "1"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected={
                      this.state.selectedTab == "1" ? "true" : "false"
                    }
                  >
                    Most Popular
                  </button>
                  <button
                    onClick={() => this.onSelectedTab(2)}
                    className={
                      this.state.selectedTab == "2"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected={
                      this.state.selectedTab == "2" ? "true" : "false"
                    }
                  >
                    New
                  </button>
                  <button
                    onClick={() => this.onSelectedTab(3)}
                    className={
                      this.state.selectedTab == "3"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected={
                      this.state.selectedTab == "3" ? "true" : "false"
                    }
                  >
                    Recommended
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                {this.state.selectedTab && this.state.selectedTab == "3" ? (
                  <div
                    className="tab-pane fade show active"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    {this.state.getCourseList &&
                      this.state.getCourseList.map((list, index) => (
                        <div>
                          {index == 0 && (
                            <div className="card-slider-new my-5">
                              <div className="row">
                                {list.courses.map((course, id) => (
                                  <div className="col-lg-3 pr-5">
                                    {id < 4 && (
                                      <Link
                                        to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                                      >
                                        <div className="card w-100">
                                          <img
                                            className="card-img-top"
                                            src={course.thumbnail_url}
                                            alt="Card image cap"
                                          />
                                          <div className="card-body">
                                            <h5 className="card-title">
                                              {course?.course_name?.length <= 50
                                                ? course?.course_name
                                                : course?.course_name?.substring(
                                                    0,
                                                    50
                                                  ) + "..."}
                                            </h5>
                                            <p>
                                              {course.no_of_chapters} Lessons
                                            </p>
                                            <a href="" className="btn-free">
                                              {course.course_type === 0 &&
                                              (course.is_subscribed === true ||
                                                course.is_subscribed ===
                                                  false) &&
                                              (course.is_independent === true ||
                                                course.is_independent === false)
                                                ? "Free"
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    false &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.monthly_fee}`
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    true &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.course_fee}`
                                                : "View Course"}
                                            </a>
                                            <p className="mt-4">
                                              {[...Array(course.rating)].map(
                                                (elementInArray, index1) => (
                                                  <img
                                                    src={
                                                      "./assets/new_layout/img/Path 9.png"
                                                    }
                                                  />
                                                )
                                              )}
                                              {/*<img src={"./assets/new_layout/img/Path 9.png"} />
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                          <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>*/}
                                              <small>({course.rating}.0)</small>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ) : this.state.selectedTab && this.state.selectedTab == "2" ? (
                  <div
                    className="tab-pane fade show active"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    {this.state.getCourseList &&
                      this.state.getCourseList.map((list, index) => (
                        <div>
                          {index == 3 && (
                            <div className="card-slider-new my-5">
                              <div className="row">
                                {list.courses.map((course, id) => (
                                  <div className="col-lg-3 pr-5">
                                    {id < 4 && (
                                      <Link
                                        to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                                      >
                                        <div className="card w-100">
                                          <img
                                            className="card-img-top"
                                            src={course.thumbnail_url}
                                            alt="Card image cap"
                                          />
                                          <div className="card-body">
                                            <h5 className="card-title">
                                              {course?.course_name?.length <= 50
                                                ? course?.course_name
                                                : course?.course_name?.substring(
                                                    0,
                                                    50
                                                  ) + "..."}
                                            </h5>
                                            <p>
                                              {course.no_of_chapters} Lessons
                                            </p>
                                            <a href="" className="btn-free">
                                              {course.course_type === 0 &&
                                              (course.is_subscribed === true ||
                                                course.is_subscribed ===
                                                  false) &&
                                              (course.is_independent === true ||
                                                course.is_independent === false)
                                                ? "Free"
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    false &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.monthly_fee}`
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    true &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.course_fee}`
                                                : "View Course"}
                                            </a>
                                            <p className="mt-4">
                                              {[...Array(course.rating)].map(
                                                (elementInArray, index1) => (
                                                  <img
                                                    src={
                                                      "./assets/new_layout/img/Path 9.png"
                                                    }
                                                  />
                                                )
                                              )}
                                              {/*<img src={"./assets/new_layout/img/Path 9.png"} />
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                          <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>*/}
                                              <small>({course.rating}.0)</small>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {this.state.getCourseList &&
                      this.state.getCourseList.map((list, index) => (
                        <div>
                          {index <= 0 && (
                            <div className="card-slider-new my-5">
                              <div className="row">
                                {list.courses.map((course, id) => (
                                  <div className="col-lg-3 pr-5">
                                    {id < 4 && (
                                      <Link
                                        to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                                      >
                                        <div className="card w-100">
                                          <img
                                            className="card-img-top"
                                            src={course.thumbnail_url}
                                            alt="Card image cap"
                                          />
                                          <div className="card-body">
                                            <h5 className="card-title">
                                              {course?.course_name?.length <= 50
                                                ? course?.course_name
                                                : course?.course_name?.substring(
                                                    0,
                                                    50
                                                  ) + "..."}
                                            </h5>
                                            <p>
                                              {course.no_of_chapters} Lessons
                                            </p>
                                            <a href="" className="btn-free">
                                              {course.course_type === 0 &&
                                              (course.is_subscribed === true ||
                                                course.is_subscribed ===
                                                  false) &&
                                              (course.is_independent === true ||
                                                course.is_independent === false)
                                                ? "Free"
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    false &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.monthly_fee}`
                                                : course.course_type === 1 &&
                                                  course.is_independent ===
                                                    true &&
                                                  course.is_subscribed === false
                                                ? `£ ${course.course_fee}`
                                                : "View Course"}
                                            </a>
                                            <p className="mt-4">
                                              {[...Array(course.rating)].map(
                                                (elementInArray, index1) => (
                                                  <img
                                                    src={
                                                      "./assets/new_layout/img/Path 9.png"
                                                    }
                                                  />
                                                )
                                              )}
                                              {/*<img src={"./assets/new_layout/img/Path 9.png"} />
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                              <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>
                                                                          <span><img src={"./assets/new_layout/img/Path 9.png"} /></span>*/}
                                              <small>({course.rating}.0)</small>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    getCourses: state.coursesReducer.courses,
    isFavourite: state?.coursesReducer?.isFavourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PopularTabs));
