import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDashboardBanner } from "../../../store/protected/dashboard/action";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const HomeSlider = (props) => {

    const [sliderSlides, setSliderSlides] = useState([1, 2, 3, 4, 5, 6]);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed:5500,
        autoplay: true,
        //easing: 'linear',
        easing: 'easeOutElastic',
        draggable: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
    };
   
      useEffect(() => {
        props.dispatchBannerCategory({
          bannerCategory: "home",
        });
    },[]); 


    return (
        <section className="hero home_slider">
            {/* <div className="slick"> */}
            <Slider {...settings}>
                    {props?.dashboardBanner && props?.dashboardBanner?.map((element, index) => (
                        <div className="box-1" key={index}>
                            <span>
                                <figure>
                                    {(element.link === null || element.link === 'https://')? (
                                        element.banner_media_type != 'image' ?
                                            <video width="100%" height="452px" style={{ width: "100%" }} controls preload="auto">
                                                <source src={element?.banner_url} type="video/mp4" />
                                                <source src={element?.banner_url} type="video/ogg" />
                                            </video>
                                            :
                                            <img
                                                alt={
                                                    element?.banner_alt_text
                                                        ? element?.banner_alt_text
                                                        : "Banner image"
                                                }
                                                src={element?.banner_url}
                                                style={{ width: '100%' }}
                                            />

                                    ) : (
                                        <a target="_blank" href={element.link}>
                                            {element.banner_media_type != 'image' ?
                                                <video width="100%" height="452px" style={{ width: "100%" }} controls preload="auto">
                                                    <source src={element?.banner_url} type="video/mp4" />
                                                    <source src={element?.banner_url} type="video/ogg" />
                                                </video>
                                                :
                                                <img
                                                    alt={
                                                        element?.banner_alt_text
                                                            ? element?.banner_alt_text
                                                            : "Banner image"
                                                    }
                                                    src={element?.banner_url}
                                                    style={{ width: '100%' }}
                                                />
                                            }
                                        </a>
                                    )}
                                </figure>
                            </span>
                        </div>
                    ))
                    }
                </Slider>
            {/* </div> */}
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        dashboardBanner: state.dashboardReducer.dashboardBanner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchBannerCategory: (data) => dispatch(getDashboardBanner(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HomeSlider));
