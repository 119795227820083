import React, { Component } from "react";

import Login from "../../gaust/login";
import PodcastSubscriptionCheck from "../podcasts/PodcastSubscriptionCheck";
import EmbededPop from "../podcasts/embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";

import { isLatestSuccess } from "../../../store/protected/courses/action";

import ReactHtmlParser from "react-html-parser";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class PodcastResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      showLoginModel: false,
      SubscriptionName: "",
      podcastUrl: "",
      podcastData: null,
    };
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  onPlay = (name) => {
    console.log("nameee", name);
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  onPlayLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  podcastCondition = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  buySubscrition = (name) => {
    console.log("na", name);
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  goToLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  notSubscribed = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  embeded = (data) => {
    this.setState({
      podcastData: data,
    });
    window.$("#embededPupUp").modal("show");
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.podcast_category_url +
      "/" +
      url?.podcast_title_url+qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };

  playAudio = (service_id) => {
    console.log("play ausio", service_id);
    this.props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: this.props.token,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props?.PodcastList?.length > 0 && (
          <>
            <EmbededPop data={this.state.podcastData} />
            <SharePopUpModel url={this.state.podcastUrl} />
            <PodcastSubscriptionCheck
              token={this.props.token}
              history={this.props.history}
              name={this.state?.SubscriptionName}
            />
            <div className="podcast-row page-height">
              <div className="container">
                <div className="podcast-row-header">
                  <h2>Podcasts Result</h2>
                </div>
                <div className="podcast-list">
                  <div className="row">
                    {this.props?.PodcastList?.map((podcast, index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <div className="music-box">
                          <img
                            alt="img"
                            className="audio-img"
                            src={podcast?.banner_url}
                          />
                          {!this.props.token ? (
                            <div
                              onClick={this.podcastCondition}
                              className="demoAudio"
                            >
                              <AudioPlayer
                                src={podcast.podcast_url}
                                playing={false}
                                defaultDuration={podcast.duration}
                                defaultCurrentTime="00:00"
                                onPlayError={() => this.onPlayLogin()}
                              />
                            </div>
                          ) : podcast.is_subscribed == 1 ? (
                            <AudioPlayer
                              src={podcast.podcast_url}
                              onPlay={() => this.playAudio(podcast.id)}
                              playing={false}
                            />
                          ) : (
                            <div
                              onClick={() =>
                                this.buySubscrition(podcast.tag_name)
                              }
                              className="demoAudio"
                            >
                              <AudioPlayer
                                src={podcast.podcast_url}
                                playing={false}
                                defaultDuration={podcast.duration}
                                defaultCurrentTime="00:00"
                                onPlayError={() =>
                                  this.onPlay(podcast.tag_name)
                                }
                              />
                            </div>
                          )}
                          <div className="audio-cnt">
                            <h2>{podcast?.podcast_title}</h2>
                            <div className="hide-audio-cnt">
                              {ReactHtmlParser(podcast?.description)}
                            </div>
                            <h6 className="btm-brd-hide">
                              <span></span>
                            </h6>
                          </div>
                          <div className="audio-action">
                            {!this.props.token ? (
                              <button onClick={this.goToLogin}>
                                <img
                                  alt="icon"
                                  src="/assets/images/download-icon.png"
                                />
                              </button>
                            ) : podcast.is_subscribed == 1 ? (
                              <a href={podcast.podcast_url} download>
                                <button>
                                  <img
                                    alt="icon"
                                    src="/assets/images/download-icon.png"
                                  />
                                </button>
                              </a>
                            ) : (
                              <button onClick={this.notSubscribed}>
                                <img
                                  alt="icon"
                                  src="/assets/images/download-icon.png"
                                />
                              </button>
                            )}

                            {!this.props.token ? (
                              <button onClick={() => this.sharePop(podcast)}>
                                <img
                                  alt="icon"
                                  src="/assets/images/share-icon.png"
                                />
                              </button>
                            ) : (
                              <button onClick={() => this.sharePop(podcast)}>
                                <img
                                  alt="icon"
                                  src="/assets/images/share-icon.png"
                                />
                              </button>
                            )}

                            {this.props.token ? (
                              <button onClick={() => this.embeded(podcast)}>
                                <img
                                  alt="icon"
                                  src="/assets/images/coding-icon.png"
                                />
                              </button>
                            ) : (
                              <button onClick={this.goToLogin}>
                                <img
                                  alt="icon"
                                  src="/assets/images/coding-icon.png"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {this.state.showLoginModel === true && <Login />}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PodcastResult));
