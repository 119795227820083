import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  GET_MEMBER_DETAIL,
  GET_MEMBER_DETAIL_SUCCESS,
  GET_MEMBER_DETAIL_ERROR,
  GET_MEMBER_LOYALITY_DETAIL,
  GET_MEMBER_LOYALITY_DETAIL_SUCCESS,
  GET_MEMBER_LOYALITY_DETAIL_ERROR,
} from "./actionType";

const initialState = {
  error: null,
  loading: null,
  members: [],
  memberDetail: null,
  memberLoyalityDetail: null,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      state = {
        error: null,
        loading: true,
        members: [],
      };
      break;
    case GET_MEMBERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        members: action.payload,
      };
      break;
    case GET_MEMBERS_ERROR:
      state = {
        ...state,
        loading: false,
        members: [],
        error: action.payload,
      };
      break;
    case GET_MEMBER_DETAIL:
      state = {
        error: null,
        loading: true,
        memberDetail: null,
      };
      break;
    case GET_MEMBER_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        memberDetail: action.payload,
      };
      break;
    case GET_MEMBER_DETAIL_ERROR:
      state = {
        ...state,
        loading: false,
        memberDetail: null,
        error: action.payload,
      };
      break;
    case GET_MEMBER_LOYALITY_DETAIL:
      state = {
        error: null,
        loading: true,
        memberLoyalityDetail: null,
      };
      break;
    case GET_MEMBER_LOYALITY_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        memberLoyalityDetail: action.payload,
      };
      break;
    case GET_MEMBER_LOYALITY_DETAIL_ERROR:
      state = {
        ...state,
        loading: false,
        memberDetail: null,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default memberReducer;
