import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Login from "../../gaust/login";
import PodcastSubscriptionCheck from "./PodcastSubscriptionCheck";
import EmbededPop from "./embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";
import { isLatestSuccess } from "../../../store/protected/courses/action";
import DataLoading from "../../common/DataLoading";
import { getPodcast } from "../../../store/protected/podcast/action";

const EntrepreneurshipBox = (props) => {
  const [playing, setPlaying] = useState(true);
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState("");
  const [podcastUrl, setPodcastUrl] = useState("");
  const [podcastData, setPodcastData] = useState("");
  const [loading, setLoading] = useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   if (props?.categoryData && props?.categoryData.length > 0) {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 3000);
  //   } else {
  //     setLoading(true)
  //   }
  // }, [props?.categoryData]);

  const openHeaderContentPopup = (value) => {
    if (value === true) {
      setShowLoginModel(true);
      window.$("#login-model").modal("show");
    }
  };

  const onPlay = (name) => {
    setSubscriptionName(name);
    window.$("#subsModel").modal("show");
  };

  const onPlayLogin = () => {
    setShowLoginModel(true);
    window.$("#login-model").modal("show");
  };

  const podcastCondition = () => {
    setShowLoginModel(true);
    window.$("#login-model").modal("show");
  };

  const goToLogin = () => {
    setShowLoginModel(true);
    window.$("#login-model").modal("show");
  };

  const notSubscribed = (name) => {
    setSubscriptionName(name);
    window.$("#subsModel").modal("show");
  };

  const embeded = (data) => {
    console.log(data);
    setPodcastData(data);
    window.$("#embededPupUp").modal("show");
  };

  const sharePop = (url) => {
    let str = "111" + props?.user?.user?.id + "111"; //userid=413
    let qrstr = "?q=" + str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.category_title_url +
      "/" +
      url?.podcast_title_url +
      qrstr;
    setPodcastUrl(podcast_url);
    window.$("#shareModel").modal("show");
  };

  const playAudio = (service_id) => {
    props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: props.token,
    });
  };

  return (
    <section className="podcast my-5" id="podcsat">
      {props?.categoryData && props?.categoryData?.length > 0 && (
        <>
          {podcastData && podcastData != "" && (
            <EmbededPop data={podcastData} />
          )}
          {podcastUrl && podcastUrl != "" && (
            <SharePopUpModel url={podcastUrl} />
          )}
          {subscriptionName && subscriptionName != "" && (
            <PodcastSubscriptionCheck
              token={props.token}
              history={props.history}
              name={subscriptionName}
            />
          )}
        </>
      )}
      {loading == false ? (
        <div className="container my-5">
          <div className="card-slider-new-new">
            <Slider {...settings}>
              {props?.categoryData &&
                props?.categoryData?.map((element, index) => (
                  <div className="col-lg-12 pr-5" key={index}>
                    <div className="card w-100">
                      <img
                        className="img-top"
                        style={{ width: "150px" }}
                        src={element?.banner_url}
                        loading="lazy"
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{element.duration}</h5>
                        <p>
                          {!props.token ? (
                            <div
                              onClick={podcastCondition}
                              className="demoAudio"
                            >
                              {" "}
                              <AudioPlayer
                                src=""
                                onPlayError={onPlayLogin}
                              />{" "}
                            </div>
                          ) : element.is_subscribed == 1 ? (
                            <AudioPlayer
                              src={element.podcast_url}
                              onPlay={() => playAudio(element.id)}
                              playing={false}
                            />
                          ) : (
                            <div
                              onClick={() => notSubscribed(element.tag_name)}
                              className="demoAudio"
                            >
                              <div onClick={onPlay} className="demoAudio">
                                <AudioPlayer
                                  src={element.podcast_url}
                                  playing={false}
                                  defaultDuration={element.duration}
                                  defaultCurrentTime="00:00"
                                  onPlayError={() => onPlay(element.tag_name)}
                                />
                              </div>
                            </div>
                          )}
                          {/* <span><a href="" className="btn-1"><img className="podcast_icon_image" src={"./assets/new_layout/img/Path 3048.png"} /></a></span>
                                                    <span><a href="" className="btn-2"><img className="podcast_icon_image" src={"./assets/new_layout/img/play.png"} /></a></span>
                                                    <span><a href="" className="btn-3"><img className="podcast_icon_image" src={"./assets/new_layout/img/Path 3049.png"} /></a></span> */}
                        </p>
                        <hr />
                        <h4>{element?.podcast_title} </h4>
                        <p className="new-p">
                          {" "}
                          {ReactHtmlParser(element?.description)}
                        </p>
                        <div className="side-div">
                          <p>
                            {!props.token ? (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/download (1).png"}
                                onClick={() => goToLogin()}
                                className="link_cursor"
                              />
                            ) : element.is_subscribed == 1 ? (
                              <a
                                href={element.podcast_url}
                                download
                                className="link_cursor"
                              >
                                <img
                                  alt="icon"
                                  src={
                                    "./assets/new_layout/img/download (1).png"
                                  }
                                />
                              </a>
                            ) : (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/download (1).png"}
                                onClick={() => notSubscribed(element.tag_name)}
                                className="link_cursor"
                              />
                            )}
                            <br />

                            {!props.token ? (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/share (1).png"}
                                onClick={() => goToLogin()}
                                className="link_cursor"
                              />
                            ) : element.is_subscribed == 1 ? (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/share (1).png"}
                                onClick={() => sharePop(element)}
                                className="link_cursor"
                              />
                            ) : (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/share (1).png"}
                                onClick={() => notSubscribed(element.tag_name)}
                                className="link_cursor"
                              />
                            )}
                            <br />

                            {!props.token ? (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/coding.png"}
                                onClick={() => goToLogin()}
                                className="link_cursor"
                              />
                            ) : element.is_subscribed == 1 ? (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/coding.png"}
                                onClick={() => embeded(element)}
                                className="link_cursor"
                              />
                            ) : (
                              <img
                                alt="icon"
                                src={"./assets/new_layout/img/coding.png"}
                                onClick={() => notSubscribed(element.tag_name)}
                                className="link_cursor"
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      ) : (
        <DataLoading />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    podcasts: state.podcastReducer?.podcasts,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPodcastCategory: (data) => dispatch(getPodcast(data)),
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EntrepreneurshipBox));
