import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CustomHeader from "../../../layout/gaust/publicCustomHeader";
import Footer from "../../../layout/gaust/Footer";

import { resetPassword } from "../../../store/protected/auth/actions";
import { ToastContainer, toast } from 'react-toastify';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.query = new URLSearchParams(window.location.search);
    this.token = this.query.get("token");
  }
  state = {
    new_password: "",
    confirm_password: "",

    empty_error: false,
    match_error: false,
    format_error: false,
  };
  componentDidMount() {
    this.setState({
      token: this.token,
    });

    // console.log('history', this.token)
  }

  handleUserInputReg = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleResetForm = (event) => {
    //'click button');
    event.preventDefault();
    let errors = {};
    const passRejex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
    if (!this.state.new_password || !this.state.confirm_password) {
      // errors.new_password = 'Password Is Required';
      this.setState({
        empty_error: true,
        match_error: false,
        format_error: false,
      });
    } else if (!passRejex.test(this.state.new_password)) {
      this.setState({
        empty_error: false,
        match_error: false,
        format_error: true,
      });
    } else if (this.state.new_password !== this.state.confirm_password) {
      this.setState({
        empty_error: false,
        match_error: true,
        format_error: false,
      });
      toast.error("Password And Confirm Password Does Not Match", {
				position: "top-right",
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
      
    } else {
      alert("Your password has been changed successfully");
    }

    console.log(
      "Passwords",
      this.state.new_password,
      this.state.confirm_password
    );

    this.setState(errors);

    if (Object.keys(errors).length === 0) {
      this.props.dispatchResetPassword({
        token: this.state?.token,
        new_password: this.state?.new_password,
        confirm_password: this.state?.confirm_password,
        history: this.props.history,
      });
    }
  };
  Toggle = () => {
    let temp = document.getElementById("password-field-reg");
    let icon = document.getElementById("pass-reg");
    if (temp.className === "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  Toggle1 = () => {
    let temp = document.getElementById("password-field-reg1");
    let icon = document.getElementById("pass-reg1");
    if (temp.className === "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  render() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    return (
      <div>
        <CustomHeader />
        <div className="course-row">
          <div className="container">
            <div className="container">
              <div className="reset-pass">
                <img src="assets/images/forgot-pass.png" alt="" />
                <div className="pass-box">
                  <form
                    id="register-form"
                    onSubmit={this.handleResetForm}
                    autoComplete="off"
                    className="form"
                  >
                    <h3>Forgot Password?</h3>
                    <p>You can reset your password here.</p>

                    <div className="form-group">
                      <input
                        type="password"
                        className="active"
                        id="password-field-reg"
                        name="new_password"
                        value={this.state.new_password}
                        placeholder="New Password"
                        onChange={this.handleUserInputReg}
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                      />
                      <span className="show-pass">
                        <i
                          id="pass-reg"
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.Toggle}
                        ></i>
                      </span>
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="active"
                        id="password-field-reg1"
                        name="confirm_password"
                        value={this.state.confirm_password}
                        placeholder="Confirm Password"
                        onChange={this.handleUserInputReg}
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required
                      />
                      <span className="show-pass">
                        <i
                          id="pass-reg1"
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.Toggle1}
                        ></i>
                      </span>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="sbm">
                        Reset Password
                      </button>
                    </div>
                    <input
                      type="hidden"
                      className="hide"
                      name="token"
                      id="token"
                      value=""
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchResetPassword: (data) => dispatch(resetPassword(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPassword));

// export default ResetPassword
