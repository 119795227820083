import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
//import Header from "../../../layout/gaust/Header";
// import AuthHeader from "../../../layout/gaust/newLayout/Header2";
import Header from "../../../layout/gaust/newLayout/Header";
import Login from "../../../components/gaust/login";
import Footer from "../../../layout/auth/Afooter";
import { getMembers } from "../../../store/protected/members/action";
import { BASE_URL } from "../../../helpers/Constants";
import ReactHtmlParser from "react-html-parser";
import Pagination from "react-js-pagination";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Members extends PureComponent {
  state = {
    showLoginModel: false,
    users: null,
    search: null,
    sortingValue: null,
    allUsers: null,
    text: null,
  };

  searchSpace = (event) => {
    let keyword = this.state.text;

    axios
      .get(`${BASE_URL}api/searchUsers?search_text=${keyword}`)
      .then((result) => {
        this.setState({
          users: result.data?.result?.user,
        });
      })
      .catch((err) => {
        console.log("Error in Member fetch ", err);
      });
  };

  alphabetical = (event) => {
    let alpha = event.target.value;
    this.setState({ sortingValue: alpha }, () => {
      if (this.state.users) {
        this.renderMemberList();
      }
    });
  };

  async getMemberData(pageNumber = 1) {
    if (this.state.sortingValue !== null) {
      let url = `${BASE_URL}api/searchUsers?sort=${this.state?.sortingValue}&page=${pageNumber}`;
      axios
        .get(url, {
          headers: {
            authorization: "Bearer" + this.props.token,
          },
        })
        .then((result) => {
          this.setState({
            users: result.data.result.user,
          });
        })
        .catch((err) => {
          console.log("Error in Member fetch  alphabetical", err);
        });
    } else {
      const url = `${BASE_URL}api/allMembers?page=${pageNumber}`;
      const response = await axios.get(url, {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      });
      this.setState({
        users: response.data.result.users,
      });
    }
  }

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    // document.title = "Members :: The Maetrix";
    this.getMemberData();
  }

  handleChage = (e) => {
    if (e.target.value === "") {
      this.setState({
        text: null,
      });
      this.getMemberData();
    } else {
      this.setState({ text: e.target.value });
    }
  };

  renderMemberList() {
    if (this.state.sortingValue !== null) {
      if (this.state.sortingValue === "alphabetical") {
        axios
          .get(`${BASE_URL}api/searchUsers?sort=${this.state.sortingValue}`)
          .then((result) => {
            this.setState({
              users: result.data?.result?.user,
            });
          })
          .catch((err) => {
            console.log("Error in Member fetch  alphabetical", err);
          });
      } else if (this.state.sortingValue === "latest") {
        axios
          .get(`${BASE_URL}api/searchUsers?sort=${this.state.sortingValue}`)
          .then((result) => {
            this.setState({
              users: result.data?.result?.user,
            });
          })
          .catch((err) => {
            console.log("Error in Member fetch in latest ", err);
          });
      } else {
        this.getMemberData();
      }
    }
  }
  render() {
    //console.log('lsit useeee', this.state.users);
    return (
      <div>
        {" "}
        {this.props.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="member-row page-height">
          <div className="container">
            <div className="memb-head">
              <div className="row">
                <div className="col-sm-6">
                  <div className="srch-memb">
                    <input
                      type="text"
                      placeholder="Search Member"
                      value={this.state.text}
                      onChange={(e) => this.handleChage(e)}
                    />
                    <button onClick={this.searchSpace}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="fltr-memb">
                    <select onChange={(e) => this.alphabetical(e)}>
                      <option value="latest">Newly Joined</option>
                      <option value="alphabetical">Alphabetical</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <React.Fragment>
              <div className="row">
                {this.state?.users?.data?.map((list, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <div className="member-box">
                      <img
                        alt="profile_Image"
                        src={list.profile_image ? list.profile_image : ""}
                      />
                      <h2>{list.name ? list.name : "unkonow"}</h2>
                      <p>
                        {list.bio ? ReactHtmlParser(list.bio) : "(Empty Bio)"}
                      </p>
                      <a href={`/member/${list.id}/${list.name_url}`}>
                        Profile
                      </a>
                    </div>
                  </div>
                ))}{" "}
              </div>
              <div className="pagination-row">
                <Pagination
                  totalItemsCount={this?.state?.users?.total}
                  activePage={this.state?.users?.current_page}
                  itemsCountPerPage={this.state?.users?.per_page}
                  onChange={(pageNumber) => this.getMemberData(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            </React.Fragment>
          </div>
        </div>
        <Footer token={this.props.token} />{" "}
        {this.state.showLoginModel === true && <Login />}{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    members: state.memberReducer?.members?.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetMembers: (data) => dispatch(getMembers(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Members));
