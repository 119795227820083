import { all } from "redux-saga/effects";
import authSaga from "./protected/auth/saga";
import coursesSaga from "./protected/courses/saga";
import userSaga from "./protected/user-profile/saga";
import userSettingsSaga from "./protected/user-settings/saga";
import subscriptionsListSaga from "./protected/subscription/saga";
import cartSaga from "./protected/cart/saga";
import bannerSaga from "./protected/banner/saga";
import videosSaga from "./protected/videos/saga";
import podcastSaga from "./protected/podcast/saga";
import blogSaga from "./protected/blogs/saga";
import dashboardSaga from "./protected/dashboard/saga";
import membersSaga from "./protected/members/saga";
import activitySaga from "./protected/my-activity/saga";
import workBookSaga from "./protected/workbook/saga";
import aiContentSaga from "./protected/ai-content/saga";
import roadmapSaga from "./protected/roadmap/saga";
// import forgetPasswordSaga from './data/forget-password/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    coursesSaga(),
    userSaga(),
    userSettingsSaga(),
    subscriptionsListSaga(),
    cartSaga(),
    bannerSaga(),
    videosSaga(),
    podcastSaga(),
    blogSaga(),
    dashboardSaga(),
    membersSaga(),
    activitySaga(),
    workBookSaga(),
    aiContentSaga(),
    roadmapSaga(),
  ]);
}
