import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "../../../gaust/login";
import SubscriptionCheck from "../../blogs/subscriptionCheck";
import SharePopUpModel from "../../videos/SharedPopUpModel";
import DataLoading from "../../../common/DataLoading";
import ReactHtmlParser from "react-html-parser";

const LatestBlogs = (props) => {
  const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [tagName, setTagName] = useState("");
  const [blogUrl, setBlogUrl] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [loading, setLoading] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props?.BlogList && props?.BlogList.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(true);
    }
  }, [props?.BlogList]);

  const openHeaderContentPopup = (value) => {
    if (value === true) {
      setShowSubscriptionModel(true);
      window.$("#login-model").modal("show");
    }
  };

  const goBlog = (blog) => {
    props.history.push(
      `/blog/${blog.blog_category_url}/${blog.blog_title_url}`
    );
  };

  const goBuySubscription = (id) => {
    setTagName(id);
    window.$("#subsModel1").modal("show");
  };

  const goLogin = () => {
    setShowLoginModel(true);
    window.$("#login-model").modal("show");
  };

  const sharePop = (url) => {
    let str = "111" + this.props?.user?.user?.id + "111"; //userid=413
    let qrstr = "?q=" + str;
    setBlogUrl(`${window.location.origin}/blog/${url}` + qrstr);
    window.$("#shareModel").modal("show");
  };

  return (
    <>
      {props?.BlogList?.length > 0 && (
        <>
          {blogUrl !== "" && <SharePopUpModel url={blogUrl} />}
          <SubscriptionCheck
            tag={tagName}
            history={props.history}
            name={subscriptionName}
          />
        </>
      )}

      <section className="blogs my-5">
        <div className="container mt-3 pt-3">
          <div className="row">
            <div className="col-12 col-md-12 text-center">
              <h2 className="mb-0">Latest Blogs</h2>
            </div>
          </div>
          {loading == false ? (
            <div className="">
              <Slider {...settings}>
                {props?.BlogList &&
                  props?.BlogList?.map((element, index) => (
                    <div className="" key={index}>
                      <div className="card h-100">
                        <img
                          src={element.banner_url}
                          className="card-img-top"
                          alt="..."
                        />
                        <div
                          className="card-body link_cursor"
                          onClick={() => goBlog(element)}
                        >
                          <h5 className="card-title link_cursor">
                            {element.blog_title}
                          </h5>
                          <p className="card-text link_cursor">
                            5 Mins to Read
                            <span>
                              {element.author_name !== null
                                ? element.author_name
                                : "Richard"}
                            </span>
                          </p>
                          {/*<span className="reward-section-2"><img src={"./assets/new_layout/img/vector.png"} /> 116</span>*/}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          ) : (
            <DataLoading />
          )}
        </div>
        {showLoginModel === true && <Login />}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BlogList: state?.dashboardReducer?.services?.blogs,
    user: state.authReducer?.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LatestBlogs));
