import React, { PureComponent } from "react";

import HomeBanner from "./homeBanner";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/gaust/Footer";
import LatestCourses from "../../auth/dashboard/LetestCourses";
import PublicLatestVideos from "../../auth/dashboard/publicLatestVideo";
import LatestPodcasts from "../../auth/dashboard/LetestPodcasts";
import LatestBlogs from "../../auth/dashboard/LetestBlogs";
import Login from "../../../components/gaust/login";
import { getAllServices } from "../../../store/protected/dashboard/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class HomePage extends PureComponent {
  state = {
    showLoginModel: false,
  };
  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    //  document.title = "Home :: The Maetrix";

    this.props.dispatchServicesToken({});
  
  }
  render() {
    return (
      <React.Fragment>
        <Header hitHome={this.myFunction} />
        <HomeBanner />
        <LatestCourses />
        <PublicLatestVideos />
        <LatestPodcasts />
        <LatestBlogs />

        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));
