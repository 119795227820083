import { BASE_URL } from "../../../helpers/Constants";

import { UPDATE_USER, UPDATE_PASSWORD, UPDATE_USER_VIDEO } from "./actionTypes";
import {
  updateUserSuccess,
  updateUserError,
  updatePasswordSuccess,
  updatePasswordError,
  updateUserVideoSuccess,
  updateUserVideoError,
} from "./actions";
import { ToastContainer, toast } from "react-toastify";
import { userProfileUpdateSuccess } from "../auth/actions";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function* updateUser(data) {
  try {
    //console.log('enter update function');

    let formData = new FormData();
    let reqObj = {};
    const vr = Object.keys(data.payload);
    vr.forEach((element, i) => {
      //'key', element, 'data.payload[element]', data.payload[element]);
      if (data.payload[element] == "" || data.payload[element] == null) {
        delete data.payload[element];
      } else {
        formData.append(element, data.payload[element]);
        reqObj[element] = data.payload[element];
      }
    });

    // formData.append('password', (data.payload.password));

    const response = yield fetch(BASE_URL + "api/profile/updateV2", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(updateUserSuccess(response));
      //console.log('reqObj', reqObj);
      yield put(userProfileUpdateSuccess(reqObj));
      // toast(response.message ? response.message : 'Registration successfull');
      // data.payload.history.push('/dashboard');
    } else {
      yield put(
        updateUserError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    //console.log('catch error', error);
    yield put(updateUserError("Internal Error"));
  }
}

function* updatePassword(data) {
  try {
    //console.log('enter update function');

    let formData = new FormData();
    let reqObj = {};
    const vr = Object.keys(data.payload);
    vr.forEach((element, i) => {
      //console.log('key', element, 'data.payload[element]', data.payload[element]);
      if (data.payload[element] == "" || data.payload[element] == null) {
        delete data.payload[element];
      } else {
        formData.append(element, data.payload[element]);
        reqObj[element] = data.payload[element];
      }
    });

    // formData.append('password', (data.payload.password));

    const response = yield fetch(
      BASE_URL + "api/changePasswordAfterAuthentication",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(updatePasswordSuccess(response));
      // toast(response.message ? response.message : 'Registration successfull');
      // data.payload.history.push('/dashboard');
    } else {
      yield put(
        updatePasswordError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    //console.log('catch error', error);
    yield put(updatePasswordError("Internal Error"));
  }
}

function* updateVideo(data) {
  try {
    //console.log('enter update function');

    let formData = new FormData();
    let reqObj = {};
    const vr = Object.keys(data.payload);
    vr.forEach((element, i) => {
      //'key', element, 'data.payload[element]', data.payload[element]);
      if (data.payload[element] == "" || data.payload[element] == null) {
        delete data.payload[element];
      } else {
        formData.append(element, data.payload[element]);
        reqObj[element] = data.payload[element];
      }
    });

    // formData.append('password', (data.payload.password));

    const response = yield fetch(BASE_URL + "api/updatePromotionalVideo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      toast.success("promotional video Upload Successfully", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //console.log('respone', response);
      yield put(updateUserVideoSuccess(response));
      //console.log('reqObj', reqObj);
      // yield put(userProfileUpdateSuccess(reqObj));
      // toast(response.message ? response.message : 'Registration successfull');
      // data.payload.history.push('/dashboard');
    } else {
      yield put(
        updateUserVideoError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    //console.log('catch error', error);
    yield put(updateUserVideoError("Internal Error"));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}
export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}
export function* watchUpdateVideo() {
  yield takeEvery(UPDATE_USER_VIDEO, updateVideo);
}

function* userSettingsSaga() {
  yield all([
    fork(watchUpdateUser),
    fork(watchUpdateUserPassword),
    fork(watchUpdateVideo),
  ]);
}

export default userSettingsSaga;
