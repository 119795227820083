import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

import { withRouter } from "react-router-dom";
import { getCourseBanner } from "../../store/protected/banner/action";
import { connect } from "react-redux";

class AuthBanner extends Component {
  state = {
    bannerCategory: "course",
  };

  componentDidMount() {
    this.props.dispatchBannerList({
      banner_category: "course",
    });
  }

  render() {
    return (
      <div className="banner-slider">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            {this.props?.bannerList?.map((list, ind) => (
              <li
                key={list.id}
                data-target="#carouselExampleIndicators"
                data-slide-to={ind}
                className={ind === 0 ? "active" : ""}
              ></li>
            ))}
          </ol>
          <div className="carousel-inner">
            {this.props?.bannerList?.map((item, index) => (
              <div
                key={item.id}
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
              >
                {item.link === null ? (
                  <>
                    <img src={item.banner_url} alt={item.banner_alt_text} />
                    <div className="banner-overlay"></div>{" "}
                  </>
                ) : (
                  <a href={item.link}>
                    <img src={item.banner_url} alt={item.banner_alt_text} />
                    <div className="banner-overlay"></div>
                  </a>
                )}
                <div className="banner-caption course">
                  {index === 0 ? (
                    <h1>{ReactHtmlParser(item.banner_heading)}</h1>
                  ) : (
                    <h2>{ReactHtmlParser(item.banner_heading)}</h2>
                  )}
                  <p>{ReactHtmlParser(item.banner_text)}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="left-right-arrow">
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              {" "}
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              {" "}
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>{" "}
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bannerList: state.bannerReducer?.bannerList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchBannerList: (data) => dispatch(getCourseBanner(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthBanner));
