import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PAYMENT_DETAIL,
  GET_USER_PAYMENT_DETAIL_SUCCESS,
  GET_USER_PAYMENT_DETAIL_ERROR,
} from "./actionType";

export const getUser = (data) => {
  return {
    type: GET_USER,
    payload: data,
  };
};

export const getUserSuccess = (data) => {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  };
};

export const getUserError = (data) => {
  return {
    type: GET_USER_ERROR,
    payload: data,
  };
};

export const getUserPaymentDetail = (data) => {
  return {
    type: GET_USER_PAYMENT_DETAIL,
    payload: data,
  };
};

export const getUserPaymentDetailSuccess = (data) => {
  return {
    type: GET_USER_PAYMENT_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getUserPaymentDetailError = (data) => {
  return {
    type: GET_USER_PAYMENT_DETAIL_ERROR,
    payload: data,
  };
};
