import {
  GET_PODCAST,
  GET_PODCAST_SUCCESS,
  GET_PODCAST_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_PODCAST_BY_CATEGORY_ID,
  GET_PODCAST_BY_CATEGORY_ID_SUCCESS,
  GET_PODCAST_BY_CATEGORY_ID_ERROR,
} from "./actionType";
const initialState = {
  error: null,
  loading: null,
  podcasts: null,
  podcastDetail: null,
  podcastList: null,
  isTimeSave: false,
  getPauseTime: null,
  podcastBanner: null,
  podcastListByCategory: null,
};
const podcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PODCAST:
      state = {
        error: null,
        loading: true,
        podcasts: null,
      };
      break;
    case GET_PODCAST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        podcasts: action.payload,
      };
      break;
    case GET_PODCAST_ERROR:
      state = {
        ...state,
        loading: false,
        podcast: null,
        error: action.payload,
      };
      break;
    case GET_BANNER:
      state = {
        error: null,
        loading: true,
        podcastBanner: null,
      };
      break;
    case GET_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        podcastBanner: action.payload,
      };
      break;
    case GET_BANNER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case GET_PODCAST_BY_CATEGORY_ID:
      state = {
        ...state,
        error: null,
        loading: true,
        podcastListByCategory: null,
      };
      break;
    case GET_PODCAST_BY_CATEGORY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        podcastListByCategory: action.payload,
      };
      break;
    case GET_PODCAST_BY_CATEGORY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        podcastListByCategory: null,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default podcastReducer;
