import {
  GET_BLOG,
  GET_BLOG_SUCCESS,
  GET_BLOG_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_BLOG_DETAIL,
  GET_BLOG_DETAIL_SUCCESS,
  GET_BLOG_DETAIL_ERROR,
  GET_BLOG_BY_CATEGORY_ID,
  GET_BLOG_BY_CATEGORY_ID_SUCCESS,
  GET_BLOG_BY_CATEGORY_ID_ERROR,
} from "./actionType";

export const getBlog = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BLOG,
    payload: data,
  };
};

export const getBlogSuccess = (data) => {
  return {
    type: GET_BLOG_SUCCESS,
    payload: data,
  };
};

export const getBlogError = (error) => {
  return {
    type: GET_BLOG_ERROR,
    payload: error,
  };
};
export const getBanner = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BANNER,
    payload: data,
  };
};

export const getBannerSuccess = (data) => {
  return {
    type: GET_BANNER_SUCCESS,
    payload: data,
  };
};

export const getBannerError = (error) => {
  return {
    type: GET_BANNER_ERROR,
    payload: error,
  };
};

export const getBlogDetailById = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BLOG_DETAIL,
    payload: data,
  };
};

export const getBlogDetailByIdSuccess = (data) => {
  return {
    type: GET_BLOG_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getBlogDetailByIdError = (error) => {
  return {
    type: GET_BLOG_DETAIL_ERROR,
    payload: error,
  };
};

export const getBlogByCategoryId = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BLOG_BY_CATEGORY_ID,
    payload: data,
  };
};

export const getBlogByCategoryIdSuccess = (data) => {
  return {
    type: GET_BLOG_BY_CATEGORY_ID_SUCCESS,
    payload: data,
  };
};

export const getBlogByCategoryIdError = (error) => {
  return {
    type: GET_BLOG_BY_CATEGORY_ID_ERROR,
    payload: error,
  };
};
