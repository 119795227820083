import React, { Component, PureComponent, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getCourse,
  addToFavourite,
} from "../../../store/protected/courses/action";
import { setCartItem } from "../../../store/protected/cart/action";
import { ToastContainer, toast } from "react-toastify";

//const Entrepreneurship = (props) => {
class Entrepreneurship extends Component {
  state = {
    settings: {
      className: "center",
      //centerMode: true,
      // infinite: true,
      slidesToShow: 4,
      speed: 500
    },
    showLoginModel: false,
    selectedTab: 1,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    }
  };

  onSelectedTab = (value) => {
    //setSelectedTab(value)
    this.setState(
      {
        ...this.state,
        selectedTab: value
      }
    );
  }

  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
    // = "Courses :: Maetrix";
    this.props.dispatchCourse({
      token: this.props.token,
    });
    if (this.props.token) {
      if (localStorage.getItem("courses")) {
        this.setState(
          {
            ...this.state,
            localCart:
              localStorage.getItem("courses").length > 0
                ? JSON.parse(localStorage.getItem("courses")).map((cart) => {
                  return {
                    service_id: cart.id,
                    service_category: cart.service_category,
                  };
                })
                : [],
          },
          () => {
            this.props.dispatchAddToCart({
              token: this.props.token,
              data: this.state.localCart,
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.getCourses != this.props?.getCourses &&
      this.props?.getCourses != null
    ) {
      this.setState({
        ...this.state,
        getCourseList: this.props?.getCourses?.data,
      });
    }
    if (
      prevProps?.isFavourite != this.props.isFavourite &&
      this.props.isFavourite != null
    ) {
      if (this.props.token) {
        this.props.dispatchCourse({
          token: this.props.token,
        });
        // window.location.reload();
      } else {
        this.props.dispatchCourse({});
      }
    }
  }

  isFavourite = (id) => {
    console.log(this.props);
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <section className="entrepreneurship my-5">

        {this.state.getCourseList &&
          this.state.getCourseList.map((list, index) => (
            <div>
              {list?.courses?.length > 0 && (
                <div>
                  <div className="container" key={index}>
                    <div className="row">
                      <div className="col-12 col-md-12 text-center">
                        <h2>{list.category_name} <span>
                          <Link
                            to={`/course/${list.category_title_url}`}
                            className="view-all-btn">
                            View all {" "}
                            <i className="fa fa-long-arrow-right"
                              aria-hidden="true"></i>
                          </Link>
                        </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="container my-5">
                    <div className="card-slider-new">
                      {/*<Slider {...this.state.settings} key={index}> */}
                      <div className="row">
                        {list.courses.map((course, id) => (

                          <div className="col-lg-3 pr-5">
                            {id < 4 && (
                              <Link
                                to={`course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                              >
                                <div className="card w-100">
                                  <img className="card-img-top" src={course.thumbnail_url} alt="Card image cap" />
                                  <div className="card-body">
                                    <h5 className="card-title"> {course?.course_name?.length <= 50
                                      ? course?.course_name
                                      : course?.course_name?.substring(0, 50) +
                                      "..."}</h5>
                                    <p>{course.no_of_chapters} Lessons</p>
                                    <a href="" className="btn-free">{course.course_type === 0 &&
                                      (course.is_subscribed === true ||
                                        course.is_subscribed === false) &&
                                      (course.is_independent === true ||
                                        course.is_independent === false)
                                      ? "Free"
                                      : course.course_type === 1 &&
                                        course.is_independent === false &&
                                        course.is_subscribed === false
                                        ? `£ ${course.monthly_fee}`
                                        : course.course_type === 1 &&
                                          course.is_independent === true &&
                                          course.is_subscribed === false
                                          ? `£ ${course.course_fee}`
                                          : "View Course"}</a>
                                    <p className="mt-4">
                                      {[...Array(course.rating)].map((elementInArray, index1) => (
                                        <img src={"./assets/new_layout/img/Path 9.png"} />
                                      )
                                      )}
                                      <small>({course.rating}.0)</small>
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                      {/* </Slider> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </section>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    getCourses: state.coursesReducer.courses,
    isFavourite: state?.coursesReducer?.isFavourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Entrepreneurship));
