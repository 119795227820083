import React, { PureComponent, useRef, useState, useEffect } from "react";

import Login from "../../../components/gaust/login";
import Header from "../../../layout/gaust/newLayout/Header";
import Footer from "../../../layout/gaust/newLayout/FooterHome";
import HomeSlider from "../../../layout/gaust/newLayout/HomeSlider";
import LatestLearning from "../../../components/auth/dashboard/NewComponent/LatestLearning";
import NewsFeeds from "../../../components/auth/dashboard/NewComponent/NewsFeeds";
import LatestEpisodes from "../../../components/auth/dashboard/NewComponent/LatestEpisodes";
import LatestBoxsets from "../../../components/auth/dashboard/NewComponent/LatestBoxsets";
import LatestPodcast from "../../../components/auth/dashboard/NewComponent/LatestPodcast";
import LatestBlogs from "../../../components/auth/dashboard/NewComponent/LatestBlogs";
import AllMembers from "../../../components/auth/dashboard/NewComponent/AllMembers";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageLoading from "../../common/PageLoading";

const NewHomePage = (props) => {
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [referral, setReferral] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setLoading(params.get("referral"));
  }, []);

  const loginModel = (value) => {
    if (value === true) {
      setShowLoginModel(true);
      window.$("#login-model").modal("show");
    }
  };
  //setShowLoginModel(true);
  //window.$("#login-model").modal("show");
  useEffect(() => {
    props.dispatchServicesToken({});
    //this.myFunction = this.myFunction.bind(this);
  }, []);

  const myFunction = (value) => {
    setShowLoginModel(true);

    setTimeout(function () {
      console.log("Hello World");
      window.$("#login-model").modal("show");
      // window.$(".reg-form").show();
      // window.$(".login-form").hide();
      // window.$(".forgot-form").hide();
    }, 200);
    /*
        if (value === true) {
          this.setState(
            {
              showLoginModel: true,
            },
            () => {
              window.$("#login-model").modal("show");
            }
          );
        }
       */
  };

  return (
    <React.Fragment>
      <div className="matrix_new_layout">
        {/*<Header hitHome={loginModel} />*/}
        <Header hitHome={myFunction} />
        {loading && loading == true ? (
          <PageLoading isLoading={loading} />
        ) : (
          <>
            <HomeSlider />
            {/*<LatestLearning />*/}
            <NewsFeeds />
            {/* <LatestEpisodes />
            <LatestBoxsets />
            <LatestPodcast token={props?.token} />
            <LatestBlogs token={props.token} /> */}
            <AllMembers />
          </>
        )}
        <Footer />
        {showLoginModel === true && <Login />}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewHomePage));
