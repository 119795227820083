import React, { useState, useEffect } from "react";
import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DataLoading from "../../common/DataLoading";
import {
  getRoadmapLessons,
  getRoadmapAssigns,
  updateRoadmapAssigns,
} from "../../../store/protected/roadmap/actions";
import {
  updateDashboardUserGoal,
  getDashboardUserGoal,
} from "../../../store/protected/dashboard/action";
import "./Roadmap.css";
const Roadmap = (props) => {
  const [lessons, setLessons] = useState([]);
  const [roadmap, setRoadmap] = useState([]);
  const [assigns, setAssigns] = useState([]);
  const[goal, setGoal] = useState(null);
  const[task, setTask] = useState(null);
  const[success, setSuccess] = useState(null);
  const[loadedProps, setLoadedProps] = useState(false);
  
  useEffect(() => {
    props.dispatchGetRoadmapLessons({
      token: props.token,
    });
    props.dispatchGetRoadmapAssigns({
      token: props.token,
    });
    props.dispatchGetDashboardUserGoal({
      token: props.token,
    });
  }, []);
  useEffect(() => {
    setAssigns(props.assigns);
    initRoadmap(props.lessons, props.assigns);
  }, [props.lessons, props.assigns]);
  useEffect(() => {
    if (
      !loadedProps &&
      props.userGoal &&
      props.userGoal.length > 0
    ) {
        setGoal(props.userGoal[0]?.goal)
        setTask(props.userGoal[0]?.task)
        setSuccess(props.userGoal[0]?.success)
        setLoadedProps(true)
    }
  }, [props.userGoal, loadedProps]);
  const checkFocusCount = () => {
    if (assigns.filter((a) => a.status == "focus").length >= 3) return false;
        return true 
    };
  const onChangeGoal = (e) => {
      // this.setState({ goal: e.target.value });
      setGoal(e.target.value)
  };
  const onChangeTask = (e) => {
      // this.setState({ task: e.target.value });
      setTask(e.target.value)
  };
  const onChangeSuccess = (e) => {
      // this.setState({ success: e.target.value });
      setSuccess(e.target.value)
  };
  const onSubmit = (e) => {
    props.dispatchUpdateDashboardUserGoal({
      token: props.token,
      goal: goal,
      task: task,
      success: success,
    });
  };
  const onClickRoadmap = (lessonId) => {
    let updatedAssigns = [...assigns];
    let updatedLessons = [...lessons];
    let assign = updatedAssigns.find((a) => a.lesson_id == lessonId);
    let lesson = null;
    if (!assign) {
      lesson = updatedLessons.find((l) => l.id == lessonId);
      lesson.status = "stuck";
      setAssigns(
        assigns.concat({
          lesson_id: lessonId,
          status: "stuck",
        })
      );
    } else {
      if (assign.status == "stuck") {
        if (checkFocusCount()) {
          assign.status = "focus";
        } else {
          toast.error("Can focus 3 lessons at max.");
          return;
        }
      } else if (assign.status == "focus") {
        assign.status = "complete";
      } else if (assign.status == "complete") {
        assign = null;
      }
      if (assign) {
        lesson = updatedLessons.find((l) => l.id == assign.lesson_id);
        lesson.status = assign.status;

        setAssigns(updatedAssigns);
      } else {
        lesson = updatedLessons.find((l) => l.id == lessonId);
        lesson.status = "default";
        updatedAssigns = assigns.filter((item) => item.lesson_id !== lessonId);
        setAssigns(updatedAssigns);
      }
    }
    // setLessons(updatedLessons);
    props.dispatchUpdateRoadmapAssigns({
      lesson_id: lessonId,
      status: lesson.status,
      token: props.token,
    });
  };
  const initRoadmap = (lessons, assigns) => {
    let updatedRLessons = [...lessons];
    assigns.forEach((assign) => {
      const lesson = updatedRLessons.find((l) => l.id == assign.lesson_id);
      if (lesson) {
        lesson.status = assign.status;
      }
      return lesson;
    });
    setLessons(updatedRLessons);
    let roadmap = lessons.reduce((acc, obj) => {
      const key = obj.cat_name; // Property to group by

      if (!acc[key]) {
        acc[key] = []; // Initialize an array for this key if it doesn't exist
      }

      acc[key].push(obj); // Push the object to the array associated with the key
      return acc;
    }, {});
    console.log(roadmap);
    setRoadmap(roadmap);
  };
  return (
    <React.Fragment>
      <Header token={props.token} />
      <ToastContainer />
      <div className="container page-height roadmap">
        <div className="title-section">
          {/* <img src="./assets/images/asethic-logo-1.png"></img> */}
          <h3>Your Learning Roadmap</h3>
        </div>
        
        <div className="roadmap-section">
          {Object.keys(roadmap).map((key) => (
            <div key={"cat_" + key} className="lessons">
              <p className="category">{key}</p>
              <div className="group">
                {roadmap[key].map((lesson) => (
                  <p
                    key={`lesson_${lesson.id}`}
                    className={`lesson ${lesson.status}`}
                    onClick={() => onClickRoadmap(lesson.id)}
                  >
                    {lesson.lesson_name}
                  </p>
                ))}
              </div>
            </div>
          ))}
          {Object.keys(roadmap) && Object.keys(roadmap).length > 0 && (
            <div className="lessons labels">
              <div className="group no-border">
                <p className="lesson stuck ">Stuck</p>
                <p className="lesson focus">Focus</p>
                <p className="lesson complete">Complete</p>
              </div>
            </div>
          )}
              </div>
              {!props.updatingGoal ? (
            <div className="dashboard-ai-metadata text-center">
                <div className="metadata-row">
                <label>Goal</label>
                <textarea
                    className="text-height-3"
                    onChange={onChangeGoal}
                    value={goal}
                ></textarea>
                </div>
                <div className="metadata-row">
                <label>Focus</label>
                <div className="text-height-2 focus">
                    {props.roadmapAssigns.map((assign) => (
                    <p>{assign.lesson_name}</p>
                    ))}
                </div>
                </div>
                <div className="metadata-row">
                <label>Task</label>
                <textarea
                    className="text-height-3"
                    onChange={onChangeTask}
                    value={task}
                ></textarea>
                </div>
                <div className="metadata-row">
                <label>Success</label>
                <textarea
                    className="text-height-4"
                    onChange={onChangeSuccess}
                    value={success}
                ></textarea>
                </div>
                <button
                className="btn btn-submit"
                onClick={onSubmit}
                >
                Commit
                </button>
            </div>
            ) : (
            <DataLoading />
        )}
      </div>
      <Footer token={props.token} />
    </React.Fragment>
  );
};

const mapStateToProps = (status) => {
  return {
    lessons: status?.roadmapReducer?.lessons,
    assigns: status?.roadmapReducer?.assigns,
    loading: status?.roadmapReducer?.loading,
    roadmapAssigns: status.roadmapReducer.assigns,
    updatingGoal: status.dashboardReducer.updatingGoal,
    userGoal: status.dashboardReducer.goal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetRoadmapLessons: (data) => dispatch(getRoadmapLessons(data)),
    dispatchGetRoadmapAssigns: (data) => dispatch(getRoadmapAssigns(data)),
    dispatchUpdateRoadmapAssigns: (data) => dispatch(updateRoadmapAssigns(data)),
    dispatchUpdateDashboardUserGoal: (data) => dispatch(updateDashboardUserGoal(data)),
    dispatchGetDashboardUserGoal: (data) => dispatch(getDashboardUserGoal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Roadmap));
