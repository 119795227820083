import React, { Component } from "react";
import { Link } from "react-router-dom";

class PublicCustomHeader extends Component {
  render() {
    return (
      <div className="custom-header">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <img
                src="/assets/new_layout/img/logo2.png"
                className="logo-icon"
                alt=""
              />
            </div>
            <div className="col-6">
              <div className="custom-header-right">
                <Link to="/">Back To Home Page</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicCustomHeader;
