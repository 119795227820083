import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../helpers/Constants";
import { withRouter } from "react-router-dom";

class NewsFeeds extends Component {
  state = {
    feed: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}api/socialMediaData`)
      .then((result) => {
        this.setState({
          feed: result?.data?.result,
        });
      })
      .catch((err) => {
        console.log("Error in Member fetch ", err);
      });
  }

  render() {
    return (
      <div className="news-feed">
        <h2>NewsFeed</h2>
        <div className="news-feed-row">
          {this?.state?.feed?.map((feeds, index) =>
            feeds.type === "event" ? (
              <div className="news-feed-box" key={index}>
                <a
                  href={feeds.event_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {feeds.image_url !== null && (
                    <img alt="img" src={feeds.image_url} className="mic-icon" />
                  )}
                  <h3>
                    <img alt="img" src="assets/images/fb-icon.png" />
                    {feeds.user ? feeds.user : "User"} added an{" "}
                    <strong>event</strong>
                  </h3>
                  <h3>{feeds.event_title}</h3>
                  <h6>{feeds.time}</h6>
                  <p>{feeds.event_description}...</p>
                </a>
              </div>
            ) : feeds?.type === "feed" ? (
              <div className="news-feed-box" key={index}>
                <a
                  href={feeds.feed_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {feeds.image_url !== null && (
                    <img alt="img" src={feeds.image_url} className="mic-icon" />
                  )}
                  <h3>
                    <img alt="img" src="assets/images/fb-icon.png" />
                    {feeds.user ? feeds.user : "User"} added a{" "}
                    <strong>post</strong>
                  </h3>
                  <p>
                    {feeds.feed_description}...<strong>View more</strong>
                  </p>
                  <h6>{feeds.time}</h6>
                </a>
              </div>
            ) : (
              <div className="news-feed-box" key={index}>
                <a
                  href={feeds.insta_feed_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {feeds.image_url !== null && (
                    <img alt="img" src={feeds.image_url} className="mic-icon" />
                  )}
                  <h3>
                    <img alt="img" src="assets/images/insta-icon.png" />
                    <strong>The Aesthetic Entrepreneurs</strong> added a post
                  </h3>
                  <p>{feeds.insta_feed_title}</p>
                  <h6>{feeds.time}</h6>
                </a>
              </div>
            )
          )}
          {/* <div className="col-lg-8 col-md-7">
                            <div className="calendar shadow bg-white p-5">
                                <div className="d-flex align-items-center"><i className="fa fa-calendar fa-3x mr-3"></i>
                                    <h2 className="month font-weight-bold mb-0 text-uppercase">December 2019</h2>
                                </div>
                                <p className="font-italic text-muted mb-5">No events for this day.</p>
                                <ol className="day-names list-unstyled">
                                    <li className="font-weight-bold text-uppercase">Sun</li>
                                    <li className="font-weight-bold text-uppercase">Mon</li>
                                    <li className="font-weight-bold text-uppercase">Tue</li>
                                    <li className="font-weight-bold text-uppercase">Wed</li>
                                    <li className="font-weight-bold text-uppercase">Thu</li>
                                    <li className="font-weight-bold text-uppercase">Fri</li>
                                    <li className="font-weight-bold text-uppercase">Sat</li>
                                </ol>

                                <ol className="days list-unstyled">
                                    <li>
                                        <div className="date">1</div>
                                        <div className="event bg-success">Event with Long Name</div>
                                    </li>
                                    <li>
                                        <div className="date">2</div>
                                    </li>
                                    <li>
                                        <div className="date">3</div>
                                    </li>
                                    <li>
                                        <div className="date">4</div>
                                    </li>
                                    <li>
                                        <div className="date">5</div>
                                    </li>
                                    <li>
                                        <div className="date">6</div>
                                    </li>
                                    <li>
                                        <div className="date">7</div>
                                    </li>
                                    <li>
                                        <div className="date">8</div>
                                    </li>
                                    <li>
                                        <div className="date">9</div>
                                    </li>
                                    <li>
                                        <div className="date">10</div>
                                    </li>
                                    <li>
                                        <div className="date">11</div>
                                    </li>
                                    <li>
                                        <div className="date">12</div>
                                    </li>
                                    <li>
                                        <div className="date">13</div>
                                        <div className="event all-day begin span-2 bg-warning">Event Name</div>
                                    </li>
                                    <li>
                                        <div className="date">14</div>
                                    </li>
                                    <li>
                                        <div className="date">15</div>
                                        <div className="event all-day end bg-success">Event Name</div>
                                    </li>
                                    <li>
                                        <div className="date">16</div>
                                    </li>
                                    <li>
                                        <div className="date">17</div>
                                    </li>
                                    <li>
                                        <div className="date">18</div>
                                    </li>
                                    <li>
                                        <div className="date">19</div>
                                    </li>
                                    <li>
                                        <div className="date">20</div>
                                    </li>
                                    <li>
                                        <div className="date">21</div>
                                        <div className="event bg-primary">Event Name</div>
                                        <div className="event bg-success">Event Name</div>
                                    </li>
                                    <li>
                                        <div className="date">22</div>
                                        <div className="event bg-info">Event with Longer Name</div>
                                    </li>
                                    <li>
                                        <div className="date">23</div>
                                    </li>
                                    <li>
                                        <div className="date">24</div>
                                    </li>
                                    <li>
                                        <div className="date">25</div>
                                    </li>
                                    <li>
                                        <div className="date">26</div>
                                    </li>
                                    <li>
                                        <div className="date">27</div>
                                    </li>
                                    <li>
                                        <div className="date">28</div>
                                    </li>
                                    <li>
                                        <div className="date">29</div>
                                    </li>
                                    <li>
                                        <div className="date">30</div>
                                    </li>
                                    <li>
                                        <div className="date">31</div>
                                    </li>
                                    <li className="outside">
                                        <div className="date">1</div>
                                    </li>
                                    <li className="outside">
                                        <div className="date">2</div>
                                    </li>
                                    <li className="outside">
                                        <div className="date">3</div>
                                    </li>
                                    <li className="outside">
                                        <div className="date">4</div>
                                    </li>
                                </ol>
                            </div>
                        </div> */}
          {/* </marquee> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news_feeds: state?.dashboardReducer?.services?.news_feeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsFeeds));
