import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import AuthFooter from "../../../layout/auth/Afooter";
import DashboardBanner from "./DashboardBanner";
import LastViewService from "./LastViewService";
import GroupChat from "./GroupChat";
import LetestCourses from "./LetestCourses";
import LetestBlogs from "./LetestBlogs";
import LatestMembers from "./LatestMembers";
import {
  getAllServices,
  getLatestServices,
} from "../../../store/protected/dashboard/action";
import LetestPodcast from "./LetestPodcasts";
import FeedVideo from "./FeedVideo";

import { loginUser } from "../../../store/actions";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

// import { withRouter } from "react-router";

class Dashboard extends PureComponent {
  componentDidMount() {
    // document.title = "Dashboard :: The Maetrix";
    this.props.dispatchServicesToken({
      token: this.props.token,
    });
    this.props.dispatchLetestService({
      token: this.props.token,
    });
  }
  render() {
    return (
      <React.Fragment>
        <AuthHeader token={this.props.token} />
        <DashboardBanner />
        <div className="container page-height">
          <div className="dashboard-top">
            <div className="row">
              <LastViewService />
              {/* <GroupChat
                userDetail={this.props.userName}
                token={this.props?.token}
              /> */}
            </div>
          </div>
        </div>
        <FeedVideo />
        <LetestCourses />
        <LetestPodcast token={this.props.token} />
        <LetestBlogs token={this.props.token} />
        <LatestMembers />

        <AuthFooter token={this.props.token} />
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userName: state.authReducer?.user?.user,
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchLetestService: (data) => dispatch(getLatestServices(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
