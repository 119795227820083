import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import Header from "../../../layout/gaust/Header";
import BlogBanner from "./BlogBanner";
import BlogCategory from "./BlogCategory";
import SubscriptionCheck from "./subscriptionCheck";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isLatestSuccess } from "../../../store/protected/courses/action";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class Blogs extends PureComponent {
  state = {
    url: "",
    blogUrl: "",
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    showSubscriptionModel: false,
    showLoginModel: false,
    tagName: "",
  };

  componentDidMount() {
    //  document.title = "Blogs :: The Maetrix";
    
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  goBlog = (blog) => {
    this.props.dispatchLatestService({
      id: blog.id,
      category: "blog",
      token: this.props.token,
    });

    this.props.history.push(
      `/blog/${blog.category_title_url}/${blog.blog_title_url}`
      
    );
   
    
  };
  goBlogFree = (blog) => {
    this.props.dispatchLatestService({
      id: blog.id,
      category: "blog",
      token: this.props.token,
    });

    this.props.history.push(
      `/blog/${blog.category_title_url}/${blog.blog_title_url}`
    );
  };

  goBuySubscription = (tag) => {
    console.log("model;;", tag);

    this.setState({
      tagName: tag,
    });
    window.$("#subsModel1").modal("show");
  };

  goLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    this.setState({
      blogUrl: `${window.location.origin}/blog/${url.category_title_url}/${url.blog_title_url}`+qrstr,
    });
    window.$("#shareModel").modal("show");
  };

  render() {
    return (
      <React.Fragment>
        <SharePopUpModel url={this.state.blogUrl} />
        {
          <SubscriptionCheck
            tag={this.state.tagName}
            history={this.props.history}
          />
        }
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <BlogBanner />
        <div className="all-blog page-height">
          <BlogCategory token={this.props.token} />
          <div className="blog-row">
            <div className="container">
              {this.props?.getBlog?.data.map(
                (category, ind) =>
                  category?.blogs?.length > 0 && (
                    <React.Fragment key={ind}>
                      <div className="blog-row-header">
                        <h2>{category.category_name}</h2>
                        <Link
                          to={`/blog/${category.category_title_url}`}
                          className="right-link"
                        >
                          View All{" "}
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                      <OwlCarousel
                        className="owl-theme"
                        margin={10}
                        items={4}
                        responsive={this.state.responsive}
                      >
                        {category?.blogs.map((blog, index) => (
                          <div className="blog-slider" key={index}>
                            <div className="blog-list">
                              <div className="item">
                                <div className="blog-sld-box">
                                  <div className="blog-sld-img">
                                    <img alt="img" src={blog.banner_url} />
                                  </div>
                                  <div className="blog-sld-cnt">
                                    <div className="blog-sld-top">
                                      {!this.props.token ? (
                                        <button
                                          onClick={() => this.goLogin()}
                                          type="button"
                                          className="share-blg"
                                        >
                                          <img
                                            alt="img"
                                            src="/assets/images/share-gray-icon.png"
                                          />
                                        </button>
                                      ) : blog.is_subscribed == 1 ? (
                                        <button
                                          onClick={() => this.sharePop(blog)}
                                          type="button"
                                          className="share-blg"
                                        >
                                          <img
                                            alt="img"
                                            src="/assets/images/share-gray-icon.png"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.goBuySubscription(
                                              blog.tag_name
                                            )
                                          }
                                          type="button"
                                          className="share-blg"
                                        >
                                          <img
                                            alt="img"
                                            src="/assets/images/share-gray-icon.png"
                                          />
                                        </button>
                                      )}
                                      <h2 className="title">
                                        {blog.blog_title}
                                      </h2>
                                    </div>
                                    <div className="blog-sld-footer">
                                      <img
                                        alt="img"
                                        src={
                                          blog.author_image !== null
                                            ? blog.author_image
                                            : "assets/images/course-slider-3.jpg"
                                        }
                                      />
                                      <h5>
                                        {blog.author_name !== null
                                          ? blog.author_name
                                          : "Richard"}
                                      </h5>
                                      <p>{blog.created_at}</p>
                                    </div>
                                    {blog.without_login === true ? (
                                      <button
                                        onClick={() => this.goBlogFree(blog)}
                                        className="dlt-blg"
                                      >
                                        Read More
                                      </button>
                                    ) : !this.props.token ? (
                                      <button
                                        onClick={this.goLogin}
                                        className="dlt-blg"
                                      >
                                        Read More
                                      </button>
                                    ) : blog.is_subscribed == 1 ? (
                                      <button
                                        onClick={() => this.goBlog(blog)}
                                        className="dlt-blg"
                                      >
                                        Read More
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.goBuySubscription(blog.tag_name)
                                        }
                                        className="dlt-blg"
                                      >
                                        Read More
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    </React.Fragment>
                  )
              )}
            </div>
          </div>
        </div>
        <Footer userDetail={this.props.userName} token={this.props.token}/>
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // getCourses: state.coursesReducer.courses
    getBlog: state.blogReducer.blogs,
    userName: state.authReducer?.user?.user,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Blogs));
