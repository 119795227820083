import React from "react";
import ReactDOM from "react-dom";
import Aesthetic from "./Aesthetic";
import store from "./store";
import { Provider } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

// ReactDOM.render

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Aesthetic />
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
// swDev();
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
