import React, { PureComponent } from "react";

import EmbededPop from "../podcasts/embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class PodcastActivity extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    playing: true,
    show: false,
    podcastData: {},
  };

  embeded = (data) => {
    this.setState({
      podcastData: data,
    });
    window.$("#embededPupUp").modal("show");
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.podcast_category_url +
      "/" +
      url?.podcast_title_url+qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };

  render() {
    return (
      <React.Fragment>
        <EmbededPop data={this.state.podcastData} />
        <SharePopUpModel url={this.state.podcastUrl} />
        {this.props?.PodcastList?.length > 0 && (
          <div className="podcast-row">
            <div className="podcast-row-header">
              <h2>Latest Podcasts</h2>
            </div>
            <div className="podcast-list">
              {this.props?.PodcastList?.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  items={3}
                  responsive={this.state.responsive}
                >
                  {this.props?.PodcastList.map((podcast, index) => (
                    <div className="podcast-list" key={index}>
                      <div className="item">
                        <div className="music-box">
                          <img
                            alt="img"
                            className="audio-img"
                            src={podcast?.banner_url}
                          />
                          <AudioPlayer
                            src={podcast.podcast_url}
                            playing={false}
                          />

                          <div className="audio-cnt">
                            <h2>{podcast?.podcast_title}</h2>
                            <div className="hide-audio-cnt">
                              {ReactHtmlParser(podcast?.description)}
                            </div>
                            <h6 className="btm-brd-hide">
                              <span></span>
                            </h6>
                          </div>
                          <div className="audio-action">
                            <a href={podcast.podcast_url} download>
                              <button>
                                <img
                                  alt="img"
                                  src="/assets/images/download-icon.png"
                                />
                              </button>
                            </a>
                            <button onClick={() => this.sharePop(podcast)}>
                              <img
                                alt="img"
                                src="/assets/images/share-icon.png"
                              />
                            </button>
                            <button onClick={() => this.embeded(podcast)}>
                              <img
                                alt="img"
                                src="/assets/images/coding-icon.png"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PodcastList: state?.activityReducer?.allActivity?.podcasts,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PodcastActivity));
