import React, { Component } from "react";

import Login from "../../gaust/login";
import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/gaust/Footer";
import CheckpoutPopUp from "./checkoutPopUp";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import axios from "axios";

import {
  getCartItem,
  deleteCartItem,
} from "../../../store/protected/cart/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Cart extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    //  document.title = "Cart :: The Maetrix";
    this.props.token
      ? this.props.dispatchCart({
          token: this.props.token,
        })
      : this.getLocalStorageCart();
    
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeleted !== this.props?.isDeleted &&
      this.props?.isDeleted !== null
    ) {
      this.props.dispatchCart({
        token: this.props.token,
      });
    }
  }

  loginCheck = () => {
    !this.props.token &&
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
  };

  handleRemove = (id, index) => {
    if (this.props.token) {
      this.props.dispatchRemoveFromCart({
        cart_id: id,
        token: this.props.token,
      });
    } else {
      let temp = this.state?.cartItem;
      temp.splice(index, 1);
      this.setState(
        {
          ...this.state,
          cartItem: temp,
        },
        () => {
          localStorage.setItem("courses", JSON.stringify(this.state?.cartItem));
        }
      );
    }
  };

  getLocalStorageCart = () => {
    this.setState({
      ...this.state,
      cartItem: JSON.parse(localStorage.getItem("courses")),
    });
  };

  checkoutPopUp = async (cartItems) => {
    const stripe = await stripePromise;
    let services = [];
    if (cartItems.cart_list) {
      services =
        cartItems?.cart_list?.length > 0
          ? cartItems?.cart_list?.map((cart) => {
              return {
                service_id: cart?.service_id,
                service_category: cart?.service_category,
                service_name: cart?.service_name,
              };
            })
          : [];
    }
    //const url = `${BASE_URL}api/createSessionForPaymentTest`;
    const url = `${BASE_URL}api/createSessionForPayment`;
    const response = await axios.post(
      url,
      { services },
      {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      }
    );
    const apiResult = await response.data.result;
    localStorage.setItem("cart_Checkout", JSON.stringify(apiResult));
    const result = await stripe.redirectToCheckout({
      sessionId: apiResult?.session?.id,
    });
    if (result.error) {
      console.log("error", result.error.message);
    }
  };

  render() {
    let cartTotal = 0;
    if (this.state.cartItem) {
      this.state.cartItem.map((item, index) => {
        cartTotal += parseInt(item.course_fee);
      });
    }
    return (
      <React.Fragment>
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}

        <div className="mycart-row page-height">
          <div className="container">
            <h2>My Cart</h2>
            <div className="row">
              <div className="col-lg-9 col-md-8">
                {this.props.token
                  ? this.props?.cartItem?.cart_list?.map((list, index) => (
                      <div className="mycart-box" key={index}>
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="cours-bnr">
                              <img alt="img" src={list?.banner} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cours-title">
                              <h3>{list?.service_category}</h3>
                              <h4>{list?.service_name}</h4>
                              {list?.no_sub_entities ? (
                                <p>{list?.no_sub_entities} Lessons</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="cours-action">
                              <h3>
                                <span>
                                  {" "}
                                  £{list.service_price ? list.service_price : 0}
                                  <i
                                    className="fa fa-tag"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </h3>
                              <button
                                onClick={() =>
                                  this.handleRemove(list.id, index)
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : this.state?.cartItem?.map((list, index) => (
                      <div className="mycart-box" key={index}>
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="cours-bnr">
                              <img alt="img" src={list?.course_banner} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cours-title">
                              <h3>{list?.service_category}</h3>
                              <h4>{list?.course_name}</h4>
                              <p>{list?.no_of_lessons} Lessons</p>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="cours-action">
                              <h3>
                                <span>
                                  {" "}
                                  £{list.course_fee ? list.course_fee : 0}
                                  <i
                                    className="fa fa-tag"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </h3>
                              <button
                                onClick={() =>
                                  this.handleRemove(list.id, index)
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              <div className="col-lg-3 col-md-4">
                {this.props.token ? (
                  <div className="course-item-dtl">
                    <h3>Total Items: {this.props?.cartItem?.no_of_services}</h3>
                    <h2>
                      <span>Total</span>£ {this.props?.cartItem?.total_amount}
                    </h2>
                    {this.props?.cartItem?.cart_list.length !== 0 ? (
                      <button
                        type="button"
                        onClick={() => {
                          this.checkoutPopUp(this.props?.cartItem);
                        }}
                      >
                        Checkout
                      </button>
                    ) : (
                      <button type="button" onClick={this.loginCheck}>
                        Checkout
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="course-item-dtl">
                    <h3>
                      Total Items:{" "}
                      {this.props?.cartItem?.no_of_services
                        ? this.props?.cartItem?.no_of_services
                        : "0"}
                    </h3>
                    <h2>
                      <span>Total</span>£ {cartTotal}
                    </h2>
                    {
                      <button type="button" onClick={this.loginCheck}>
                        Checkout
                      </button>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.showLoginModel === true && <Login />}
        </div>
        <Footer />
        {
          <CheckpoutPopUp
            key={this.props?.cartItem?.cart_list?.length}
            cartItem={this.props.cartItem}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItem: state.cartReducer?.cartItems,
    isDeleted: state.cartReducer?.isDeleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCart: (data) => dispatch(getCartItem(data)),
    dispatchRemoveFromCart: (data) => dispatch(deleteCartItem(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));
