import React, { PureComponent } from "react";

import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import CourseActivity from "./CourseActivity";
import VideoActivity from "./VideoActivity";
import BlogActivity from "./BlogActivity";
import PodcastActivity from "./PodcastActivity";
import FavouriteActivity from "./FavouriteAcitivity";

import { getAllActivity } from "../../../store/protected/my-activity/action";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

class MyActivity extends PureComponent {
  componentDidMount() {
    // document.title = "User Activity :: The Maetrix";
    this.props.dispatchActivity({
      token: this.props.token,
    });
  }
  render() {
    //console.log('all activity', this.props.allActivity);
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <ToastContainer />
        <div className="container page-height">
          <div className="activity-row">
            <h1>My Favourites</h1>
            <div className="activity-cnt">
              {this.props?.allActivity?.courses?.length === 0 &&
                this.props?.allActivity?.videos?.length === 0 &&
                this.props?.allActivity?.podcasts?.length === 0 &&
                this.props?.allActivity?.blogs?.length === 0 &&
                this.props?.allActivity?.favourites?.length === 0 ? (
                "No activity found"
              ) : (
                <>
                  {/*<CourseActivity token={this.props.token} />
                  <VideoActivity />
                  <PodcastActivity />
                  <BlogActivity />
                  */}
                  <FavouriteActivity />
                </>
              )}
            </div>
          </div>
        </div>

        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allActivity: state?.activityReducer?.allActivity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchActivity: (data) => dispatch(getAllActivity(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyActivity));
