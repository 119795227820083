import React, { useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"

const NewUserSuccess = ({ data }) => {
	let item = JSON.parse(localStorage.getItem("Checkout_Response"))
	let sessionId = localStorage.getItem("sessionId")
	let token = localStorage.getItem("user_token")

	useEffect(() => {
		updatePayment()
	}, [])

	async function updatePayment() {
		try {
			const response = await axios.post(
				"https://admin.themaetrix.com/api/updateValidityAfterSubscriptionPaymentV2",
				{
					session_id: sessionId,
					services: [
						{
							service_name: item.service_name,
							category: item.service_category,
							service_id: item.service_id,
						},
					],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			console.log(response.data, "response")
		} catch (error) {
			console.log(error, "error")
		}
	}

	return (
		<div className="row p-5">
			<div className="col-md-7 m-auto">
				<div className="card">
					<div className="sign-up-form active card-body">
						<div className="text-center">
							<div className="row">
								<div className="col-md-5 m-auto">
									<img src="/assets/images/asethic-logo-1.png" alt="" />
								</div>
								<div className="col-md-12 m-auto">
									<p>
										It's official - you're now part of the AE family! You are now part of an
										incredible group of practitioners, just like you who are working on their goals
										and dreams to build the business that they deserve. We are so excited to have
										you here.
										<br></br> <br></br> <br></br>
										Your welcome email is on its way to you with your login details. Be sure to
										check your junk mail in case it gets stuck! If you don't get it then please
										email us on xxxx or Contact us on 01798 874787.
										<br></br> <br></br> <br></br>
										Now, whilst you're waiting for access, sit back and watch the video below and
										let's start getting you results.
									</p>
								</div>

								<div className="col-md-5 m-auto">
									<img src=".//assets/images/user.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		data: state.authReducer,
	}
}

export default connect(mapStateToProps, {})(NewUserSuccess)
