import {
  SET_CART_ITEM,
  SET_CART_ITEM_SUCCESS,
  SET_CART_ITEM_ERROR,
  GET_CART_ITEM,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_ERROR,
  DELETE_CART_ITEM,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_ERROR,
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: null,
  cartItems: null,
  isAdded: false,
  addedResponse: false,
  isDeleted: null,
  payment_done: false,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_ITEM:
      state = {
        error: null,
        loading: true,
        addedResponse: false,
        cartItems: null,
      };
      break;
    case SET_CART_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        addedResponse: action.payload,
      };
      break;
    case SET_CART_ITEM_ERROR:
      state = {
        ...state,
        loading: false,
        addedResponse: false,
        error: action.payload,
      };
      break;
    case GET_CART_ITEM:
      state = {
        ...state,
        loading: true,
        cartItems: null,
        error: null,
      };
      break;
    case GET_CART_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        cartItems: action.payload,
      };
      break;
    case GET_CART_ITEM_ERROR:
      state = {
        ...state,
        loading: false,
        cartItems: null,
        error: action.payload,
      };
      break;

    case DELETE_CART_ITEM:
      state = {
        ...state,
        loading: true,
        isDeleted: null,
      };
      break;
    case DELETE_CART_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
      break;
    case DELETE_CART_ITEM_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isDeleted: false,
      };
      break;

    case CHECKOUT_PAYMENT:
      state = {
        error: null,
        loading: true,
        payment_done: false,
      };
      break;
    case CHECKOUT_PAYMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        payment_done: true,
      };
      break;
    case CHECKOUT_PAYMENT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        payment_done: false,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default cartReducer;
