export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_ERROR = "GET_BLOG_ERROR";

export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";

export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";
export const GET_BLOG_DETAIL_SUCCESS = "GET_BLOG_DETAIL_SUCCESS";
export const GET_BLOG_DETAIL_ERROR = "GET_BLOG_DETAIL_ERROR";

export const GET_BLOG_BY_CATEGORY_ID = "GET_BLOG_BY_CATEGORY_ID";
export const GET_BLOG_BY_CATEGORY_ID_SUCCESS =
  "GET_BLOG_BY_CATEGORY_ID_SUCCESS";
export const GET_BLOG_BY_CATEGORY_ID_ERROR = "GET_BLOG_BY_CATEGORY_ID_ERROR";
