import { loadStripe } from "@stripe/stripe-js";

//  Production API URL

// export const BASE_URL = "https://admin.themaetrix.com/";
// export const BASE_URL = "http://localhost:8000/";
// export const BASE_URL = "http://testadmin.apponward.com/";
export const BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/";
// development youtube channel id
export const CHANNEL_ID = "UCxdvdf4deEOyIcUSB4cZwcw";
// development youtube data api key
export const YOUTUBE_API_KEY = "AIzaSyAPNFdq5UrAfAw2SPWTxUWFzzApsudQ_c4";

//  Development API URL

// export const BASE_URL = "https://apimaetrix.apponward.com/";

//export const stripePromise= loadStripe('pk_test_bjFVxgOiMyRaC18bLaZLvugZ004CVnARWI');

//development facebook client id
export const CLIENT_ID =
  "448299962760-ek5k5m3a0ar6ijdj8s4pi77lnl97he17.apps.googleusercontent.com";

//production stripe public key test server
//export const stripePromise= loadStripe('pk_test_cu7RKFwM53OHISUmFa7SAxpK');

//production stripe public key live server
export const stripePromise = loadStripe(
  "pk_live_51OIXynBdVr6uG6Ki65MxzedwDfutM6jJfR6DvMegMxdhU1bUQgWs36S3QwLMVpTyjflKTuUTgGwdULYhzxa41aZx003LWzlyxl"
);
// export const stripePromise = loadStripe(
//   "pk_test_51OIXynBdVr6uG6Ki0MynNRyNO0H4fqfKAdu5yaudiCGUu1pDnnbZ2vLvsTdgMpW6j1zwPCT2qFiE99AZPLbTgLyd00OtB3JSea"
// );

export const ToastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

{
  /* <IfModule mod_rewrite.c>
RewriteEngine On
RewriteBase /subdirectory
RewriteRule ^index\.html$ - [L]
RewriteCond %{REQUEST_FILENAME} !-f
RewriteCond %{REQUEST_FILENAME} !-d
RewriteCond %{REQUEST_FILENAME} !-l
RewriteRule . /index.html [L]
</IfModule> */
}
