import React, { useState, PureComponent, useEffect } from "react";
import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import {
  createAIContent,
  saveAIContent,
  addAIContentFeedback,
} from "../../../store/protected/ai-content/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import { BASE_URL } from "../../../helpers/Constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import "./AIContent.css";
class AIContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      question: "",
      aiContent: "",
      feedback: "",
      aiGenerating: false,
      like: 0,
    };
    this.abort = null;
  }
  onChangeQuestion = (event) => {
    this.setState({ question: event.target.value });
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  onClickType = (type) => {
    this.setState({ type });
  };
  onKeyDownQuestion = async (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      this.setState({
        question: this.state.question + "\n",
      });
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + 30 + "px";
    } else if (event.key === "Enter") {
      event.preventDefault();
      try {
        this.generateAIContent();
      } catch (error) {
        console.error(error);
      }
    }
  };
  stopGenerating = () => {
    if (this.abort) this.abort.abort();
    this.setState({
      aiGenerating: false,
    });
    this.props.dispatchSaveAIContent({
      token: this.props.token,
      question: this.state.question,
      answer: this.state.aiContent,
      type: this.state.type,
    });
  };
  generateAIContent = async () => {
    if (this.state.question == "") {
      toast.error("Please input your question.");
      return;
    }
    if (this.state.type == "") {
      toast.error("Please select content category.");
      return;
    }
    const pThis = this;
    this.abort = new AbortController();
    this.setState({
      aiGenerating: true,
      aiContent: "",
    });
    try {
      await fetchEventSource(`${BASE_URL}api/ai/content/stream`, {
        method: "POST",
        signal: this.abort.signal,
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json",
          Authorization: "Bearer " + pThis.props.token,
        },
        body: JSON.stringify({
          question: pThis.state.question,
          type: pThis.state.type,
        }),
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          try {
            if (event.data != "[DONE]") {
              const parsedData = JSON.parse(event.data);
              if (
                parsedData &&
                parsedData.choices &&
                parsedData.choices.length > 0 &&
                parsedData.choices[0]?.delta &&
                parsedData.choices[0]?.delta.content
              ) {
                setTimeout(() => {
                  pThis.setState({
                    aiContent:
                      pThis.state.aiContent +
                      parsedData.choices[0]?.delta.content,
                  });
                }, 1);
              }
            } else if (event.data == "[DONE]") {
              console.log("finished");
              pThis.setState({ aiGenerating: false });
              pThis.props.dispatchSaveAIContent({
                token: pThis.props.token,
                question: pThis.state.question,
                answer: pThis.state.aiContent,
                type: pThis.state.type,
              });
            }
          } catch (e) {}
        },
        onclose() {
          console.log("Connection closed by the server");
        },
        onerror(err) {
          console.log("There was an error from server", err);
        },
      });
    } catch (e) {
      this.abort.abort();
    }
  };
  getAIContent = async () => {
    if (this.state.question == "") {
      toast.error("Please input your question.");
      return;
    }
    if (this.state.type == "") {
      toast.error("Please select content category.");
      return;
    }
    this.props.dispatchCreateAIContent({
      token: this.props.token,
      question: this.state.question,
      type: this.state.type,
    });
  };
  onLike = async (like) => {
    this.setState({ like });
  };
  onChangeFeedback = (e) => {
    this.setState({
      feedback: e.target.value,
    });
  };

  submitFeedback = () => {
    if (!this.state.aiContent || this.state.aiContent == "") {
      toast.error("No content generated");
      return;
    }
    if (this.state.feedback == "") {
      toast.error("Please write feedback to us");
      return;
    }
    this.props.dispatchAddFeedback({
      id: this.props.aiContent.id,
      like: this.state.like,
      feedback: this.state.feedback,
      token: this.props.token,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <ToastContainer />
        <div className="container page-height ai-content">
          <div className="ai-container">
            <div className="brief">
              <p>
                Tell Maetrix what you would like to do, or ask a question.{" "}
                <br />
                e.g -"I want to launch my new treatment" - we'll pick up the
                heavy lifting, all you need to do is refine and finesse.
              </p>
            </div>
            <div className="border-2 border-n-3 rounded-xl border-solid course-boxset-question relative flex items-bottom ">
              <textarea
                className={`${
                  !this.state.aiGenerating &&
                  this.state.aiContent &&
                  this.state.aiContent != "" &&
                  this.props.aiContent?.question == this.state.question
                    ? "small"
                    : "normal"
                }`}
                placeholder="Ask M.A.E.T.R.I.X"
                value={this.state.question}
                onChange={this.onChangeQuestion}
                onKeyDown={this.onKeyDownQuestion}
              />
              {/* {props.loading && <div className="dot-pulse"></div>} */}
              {!this.state.aiGenerating ? (
                <button
                  className={`btn btn-send ${
                    this.state.aiContent &&
                    this.state.aiContent != "" &&
                    this.props.aiContent?.question == this.state.question
                      ? ""
                      : "small"
                  }`}
                  onClick={() => this.generateAIContent()}
                >
                  {this.state.aiContent &&
                  this.state.aiContent != "" &&
                  this.props.aiContent?.question == this.state.question ? (
                    <i className="fa fa-refresh" aria-hidden="true">
                      &nbsp; Regenerate
                    </i>
                  ) : (
                    <img src="/assets/images/ai_icons/bot-icon.png" />
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-send"
                  onClick={() => this.stopGenerating()}
                >
                  Stop
                </button>
              )}
            </div>
            <div className="types">
              <div className="d-flex">
                <div
                  className={`type ${
                    this.state.type == "VIDEO" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("VIDEO")}
                >
                  <img
                    src="/assets/images/ai_icons/video.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/video_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Video Script</p>
                </div>
                <div
                  className={`type ${
                    this.state.type == "BLOG" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("BLOG")}
                >
                  <img
                    src="/assets/images/ai_icons/blog.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/blog_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Blog Post</p>
                </div>
                <div
                  className={`type ${
                    this.state.type == "SOCIAL" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("SOCIAL")}
                >
                  <img
                    src="/assets/images/ai_icons/social.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/social_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Social Media Post</p>
                </div>
                <div
                  className={`type ${
                    this.state.type == "CAMPAIGN" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("CAMPAIGN")}
                >
                  <img
                    src="/assets/images/ai_icons/campaign.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/campaign_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Campaign</p>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className={`type ${
                    this.state.type == "DESIGN_BRIEF" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("DESIGN_BRIEF")}
                >
                  <img
                    src="/assets/images/ai_icons/brief.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/brief_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Design Brief</p>
                </div>
                <div
                  className={`type ${
                    this.state.type == "HOMEPAGE" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("HOMEPAGE")}
                >
                  <img
                    src="/assets/images/ai_icons/homepage.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/homepage_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Web page</p>
                </div>
                <div
                  className={`type ${
                    this.state.type == "EMAIL" ? "active" : ""
                  }`}
                  onClick={() => this.onClickType("EMAIL")}
                >
                  <img
                    src="/assets/images/ai_icons/email.png"
                    className="default"
                    alt=""
                  />
                  <img
                    src="/assets/images/ai_icons/email_hover.png"
                    className="hover"
                    alt=""
                  />
                  <p>Email</p>
                </div>
              </div>
            </div>
            <div className="answer py-12">
              {this.state.aiGenerating && (
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="circle-half-stroke"
                  className="svg-inline--fa fa-circle-half-stroke fa-fw animate-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256c0-114.9-93.1-208-208-208V464c114.9 0 208-93.1 208-208zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                  ></path>
                </svg>
              )}
              {this.state.aiContent && (
                <ReactMarkdown>{this.state.aiContent}</ReactMarkdown>
              )}
              {this.props.aiContent &&
                !this.state.aiGenerating &&
                this.state.aiContent != "" && (
                  <div className="feedback">
                    <i
                      className="fa fa-thumbs-o-up"
                      aria-hidden="true"
                      data-toggle="modal"
                      data-target="#feedback-backmodal"
                      onClick={() => this.onLike(1)}
                    ></i>
                    <i
                      className="fa fa-thumbs-o-down"
                      aria-hidden="true"
                      data-toggle="modal"
                      data-target="#feedback-backmodal"
                      onClick={() => this.onLike(-1)}
                    ></i>
                  </div>
                )}
              <div
                className="feedback-modal modal fade"
                id="feedback-backmodal"
              >
                <div className="modal-dialog modal-dialog-bottom">
                  <div className="modal-content">
                    <div className="modal-header">
                      <p>
                        {this.state.like == 1 ? (
                          <i className="fa fa-thumbs-o-up" />
                        ) : (
                          <i className="fa fa-thumbs-o-down" />
                        )}
                        &nbsp;Provide feedback
                      </p>
                    </div>
                    <div className="modal-body">
                      <div>
                        <p>What did you like about the response?</p>
                        <textarea onChange={this.onChangeFeedback}></textarea>
                      </div>
                      <div className="feedback-btn">
                        {this.props.savingFeedback ? (
                          <button className="btn" disabled>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                            Submitting feedback
                          </button>
                        ) : (
                          <button
                            className="btn"
                            onClick={this.submitFeedback}
                            data-loading-text="<i className='fa fa-circle-o-notch fa-spin'></i> Processing Order"
                          >
                            Submit feedback
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aiContent: state?.aiContentReducer?.aiContent,
    loading: state?.aiContentReducer?.loadingAIContent,
    error: state?.aiContentReducer?.error,
    feedback: state?.aiContentReducer?.feedback,
    savingFeedback: state?.aiContentReducer?.savingFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCreateAIContent: (data) => dispatch(createAIContent(data)),
    dispatchSaveAIContent: (data) => dispatch(saveAIContent(data)),
    dispatchAddFeedback: (data) => dispatch(addAIContentFeedback(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AIContent));
