import React, { Component } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Login from "../../../components/gaust/login";
import Footer from "../../../layout/auth/Afooter";

class PrivacyPolicy extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    //console.log('value', value);
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  componentDidMount() {
    // document.title = "Privacy and Policy :: The Maetrix";
  }

  render() {
    return (
      <React.Fragment>
        {this.props.token ? (
          <AuthHeader token={this.props.token} history={this.props.history} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="privacyPolicy page-height">
          <div className="container">
            <h2>TwentyTwoPounds Ltd Privacy Policy</h2>

            <p>
              TwentyTwoPounds Ltd understands that your privacy is important to
              you and that you care about how your personal data is used. We
              respect and value the privacy of all of our clients and members
              and will only collect and use personal data in ways that are
              described here, and in a way that is consistent with our
              obligations and your rights under the law.
            </p>

            <h4>Information About Us</h4>
            <p>
              TwentyTwoPounds Ltd a limited company registered in England under
              company number 0826400.
            </p>
            <p>Registered address: 6 Chestnut Walk, Worthing BN13 3QL</p>
            <p>VAT number: 252083819</p>
            <p>Data Protection Officer: Peter Glock,</p>
            <p>Email address: Peter@iconsult.global</p>
            <p>We are registered with the Information Commissioners Office</p>
            <br />
            <h4>What Does This Notice Cover?</h4>
            <p>
              This Privacy Information explains how we use your personal data:
              how it is collected, how it is held, and how it is processed. It
              also explains your rights under the law relating to your personal
              data.
            </p>
            <p>
              <br />
            </p>
            <h4>What is Personal Data?</h4>
            <p>
              Personal data is defined by the General Data Protection Regulation
              (EU Regulation 2016/679) (the “GDPR”
              <span lang="pt-PT">) as&nbsp;</span>‘any information relating to
              an identifiable person who can be directly or indirectly
              identified in particular by reference to an identifier’.
            </p>
            <p>
              Personal data is, in simpler terms, any information about you that
              enables you to be identified. Personal data covers obvious
              information such as your name and contact details, but it also
              covers less obvious information such as identification numbers,
              electronic location data, and other online identifiers.
            </p>
            <p>
              The right to data portability. This means that, if you have
              provided personal data to us directly, we are using it with your
              consent or for the performance of a contract, and that data is
              processed using automated means, you can ask us for a copy of that
              personal data to re-use with another service or business in many
              cases.
            </p>
            <p>
              Further information about your rights can also be obtained from
              the Information Commissioner’s Office or your local Citizens
              Advice Bureau.
            </p>
            <p>
              If you have any cause for complaint about our use of your personal
              data, you have the right to lodge a complaint with the Information
              Commissioner’<span lang="it-IT">s Office.</span>
            </p>
            <br />
            <h4>What Personal Data Do You Collect?</h4>
            <p>
              We may collect some or all of the following personal data (this
              may vary according to your relationship with us:
            </p>
            <p>
              In accordance with Data Protection Law we hereby notify you that
              this iCONSULT will collect and process personal information in
              order to carry out our service as your aesthetic medical
              practitioner. The Patient Registration form and medical
              questionnaire and consultation record requests personal
              information about you which identifies you individually (including
              your name, address, date of birth, email address, telephone
              numbers, certain financial information where necessary) and also
              sensitive personal information (including information about your
              medical history) which we consider to be reasonably necessary to
              enable the Clinic to provide services to you. You will also be
              invited to have a photograph or photographs taken of the relevant
              part/s of your face and/or body to assist your Healthcare
              Professional in the consultation and treatment.
            </p>
            <p>
              The information you give and any photographs you permit to be
              taken will be processed and held electronically and stored online
              by the Clinic/your Healthcare Professional through the iConsult
              System. If you return to the same Clinic for further consultations
              using the iConsult database the information obtained and
              photographs taken during the course of such further consultation
              will be added to your records and stored in the same way as above.
            </p>
            <p>
              The information and photographs are processed and stored on behalf
              of the Clinic/your Healthcare Professional by iConsult Software
              Limited for the purpose of keeping records of your consultations
              with your aesthetic medical practitioner and treatments carried
              out.
            </p>
            <p>
              Your information may also be used by the Clinic/your Healthcare
              Professional for the purpose of offering and providing benefits to
              you, for statistical profiling and for analysis of the development
              of the activities of the Clinic
            </p>
            <p>
              In order to effectively store records online it may be necessary
              for iConsult Software Limited to transfer personal information to
              third party data storage providers on the basis that they are
              contractually obliged to keep all information secure and
              confidential. The information may be accessed at any time by your
              Clinic/Healthcare Professional. The information will not otherwise
              be disclosed by iConsult Software Limited and its data storage
              providers except where required by law.
            </p>
            <p>
              You are entitled, subject to Data Protection Law, to request from
              the Clinic/your Healthcare Professional a copy of information held
              about you. Please direct your request to your Clinic so that they
              may retrieve the relevant data from iConsult Software Limited for
              you. For your care and protection there are certain restrictions
              on release of health information.
            </p>
            <p>
              By proceeding with your consultation you consent to the collection
              processing and storage of personal information as outlined above.
            </p>
            <br />
            <h4>GDPR Compliance</h4>
            <p>
              1. For the purposes of understanding roles in regard to the
              General Data Protection Regulation (GDPR) (Regulation (EU)
              2016/679) - TwentyTwoPounds Ltd is defined as the Data Processor
              and the Client is the Data Controller.
            </p>
            <p>
              2. Use of the Website and the Services is also governed by
              iConsult Software Privacy Policy which is incorporated into these
              terms and conditions by this reference.
            </p>
            <p>
              3. The Client, as Data Controller, appoints TwentyTwoPounds Ltd as
              a Processor to process the Personal Data as described on the
              Client’<span lang="de-DE">s behalf.</span>
            </p>
            <p>
              4. TwentyTwoPounds Ltd will only process the Personal Data to
              provide the Services or otherwise to comply with applicable laws
              or regulatory requirement.
            </p>
            <p>
              5. TwentyTwoPounds Ltd will ensure that any person with access to
              or processing the Personal Data is subject to a duty of
              confidence.
            </p>
            <p>
              6. TwentyTwoPounds Ltd will take appropriate technical and
              organisational security measures to ensure the security of
              processing and protect the Personal Data from accidental or
              unlawful destruction, loss, alteration, unauthorised access or
              disclosure or unlawful processing.
            </p>
            <p>
              7. The Client authorises TwentyTwoPounds Ltd to appoint
              subprocessors as they deem appropriate or necessary for the
              provision of the Services.
            </p>
            <p>
              8. TwentyTwoPounds Ltd will assist the Client in providing subject
              access and allowing data subjects to exercise their rights under
              the GDPR.
            </p>
            <p>
              9. TwentyTwoPounds Ltd will assist the Client in meeting its GDPR
              obligations in relation to the security of processing, the
              notification of personal data breaches and data protection impact
              assessments.
            </p>
            <p>
              10. TwentyTwoPounds Ltd will provide mechanisms for the Client to
              download all Personal Data at any time, to delete the record of a
              single Data Subject, and to delete all Personal Data at the end of
              the contract.
            </p>
            <p>
              11. The Client may exercise their right of Audit under GDPR
              legislation through TwentyTwoPounds Ltd providing an audit report
              not older than 18 months prepared by an independent external
              auditor demonstrating TwentyTwoPounds Ltd technical and
              organisational measures are sufficient to meet the obligations of
              a Data Processor under GDPR.
            </p>
            <p>
              12. TwentyTwoPounds Ltd will submit to Client audits and
              inspections, provided the Client pays an applicable audit fee in
              full, and in advance of the commencement of such audit.
            </p>
            <p>
              13. TwentyTwoPounds Ltd will immediately inform the Client if it
              is asked to do something infringing the GDPR or other data
              protection law of the EU or a member state.
            </p>
            <p>
              14. Nothing within this contract relieves TwentyTwoPounds Ltd of
              its own direct responsibilities and liabilities under the GDPR.
            </p>
            <p>
              <strong>Cookies (or browser cookies)</strong>. A cookie is a small
              file placed on the hard drive of your computer. You may refuse to
              accept browser cookies by activating the appropriate setting on
              your browser. However, if you select this setting you may be
              unable to access certain parts of our Sites. Unless you have
              adjusted your browser setting so that it will refuse cookies, our
              system will issue cookies when you direct your browser to our
              Sites.
            </p>
            <p>
              <strong>Flash Cookies</strong>. Certain features of our Sites may
              use local stored objects (or Flash cookies) to collect and store
              information about your preferences and navigation to, from and on
              our Sites. Flash cookies are not managed by the same browser
              settings as are used for browser cookies. For information about
              managing your privacy and security settings for Flash cookies, see
              Choices about How We Use and Disclose Your Information.
            </p>
            <p>
              <strong>Web Beacons</strong>. Pages of our the Sites and our
              e-mails may contain small electronic files known as web beacons
              (also referred to as clear gifs. pixel tags and single-pixel gifs)
              that permit us, for example, to count users who have visited those
              pages or opened an e-mail and for other related website statistics
              (for example, recording the popularity of certain website content
              and verifying system and server integrity).
            </p>

            <br />
            <h4>Accessing and Correcting Your Information</h4>
            <p>
              You can review and change your personal information by logging
              into the Sites and visiting your account profile page.
            </p>
            <p>
              You may also send us an e-mail at support@iconsult.global to
              request access to, correct or delete any personal information that
              you have provided to us. In some cases, we cannot delete your
              personal information except by also deleting your user account. We
              may not accommodate a request to change information if we believe
              the change would violate any law or legal requirement or cause the
              information to be incorrect.
            </p>
            <p>
              If you delete your Interactive Content from the WebSites, copies
              of your Interactive Content may remain viewable in cached and
              archived pages, or might have been copied or stored by other users
              of the Sites. Proper access and use of information provided on the
              Sites, including Interactive Content, is governed by our Terms of
              Use.
            </p>
            <br />
            <h4>Data Security</h4>
            <p>
              All communications with our servers are encrypted with SSL/TLS
              (Transport Layer Security).
            </p>
            <p>
              We follow the latest industry standards and guidelines for
              protecting our customer's data against unauthorised access.
            </p>
            <p>
              For improved security we also offer the ability to use two-factor
              authentication, This feature is also implemented by the banks
              requiring you to use a separate device, an application on your
              phone or SMS code verification when you log into your bank
              account. When activated even if someone steals your password they
              cannot access the software without the second authentication
              mechanism.
            </p>
            <p>We implement two-factor authentication using:</p>
            <p>
              <strong>SMS passcodes</strong>
            </p>
            <p>
              This privacy policy describes the types of information we may
              collect from you or that you may provide when you visit our
              WebSites or use our Services in general, and our practices for
              collecting, using, maintaining, protecting and disclosing that
              information.
            </p>
            <p>
              We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access,
              use, alteration and disclosure. All information you provide to us
              is stored on our secure servers behind firewalls. Any payment
              transactions will be encrypted using SSL technology.
            </p>
            <p>
              The safety and security of your information also depends on you.
              Where we have given you (or where you have chosen) a password for
              access to certain parts of our Sites, you are responsible for
              keeping this password confidential. We ask you not to share your
              password with anyone. We urge you to be careful about giving out
              information in public areas of the WebSites like message boards.
              The information you share in public areas may be viewed by any
              user of the WebSites.
            </p>
            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our WebSites or via our
              Services. Any transmission of personal information is at your own
              risk. We are not responsible for circumvention of any privacy
              settings or security measures contained on the WebSites, Software
              or via the Services.
            </p>
            <br />
            <p>
              <strong>DATA RETENTION</strong>
            </p>
            <p>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature, and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements.
            </p>
            <p>
              By law we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              six years after they cease being customers for tax purposes.
            </p>
            <p>
              In some circumstances you can ask us to delete your data: see
              below for further information.
            </p>
            <p>
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes in which case we may use this information
              indefinitely without further notice to you.
            </p>
            <br />
            <h4>Data Hosting</h4>
            <p>
              Consult Software Ltd use Marotori and Amazon Web Services Its as
              our hosting Partners
            </p>
            <p>
              All services provided by Marotori may be used for lawful purposes
              only. Transmission, storage or presentation of any information,
              data or material in violation of any British law is prohibited.
              This includes, but is not limited to: copyrighted material,
              material we judge to be threatening or obscene, or material
              protected by trade secret and other statute. The subscriber agrees
              to indemnify and hold Marotori risk-free from any claims resulting
              from the use of service which damages the subscriber or any other
              party. This agreement is specifically bound by British law and
              will be upheld in an English Court of law.
            </p>
            <p>
              Prohibited are sites that promote any illegal activity or present
              content that may be damaging to Marotori’s servers, or any other
              server on the Internet. Links to such materials are also
              prohibited.
            </p>
            <strong>Examples of unacceptable content or links:</strong>
            <p>• Pirated software</p>
            <p>• Hacking programs or archives</p>
            <p>• Warez sites</p>
            <p>• Child pornography</p>
            <p>
              • Images, movies or sound with which you are not or do not hold
              copyright privileges to.
            </p>
            <p>
              <strong>NOTICE</strong>: IF YOUR ACCOUNT IS FOUND TO BE IN BREACH
              OF THIS CONTRACT IN TERMS OF PROVIDING / STORING CONTENT WE DEEM
              TO BE ILLEGAL, DEPENDING ON THE SEVERITY, YOUR ACCOUNT WILL BE
              TERMINATED IMMEDIATELY AND THE PROPER AUTHORITIES NOTIFIED OF YOUR
              ACTIONS. FOR MINOR BREACHES, YOU WILL BE SENT AN EMAIL WITH WHICH
              YOU HAVE 24(TWENTY-FOUR)HOURS TO COMPLY OR YOUR ACCOUNT WILL BE
              TERMINATED.
            </p>
            <h4>Service Agreement</h4>
            <p>
              Marotori will provide connection to all organizations and
              individuals who agree to abide by Marotori access terms,
              conditions, and fee schedules. User is responsible for providing
              any equipment and/or software necessary to access the Marotori
              system and Internet facilities, unless otherwise provided for in
              this Agreement.
            </p>
            <p>
              User is entitled to use any web hosting services provided by
              Marotori. User is responsible for any licensing fees for any
              "shareware" products provided by Marotori to User.
            </p>
            <p>
              Marotori will provide the Web Hosting services provided in the
              service package
            </p>
            <p>
              chosen by user, including a server or web space on a shared
              server, connection of the server to Marotori's high-speed Internet
              connection, backup of the server's hard disk (if nominated to do
              so for dedicated servers) and other maintenance on the server,
              domain name (so long as nominated to do so), e-mail, and file
              transfer services as provided in the chosen service package,
              automated server monitoring and limited log file generation and
              access. All equipment provided by Marotori shall remain Marotori's
              property.
            </p>
            <p>
              The services and software offered are subject to change and
              limitation at Marotori’s discretion, as is any pricing schedule.
              Marotori will notify the user of any changes by electronic mail to
              the agent named in this Agreement or other user officer, unless
              the change is judged by Marotori to be necessary to preserve
              proper security or functioning of Marotori's system. If the user
              objects to any change in service, unless the change is one
              Marotori has determined is necessary for security purposes or to
              maintain proper operation of Marotori's system, user will be
              entitled to cancel their account.
            </p>
            <p>
              User's continued use of the Marotori Web Hosting services after
              the effective date of such modified general terms and conditions,
              policies, or changes in services or software will constitute
              user's acceptance of such modified terms.
            </p>
            <p>
              This Agreement covers Web Hosting services, and any ancillary
              services such as domain name service and electronic mail.
            </p>
            <br />
            <h4>Traffic Usage</h4>
            <p>
              All account plans come with a predetermined amount of traffic
              (bandwidth) allowance. We monitor all accounts and will notify the
              user once they have reached their bandwidth limit. Following this,
              the user has two options. Either be charged £1.00/GB in overage,
              rounded to the nearest gigabyte or have the account temporarily
              suspended until the end of the users billing month.
            </p>
            <br />
            <h4>IRC / Other Services</h4>
            <p>
              No IRC related services are to be run from our network at any
              time. This includes (but not limited to); eggdrops, IRCDs, BNCs
              and BitchX. No services are to be run from any of Marotori’s
              shared servers without prior consent from Marotori Administration.
              There will generally be a small monthly charge associated with
              running a service, determined upon the programs resource usage, at
              Marotori administrations discretion.
            </p>
            <p>
              <br />
            </p>
            <h4>Server Abuse</h4>
            <p>
              Any attempt to undermine or cause harm to a server or customer of
              Marotori is strictly prohibited. All information regarding any
              attack on Marotori’s servers will be handed over to the proper
              authorities. Distribution of spam emails from any of Marotori's
              servers is strictly prohibited. Marotori will deem emails 'spam'
              at their discretion. Any reports of spam like activity will be
              investigated and the proper action taken if we deem it necessary.
            </p>
            <p>
              <br />
            </p>
            <h4>Shared Server Usage</h4>
            <p>
              Use of Marotori’s shared services is subject to a fair use policy.
              If we find an account to be hosting a run-away script (some form
              of script that is using a large amount of system resources), we
              will terminate the script in question and notify the account
              owner. Marotori will attempt to find a possible cause for the
              run-away script, however, it is the users responsibility to amend
              the problem. If the problem continues to arise, Marotori may take
              the following steps, at the company’s discretion: completely
              remove the script from the system, temporarily suspend the account
              in question or terminate the account in question.
            </p>
            <p>
              <br />
            </p>
            <h4>Refusal of Service</h4>
            <p>
              Marotori reserves the right to refuse, cancel or suspend service,
              at our sole discretion.
            </p>
            <p>
              All sub-networks, distributive hosting sites and dedicated servers
              of Marotori must adhere to the above policies unless otherwise
              excluded.
            </p>
            <p>
              <br />
            </p>
            <h4>Billing</h4>
            <p>
              By the Account Activation Date of each month, Marotori shall
              either: (1) debit the client's credit card (when such information
              has been provided by the client); or (2) deliver, by e-mail or
              regular mail, an invoice in accordance with the applicable Service
              Fees for services rendered for the current month. When an invoice
              is delivered to the client, payment shall be remitted to Marotori
              by no later than the specified payment due date. Marotori will
              attempt to provide appropriate notice for such due fees. Marotori
              shall be entitled to immediately terminate this agreement for
              client's failure to make timely payments. You will be provided
              with an invoice on a monthly basis. Credit cards may be billed
              automatically on a monthly basis. It is the client's
              responsibility to ensure that they have sufficient credit to cover
              this transaction. In the event that there is insufficient credit,
              we will send an e-mail notification, at which point we will need
              to be provided with another credit card account number within 24
              hours, or payment from another means. If we do not receive a
              response within 24 hours, the account, and all accounts under that
              account plan, will be suspended.
            </p>
            <p>
              All fees, unless otherwise stated, are listed in the British
              Pound.
            </p>
            <p>
              <br />
            </p>
            <h4>Service Fees</h4>
            <p>
              If client terminates this agreement, client shall be responsible
              for any and all outstanding fees owed to Marotori and agrees to
              pay any and all fees incurred by client. Because the services are
              provided on a monthly basis, the client will be responsible for
              service fees incurred each month, regardless of when client
              provides notice of termination. Thus, for example, if the client
              provides notice of termination on the 15th day of a particular
              month, the client will be responsible for service fees for the
              entire month, and such fees will not be pro-rated or refunded. If
              client has retained the services for one year (or any term longer
              than one month) and has pre-paid Marotori for such services,
              refunds will be issued for any unused full month of the services,
              upon client's request. Therefore, if client's account is canceled
              at any point during the one-year term, client will be entitled to
              a refund for the months remaining, after notice given by the 25th
              day of the preceding month.
            </p>
            <p>
              <br />
            </p>
            <h4>Account Deactivations</h4>
            <p>
              Any account deactivated due to non-payment will require a
              reactivation fee of £10.00 prior to reactivation.
            </p>
            <p>
              <br />
            </p>
            <h4>Cancellation Refunds</h4>
            <p>
              A month will not be partially refunded to accounts. No refunds are
              given on any VPS or dedicated servers or setup fees for VPS or
              dedicated servers. Neither can Marotori provide any refund on
              Domain Names purchased through the company. We require 30 days
              notice for a cancellation. Marotori honor a 7 (seven) day full
              refund on hosting services purchased, except those which are
              excluded above, beginning from the date of signup. After the 7
              (seven) days have expired, the above Service Fees clause will
              apply. Users may be charged a credit card processing fee on the 7
              day refund.
            </p>
            <p>
              <br />
            </p>
            <h4>Limitation of Liability</h4>
            <p>
              Marotori shall not be responsible for any claimed damages,
              including incidental and consequential damages, which may arise
              from Marotori’s servers going off-line or being unavailable for
              any reason whatsoever. Furthermore, Marotori shall not be
              responsible for any claimed damages, including incidental or
              consequential damages, resulting from the corruption or deletion
              of any web site from one of Marotori’s servers. All damages shall
              be limited to the immediate termination of service.
            </p>
            <p>
              No waiver of any breach of any provision of this Agreement will
              constitute a waiver of any prior, concurrent or subsequent breach
              of this Agreement or any provision thereof. If any provision of
              the Agreement is or becomes illegal or otherwise unenforceable,
              such provision will not invalidate the other provisions hereof;
              provided if Marotori determines that any such unenforceable
              provision is essential, it may terminate this Agreement upon
              notice.
            </p>
            <p>
              User grants any permissions or licenses (including but not limited
              to copyright licenses), as may be required, and within User's
              power to grant, to Marotori in order to provide hosting and
              related services to User, or as may be required for Marotori to
              operate for User's benefit.
            </p>
            <p>
              <br />
            </p>
            <h4>Violations</h4>
            <p>
              Violations of these policies should be referred to
              admin@marotori.com. All complaints will be investigated promptly.
              Failure to follow any term or condition will be grounds for
              immediate account deactivation.
            </p>
            <p>
              <br />
            </p>
            <h4>Disclaimer</h4>
            <p>
              Marotori cannot be held liable for system down time, crashes or
              data loss. We cannot be held liable for any predicated estimate of
              profits which a client would have gained if their site was
              functioning. Certain services provided by Marotori are resold.
              Thus, certain equipment, routing, software and programming used by
              Marotori are not directly owned or written by marotori.com.
              Moreover, Marotori holds no responsibility for the use of our
              clients' accounts. Failure to comply with any terms or conditions
              will result in the automatic deactivation of the account in
              question. We reserve the right to remove any account, without
              advance notice for any reason without restitution, as Marotori
              Administration sees fit.
            </p>
            <p>
              <br />
            </p>
            <h4>Account Activation</h4>
            <p>
              By activating your account with Marotori, you agree to the above
              policies and disclaimer. Upon requesting activation of an account,
              you are required to accept these policies, guidelines and
              disclaimer, and it is the users responsibility to keep an up to
              date hard copy of this agreement.
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>NOTICE</strong>: If you sign up for an account and fail to
              comply with these terms, no refunds will be given. We will,
              however, advise you by e-mail or phone prior to taking any action
              to provide you with an opportunity to correct the problem.
            </p>
            <p>
              Marotori reserves the right to amend any or all of the above
              policies, guidelines and disclaimer without notification. We also
              retain the right to make changes to our account plans without
              prior notification however we will do our best to notify you of
              any changes.
            </p>
          </div>
        </div>
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
