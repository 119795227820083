import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import ReactHtmlParser from "react-html-parser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DataLoading from "../../../common/DataLoading";
import {
  getLatestServices,
  getDashboardAIAnswer,
  saveDashboardAIAnswer,
  updateDashboardUserGoal,
  getDashboardUserGoal,
} from "../../../../store/protected/dashboard/action";
import { getRoadmapAssigns } from "../../../../store/protected/roadmap/actions";
import { BASE_URL } from "../../../../helpers/Constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
// const LatestLearning = (props) => {
class LatestLearning extends PureComponent {
  // const [learningSlides, setLearningSlides] = useState([1, 2, 3, 4, 5, 6]);
  // const [loading, setLoading] = useState(true);
  // const [question, setQuestion] = useState("");
  // const [aiAnswer, setAiAnswer] = useState("");
  // const [aiQuestion, setAiQuestion] = useState("");
  // const [goal, setGoal] = useState("");
  // const [task, setTask] = useState("");
  // const [success, setSuccess] = useState("");
  constructor(props) {
    super(props);
    this.state = {
      learningSlides: [1, 2, 3, 4, 5, 6],
      question: "",
      aiGenerating: false,
      aiAnswer: "",
      goal: "",
      task: "",
      success: "",
      loadedProps: false,
    };
    this.abort = null;
    this.settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatchLetestService({
      token: this.props.token,
    });
    this.props.dispatchGetRoadmapAssigns({
      token: this.props.token,
    });
    this.props.dispatchGetDashboardUserGoal({
      token: this.props.token,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      this.state.loadedProps == false &&
      this.props &&
      this.props.userGoal &&
      this.props.userGoal.length > 0
    ) {
      this.setState({
        goal: this.props?.userGoal[0]?.goal,
        task: this.props?.userGoal[0]?.task,
        success: this.props?.userGoal[0]?.success,
        loadedProps: true,
      });
    }
    // if (
    //   this.props.aiAnswer?.answer != "" &&
    //   this.props.aiAnswer?.answer != this.state.aiAnswer &&
    //   this.state.question != ""
    // ) {
    //   this.setState({
    //     aiAnswer: this.props.aiAnswer.answer,
    //   });
    // }
  }

  // useEffect(() => {
  //   if (props && props.userGoal && props.userGoal.length > 0) {
  //     setGoal(props?.userGoal[0]?.goal);
  //     setTask(props?.userGoal[0]?.task);
  //     setSuccess(props?.userGoal[0]?.success);
  //   }
  // }, [props?.userGoal]);
  // useEffect(() => {
  //   if (
  //     props.aiAnswer?.answer != "" &&
  //     props.aiAnswer?.answer != aiAnswer &&
  //     question != ""
  //   ) {
  //     setAiAnswer(props.aiAnswer?.answer);
  //     setAiQuestion(props.aiAnswer?.question);
  //   }
  // }, [props.aiAnswer]);

  onChangeQuestion = (event) => {
    this.setState({ question: event.target.value });
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  onKeyDownQuestion = async (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      this.setState({ question: this.state.question + "\n" });
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + 30 + "px";
    } else if (event.key === "Enter") {
      event.preventDefault();
      try {
        this.generateAIAnswer();
      } catch (error) {
        console.error(error);
      }
    }
  };
  stopGeneratingAiAnswer = () => {
    if (this.abort) this.abort.abort();
    this.setState({
      aiGenerating: false,
    });
    this.props.dispatchSaveAIAnswer({
      token: this.props.token,
      question: this.state.question,
      answer: this.state.aiAnswer,
    });
  };
  generateAIAnswer = async () => {
    const pThis = this;
    this.abort = new AbortController();
    this.setState({
      aiGenerating: true,
      aiAnswer: "",
    });
    try {
      await fetchEventSource(`${BASE_URL}api/ai/dashboard/stream`, {
        method: "POST",
        signal: this.abort.signal,
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json",
          Authorization: "Bearer " + pThis.props.token,
        },
        body: JSON.stringify({
          question: pThis.state.question,
        }),
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          try {
            if (event.data != "[DONE]") {
              const parsedData = JSON.parse(event.data);
              if (
                parsedData &&
                parsedData.choices &&
                parsedData.choices.length > 0 &&
                parsedData.choices[0]?.delta &&
                parsedData.choices[0]?.delta.content
              ) {
                console.log(parsedData.choices[0]?.delta.content);
                setTimeout(() => {
                  pThis.setState({
                    aiAnswer:
                      pThis.state.aiAnswer +
                      parsedData.choices[0]?.delta.content,
                  });
                }, 1);
              }
            } else if (event.data == "[DONE]") {
              console.log("finished");
              pThis.setState({ aiGenerating: false });
              pThis.props.dispatchSaveAIAnswer({
                token: pThis.props.token,
                question: pThis.state.question,
                answer: pThis.state.aiAnswer,
              });
            }
          } catch (e) {}
        },
        onclose() {
          console.log("Connection closed by the server");
        },
        onerror(err) {
          console.log("There was an error from server", err);
        },
      });
    } catch (e) {
      pThis.abort.abort();
    }
  };

  getAIAnswer = async () => {
    if (this.state.question && this.state.question != "") {
      this.props.dispatchGetAIAnswer({
        token: this.props.token,
        question: this.state.question,
      });
    } else {
      toast.error("Please input your question.");
    }
  };
  onChangeGoal = (e) => {
    this.setState({ goal: e.target.value });
  };
  onChangeTask = (e) => {
    this.setState({ task: e.target.value });
  };
  onChangeSuccess = (e) => {
    this.setState({ success: e.target.value });
  };
  onSubmit = (e) => {
    this.props.dispatchUpdateDashboardUserGoal({
      token: this.props.token,
      goal: this.state.goal,
      task: this.state.task,
      success: this.state.success,
    });
  };
  render() {
    return (
      <section className="hero-2 latest_learning">
        <ToastContainer />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="sec">
                <h3 className="mb-0">
                  Welcome to M.A.E.T.R.I.X, {this.props?.userName.name}
                </h3>
              </div>

              {this.props.loading == false ? (
                <>
                  <div className="ai-container">
                    <div className="border-2 border-n-3 rounded-xl border-solid course-boxset-question relative flex items-bottom ">
                      <textarea
                        className={`${
                          !this.state.aiGenerating &&
                          this.state.aiAnswer != "" &&
                          this.props.aiAnswer?.question == this.state.question
                            ? "small"
                            : "normal"
                        }`}
                        placeholder="Ask C.L.A.R.A"
                        value={this.state.question}
                        onChange={this.onChangeQuestion}
                        onKeyDown={this.onKeyDownQuestion}
                      />
                      {/* {this.props.loadingChat && (
                        <div className="dot-pulse"></div>
                      )} */}
                      {!this.state.aiGenerating ? (
                        <button
                          className={`btn btn-send ${
                            this.state.aiAnswer != "" &&
                            this.props.aiAnswer?.question == this.state.question
                              ? ""
                              : "small"
                          }`}
                          onClick={() => this.generateAIAnswer()}
                        >
                          {this.state.aiAnswer != "" &&
                          this.props.aiAnswer?.question ==
                            this.state.question ? (
                            <i className="fa fa-refresh" aria-hidden="true">
                              &nbsp; Regenerate
                            </i>
                          ) : (
                            // <i
                            //   className="fa fa-angle-double-up"
                            //   aria-hidden="true"
                            // ></i>
                            <img src="/assets/images/ai_icons/bot-icon.png" />
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn btn-send"
                          onClick={() => this.stopGeneratingAiAnswer()}
                        >
                          Stop
                        </button>
                      )}
                    </div>
                    <div className="ai-answer py-12">
                      {this.state.aiGenerating && (
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="circle-half-stroke"
                          className="svg-inline--fa fa-circle-half-stroke fa-fw animate-spin"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M464 256c0-114.9-93.1-208-208-208V464c114.9 0 208-93.1 208-208zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                          ></path>
                        </svg>
                      )}
                      {this.state.aiAnswer && (
                        <ReactMarkdown>{this.state.aiAnswer}</ReactMarkdown>
                      )}
                    </div>
                  </div>
                  
                  {/* {!this.props.updatingGoal ? (
                    <div className="dashboard-ai-metadata">
                      <div className="metadata-row">
                        <label>Goal</label>
                        <textarea
                          className="text-height-3"
                          onChange={this.onChangeGoal}
                          value={this.state.goal}
                        ></textarea>
                      </div>
                      <div className="metadata-row">
                        <label>Focus</label>
                        <div className="text-height-2 focus">
                          {this.props.roadmapAssigns.map((assign) => (
                            <p>{assign.lesson_name}</p>
                          ))}
                        </div>
                      </div>
                      <div className="metadata-row">
                        <label>Task</label>
                        <textarea
                          className="text-height-3"
                          onChange={this.onChangeTask}
                          value={this.state.task}
                        ></textarea>
                      </div>
                      <div className="metadata-row">
                        <label>Success</label>
                        <textarea
                          className="text-height-4"
                          onChange={this.onChangeSuccess}
                          value={this.state.success}
                        ></textarea>
                      </div>
                      <button
                        className="btn btn-submit"
                        onClick={this.onSubmit}
                      >
                        Commit
                      </button>
                    </div>
                  ) : (
                    <DataLoading />
                  )} */}
                  <Slider {...this.settings}>
                    {this.props?.latestServices &&
                      this.props?.latestServices.length > 0 &&
                      this.props?.latestServices?.map((element, index) => (
                        <div className="box-1" key={index} id={element?.id}>
                          <span>
                            {element?.service_category == "course" ? (
                              <Link
                                to={`/course/${element?.service_category_url}/${element?.service_id}/${element.service_title_url}`}
                              >
                                <figure>
                                  <img
                                    loading="lazy"
                                    src={element?.service_thumbnail}
                                    style={{ width: "100%" }}
                                  />
                                </figure>
                              </Link>
                            ) : (
                              <Link
                                to={`/video/${element?.service_category_url}/${element?.service_title_url}`}
                              >
                                <figure>
                                  <img
                                    src={element?.service_thumbnail}
                                    style={{ width: "100%" }}
                                  />
                                </figure>
                              </Link>
                            )}
                            <div className="new-div">
                              <h2>{element?.service_name}</h2>
                              <p>{element?.complete_percentage}% Completed</p>
                            </div>
                          </span>
                        </div>
                      ))}
                  </Slider>
                </>
              ) : (
                <DataLoading />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    latestServices: state.dashboardReducer.latestServices,
    loading: state.dashboardReducer.loading,
    userName: state.authReducer?.user?.user,
    loadingChat: state.dashboardReducer.loadingChat,
    aiAnswer: state.dashboardReducer.aiAnswer,
    roadmapAssigns: state.roadmapReducer.assigns,
    updatingGoal: state.dashboardReducer.updatingGoal,
    userGoal: state.dashboardReducer.goal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLetestService: (data) => dispatch(getLatestServices(data)),
    dispatchGetAIAnswer: (data) => dispatch(getDashboardAIAnswer(data)),
    dispatchSaveAIAnswer: (data) => dispatch(saveDashboardAIAnswer(data)),
    dispatchGetRoadmapAssigns: (data) => dispatch(getRoadmapAssigns(data)),
    dispatchUpdateDashboardUserGoal: (data) =>
      dispatch(updateDashboardUserGoal(data)),
    dispatchGetDashboardUserGoal: (data) =>
      dispatch(getDashboardUserGoal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LatestLearning));
