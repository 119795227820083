import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthHeader from "../../../layout/auth/Aheader";
import AuthFooter from "../../../layout/auth/Afooter";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import axios from "axios";
import Header2 from "../../../layout/gaust/newLayout/Header2";
import { getMemberLoyalityDetail } from "../../../store/protected/members/action";
import * as clipboard from "clipboard-polyfill";
import { getSubnscriptionList } from "../../../store/protected/subscription/action";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
const APP_URL = process.env.REACT_APP_URL || "https://themaetrix.com/";
class LoyalityPoints extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    // document.title = "Maetrix Subscription :: The Maetrix";
    console.log(this.props);
    this.props.dispatchSubscription({
      token: this.props.token,
    });
    this.props.getMemberLoyalityDetail({
      token: this.props.token,
    });
    this.setState({
      link: `${APP_URL}referral?code=${this.props.userName.referral_code}`,
    });
  }
  onClickCopy = (url) => {
    clipboard.writeText(url).then(
      () => {
        toast.success("Copied!", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      () => {
        toast.error("Failed to copy", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };
  render() {
    return (
      <div>
        <ToastContainer />
        <AuthHeader token={this.props.token} />
        <section className="subscription my-5 py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 text-center">
                <h2>
                  Affiliate programme{" "}
                  {/*<span className="back-btn-section"><a href="" className="back-btn"><img src={"/assets/new_layout/img/Path 13.png"} /> Back</a></span>*/}
                </h2>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4 my-5">
              <div className="col-md-12 text-center">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                  style={{ justifyContent: "center" }}
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link  active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      data-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Earn Rewards
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      data-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Redeem Reward
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-new-tab"
                      data-toggle="pill"
                      data-target="#pills-new"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="false"
                    >
                      Points Earned
                    </button>
                  </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade  show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab-box">
                            <div className="row g-4 my-5 px-5 py-5 affiliate-box">
                              <div className="col-md-8 text-left">
                                <p>Your affiliate link</p>
                                <span>YOUR OFFER</span>
                                <ul className="affiliate-offers">
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                    Earn 10% on all subscription income{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                    Lifetime commissions*{" "}
                                  </li>
                                </ul>
                                <div className="d-flex items-center">
                                  <input value={this.state.link}></input>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      this.onClickCopy(this.state.link)
                                    }
                                  >
                                    Copy
                                  </button>
                                </div>
                                <div className="social-buttons">
                                  <p>Share this link with</p>
                                  <div>
                                    <FacebookShareButton url={this.state.link}>
                                      <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                    <TelegramShareButton url={this.state.link}>
                                      <TelegramIcon size={32} round={true} />
                                    </TelegramShareButton>
                                    <TwitterShareButton url={this.state.link}>
                                      <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton>
                                    <WhatsappShareButton url={this.state.link}>
                                      <WhatsappIcon size={32} round={true} />
                                    </WhatsappShareButton>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 d-flex items-center">
                                <img src="/assets/images/affiliate.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab-box">
                            <div className="row g-4 my-5 px-5 py-5">
                              <div className="col-md-6" id="left-side">
                                <div className="card h-100">
                                  <div className="bg-1">
                                    <img
                                      src={"/assets/new_layout/img/new (1).png"}
                                    />
                                  </div>
                                  <div className="bg-2">
                                    <img
                                      src={"/assets/new_layout/img/new (1).png"}
                                    />
                                  </div>
                                  <div className="bg-3">
                                    <img
                                      src={"/assets/new_layout/img/new (3).png"}
                                    />
                                  </div>
                                  <div className="bg-4">
                                    <img
                                      src={"/assets/new_layout/img/new (4).png"}
                                    />
                                  </div>
                                  <div className="bg-5">
                                    <img
                                      src={"/assets/new_layout/img/new (5).png"}
                                    />
                                  </div>
                                  <div className="bg-6">
                                    <img
                                      src={"/assets/new_layout/img/new (6).png"}
                                    />
                                  </div>
                                  <div className="bg-7">
                                    <img
                                      src={"/assets/new_layout/img/new (7).png"}
                                    />
                                  </div>
                                  <div className="card-body pb-5 text-center">
                                    <p className="text-center pt-3">
                                      <img
                                        src={
                                          "/assets/new_layout/img/new (2).png"
                                        }
                                      ></img>
                                    </p>
                                    <h5 className="text-center text-white py-3">
                                      {/* {this.props?.memberLoyalityDetail &&
                                      this.props?.memberLoyalityDetail
                                        ?.total_points
                                        ? this.props?.memberLoyalityDetail
                                            ?.total_points
                                        : 0} */}
                                      {this.props?.userName?.loyality_points}
                                    </h5>
                                    <p className="text-center text-white">
                                      Total Loyalty Points
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{ textAlign: "left" }}
                              >
                                <div className="section-right">
                                  <h5>Total Loyalty Points available</h5>
                                  <p
                                    className="card-text pb-2 mb-3"
                                    style={{ color: "#232075" }}
                                  >
                                    Lorem ipsum dolor sit amet consectetur. Sit
                                    vel vulputate convallis metus massa velit
                                    adipiscing. Velit blandit diam in faucibus
                                    iaculis nullam. Tortor pretium nullam etiam
                                    accumsan.
                                  </p>
                                  <a
                                    href="#"
                                    className="choose-btn"
                                    style={{
                                      background: "#DB3E28",
                                      textDecoration: "none",
                                      color: "#fff",
                                      padding: "12px 50px",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    Redeem
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab-box">
                            <div className="row g-4 my-5 px-5 py-5">
                              <div
                                className="col-md-12 text-left"
                                id="left-side"
                              >
                                <a
                                  href=""
                                  style={{
                                    color: "#DB3E28",
                                    textDecoration: "underline",
                                  }}
                                >
                                  How to earn Loyalty points?
                                </a>
                                <p className="mt-2">
                                  <img
                                    src={"/assets/new_layout/img/redeem.png"}
                                  />{" "}
                                  Earn loyalty points by sharing content on
                                  social media platforms!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="redeem-box">
                          <div className="row pt-3">
                            <div className="col-md-3">
                              <img src={"/assets/new_layout/img/new (2).png"} />
                            </div>
                            <div className="col-md-9 pt-1">
                              <h3 className="mb-1">Available points </h3>
                              <p>
                                {this.props?.memberLoyalityDetail &&
                                this.props?.memberLoyalityDetail?.total_points
                                  ? this.props?.memberLoyalityDetail
                                      ?.total_points
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*
                    <div className="row row-cols-1 row-cols-md-4 g-4 my-5" id="redeem-section-card">
                      <div className="col">
                        <div className="card h-100">
                          <img className="card-img-top" src={"/assets/new_layout/img/redeem-banner.png"} alt="Card image cap" />
                          <div className="card-body pb-5">
                            <h5 className="card-title text-center pt-4">20% Discount</h5>
                            <p className="card-text text-center pt-1">196 of 1000 points</p>
                            <p className="card-text text-center"><img src={"/assets/new_layout/img/progress-bar.png"} /></p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100">
                          <img className="card-img-top" src={"/assets/new_layout/img/redeem-banner.png"} alt="Card image cap" />
                          <div className="card-body pb-5">
                            <h5 className="card-title text-center pt-4">20% Discount</h5>
                            <p className="card-text text-center pt-1">196 of 1000 points</p>
                            <p className="card-text text-center"><img src={"/assets/new_layout/img/progress-bar.png"} /></p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100">
                          <img className="card-img-top" src={"/assets/new_layout/img/redeem-banner.png"} alt="Card image cap" />
                          <div className="card-body pb-5">
                            <h5 className="card-title text-center pt-4">20% Discount</h5>
                            <p className="card-text text-center pt-1">196 of 1000 points</p>
                            <p className="card-text text-center"><img src={"/assets/new_layout/img/progress-bar.png"} /></p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100">
                          <img className="card-img-top" src={"/assets/new_layout/img/redeem-banner.png"} alt="Card image cap" />
                          <div className="card-body pb-5">
                            <h5 className="card-title text-center pt-4">20% Discount</h5>
                            <p className="card-text text-center pt-1">196 of 1000 points</p>
                            <p className="card-text text-center"><img src={"/assets/new_layout/img/progress-bar.png"} /></p>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-new"
                    role="tabpanel"
                    aria-labelledby="pills-new-tab"
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="redeem-box">
                          <div className="row pt-3">
                            <div className="col-md-3">
                              <img src={"/assets/new_layout/img/new (2).png"} />
                            </div>
                            <div className="col-md-9 pt-1">
                              <h3 className="mb-1">Available points </h3>
                              <p>
                                {this.props?.memberLoyalityDetail &&
                                this.props?.memberLoyalityDetail?.total_points
                                  ? this.props?.memberLoyalityDetail
                                      ?.total_points
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-0 col-md-3"></div>
                      <div className="col-0 col-md-3"></div>
                      <div className="col-md-3">
                        <div
                          className="redeem-box"
                          style={{ background: "#A6A6A6" }}
                        >
                          <div className="row pt-3">
                            <div className="col-md-3">
                              <img src={"/assets/new_layout/img/gift.png"} />
                            </div>
                            <div className="col-md-9 pt-1">
                              <h3 className="mb-1">Points Redeemed</h3>
                              <p>
                                {this.props?.memberLoyalityDetail &&
                                this.props?.memberLoyalityDetail
                                  ?.points_redeemed
                                  ? this.props?.memberLoyalityDetail
                                      ?.points_redeemed
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row row-cols-1 row-cols-md-4 g-4 my-5"
                      id="status-section-card"
                    >
                      {this.props?.memberLoyalityDetail &&
                        this.props?.memberLoyalityDetail?.point_details?.map(
                          (list, index) => (
                            <div className="col-md-12" key={index}>
                              <div className="gray-bg">
                                {list?.type && list?.type == "redeemed" ? (
                                  <h4>
                                    {list?.title}
                                    <span className="colorred">
                                      -{list?.score}
                                    </span>
                                  </h4>
                                ) : (
                                  <h4>
                                    {list?.title}
                                    <span className="colorgreen">
                                      +{list?.score}
                                    </span>
                                  </h4>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      {/* <div className="col-md-12">
                        <div className="white-bg">
                          <h4>Coins Earned by Watching Course <span className="colorgreen">+51</span></h4>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="gray-bg">
                          <h4>Redeemed 5% Discount <span className="colorred">-500</span></h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <AuthFooter userDetail={this.props.userName} token={this.props.token} />
        {}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptionsList: state.subscriptionsListReducer.subscriptionsList,
    userName: state.authReducer?.user?.user,
    memberLoyalityDetail: state.memberReducer?.memberLoyalityDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSubscription: (data) => dispatch(getSubnscriptionList(data)),
    getMemberLoyalityDetail: (data) => dispatch(getMemberLoyalityDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoyalityPoints));
