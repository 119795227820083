import { BASE_URL } from "../../../helpers/Constants";
import {
  GET_DASHBOARD_BANNER,
  GET_ALL_SERVICES,
  GET_LATEST_SERVICES,
  ADD_TRACKING_DATA,
  GET_DASHBOARD_AI_ANSWER,
  SAVE_DASHBOARD_AI_ANSWER,
  GET_DASHBOARD_USER_GOAL,
  UPDATE_DASHBOARD_USER_GOAL,
  GET_USER_DASHBOARD_AI,
  DELETE_USER_DASHBOARD_AI,
  CREATE_USER_AI_CONTENT,
} from "./actionType";
import {
  getDashboardBannerSuccess,
  getDashboardBannerError,
  getAllServicesSuccess,
  getAllServicesError,
  getLatestServicesSuccess,
  getLatestServicesError,
  getDashboardAIAnswerSuccess,
  getDashboardAIAnswerError,
  saveDashboardAIAnswerSuccess,
  saveDashboardAIAnswerError,
  getDashboardUserGoalSuccess,
  getDashboardUserGoalError,
  updateDashboardUserGoalSuccess,
  updateDashboardUserGoalError,
  getUserDashboardAISuccess,
  getUserDashboardAIError,
  deleteUserDashboardAISuccess,
  deleteUserDashboardAIError,
} from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getDashboardBanner(data) {
  try {
    const response = yield fetch(
      BASE_URL +
        "api/bannerList?banner_category=" +
        data.payload.bannerCategory,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // "Authorization": "Bearer " + data.payload.token
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getDashboardBannerSuccess(response.result));
    } else {
      yield put(getDashboardBannerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getDashboardBannerError(error.message));
  }
}

function* getAllServices(data) {
  try {
    const response = yield fetch(
      BASE_URL + "api/latestServices?token=" + data.payload.token,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getAllServicesSuccess(response.result));
    } else {
      yield put(getAllServicesError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getAllServicesError(error.message));
  }
}

function* getLatestServices(data) {
  try {
    const response = yield fetch(BASE_URL + "api/lastVideo", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getLatestServicesSuccess(response.result));
    } else {
      yield put(getLatestServicesError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getLatestServicesError(error.message));
  }
}

function* addTrackingData(data) {
  console.log(data);
  try {
    let formData = new FormData();

    formData.append("user_id", data.payload?.user_id);
    formData.append("url", data.payload?.url);
    formData.append("type", data.payload?.type);
    formData.append("content_id", data.payload?.content_id);
    formData.append("client_details", data.payload?.client_details);

    const response = yield fetch(BASE_URL + "api/tracking", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        // Authorization: "Bearer " + data.payload.token,
      },
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
  } catch (error) {
    console.log("catch error", error);
  }
}
function* getDashboardAIAnswer(data) {
  try {
    let payload = {
      question: data?.payload?.question,
    };
    const response = yield fetch(BASE_URL + "api/ai/dashboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getDashboardAIAnswerSuccess(response.result));
    } else {
      yield put(getDashboardAIAnswerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getDashboardAIAnswerError(error.message));
  }
}
function* saveDashboardAIAnswer(data) {
  try {
    let payload = {
      question: data?.payload?.question,
      answer: data?.payload?.answer,
    };
    const response = yield fetch(BASE_URL + "api/ai/dashboard/save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(saveDashboardAIAnswerSuccess(response.result));
    } else {
      yield put(saveDashboardAIAnswerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(saveDashboardAIAnswerError(error.message));
  }
}

function* getDashboardUserGoal(data) {
  //console.log('enter api function', data);
  try {
    const response = yield fetch(BASE_URL + "api/dashboard/user-goal", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getDashboardUserGoalSuccess(response.result));
    } else {
      yield put(getDashboardUserGoalError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getDashboardUserGoalError(error.message));
  }
}

function* updateDashboardUserGoal(data) {
  try {
    let payload = {
      goal: data?.payload?.goal,
      task: data?.payload?.task,
      success: data?.payload?.success,
    };
    const response = yield fetch(BASE_URL + "api/dashboard/user-goal", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(updateDashboardUserGoalSuccess(response.result));
    } else {
      yield put(updateDashboardUserGoalError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(updateDashboardUserGoalError(error.message));
  }
}

function* getUserDashboardAI(data) {
  try {
    const response = yield fetch(BASE_URL + "api/ai/dashboard", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(getUserDashboardAISuccess(response.result));
    } else {
      yield put(getUserDashboardAIError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getUserDashboardAIError(error.message));
  }
}

function* deleteUserDashboardAI(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(
      BASE_URL + `api/ai/dashboard/${data.payload.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(deleteUserDashboardAISuccess(response.result));
    } else {
      yield put(deleteUserDashboardAIError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(deleteUserDashboardAIError(error.message));
  }
}
export function* watchgetBanner() {
  yield takeEvery(GET_DASHBOARD_BANNER, getDashboardBanner);
}
export function* watchgetAllServices() {
  yield takeEvery(GET_ALL_SERVICES, getAllServices);
}
export function* watchgetLatestServices() {
  yield takeEvery(GET_LATEST_SERVICES, getLatestServices);
}
export function* watchAddTrackingData() {
  yield takeEvery(ADD_TRACKING_DATA, addTrackingData);
}
export function* watchGetAIAnswer() {
  yield takeEvery(GET_DASHBOARD_AI_ANSWER, getDashboardAIAnswer);
}
export function* watchSaveAIAnswer() {
  yield takeEvery(SAVE_DASHBOARD_AI_ANSWER, saveDashboardAIAnswer);
}
export function* watchGetUserGoal() {
  yield takeEvery(GET_DASHBOARD_USER_GOAL, getDashboardUserGoal);
}
export function* watchUpdateUserGoal() {
  yield takeEvery(UPDATE_DASHBOARD_USER_GOAL, updateDashboardUserGoal);
}
export function* watchgetUserDashboardAI() {
  yield takeEvery(GET_USER_DASHBOARD_AI, getUserDashboardAI);
}
export function* watchdeleteUserDashboardAI() {
  yield takeEvery(DELETE_USER_DASHBOARD_AI, deleteUserDashboardAI);
}

function* dashboardSaga() {
  yield all([
    fork(watchgetBanner),
    fork(watchgetAllServices),
    fork(watchgetLatestServices),
    fork(watchAddTrackingData),
    fork(watchGetAIAnswer),
    fork(watchSaveAIAnswer),
    fork(watchGetUserGoal),
    fork(watchUpdateUserGoal),
    fork(watchgetUserDashboardAI),
    fork(watchdeleteUserDashboardAI),
  ]);
}

export default dashboardSaga;
