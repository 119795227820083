import {
  GET_DASHBOARD_BANNER,
  GET_DASHBOARD_BANNER_SUCCESS,
  GET_DASHBOARD_BANNER_ERROR,
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_ERROR,
  GET_LATEST_SERVICES,
  GET_LATEST_SERVICES_SUCCESS,
  GET_LATEST_SERVICES_ERROR,
  GET_DASHBOARD_AI_ANSWER,
  GET_DASHBOARD_AI_ANSWER_SUCCESS,
  GET_DASHBOARD_AI_ANSWER_ERROR,
  SAVE_DASHBOARD_AI_ANSWER,
  SAVE_DASHBOARD_AI_ANSWER_SUCCESS,
  SAVE_DASHBOARD_AI_ANSWER_ERROR,
  UPDATE_DASHBOARD_USER_GOAL,
  UPDATE_DASHBOARD_USER_GOAL_SUCCESS,
  UPDATE_DASHBOARD_USER_GOAL_ERROR,
  GET_DASHBOARD_USER_GOAL,
  GET_DASHBOARD_USER_GOAL_SUCCESS,
  GET_DASHBOARD_USER_GOAL_ERROR,
  GET_USER_DASHBOARD_AI,
  GET_USER_DASHBOARD_AI_SUCCESS,
  GET_USER_DASHBOARD_AI_ERROR,
  DELETE_USER_DASHBOARD_AI,
  DELETE_USER_DASHBOARD_AI_SUCCESS,
  DELETE_USER_DASHBOARD_AI_ERROR,
} from "./actionType";

const initialState = {
  error: null,
  loading: false,
  dashboardBanner: [],
  services: [],
  latestServices: null,
  aiAnswer: null,
  loadingChat: false,
  goal: [],
  aiAnswers: [],
  loadingAI: false,
  updatingGoal: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_BANNER:
      state = {
        ...state,
        error: null,
        loading: true,
        dashboardBanner: [],
      };
      break;

    case GET_DASHBOARD_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        dashboardBanner: action.payload,
      };
      break;

    case GET_DASHBOARD_BANNER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        dashboardBanner: [],
      };
      break;
    case GET_ALL_SERVICES:
      state = {
        ...state,
        error: null,
        loading: true,
        services: [],
      };
      break;

    case GET_ALL_SERVICES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        services: action.payload,
      };
      break;

    case GET_ALL_SERVICES_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        services: [],
      };
      break;
    case GET_LATEST_SERVICES:
      state = {
        ...state,
        error: null,
        loading: true,
        latestServices: null,
      };
      break;

    case GET_LATEST_SERVICES_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        latestServices: action.payload,
      };
      break;

    case GET_LATEST_SERVICES_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        latestServices: null,
      };
      break;
    case GET_DASHBOARD_AI_ANSWER:
      state = {
        ...state,
        loadingChat: true,
        error: null,
        aiAnswer: null,
      };
      break;
    case GET_DASHBOARD_AI_ANSWER_SUCCESS:
      state = {
        ...state,
        loadingChat: false,
        error: null,
        aiAnswer: {
          answer: action.payload.answer ? action.payload.answer : null,
          question: action.payload.question ? action.payload.question : null,
        },
      };
      break;

    case GET_DASHBOARD_AI_ANSWER_ERROR:
      state = {
        ...state,
        loadingChat: false,
        error: action.payload,
        aiAnswer: null,
      };
      break;
    case SAVE_DASHBOARD_AI_ANSWER:
      state = {
        ...state,
        loadingChat: true,
        error: null,
        aiAnswer: null,
      };
      break;
    case SAVE_DASHBOARD_AI_ANSWER_SUCCESS:
      state = {
        ...state,
        loadingChat: false,
        error: null,
        aiAnswer: {
          answer: action.payload.answer ? action.payload.answer : null,
          question: action.payload.question ? action.payload.question : null,
        },
      };
      break;

    case SAVE_DASHBOARD_AI_ANSWER_ERROR:
      state = {
        ...state,
        loadingChat: false,
        error: action.payload,
        aiAnswer: null,
      };
      break;
    case GET_DASHBOARD_USER_GOAL:
      state = {
        ...state,
        error: null,
        updatingGoal: true,
        goal: [],
      };
      break;

    case GET_DASHBOARD_USER_GOAL_SUCCESS:
      state = {
        ...state,
        updatingGoal: false,
        error: null,
        goal: action.payload,
      };
      break;

    case GET_DASHBOARD_USER_GOAL_ERROR:
      state = {
        ...state,
        updatingGoal: false,
        error: action.payload,
      };
      break;
    case UPDATE_DASHBOARD_USER_GOAL:
      state = {
        ...state,
        error: null,
        updatingGoal: true,
        goal: [],
      };
      break;

    case UPDATE_DASHBOARD_USER_GOAL_SUCCESS:
      state = {
        ...state,
        updatingGoal: false,
        error: null,
        goal: action.payload,
      };
      break;

    case UPDATE_DASHBOARD_USER_GOAL_ERROR:
      state = {
        ...state,
        updatingGoal: false,
        error: action.payload,
      };
      break;
    case GET_USER_DASHBOARD_AI:
      state = {
        error: null,
        loadingAI: true,
        aiAnswers: [],
      };
      break;
    case GET_USER_DASHBOARD_AI_SUCCESS:
      state = {
        ...state,
        loadingAI: false,
        error: null,
        aiAnswers: action.payload,
      };
      break;

    case GET_USER_DASHBOARD_AI_ERROR:
      state = {
        ...state,
        loadingAI: false,
        error: action.payload,
        aiAnswers: [],
      };
      break;
    case DELETE_USER_DASHBOARD_AI:
      state = {
        ...state,
        error: null,
        loadingAI: true,
      };
      break;

    case DELETE_USER_DASHBOARD_AI_SUCCESS:
      state = {
        ...state,
        loadingAI: false,
        error: null,
        aiAnswers: state.aiAnswers
          .filter((item) => item.id != action.payload)
          .concat([]),
      };
      break;

    case DELETE_USER_DASHBOARD_AI_ERROR:
      state = {
        ...state,
        loadingAI: false,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default dashboardReducer;
