import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import Header from "../../../layout/gaust/Header";
import PodcastBanner from "./PodcastBanner";
import EntrepreneurshipBox from "./EntrepreneurshipBox";
import PodcastCategoryNew from "./PodcastCategoryNew";
import NewPopularTabs from "./NewPopularTabs";
import PodcastSubscriptionCheck from "./PodcastSubscriptionCheck";
import EmbededPop from "./embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getPodcast } from "../../../store/protected/podcast/action";
import { isLatestSuccess } from "../../../store/protected/courses/action";

class Podcast extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    playing: true,
    showLoginModel: false,
    podcastUrl: "",
    podcastData: null,
    SubscriptionName: "",
  };

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  onPlay = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  onPlayLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  podcastCondition = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  buySubscrition = () => {
    window.$("#subsModel").modal("show");
  };

  goToLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  notSubscribed = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  embeded = (data) => {
    this.setState({
      podcastData: data,
    });
    window.$("#embededPupUp").modal("show");
  };

  sharePop = (url) => {
    let str = "111" + this.props?.user?.user?.id + "111"; //userid=413
    let qrstr = "?q=" + str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.category_title_url +
      "/" +
      url?.podcast_title_url +
      qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };

  playAudio = (service_id) => {
    console.log("play ausio", service_id);
    this.props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: this.props.token,
    });
  };
  componentDidMount() {
    // document.title = "Podcasts :: The Maetrix";
    //  window.location.reload();
    this.props.dispatchPodcastCategory({
      token: this.props.token,
    });
  }

  render() {
    return (
      <div>
        {/* <EmbededPop data={this.state.podcastData} />
        <SharePopUpModel url={this.state.podcastUrl} />
        <PodcastSubscriptionCheck
          token={this.props.token}
          history={this.props.history}
          name={this.state?.SubscriptionName}
        /> */}
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <PodcastBanner />
        <div className="all-podcast page-height">
          <NewPopularTabs
            categoryData={this.props?.podcasts?.data}
            token={this.props.token}
          />
          <PodcastCategoryNew
            token={this.props.token}
            category={this.props.token}
          />

          <div className="podcast-row">
            <div className="container">
              {this.props?.podcasts?.data.map(
                (category, ind) =>
                  category?.podcasts?.length > 0 && (
                    <React.Fragment key={ind}>
                      <div className="podcast-row-header">
                        <h2>{category.category_name}</h2>
                        <Link
                          to={`/podcast/${category.category_title_url}`}
                          className="right-link"
                        >
                          View All{" "}
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                      <EntrepreneurshipBox
                        categoryData={category?.podcasts}
                        token={this.props.token}
                      />
                      {/* <OwlCarousel
                        className="owl-theme"
                        margin={20}
                        items={4}
                        responsive={this.state.responsive}
                      >
                        {category?.podcasts.map((podcast, index) => (
                          <div className="podcast-list" key={index}>
                            <div className="item">
                              <div className="music-box">
                                <img
                                  className="audio-img"
                                  alt="img"
                                  src={podcast?.banner_url}
                                />
                                {!this.props.token ? (
                                  <div
                                    onClick={this.podcastCondition}
                                    className="demoAudio"
                                  >
                                    {" "}
                                    <AudioPlayer
                                      src={podcast.podcast_url}
                                      playing={false}
                                      defaultDuration={podcast.duration}
                                      defaultCurrentTime="00:00"
                                      onPlayError={() => this.onPlayLogin()}
                                    />{" "}
                                  </div>
                                ) : podcast.is_subscribed == 1 ? (
                                  <AudioPlayer
                                    src={podcast.podcast_url}
                                    onPlay={() => this.playAudio(podcast.id)}
                                    playing={false}
                                  />
                                ) : (
                                  <div
                                    onClick={this.onPlay}
                                    className="demoAudio"
                                  >
                                    <AudioPlayer
                                      src={podcast.podcast_url}
                                      playing={false}
                                      defaultDuration={podcast.duration}
                                      defaultCurrentTime="00:00"
                                      onPlayError={() =>
                                        this.onPlay(podcast.tag_name)
                                      }
                                    />
                                  </div>
                                )}
                                <div className="audio-cnt">
                                  <h2>{podcast?.podcast_title}</h2>
                                  <div className="hide-audio-cnt">
                                    {ReactHtmlParser(podcast?.description)}
                                  </div>
                                  <p className="btm-brd-hide">
                                    <span></span>
                                  </p>
                                </div>
                                <div className="audio-action">
                                  {!this.props.token ? (
                                    <button onClick={this.goToLogin}>
                                      <img
                                        alt="icon"
                                        src="/assets/images/download-icon.png"
                                      />
                                    </button>
                                  ) : podcast.is_subscribed == 1 ? (
                                    <a href={podcast.podcast_url} download>
                                      <button>
                                        <img
                                          alt="icon"
                                          src="/assets/images/download-icon.png"
                                        />
                                      </button>
                                    </a>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.onPlay(podcast.tag_name)
                                      }
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/download-icon.png"
                                      />
                                    </button>
                                  )}

                                  {!this.props.token ? (
                                    <button
                                      onClick={() => this.sharePop(podcast)}
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/share-icon.png"
                                      />
                                    </button>
                                  ) : podcast.is_subscribed == 1 ? (
                                    <button
                                      onClick={() => this.sharePop(podcast)}
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/share-icon.png"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => this.sharePop(podcast)}
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/share-icon.png"
                                      />
                                    </button>
                                  )}

                                  {!this.props.token ? (
                                    <button onClick={this.goToLogin}>
                                      <img
                                        alt="icon"
                                        src="/assets/images/coding-icon.png"
                                      />
                                    </button>
                                  ) : podcast.is_subscribed == 1 ? (
                                    <button
                                      onClick={() => this.embeded(podcast)}
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/coding-icon.png"
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.notSubscribed(podcast.tag_name)
                                      }
                                    >
                                      <img
                                        alt="icon"
                                        src="/assets/images/coding-icon.png"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel> */}
                    </React.Fragment>
                  )
              )}
            </div>
          </div>
        </div>
        <Footer userDetail={this.props.userName} token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    podcasts: state.podcastReducer?.podcasts,
    userName: state.authReducer?.user?.user,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPodcastCategory: (data) => dispatch(getPodcast(data)),
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Podcast));
//export default Podcast
