import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class SharePopUp extends PureComponent {
  render() {
    return (
      <div className="subscription-pops">
        <div className="modal fade" id="subsModel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="subs-cnt">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="subs-right-box">
                        <h3>You need to take subscription plan</h3>
                        <Link to="/buy-subscriptions">
                          {" "}
                          <button type="button" onClick={this.goToSubscription}>
                            Choose Plan
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharePopUp));
