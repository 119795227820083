import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
//import Header from "../../../layout/gaust/Header";
// import AuthHeader from "../../../layout/gaust/newLayout/Header2";
import Header from "../../../layout/gaust/newLayout/Header";
import Login from "../../../components/gaust/login";
import Footer from "../../../layout/auth/Afooter";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getMemberDetail } from "../../../store/protected/members/action";

import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class MemberDetail extends PureComponent {
  state = {
    showLoginModel: false,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  myFunction = (value) => {
    //console.log('value', value);
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  componentDidMount() {
    // document.title = "Member Detail :: The Maetrix";
    this.props.getMemberDetail({
      id: this.props.match.params.id,
      token: this.props.token,
    });
  }

  render() {
    let reviewList = this.props?.memberDetail?.reviews;
    let googleReview = this.props?.memberDetail?.google_reviews;
    return (
      <React.Fragment>
        {this.props.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="prof-bnr">
          <img
            src={
              this.props?.memberDetail?.banner_url
                ? this.props?.memberDetail?.banner_url
                : "/assets/images/prof.png"
            }
            alt=""
          />
          <div className="overlay-clr"></div>
        </div>
        <div className="profile-head">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="prof-left">
                  <img
                    className="prof-image"
                    src={
                      this.props?.memberDetail?.profile_image
                        ? this.props?.memberDetail?.profile_image
                        : "/assets/images/profile-img.jpg"
                    }
                    alt=""
                  />
                  <h3>{this.props?.memberDetail?.name}</h3>
                  <p>
                    {/* <span>Booking URL - </span> */}
                    {this.props?.memberDetail?.booking_url && (
                      <a
                        href={this.props?.memberDetail?.booking_url}
                        target="_blank"
                      >
                        {" "}
                        a<button className="bookNowUrl">Book now</button>
                        {/* {this.props?.memberDetail?.booking_url} */}
                      </a>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="prof-right">
                  <ul>
                    <li>
                      {this.props?.memberDetail?.group_url && (
                        <a
                          href={this.props?.memberDetail?.group_url}
                          target="_blank"
                        >
                          <img src="/assets/images/Image-from-iOS.png" />
                        </a>
                      )}
                    </li>
                    <li>
                      {this.props?.memberDetail?.website_url && (
                        <a
                          href={this.props?.memberDetail?.website_url}
                          target="_blank"
                        >
                          <img src="/assets/images/web.jpg" />
                        </a>
                      )}
                    </li>
                    <li>
                      {this.props?.memberDetail?.gmail && (
                        <a
                          href={`mailto:${this.props?.memberDetail?.gmail}`}
                          target="_blank"
                        >
                          <img src="/assets/images/profile-mail.png" />
                        </a>
                      )}
                    </li>
                    <li>
                      {" "}
                      {this.props?.memberDetail?.facebook && (
                        <a
                          href={this.props?.memberDetail?.facebook}
                          target="_blank"
                        >
                          <img src="/assets/images/profile-fb.png" />
                        </a>
                      )}
                    </li>
                    <li>
                      {this.props?.memberDetail?.instagram && (
                        <a
                          href={this.props?.memberDetail?.instagram}
                          target="_blank"
                        >
                          <img src="/assets/images/profile-insta.png" />
                        </a>
                      )}
                    </li>
                    <li>
                      {this.props?.memberDetail?.linkedin && (
                        <a
                          href={this.props?.memberDetail?.linkedin}
                          target="_blank"
                        >
                          <img src="/assets/images/profile-linkedin.png" />
                        </a>
                      )}
                    </li>
                    <li>
                      {this.props?.memberDetail?.youtube && (
                        <a
                          href={this.props?.memberDetail?.youtube}
                          target="_blank"
                        >
                          <img src="/assets/images/profile-youtube.png" />
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bio-dtl">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <div className="bio-txt">
                  <h2>Biography</h2>
                  {this.props?.memberDetail?.bio
                    ? ReactHtmlParser(this.props?.memberDetail?.bio)
                    : "User bio not updated"}
                  {/* <button type="button">Read More</button> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="bio-map">
                  <h2>Address</h2>
                  <p>
                    {this.props?.memberDetail?.address
                      ? this.props?.memberDetail?.address
                      : "Address not updated"}
                  </p>
                  {this.props?.memberDetail?.lat_lng &&
                    Object.keys(this.props?.memberDetail?.lat_lng).length >
                      0 && (
                      <iframe
                        src={`https://maps.google.com/maps?q=${this.props?.memberDetail?.lat_lng?.lat}, ${this.props?.memberDetail?.lat_lng?.lng}&z=15&output=embed`}
                        width="360"
                        height="270"
                        frameBorder="0"
                        style={{ border: "0" }}
                      ></iframe>
                    )}
                </div>
              </div>
              <div className="col-md-12">
                {this.props?.memberDetail?.promotional_video && (
                  <div className="prof-vdo">
                    <video
                      src={this.props?.memberDetail?.promotional_video}
                      type="video"
                      controls
                    ></video>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="google-rvw-row">
          <div className="container">
            <div className="ggl-rvw-head">
              <h2>
                <img src="/assets/images/google-img.png" />
                Google Reviews
                <span>What our google reviewers say</span>
              </h2>
            </div>
            {googleReview?.length > 0 ? (
              <OwlCarousel
                className="owl-theme"
                margin={10}
                responsive={this.state.responsive}
              >
                {googleReview.map((list, index) => (
                  <div className="ggl-rvw-box" key={index}>
                    <h4>{list?.author_name}</h4>
                    <p className="rating">
                      {list.rating === 1 && (
                        <>
                          {" "}
                          <span>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>(
                          {list.rating})
                        </>
                      )}
                      {list.rating === 2 && (
                        <>
                          {" "}
                          <span>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>(
                          {list.rating})
                        </>
                      )}
                      {list.rating === 3 && (
                        <>
                          {" "}
                          <span>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>(
                          {list.rating})
                        </>
                      )}
                      {list.rating === 4 && (
                        <>
                          {" "}
                          <span>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                          <i className="fa fa-star" aria-hidden="true"></i>(
                          {list.rating})
                        </>
                      )}
                      {list.rating === 5 && (
                        <>
                          {" "}
                          <span>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </span>
                          ({list.rating})
                        </>
                      )}
                    </p>
                    <p>{list?.text}...</p>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              "Google Review Not Found"
            )}
          </div>
        </div>
        {/*
        <div className="fb-rvw-row">
          <div className="container">
            <div className="fb-rvw-head">
              <h2>
                <img src="/assets/images/fb-review.png" />
                Facebook Reviews
                <span>What our facebook reviewers say.</span>
              </h2>
            </div>
            
            {reviewList?.length > 0 ? (
              <OwlCarousel
                className="owl-theme"
                margin={10}
                responsive={this.state.responsive}
              >
                {reviewList &&
                  reviewList.map((review, index) => (
                    <div className="fb-rvw-box" key={index}>
                      <h4>
                        {review.reviewer?.user_name
                          ? review.reviewer?.user_name
                          : "Facebook User"}
                      </h4>
                      <p className="rating">
                        {review.recommendation_type === "positive" && (
                          <>
                            <span>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </span>
                            (Positive)
                          </>
                        )}
                        {review.recommendation_type === "negative" && (
                          <>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            (Negative)
                          </>
                        )}
                      </p>
                      <p>{review.review_text}</p>
                    </div>
                  ))}
              </OwlCarousel>
            ) : (
              <p style={{ color: "white" }}>Facebook Review Not Found</p>
            )}
          </div>
        </div> */}

        {/* <div className="container page-height">
                    <div className="member-dtl">
                        <div className="mbr-dtl-head">
                            <img src={this.props?.memberDetail?.profile_image} />
                            <h2>{this.props?.memberDetail?.name ? this.props?.memberDetail?.name : "User Name"}</h2>
                            <p>{this.props?.memberDetail?.bio ? this.props?.memberDetail?.bio : "(User Bio)"}</p>
                            <a href="/members" className="back-btn"><i className="fa fa-angle-left" aria-hidden="true"></i></a>
                        </div>
                        <div className="mbr-inner-dtl">
                            <h2>Fun Question & Answer</h2>
                            {this.props?.memberDetail?.fun_questions?.map((list, index) => (
                                <div className="mbr-qstn-ans" key={index}>
                                <h3 >{index+1}. {list.question}</h3>
                                <div className="ans-box">
                            <p>{list.answer ? list.answer : "No Answer"}</p>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                    </div>
                </div> */}
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    memberDetail: state.memberReducer.memberDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberDetail: (data) => dispatch(getMemberDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberDetail));

//export default MemberDetail
