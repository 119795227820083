import React, { Component } from "react";

import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
// import { CLIENT_ID } from "../../../helpers/Constants";
import FacebookLoginComponent from "../facebook/FacebookLoginComponent";
import ReactHtmlParser from "react-html-parser";
import RichTextEditor from "react-rte";
import { getUser } from "../../../store/protected/user-profile/action";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  updateUser,
  updatePassword,
  updateUserVideo,
  getUserDashboardAI,
  deleteUserDashboardAI,
} from "../../../store/protected/user-settings/actions";
import DateFormat from "../../../helpers/DateHelper";
// import { GoogleLogin } from "react-google-login";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AiWizardDrawer } from "../../common/AiWizardDrawer";
import ReactMarkdown from "react-markdown";
// import { result } from "lodash";
import axios from "axios";
import * as clipboard from "clipboard-polyfill";
// import Bar from "./ProgressBar/Bar";

class Setting extends Component {
  constructor(props) {
    super(props);
  }

  responseGoogle = (response) => {
    console.log("map responce", response);
  };

  Toggle = () => {
    let temp = document.getElementById("password");
    let icon = document.getElementById("pass-status");
    if (temp.className === "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  Toggle1 = () => {
    let temp = document.getElementById("password1");
    let icon = document.getElementById("pass-status1");
    if (temp.className === "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  Toggle2 = () => {
    let temp = document.getElementById("password2");
    let icon = document.getElementById("pass-status2");
    if (temp.className === "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  state = {
    value: RichTextEditor.createValueFromString(
      this.props.getUserDetail?.user.bio,
      "html"
    ),
    aiContent: RichTextEditor.createValueFromString(
      this.props.getUserDetail?.user.ai_content,
      "html"
    ),
    length: 0,
    metaTitleLength: 60,
    showMetaLength: "",
    latLong: {},
    lat: "",
    lng: "",
    placeId: "",
    user: this.props.getUserDetail?.user ? this.props.getUserDetail?.user : "",
    openAIWizard: false,
    aiWizardType: null,
    aiWizardTitle: "Let AI boost your content",
  };
  componentDidMount() {
    // document.title = "User Setting :: The Maetrix";
    this.props.dispatchUserToken({
      token: this.props.token,
    });
    // this.props.dispatchGetAIAnswers({
    //   token: this.props.token,
    // });
  }
  deleteAI = async (item) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation)
      this.props.dispatchDeleteAIAnswer({
        id: item.id,
        token: this.props.token,
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getUserDetail?.is_user_update !==
        this.props.getUserDetail?.is_user_update &&
      this.props.getUserDetail?.is_user_update !== null
    ) {
      this.setState({
        ...this.state,
        user: this.props.getUserDetail?.user,
      });
    }

    if (
      prevProps.getUserDetail?.user?.name !==
        this.props.getUserDetail?.user?.name &&
      this.props.getUserDetail?.user?.name !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Profile Name Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#name-model").modal("hide");
    }

    //console.log('one', prevProps.getUserDetail?.user?.bio, 'tworyewruywiur', this.props.getUserDetail?.user?.bio);

    if (
      prevProps.getUserDetail?.user?.bio !==
        this.props.getUserDetail?.user?.bio &&
      this.props.getUserDetail?.user?.bio !== null
    ) {
      //console.log('enter')
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Profile Bio Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#bio-model").modal("hide");
    }
    if (
      prevProps.getUserDetail?.user?.email !==
        this.props.getUserDetail?.user?.email &&
      this.props.getUserDetail?.user?.email !== null
    ) {
      // console.log('enter')
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Profile Email Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#email-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.profile_image !==
        this.props.getUserDetail?.user?.profile_image &&
      this.props.getUserDetail?.user?.profile_image !== null
    ) {
      this.setState({
        ...this.state,
        profilePhoto: this.props.profilePhoto,
      });

      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Profile Image Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });

      window.$("#image-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.banner_image !==
        this.props.getUserDetail?.user?.banner_image &&
      this.props.getUserDetail?.user?.banner_image !== null
    ) {
      this.setState({
        ...this.state,
        banner_url: this.props?.banner_url,
      });
      //console.log("banner image");
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Banner Image Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });

      window.$("#banner-image-model").modal("hide");
    }
    if (
      prevProps.getUserDetail?.user?.address !==
        this.props.getUserDetail?.user?.address &&
      this.props.getUserDetail?.user?.address !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Address Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });

      window.$("#address-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.booking_url !==
        this.props.getUserDetail?.user?.booking_url &&
      this.props.getUserDetail?.user?.booking_url !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Booking URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#booking-url-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.website_url !==
        this.props.getUserDetail?.user?.website_url &&
      this.props.getUserDetail?.user?.website_url !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Website URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#website-url-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.gmail !==
        this.props.getUserDetail?.user?.gmail &&
      this.props.getUserDetail?.user?.gmail !== null
    ) {
      //console.log("prev email");
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Gmail URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#gmail-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.facebook !==
        this.props.getUserDetail?.user?.facebook &&
      this.props.getUserDetail?.user?.facebook !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Facebook URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#facebook-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.page_id !==
        this.props.getUserDetail?.user?.page_id &&
      this.props.getUserDetail?.user?.page_id !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Facebook Page Name Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#facebook-page-name-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.group_url !==
        this.props.getUserDetail?.user?.group_url &&
      this.props.getUserDetail?.user?.group_url !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Facebook Group URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#facebook-group-url-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.instagram !==
        this.props.getUserDetail?.user?.instagram &&
      this.props.getUserDetail?.user?.instagram !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Instagram URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#instagram-model").modal("hide");
    }
    if (
      prevProps.getUserDetail?.user?.linkedin !==
        this.props.getUserDetail?.user?.linkedin &&
      this.props.getUserDetail?.user?.linkedin !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Instagram URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#linkedin-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.youtube !==
        this.props.getUserDetail?.user?.youtube &&
      this.props.getUserDetail?.user?.youtube !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Youtube URL Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      // document.getElementById("profile-image").reset();
      // this.setState({
      //     profile_image: ''
      // });
      window.$("#youtube-model").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.meta_description !==
        this.props.getUserDetail?.user?.meta_description &&
      this.props.getUserDetail?.user?.meta_description !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Meta Description Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      window.$("#meta-description").modal("hide");
    }

    if (
      prevProps.getUserDetail?.user?.meta_title !==
        this.props.getUserDetail?.user?.meta_title &&
      this.props.getUserDetail?.user?.meta_title !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "Meta Title Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#meta-title-model").modal("hide");
    }

    if (
      prevProps.getPasswordUpdate !== this.props.getPasswordUpdate &&
      this.props.getPasswordUpdate === true
    ) {
      //console.log('enter')
      toast.success(
        this.props.getupdatestatus?.api_response?.message
          ? this.props.getupdatestatus?.api_response?.message
          : "Password Update Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      document.getElementById("updatePass").reset();
      this.setState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      window.$("#password-model").modal("hide");
    } else if (
      prevProps.getPasswordUpdate !== this.props.getPasswordUpdate &&
      this.props.getPasswordUpdate === false
    ) {
      //console.log('enter elseif condition', this.getPasswordUpdate)

      toast.error(this.props.getUpdateStatus?.error, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("updatePass").reset();
      this.setState({
        ...this.state,
        user: {
          ...this.state.user,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
      });
      window.$("#password-model").modal("hide");
    }
    if (
      prevProps.getUserDetail?.user?.ai_core_value !==
        this.props.getUserDetail?.user?.ai_core_value &&
      this.props.getUserDetail?.user?.ai_core_value !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "AI Core Value Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#ai-core-value-model").modal("hide");
    }
    if (
      prevProps.getUserDetail?.user?.ai_content !==
        this.props.getUserDetail?.user?.ai_content &&
      this.props.getUserDetail?.user?.ai_content !== null
    ) {
      toast.success(
        this.props.getupdatestatus?.updateUser?.message
          ? this.props.getupdatestatus?.updateUser?.message
          : "AI Content Updated Successfully",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      window.$("#ai-content-model").modal("hide");
    }
  }
  handleimageChange = (event) => {
    //console.log("file target", event.target.files);
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        profile_image: event.target.files[0],
        //image: URL.createObjectURL(event.target.files[0])
      },
    });
  };
  handleBannerChange = (event) => {
    //console.log('ile target',event.target.files)
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        banner_image: event.target.files[0],
        //image: URL.createObjectURL(event.target.files[0])
      },
    });
  };

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };
  onChangeAIContent = (value) => {
    this.setState({ aiContent: value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };
  setSelectedFile = (e) => {
    this.setState(
      {
        ...this.state,
        selectedVideo: e.target.files[0],
      },
      () => {
        const config = {
          onUploadProgress: (progressEvent) => {
            // let { progress } = this.state;
            // let progress = 0;
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            this.setState({ progress });
          },
        };
        let formData = new FormData();
        formData.append("file", this.state.setSelectedFile);
        axios
          .post("http://your_backend_url/api/v1/upload", formData, config)
          .then((res) => {
            if (res.data.status === 200) {
            }
          })
          .catch((err) => {
            console.log("error: ", err.message);
          });
        // e.target.reset();
      }
    );
  };

  // onUpload = (e) => {
  //   const config = {
  //     onUploadProgress: (progressEvent) => {
  //       // let { progress } = this.state;
  //       // let progress = 0;
  //      const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //       this.setState({ progress });
  //     },
  //   };
  //   let formData = new FormData();
  //   formData.append("file", this.state.setSelectedFile);
  //   axios
  //     .post("http://your_backend_url/api/v1/upload", formData, config)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         console.log("done: ", res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error: ", err.message);
  //     });
  //     e.target.reset();
  // };

  handleVideoSubmit = (event) => {
    event.preventDefault();
    this.props.dispatchUserVideoUpdate({
      promotional_video: this.state?.selectedVideo,
      token: this.props.token,
    });
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        ai_core_value:
          event?.target?.name === "ai_core_value" && event?.target?.value,
        ai_content:
          event?.target?.name === "meta_title" && event?.target?.value,
        meta_length:
          event?.target?.name === "meta_title" && event?.target?.value,
        description_length:
          event.target.name === "meta_description" && event?.target?.value,
        [event.target.name]: event.target.value,
      },
    });
  };

  autoHandleChange = (autoAddress) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        address: autoAddress,
      },
    });
  };

  handleSelect = (address) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        address: address,
      },
    });

    geocodeByAddress(address)
      .then((results) =>
        this.setState({
          lat: results[0]?.geometry?.location?.lat(),
          lng: results[0]?.geometry?.location?.lng(),
          placeId: results[0]?.place_id,
        })
      )
      .catch((error) => console.error("Error", error));
  };

  handleRegForm = (event) => {
    event.preventDefault();
    //console.log("keyvalue", event.target.id);
    if (event.target.id === "name") {
      const uName = {
        userName: this.state.user?.name,
      };
      // const uN = {
      //   name: uName.userName,
      // };

      // firebase.auth().onAuthStateChanged(function (user) {
      //     if (user) {
      //         firebase.database().ref('/users/' + user.uid).update(uName)
      //         firebase.database().ref('/active/' + user.uid).update(uN)
      //         var starCountRef = firebase.database().ref('messeges/');
      //         starCountRef.on('value', function (snapshot) {

      //             snapshot.forEach(function (childSnapshot) {
      //                 var childKey = childSnapshot.key;
      //                 var childData = childSnapshot.val();
      //                 // console.log("Firebase Message Key ",childData);
      //                 if (childData.userId === user.uid) {
      //                     firebase.database().ref('/messeges/' + childKey).update(uName)
      //                     console.log("Firebase Message Key ", childData.userId);
      //                 }
      //                 console.log("Firebase Message UserName ", childData.userName);
      //             });

      //         });
      //     } else {
      //         console.log("Update user not sign In");
      //     }
      // });

      this.props.dispatchUserUpdate({
        name: uName.userName,
        token: this.props.token,
      });
    } else if (event.target.id === "bio") {
      this.props.dispatchUserUpdate({
        bio: this.state?.value.toString("html"),
        token: this.props.token,
      });
    } else if (event.target.id === "updatePass") {
      this.props.dispatchPasswordUpdate({
        token: this.props.token,
        email: this.state.user?.email,
        password: this.state.user?.currentPassword,
        new_password: this.state.user?.newPassword,
        confirm_password: this.state.user?.confirmPassword,
      });
    } else if (event.target.id === "profile-image") {
      this.props.dispatchUserUpdate({
        profile_image: this.state.user?.profile_image,
        token: this.props.token,
      });
    } else if (event.target.id === "banner-image") {
      this.props.dispatchUserUpdate({
        banner_image: this.state.user?.banner_image,
        token: this.props.token,
      });
    } else if (event.target.id === "address") {
      this.props.dispatchUserUpdate({
        address: this.state.user?.address,
        lat: this.state?.lat,
        lng: this.state?.lng,
        place_id: this.state?.placeId,
        token: this.props.token,
      });
    } else if (event.target.id === "meta-title") {
      this.props.dispatchUserUpdate({
        meta_title: this.state.user?.meta_title,
        token: this.props.token,
      });
    } else if (event.target.id === "meta-description") {
      this.props.dispatchUserUpdate({
        meta_description: this.state.user?.meta_description,
        token: this.props.token,
      });
    } else if (event.target.id === "booking-url") {
      //console.log("hello id", event.target.id);
      this.props.dispatchUserUpdate({
        booking_url: this.state.user?.booking_url,
        token: this.props.token,
      });
    } else if (event.target.id === "website_url") {
      //console.log("hello id", event.target.id);
      this.props.dispatchUserUpdate({
        website_url: this.state.user?.website_url,
        token: this.props.token,
      });
    } else if (event.target.id === "gmail") {
      this.props.dispatchUserUpdate({
        gmail: this.state.user?.gmail,
        token: this.props.token,
      });
    } else if (event.target.id === "facebook") {
      this.props.dispatchUserUpdate({
        facebook: this.state?.user?.facebook,
        token: this.props.token,
      });
    } else if (event.target.id === "page_id") {
      this.props.dispatchUserUpdate({
        page_id: this.state?.user?.page_id,
        token: this.props.token,
      });
    } else if (event.target.id === "group_url") {
      this.props.dispatchUserUpdate({
        group_url: this.state?.user?.group_url,
        token: this.props.token,
      });
    } else if (event.target.id === "instagram") {
      this.props.dispatchUserUpdate({
        instagram: this.state?.user?.instagram,
        token: this.props.token,
      });
    } else if (event.target.id === "linkedin") {
      this.props.dispatchUserUpdate({
        linkedin: this.state?.user?.linkedin,
        token: this.props.token,
      });
    } else if (event.target.id === "youtube") {
      this.props.dispatchUserUpdate({
        youtube: this.state?.user?.youtube,
        token: this.props.token,
      });
    } else if (event.target.id === "email") {
      const uEmail = {
        userEmail: this.state?.user?.email,
      };

      // var user = firebase.auth().currentUser;

      //     user.updateEmail(uEmail.userEmail).then(function() {
      //     // Update successful.
      //     console.log('Authorized update email');
      //     }).catch(function(error) {
      //     // An error happened.
      //     });

      // firebase.auth().onAuthStateChanged(function (user) {
      //     if (user) {

      //         firebase.database().ref('/users/' + user.uid).update(uEmail)
      //     } else {
      //         console.log("Update user not sign In");
      //     }
      // });

      this.props.dispatchUserUpdate({
        email: uEmail.userEmail,
        token: this.props.token,
      });
    } else if (event.target.id === "ai_core_value") {
      this.props.dispatchUserUpdate({
        ai_core_value: this.state?.user?.ai_core_value,
        token: this.props.token,
      });
    } else if (event.target.id === "ai_content") {
      this.props.dispatchUserUpdate({
        ai_content: this.state?.aiContent.toString("html"),
        token: this.props.token,
      });
    }
  };

  copyURL = () => {
    var copyText = document.getElementById("myInput1");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    toast.success("Profile Link Copy Successfully", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  onClickAI = () => {
    this.setState({
      openAIWizard: true,
    });
  };

  handleCloseAIWizard = () => {
    this.setState({
      openAIWizard: false,
    });
  };
  onSelectAIWizardType = (type) => {
    this.setState({
      aiWizardType: type,
      aiWizardTitle: type ? type : "Let AI boost your content",
    });
    // if (type && type != "")
    //   this.props.dispatchCreateAIContent({
    //     type: type,
    //     token: this.props.token,
    //   });
  };
  onClickUseWizard = () => {
    this.setState({
      aiWizardType: null,
      openAIWizard: false,
      aiContent: RichTextEditor.createValueFromString(
        this.props.aiContent,
        "html"
      ),
    });
  };
  onClickRegenerateWizard = () => {
    this.props.dispatchCreateAIContent({
      type: this.state.aiWizardType,
      token: this.props.token,
    });
  };
  onClickCopyWizard = () => {
    clipboard.writeText(this.props.aiContent).then(
      () => {
        toast.success("Copied!", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      () => {
        toast.error("Failed to copy", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };
  render() {
    // let fb_data = JSON.parse(localStorage.getItem("Facebook_Response"));
    // if (fb_data && fb_data.accessToken)
    return (
      <div>
        <ToastContainer />
        <Header token={this.props.token} />
        <AiWizardDrawer
          open={this.state.openAIWizard}
          onClose={this.handleCloseAIWizard}
          onSelectType={this.onSelectAIWizardType}
          type={this.state.aiWizardType}
          title={this.state.aiWizardTitle}
          loadingAIConent={this.props.loadingAIConent}
          aiContent={this.props.aiContent}
          onClickUse={this.onClickUseWizard}
          onClickCopy={this.onClickCopyWizard}
          onClickRegenerate={this.onClickRegenerateWizard}
        />
        <div className="prof-bnr">
          <img
            src={
              this.state?.banner_url
                ? this.state?.banner_url
                : this.props?.banner_url
                ? this.props?.banner_url
                : "/assets/images/prof.png"
            }
            alt=""
          />
          <div className="overlay-clr"></div>
          <div className="container">
            <button type="button" className="back-btn">
              <i className="fa fa-angle-left" aria-hidden="true"></i> Settings
            </button>
            <div className="edit-bnr">
              <button
                type="button"
                className="back-btn"
                data-toggle="modal"
                data-target="#banner-image-model"
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="prof-btn">
            <input
              type="text"
              className="btn-scnd"
              value={`${window.location.origin}/member/${this.props.userProfile?.id}/${this.props.userProfile?.name_url}`}
              id="myInput1"
            />
            <button className="btn-scnd" onClick={this.copyURL}>
              Copy Profile Link
            </button>
            <a
              href={`/member/${this.props.userProfile?.id}/${this.props.userProfile?.name_url}`}
            >
              <button className="btn-first">Preview Page</button>
            </a>
          </div>
        </div>
        <div className="edit-profile-sec">
          <img
            src={
              this.state?.profilePhoto
                ? this.state?.profilePhoto
                : this.props?.profilePhoto
                ? this.props?.profilePhoto
                : "/assets/images/profile-img.jpg"
            }
            alt=""
          />
          <button type="button" data-toggle="modal" data-target="#image-model">
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
        </div>
        <div className="prof-dtl-edt">
          <div className="container">
            <h2>Personal Information</h2>
            <div className="dtl-box">
              <div className="edit-row">
                <label>Name</label>
                <p>{this.state?.user?.name ? this.state?.user?.name : ""}</p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#name-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Email</label>
                <p>{this.state?.user?.email ? this.state?.user?.email : ""}</p>
              </div>
              <div className="edit-row">
                <label>Password</label>
                <p>**********</p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#password-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row bio">
                <label>Bio</label>
                <div>
                  {this.state?.value.toString("html") !== "<p><br></p>"
                    ? ReactHtmlParser(this.state?.value.toString("html"))
                    : this.state?.user?.bio
                    ? ReactHtmlParser(this.state?.user?.bio)
                    : ""}
                </div>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#bio-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Address</label>

                <p>
                  {this.state?.user?.address ? this.state?.user?.address : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#address-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>

              <div className="edit-row fb">
                <label>Introduction Video</label>
                <form onSubmit={this.handleVideoSubmit}>
                  <input
                    className="vid-select-btn"
                    type="file"
                    accept="video/*"
                    value={this.state?.selectedFile}
                    onChange={this.setSelectedFile}
                    required
                  />
                  {this.props?.loading === true ? (
                    <button
                      className="btn btn-primary vid-submit-btn"
                      type="button"
                      // onClick={this.onUpload.bind(this)}
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      {"  "}
                      Loading...
                      {/* <Bar /> */}
                    </button>
                  ) : (
                    <button className="vid-submit-btn" type="submit">
                      Upload
                    </button>
                  )}
                </form>
              </div>

              <div className="prw-prof-vdo">
                <video
                  src={
                    this.state?.selectedVideo
                      ? URL.createObjectURL(this.state?.selectedVideo)
                      : this.props?.userProfile?.promotional_video
                  }
                  type="video"
                  controls
                ></video>
              </div>
            </div>
            <h2>Social Information</h2>
            {/* <div className="scl-login"> */}
            {/* <div className="row">
                {/* <div className="col-sm-6">
                  <GoogleLogin
                    clientId={CLIENT_ID}
                    buttonText="Login"
                    style={{ color: "white" }}
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </div> */}
            {/* <div className="col-sm-6">
                  <FacebookLoginComponent token={this.props?.token} />
                </div> */}

            <div className="dtl-box">
              <div className="edit-row web">
                <label>Meta Title</label>
                <p>
                  {this.state?.user?.meta_title
                    ? this.state?.user?.meta_title
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#meta-title-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>

              <div className="edit-row bio">
                <label>Meta Description</label>
                <p>
                  {this.state?.user?.meta_description
                    ? this.state?.user?.meta_description
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#meta-description"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>

              <div className="edit-row web">
                <label>Booking URL</label>
                <p>
                  {this.state?.user?.booking_url
                    ? this.state?.user?.booking_url
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#booking-url-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row web">
                <label>Website URL</label>
                <p>
                  {this.state?.user?.website_url
                    ? this.state?.user?.website_url
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#website-url-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Gmail</label>
                <p>{this.state?.user?.gmail ? this.state?.user?.gmail : ""}</p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#gmail-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Facebook</label>
                <p>
                  {this.state?.user?.facebook ? this.state?.user?.facebook : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#facebook-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row fb">
                <label>Facebook Page Name</label>
                <p>
                  {this.state?.user?.page_id ? this.state?.user?.page_id : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#facebook-page-name-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <p style={{ color: "#ef5308" }}>
                * In order to show the fb page reviews in your profile section,
                enter the page name and then login with Facebook.
              </p>
              <FacebookLoginComponent token={this.props?.token} />
              <div className="edit-row fb">
                <label>Facebook Group URL</label>
                <p>
                  {this.state?.user?.group_url
                    ? this.state?.user?.group_url
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#facebook-group-url-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Instagram</label>
                <p>
                  {this.state?.user?.instagram
                    ? this.state?.user?.instagram
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#instagram-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Linkedin</label>
                <p>
                  {this.state?.user?.linkedin ? this.state?.user?.linkedin : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#linkedin-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row">
                <label>Youtube</label>
                <p>
                  {this.state?.user?.youtube ? this.state?.user?.youtube : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#youtube-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            {/* {this.props.aiAnswers && this.props.aiAnswers.length > 0 && (
              <>
                <h2>I asked Maetrix </h2>
                <div className="dtl-box">
                  <div className="dashboard-ai-answers">
                    {this.props.loadingAI && <div className="dot-pulse"></div>}
                    <div id="content" className="ai-qa-list">
                      {this.props.aiAnswers &&
                        this.props.aiAnswers.map((q, index) => (
                          <div key={index} className="qa-row">
                            <i
                              class="delete fa fa-times"
                              aria-hidden="true"
                              onClick={(e) => this.deleteAI(q)}
                            ></i>
                            <div className="question">
                              <h4>{q.question}</h4>
                              <p>
                                <i className="fa fa-calendar"></i>
                                {DateFormat(q.created_at)}
                              </p>
                            </div>
                            <ReactMarkdown>{q.answer}</ReactMarkdown>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )} */}
            {/* <h2>AI Content</h2>
            <div className="dtl-box">
              <div className="edit-row ai-content">
                <label>Your Values</label>
                <p>
                  {this.state?.user?.ai_core_value
                    ? this.state?.user?.ai_core_value
                    : ""}
                </p>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#ai-core-value-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
              <div className="edit-row ai-content">
                <label>AI Content</label>
                <div>
                  {this.state?.aiContent.toString("html") !== "<p><br></p>"
                    ? ReactHtmlParser(this.state?.aiContent.toString("html"))
                    : this.state?.user?.ai_content
                    ? ReactHtmlParser(this.state?.user?.ai_content)
                    : ""}
                </div>
                <button
                  className="edit"
                  type="button"
                  data-toggle="modal"
                  data-target="#ai-content-model"
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <Footer token={this.props.token} />

        {/* ----------------------------name model-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="name-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Name</h2>
                    <p>Example: https://www.example.com</p>
                    <form onSubmit={this.handleRegForm} id="name">
                      <input
                        type="text"
                        value={
                          this.state.user?.name ? this.state.user?.name : ""
                        }
                        onChange={this.handleChange}
                        name="name"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*--------------------- booking url popup--------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="booking-url-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Booking URL</h2>
                    <p>Example: https://www.example.com</p>
                    <form onSubmit={this.handleRegForm} id="booking-url">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="URL should be with http:// or https://, like- https://www.example.com "
                        type="text"
                        value={
                          this.state.user?.booking_url
                            ? this.state.user?.booking_url
                            : ""
                        }
                        onChange={this.handleChange}
                        name="booking_url"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*--------------------- booking url popup--------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="website-url-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit website URL</h2>
                    <p>Example: https://www.example.com</p>
                    <form onSubmit={this.handleRegForm} id="website_url">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="URL should be with http:// or https://, like- https://www.example.com "
                        type="text"
                        value={
                          this.state.user?.website_url
                            ? this.state.user?.website_url
                            : ""
                        }
                        onChange={this.handleChange}
                        name="website_url"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*--------------------- meta title popup--------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="meta-title-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Meta Title</h2>
                    <p>
                      <strong>
                        Meta description text can not be more than 60
                        characters.
                      </strong>
                    </p>
                    <p>
                      <strong>Meta Title Length:</strong>{" "}
                      {this.state?.meta_length?.length !== undefined
                        ? this.state?.meta_length?.length
                        : this.state.user?.meta_title?.length}
                    </p>
                    <form onSubmit={this.handleRegForm} id="meta-title">
                      <input
                        type="text"
                        value={
                          this.state.user?.meta_title
                            ? this.state.user?.meta_title
                            : ""
                        }
                        onChange={this.handleChange}
                        name="meta_title"
                        placeholder=""
                        required
                      />
                      <p className="lengthAlert">
                        {this.state?.user?.meta_length?.length > 60 &&
                          "*Meta description text can not be more than 60 characters."}
                      </p>
                      <div className="sbm-btn">
                        {this.state?.user?.meta_length?.length > 60 ? (
                          <lable className="notSave">Save</lable>
                        ) : (
                          <button type="submit">Save</button>
                        )}

                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- gmail url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="gmail-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Gmail URL</h2>
                    <p>Example: example@domain.com</p>
                    <form onSubmit={this.handleRegForm} id="gmail">
                      <input
                        type="email"
                        pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                        title="email should be like user@domain.com"
                        value={
                          this.state.user?.gmail ? this.state.user?.gmail : ""
                        }
                        onChange={this.handleChange}
                        name="gmail"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- facebook url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="facebook-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Facebook User Id URL</h2>
                    <p>Example: https://facebook.com/user-id</p>
                    <form onSubmit={this.handleRegForm} id="facebook">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="Facebook URL should be with http:// or https://, like- https://facebook.com/username "
                        type="text"
                        value={
                          this.state?.user?.facebook
                            ? this.state?.user?.facebook
                            : ""
                        }
                        onChange={this.handleChange}
                        name="facebook"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- facebook group url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="facebook-group-url-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Facebook Group URL</h2>
                    <p>Example: https://facebook.com/group_url</p>
                    <form onSubmit={this.handleRegForm} id="group_url">
                      <input
                        type="text"
                        value={
                          this.state?.user?.group_url
                            ? this.state?.user?.group_url
                            : ""
                        }
                        onChange={this.handleChange}
                        name="group_url"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- facebook page name popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="facebook-page-name-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Facebook page name</h2>
                    <p>
                      *Enter the exact page name with proper spaces and special
                      characters
                    </p>
                    <form onSubmit={this.handleRegForm} id="page_id">
                      <input
                        type="text"
                        value={
                          this.state?.user?.page_id
                            ? this.state?.user?.page_id
                            : ""
                        }
                        onChange={this.handleChange}
                        name="page_id"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- instagram url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="instagram-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Instagram User Id URL</h2>
                    <p>Example: https://www.instagram.com/user-name</p>
                    <form onSubmit={this.handleRegForm} id="instagram">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="Instagram URL should be with http:// or https://, like- https://instagram.com/username "
                        type="text"
                        value={
                          this.state.user?.instagram
                            ? this.state.user?.instagram
                            : ""
                        }
                        onChange={this.handleChange}
                        name="instagram"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- linkedin url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="linkedin-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit LinkedIn User Name URL</h2>
                    <p>Example: https://www.linkedin.com/userid</p>
                    <form onSubmit={this.handleRegForm} id="linkedin">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="linkedin URL should be with http:// or https://, like- https://linkedin.com/username "
                        type="text"
                        value={
                          this.state.user?.linkedin
                            ? this.state.user?.linkedin
                            : ""
                        }
                        onChange={this.handleChange}
                        name="linkedin"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------------- youtube url popup------------------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="youtube-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Youtube channel URL</h2>
                    <p>Example: https://youtube.com/channel-name</p>
                    <form onSubmit={this.handleRegForm} id="youtube">
                      <input
                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
                        title="Youtube URL should be with http:// or https://, like- https://youtube.com/channel-name "
                        type="text"
                        value={
                          this.state.user?.youtube
                            ? this.state.user?.youtube
                            : ""
                        }
                        onChange={this.handleChange}
                        name="youtube"
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* --------------------------password model popup-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="password-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Update Your Password</h2>
                    <form onSubmit={this.handleRegForm} id="updatePass">
                      <div className="form-group">
                        <input
                          type="hidden"
                          value={
                            this.state?.user?.email
                              ? this.state?.user?.email
                              : ""
                          }
                          name="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        {" "}
                        <input
                          type="password"
                          className="active"
                          id="password"
                          autoComplete="on"
                          value={
                            this.state.user?.currentPassword
                              ? this.state.user?.currentPassword
                              : ""
                          }
                          name="currentPassword"
                          onChange={this.handleChange}
                          placeholder="Current Password"
                          required
                        />
                        <span className="show-pass">
                          <i
                            id="pass-status"
                            className="fa fa-eye-slash"
                            aria-hidden="true"
                            onClick={this.Toggle}
                          ></i>
                        </span>
                      </div>

                      <div className="form-group">
                        {" "}
                        <input
                          type="password"
                          className="active"
                          autoComplete="on"
                          id="password1"
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                          id="password1"
                          value={
                            this.state.user?.newPassword
                              ? this.state.user?.newPassword
                              : ""
                          }
                          name="newPassword"
                          onChange={this.handleChange}
                          placeholder="New Password"
                          required
                        />
                        <span className="show-pass">
                          <i
                            id="pass-status1"
                            className="fa fa-eye-slash"
                            aria-hidden="true"
                            onClick={this.Toggle1}
                          ></i>
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          autoComplete="on"
                          className="active"
                          id="password2"
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          id="password2"
                          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                          value={
                            this.state.user?.confirmPassword
                              ? this.state.user?.confirmPassword
                              : ""
                          }
                          name="confirmPassword"
                          onChange={this.handleChange}
                          placeholder="Confirm Password"
                          required
                        />
                        <span className="show-pass">
                          <i
                            id="pass-status2"
                            className="fa fa-eye-slash"
                            aria-hidden="true"
                            onClick={this.Toggle2}
                          ></i>
                        </span>
                      </div>
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------bio model popup-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="bio-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Bio</h2>
                    <form onSubmit={this.handleRegForm} id="bio">
                      <RichTextEditor
                        value={
                          this.state.value
                            ? this.state.value
                            : this.state.user?.bio
                            ? this.state.user?.bio
                            : ""
                        }
                        onChange={this.onChange}
                      />
                      {/* <textarea
                        rows="4"
                        cols="50"
                        type="text"
                        value={this.state.user?.bio ? this.state.user?.bio : ""}
                        name="bio"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      /> */}
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------Meta Description model popup-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="meta-description">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Meta Description</h2>
                    <p>
                      Meta description text can not be more than 156 characters.
                    </p>
                    <p>
                      <strong>Meta decription length:</strong>{" "}
                      {this.state?.user?.description_length?.length !==
                      undefined
                        ? this.state?.user?.description_length?.length
                        : this.state.user?.meta_description?.length}
                    </p>
                    <form onSubmit={this.handleRegForm} id="meta-description">
                      <textarea
                        rows="4"
                        cols="50"
                        type="text"
                        value={
                          this.state.user?.meta_description
                            ? this.state.user?.meta_description
                            : ""
                        }
                        name="meta_description"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      />
                      <p className="lengthAlert">
                        {this.state?.user?.description_length?.length > 156 &&
                          "Meta description text can not be more than 156 characters."}
                      </p>
                      <div className="sbm-btn">
                        {this.state?.user?.description_length?.length > 156 ? (
                          <lable className="notSave">Save</lable>
                        ) : (
                          <button type="submit">Save</button>
                        )}

                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------------address popup model------------------------------ */}
        <div className="setting-popup">
          <div className="modal fade" id="address-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Address</h2>
                    <form onSubmit={this.handleRegForm} id="address">
                      <PlacesAutocomplete
                        name="address"
                        value={
                          this.state.user?.address
                            ? this.state.user?.address
                            : ""
                        }
                        onChange={this.autoHandleChange}
                        onSelect={this.handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "location-search-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {/* <textarea
                        rows="4"
                        cols="50"
                        type="text"
                        value={
                          this.state.user?.address
                            ? this.state.user?.address
                            : ""
                        }
                        name="address"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      /> */}
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* --------------------------email model popup--------------------------- */}
        {/* <div className="setting-popup">
          <div className="modal fade" id="email-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Email Id</h2>
                    <form onSubmit={this.handleRegForm} id="email">
                      <input
                        type="text"
                        value={
                          this.state.user?.email ? this.state.user?.email : ""
                        }
                        name="email"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* ------------------------------image model popup------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="image-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Update Your Image</h2>
                    <form onSubmit={this.handleRegForm} id="profile-image">
                      <input
                        type="file"
                        onChange={this.handleimageChange}
                        accept="image/*"
                        name="profile_image"
                        placeholder="select file for upload"
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------------------------banner image model popup------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="banner-image-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Update Your Banner Image (1920x405)</h2>
                    <form onSubmit={this.handleRegForm} id="banner-image">
                      <input
                        type="file"
                        onChange={this.handleBannerChange}
                        accept="image/*"
                        name="banner_image"
                        placeholder="select file for upload"
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------ai core value model popup-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="ai-core-value-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Values</h2>
                    <form onSubmit={this.handleRegForm} id="ai_core_value">
                      <textarea
                        rows="4"
                        cols="50"
                        type="text"
                        value={
                          this.state.user?.ai_core_value
                            ? this.state.user?.ai_core_value
                            : ""
                        }
                        name="ai_core_value"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      />
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------ai content popup-------------------------- */}
        <div className="setting-popup">
          <div className="modal fade" id="ai-content-model">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="setting-cnt">
                    <h2>Edit Your Content</h2>
                    <form onSubmit={this.handleRegForm} id="ai_content">
                      <RichTextEditor
                        value={
                          this.state.aiContent
                            ? this.state.aiContent
                            : this.state.user?.ai_content
                            ? this.state.user?.ai_content
                            : ""
                        }
                        onChange={this.onChangeAIContent}
                      />
                      {/* <textarea
                        rows="4"
                        cols="50"
                        type="text"
                        value={this.state.user?.bio ? this.state.user?.bio : ""}
                        name="bio"
                        onChange={this.handleChange}
                        placeholder=""
                        required
                      /> */}
                      <div className="sbm-btn">
                        <button type="submit">Save</button>
                        <button
                          type="button"
                          className="cancel"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <a className="ai" onClick={this.onClickAI}>
                          <img alt="img" src="/assets/images/ai-wizard.svg" />
                          AI
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log('userDetail', state.settingReducer);

  return {
    getUserDetail: state.authReducer.user,
    getPasswordUpdate: state.settingReducer?.is_password_update,
    getUpdateStatus: state.settingReducer,
    profilePhoto: state.settingReducer?.updateUser?.data?.profile_image,
    banner_url: state.settingReducer?.updateUser?.data?.banner_url,
    video_url: state.settingReducer?.updateUserVideoData?.result?.url,
    is_success: state.settingReducer?.is_success,
    userProfile: state.userReducer?.user?.user,
    loading: state.settingReducer?.loading,
    aiAnswers: state.settingReducer.aiAnswers,
    loadingAIAnswers: state.settingReducer.loadingAI,
    loadingAIConent: state.settingReducer.loadingAIContent,
    aiContent: state.settingReducer.aiContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUserToken: (data) => dispatch(getUser(data)),
    dispatchUserUpdate: (data) => dispatch(updateUser(data)),
    dispatchUserVideoUpdate: (data) => dispatch(updateUserVideo(data)),
    dispatchPasswordUpdate: (data) => dispatch(updatePassword(data)),
    // dispatchGetAIAnswers: (data) => dispatch(getUserDashboardAI(data)),
    // dispatchDeleteAIAnswer: (data) => dispatch(deleteUserDashboardAI(data)),
    // dispatchCreateAIContent: (data) => dispatch(createUserAIContent(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Setting));
