import React, { PureComponent } from "react";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class LatestMembers extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
    showSubscriptionModel: false,
    showLoginModel: false,
  };

  render() {
    return (
      <React.Fragment>
        <div className="members-row">
          <div className="container">
            <div className="meabers-head">
              <h2>Members</h2>
              <Link to="/members" className="view-btn">
                View All{" "}
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="mmbr-row">
              {this.props?.MembersList?.length > 0 && (
                <React.Fragment>
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    items={6}
                    responsive={this.state.responsive}
                  >
                    {this.props?.MembersList?.map((user, index) => (
                      <div className="member-user" key={index}>
                        <div className="memb-prof">
                          <img
                            alt="img"
                            src={
                              user?.profile_image
                                ? user?.profile_image
                                : "assets/images/profile-img.jpg"
                            }
                          />
                        </div>
                        <h3>{user.name ? user.name : "Unknown"}</h3>
                      </div>
                    ))}
                  </OwlCarousel>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MembersList: state?.dashboardReducer?.services?.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LatestMembers));
