import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_USER_VIDEO,
  UPDATE_USER_VIDEO_SUCCESS,
  UPDATE_USER_VIDEO_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  updateUser: null,
  is_password_update: null,
  is_user_update: null,
  is_success: false,
  api_response: null,
  updateUserVideoData: null,
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      state = {
        error: null,
        loading: true,
        updateUser: null,
        is_user_update: false,
      };
      break;
    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        updateUser: action.payload,
        is_user_update: true,
      };
      break;
    case UPDATE_USER_ERROR:
      state = {
        ...state,
        loading: false,
        updateUser: null,
        error: action.payload,
        is_user_update: false,
      };
      break;
    case UPDATE_PASSWORD:
      state = {
        ...state,
        loading: true,
        error: null,
        is_password_update: null,
        api_response: null,
      };
      break;
    case UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        is_password_update: true,
        api_response: action.payload,
      };
      break;
    case UPDATE_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        is_password_update: false,
      };
      break;
    case UPDATE_USER_VIDEO:
      state = {
        error: null,
        loading: true,
        is_success: false,
        updateUserVideoData: null,
      };
      break;
    case UPDATE_USER_VIDEO_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        is_success: true,
        updateUserVideoData: action.payload,
      };
      break;
    case UPDATE_USER_VIDEO_ERROR:
      state = {
        ...state,
        loading: false,
        is_success: false,
        error: action.payload,
        updateUserVideoData: null,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default settingReducer;
