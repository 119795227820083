import {
  CREATE_USER_AI_CONTENT,
  CREATE_USER_AI_CONTENT_SUCCESS,
  CREATE_USER_AI_CONTENT_ERROR,
  SAVE_AI_CONTENT,
  SAVE_AI_CONTENT_SUCCESS,
  SAVE_AI_CONTENT_ERROR,
  GET_USER_AI_CONTENT,
  GET_USER_AI_CONTENT_SUCCESS,
  GET_USER_AI_CONTENT_ERROR,
  DELETE_USER_AI_CONTENT,
  DELETE_USER_AI_CONTENT_SUCCESS,
  DELETE_USER_AI_CONTENT_ERROR,
  ADD_FEEDBACK_AI_CONTENT,
  ADD_FEEDBACK_AI_CONTENT_SUCCESS,
  ADD_FEEDBACK_AI_CONTENT_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loadingAIContent: false,
  savingFeedback: false,
  aiContent: null,
  aiContentList: [],
  feedback: null,
};

const aiContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_AI_CONTENT:
      state = {
        ...state,
        error: null,
        loadingAIContent: true,
        aiContent: null,
      };
      break;
    case CREATE_USER_AI_CONTENT_SUCCESS:
      state = {
        ...state,
        loadingAIContent: false,
        error: null,
        aiContent: action.payload,
      };
      break;

    case CREATE_USER_AI_CONTENT_ERROR:
      state = {
        ...state,
        loadingAIContent: false,
        error: action.payload,
        aiContent: null,
      };
      break;
    case SAVE_AI_CONTENT:
      state = {
        ...state,
        error: null,
        loadingAIContent: true,
        aiContent: null,
      };
      break;
    case SAVE_AI_CONTENT_SUCCESS:
      state = {
        ...state,
        loadingAIContent: false,
        error: null,
        aiContent: action.payload,
      };
      break;

    case SAVE_AI_CONTENT_ERROR:
      state = {
        ...state,
        loadingAIContent: false,
        error: action.payload,
        aiContent: null,
      };
      break;
    case GET_USER_AI_CONTENT:
      state = {
        ...state,
        error: null,
        loadingAIContent: true,
      };
      break;
    case GET_USER_AI_CONTENT_SUCCESS:
      state = {
        ...state,
        loadingAIContent: false,
        error: null,
        aiContentList: action.payload,
      };
      break;

    case GET_USER_AI_CONTENT_ERROR:
      state = {
        ...state,
        loadingAIContent: false,
        error: action.payload,
        aiContentList: null,
      };
      break;
    case DELETE_USER_AI_CONTENT:
      state = {
        ...state,
        error: null,
        loadingAIContent: true,
      };
      break;
    case DELETE_USER_AI_CONTENT_SUCCESS:
      state = {
        ...state,
        loadingAIContent: false,
        error: null,
        aiContentList: state.aiContentList
          .filter((item) => item.id != action.payload)
          .concat([]),
      };
      break;
    case DELETE_USER_AI_CONTENT_ERROR:
      state = {
        ...state,
        loadingAIContent: false,
        error: action.payload,
      };
      break;
    case ADD_FEEDBACK_AI_CONTENT:
      state = {
        ...state,
        error: null,
        savingFeedback: true,
        feedback: null,
      };
      break;
    case ADD_FEEDBACK_AI_CONTENT_SUCCESS:
      state = {
        ...state,
        savingFeedback: false,
        error: null,
        feedback: action.payload,
      };
      break;
    case ADD_FEEDBACK_AI_CONTENT_ERROR:
      state = {
        ...state,
        savingFeedback: false,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default aiContentReducer;
