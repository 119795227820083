import React, { PureComponent } from "react";
import {
  getVideo,
  getVideosByCategoryId,
} from "../../../store/protected/videos/action";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class VideoCategory extends PureComponent {
  responsive = {
    0: {
      items: 2,
    },
    450: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
  };

  componentDidMount() {
    this.props.dispatchGetVideoCategory({
      category_name: this.props.match.params.name,
    });

    this.props.dispatchVideoCategory({
      token: this.props.token,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.id !== this.props?.match?.params?.id &&
      this.props?.match?.params?.id != null
    ) {
      console.log("now differ video", this.props?.getCourses);

      this.props.dispatchGetVideoCategory({
        category_name: this.props.match.params.name,
      });
    }
  }

  render() {
    const galleryItems = this.props?.videoCategory?.data?.map((list, index) => (
      <div className="item" key={index}>
        <Link to={`/video/${list.category_title_url}`}>
          <img alt="img" src={list?.category_icon_url} /> {list?.category_name}
        </Link>
      </div>
    ));

    return (
      <React.Fragment>
        <div className="container">
          <div className="video-cat-sld">
            <AliceCarousel
              items={galleryItems}
              responsive={this.responsive}
              buttonsDisabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    videoCategory: state.videosReducer?.videos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchVideoCategory: (data) => dispatch(getVideo(data)),
    dispatchGetVideoCategory: (data) => dispatch(getVideosByCategoryId(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoCategory));
