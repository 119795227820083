import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";
import DataLoading from "../../../common/DataLoading";

const LatestEpisodes = (props) => {
    const [episodesSlides, setEpisodesSlides] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [loading, setLoading] = useState(true);
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    useEffect(() => {
        if (props?.videoList && props?.videoList.length > 0) {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        } else {
            setLoading(true)
        }
    }, [props?.videoList]);
    
    return (
        <section className="latest my-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <h2 className="mb-0">Latest Episodes</h2>
                    </div>
                </div>
            </div>
            <div className="container my-5">

                <div className="card-slider-latest">
                    {loading === false ?
                        <Slider {...settings}>
                            {props?.videoList && props?.videoList?.map((element, index) => (
                                <div className="col-lg-12 pr-5" key={index}>
                                    <div className="row">
                                        <div className="col-md-6" id="latest-new-1">
                                            <div className="new-div p-5">
                                                <h3>{element.video_title}</h3>
                                                <p className="txt-1">{ReactHtmlParser(element?.description)}</p>
                                                <p className="txt-2">{element.duration}</p>
                                                <p className="txt-3 pb-5">
                                                    {element.video_type == 0 &&
                                                        (element.is_subscribed === true ||
                                                            element.is_subscribed === false)
                                                        ? "Free"
                                                        : element.video_type == 1 &&
                                                            element.is_subscribed === true
                                                            ? "View Video"
                                                            : `£ ${element.fee}`
                                                    }
                                                </p>
                                                <Link
                                                    to={`/video/${element.video_category_url}/${element.video_title_url}`}
                                                    className="class-learn stretched-link">
                                                    Learn More
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 new-new" style={{ background: `url(` + element.banner_url + `)`, height: "376px" }}>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        :
                        <DataLoading />
                    }
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        videoList: state?.dashboardReducer?.services?.videos,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LatestEpisodes));
