import {
  GET_PODCAST,
  GET_PODCAST_SUCCESS,
  GET_PODCAST_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_PODCAST_BY_CATEGORY_ID,
  GET_PODCAST_BY_CATEGORY_ID_SUCCESS,
  GET_PODCAST_BY_CATEGORY_ID_ERROR,
} from "./actionType";

export const getPodcast = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_PODCAST,
    payload: data,
  };
};

export const getPodcastSuccess = (data) => {
  return {
    type: GET_PODCAST_SUCCESS,
    payload: data,
  };
};

export const getPodcastError = (error) => {
  return {
    type: GET_PODCAST_ERROR,
    payload: error,
  };
};
export const getBanner = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BANNER,
    payload: data,
  };
};

export const getBannerSuccess = (data) => {
  return {
    type: GET_BANNER_SUCCESS,
    payload: data,
  };
};

export const getBannerError = (error) => {
  return {
    type: GET_BANNER_ERROR,
    payload: error,
  };
};

export const getPodcastByCategoryId = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_PODCAST_BY_CATEGORY_ID,
    payload: data,
  };
};

export const getPodcastByCategoryIdSuccess = (data) => {
  return {
    type: GET_PODCAST_BY_CATEGORY_ID_SUCCESS,
    payload: data,
  };
};

export const getPodcastByCategoryIdError = (error) => {
  return {
    type: GET_PODCAST_BY_CATEGORY_ID_ERROR,
    payload: error,
  };
};
