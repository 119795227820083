import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DataLoading from "../../../common/DataLoading";

const AllMembers = (props) => {
    const [memberSlides, setMemberSlides] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [loading, setLoading] = useState(true);
    const settings = {
        className: "center",
        centerMode: false,
        //infinite: true,
        slidesToShow: 4,
        speed: 500,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }]
    };

    useEffect(() => {
        if (props?.MembersList && props?.MembersList.length > 0) {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }else{
            setLoading(true)
        }
    }, [props?.MembersList]);


    return (
        <section className="members mt-5 pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <h2>All Members</h2>
                    </div>
                </div>
            </div>
            {loading == false ?
                <div className="container my-5">
                    <div className="card-slider">
                        <Slider {...settings}>
                            {props.MembersList && props.MembersList?.map((element, index) => (
                                <div className="col-lg-12" key={index}>
                                    <div className="card w-100">
                                        <div className="card-body">
                                            <a href={`/member/${element.id}/${element.name}`}>
                                            <h5 className="card-title" style={{ display: "flex" }}>
                                             
                                                <img style={{ width: "50px" }}
                                                    src={element?.profile_image ?
                                                        element?.profile_image
                                                        : "assets/images/profile-img.jpg"}
                                                />
                                                <span style={{ position: 'relative', top: '18px', left: '18px' }}>{element.name ? element.name : "Unknown"}</span>
                                                
                                            </h5>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                :
                <DataLoading />
            }
        </section>
    )
}
const mapStateToProps = (state) => {
    return {
        MembersList: state?.dashboardReducer?.services?.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AllMembers));
