export const GET_ROADMAP_ASSIGNS = "GET_ROADMAP_ASSIGNS";
export const GET_ROADMAP_ASSIGNS_SUCCESS = "GET_ROADMAP_ASSIGNS_SUCCESS";
export const GET_ROADMAP_ASSIGNS_ERROR = "GET_ROADMAP_ASSIGNS_ERROR";

export const GET_ROADMAP_LESSONS = "GET_ROADMAP_LESSONS";
export const GET_ROADMAP_LESSONS_SUCCESS = "GET_ROADMAP_LESSONS_SUCCESS";
export const GET_ROADMAP_LESSONS_ERROR = "GET_ROADMAP_LESSONS_ERROR";

export const UPDATE_ROADMAP_ASSIGNS = "UPDATE_ROADMAP_ASSIGNS";
export const UPDATE_ROADMAP_ASSIGNS_SUCCESS = "UPDATE_ROADMAP_ASSIGNS_SUCCESS";
export const UPDATE_ROADMAP_ASSIGNS_ERROR = "UPDATE_ROADMAP_ASSIGNS_ERROR";
