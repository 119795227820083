import { BASE_URL } from "../../../helpers/Constants";
import { GET_ALL_ACTIVITY } from "./actionType";

import { getAllActivitySuccess, getAllActivityError } from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAllActivity(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(BASE_URL + "api/UserActivity", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getAllActivitySuccess(response.result));
    } else {
      yield put(getAllActivityError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getAllActivityError(error.message));
  }
}

export function* watchgetLatestActivity() {
  yield takeEvery(GET_ALL_ACTIVITY, getAllActivity);
}

function* activitySaga() {
  yield all([fork(watchgetLatestActivity)]);
}

export default activitySaga;
