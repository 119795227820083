import { BASE_URL } from "../../../helpers/Constants";

import { GET_USER, GET_USER_PAYMENT_DETAIL } from "./actionType";

import {
  getUserSuccess,
  getUserError,
  getUserPaymentDetailSuccess,
  getUserPaymentDetailError,
} from "./action";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getUser(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(BASE_URL + "api/profile/detail", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getUserSuccess(response.result));
    } else {
      yield put(getUserError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getUserError(error.message));
  }
}
function* getPaymentTransactions(data) {
  try {
    const response = yield fetch(BASE_URL + "api/userTransactionList", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getUserPaymentDetailSuccess(response.result));
    } else {
      yield put(getUserPaymentDetailError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getUserPaymentDetailError(error.message));
  }
}

export function* watchgetUser() {
  yield takeEvery(GET_USER, getUser);
}
export function* watchgetPayment() {
  yield takeEvery(GET_USER_PAYMENT_DETAIL, getPaymentTransactions);
}

function* userSaga() {
  yield all([fork(watchgetUser), fork(watchgetPayment)]);
}
export default userSaga;
