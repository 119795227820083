export const CREATE_USER_AI_CONTENT = "CREATE_USER_AI_CONTENT";
export const CREATE_USER_AI_CONTENT_SUCCESS = "CREATE_USER_AI_CONTENT_SUCCESS";
export const CREATE_USER_AI_CONTENT_ERROR = "CREATE_USER_AI_CONTENT_ERROR";
export const GET_USER_AI_CONTENT = "GET_USER_AI_CONTENT";
export const GET_USER_AI_CONTENT_SUCCESS = "GET_USER_AI_CONTENT_SUCCESS";
export const GET_USER_AI_CONTENT_ERROR = "GET_USER_AI_CONTENT_ERROR";
export const DELETE_USER_AI_CONTENT = "DELETE_USER_AI_CONTENT";
export const DELETE_USER_AI_CONTENT_SUCCESS = "DELETE_USER_AI_CONTENT_SUCCESS";
export const DELETE_USER_AI_CONTENT_ERROR = "DELETE_USER_AI_CONTENT_ERROR";
export const ADD_FEEDBACK_AI_CONTENT = "ADD_FEEDBACK_AI_CONTENT";
export const ADD_FEEDBACK_AI_CONTENT_SUCCESS =
  "ADD_FEEDBACK_AI_CONTENT_SUCCESS";
export const ADD_FEEDBACK_AI_CONTENT_ERROR = "ADD_FEEDBACK_AI_CONTENT_ERROR";

export const SAVE_AI_CONTENT = "SAVE_AI_CONTENT";
export const SAVE_AI_CONTENT_SUCCESS = "SAVE_AI_CONTENT_SUCCESS";
export const SAVE_AI_CONTENT_ERROR = "SAVE_AI_CONTENT_ERROR";
