import React, { PureComponent } from "react";

import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import {
  getAIWorkBook,
  deleteAIWorkBook,
} from "../../../store/protected/workbook/action";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DataLoading from "../../common/DataLoading";
import DateFormat from "../../../helpers/DateHelper";
import ReactMarkdown from "react-markdown";
import html2pdf from "html2pdf.js";

class AIWorkbook extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    downloadType: "DOC"
  };
  componentDidMount() {
    this.props.dispatchMyQuestions({
      token: this.props.token,
    });
  }
  deleteWorkBook = async (item) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation)
      this.props.dispatchDeleteQuestion({
        id: item.id,
        token: this.props.token,
      });
  };
  handleChangeSaveType = (type) => {
    console.log(type.target.value)
    this.setState({
      downloadType: type.target.value
    })
  }
  download = () => {
    if (this.state.downloadType == "PDF") {
      this.downloadPDF()
    } else {
      this.downloadDoc()
    }
  }
  downloadPDF = async () => {
    const element = document.getElementById("content");
    const options = {
      filename: "workbook.pdf",
      image: { type: "jpeg", quality: 1 }, // Optional
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 3, letterRendering: true }, // Optional
      jsPDF: {
        unit: "in",
        format: "A2",
        orientation: "portrait",
      }, // Optional
    };

    html2pdf().from(element).set(options).save();
  };
  downloadDoc = async () => {
    var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + document.getElementById("content").innerHTML + footer;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'workbook.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };
  render() {
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <ToastContainer />
        <div className="container page-height">
          <div className="my-questions-row">
            {/* <h3>Question and Answers</h3> */}
            {this.props.loading == false ? (
              <>
                {this.props.aiWorkBook && this.props.aiWorkBook.length > 0 && (
                  <div className="save-as">
                    Save as
                    <select
                      name="exp_month"
                      onChange={this.handleChangeSaveType}
                      required
                    >
                      <option value="DOC">DOC</option>
                      <option value="PDF">PDF</option>

                    </select>
                    <button
                      className="btn btn-primary"
                      onClick={this.download}
                    >
                      Save
                    </button>
                  </div>
                )}

                <div
                  id="content"
                  ref={this.myRef}
                  className="pdf-container ai-qa-list"
                >
                  {this.props.aiWorkBook &&
                    this.props.aiWorkBook.map((q, index) => (
                      <div key={index} className="qa-row">
                        <i
                          class="delete fa fa-times"
                          aria-hidden="true"
                          onClick={(e) => this.deleteWorkBook(q)}
                        ></i>
                        <h3>{q.lesson_name}</h3>
                        <div className="question">
                          <h4>
                            <i
                              className="fa fa-align-left"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            {q.question}
                          </h4>
                          <p>
                            <i className="fa fa-calendar"></i>
                            {DateFormat(q.created_at)}
                          </p>
                        </div>
                        <ReactMarkdown>{q.answer}</ReactMarkdown>
                      </div>
                    ))}
                </div>
                {this.props.aiWorkBook && this.props.aiWorkBook.length > 0 && (
                  <div className="save-as">
                    Save as
                    <select
                      name="exp_month"
                      onChange={this.handleChangeSaveType}
                      required
                    >
                      <option value="DOC">DOC</option>
                      <option value="PDF">PDF</option>

                    </select>
                    <button
                      className="btn btn-primary"
                      onClick={this.download}
                    >
                      Save
                    </button>
                  </div>
                )}
              </>
            ) : (
              <DataLoading />
            )}
          </div>
        </div>

        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aiWorkBook: state?.workBookReducer?.allAIWorkBook,
    loading: state?.workBookReducer?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchMyQuestions: (data) => dispatch(getAIWorkBook(data)),
    dispatchDeleteQuestion: (data) => dispatch(deleteAIWorkBook(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AIWorkbook));
