import React, { Component } from 'react'

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { forgotPassword } from '../../../store/protected/auth/actions'

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { ToastContainer, toast } from 'react-toastify';


class ForgotPassword extends Component {

    state = {
        email: ''
    }
    
    // componentDidUpdate(prevProps){
    //     console.log('prevprops', prevProps.forgot_password_result, 'currentProps', this.props.forgot_password_result);

    // }

    componentDidUpdate(prevProps) {
        const prevData = get(prevProps, 'forgot_password_result', {});
        const currentData = get(this, 'props.forgot_password_result', {});
        if (!isEqual(prevData, currentData) && currentData) {
            document.getElementById("forgotPassword-form").reset();
            this.setState({
                email: ''
            });

        }
    }

    handleUserInputReg = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleRegForm = event => {
        //console.log('click button');
        event.preventDefault();
        if (!this.state.email) {
            alert('Please input email Id')
        }
        else{
            this.props.dispatchForgotPassword({
                email: this.state.email
            });
        }
            
        //console.log(Object.keys(errors).length)
    };


    render() {
        return (

            
            <div className="forgot-form">
                <ToastContainer /> 
                <div className="main-login-sec">
                    <h2>Maetrix</h2>
                    <h3>Forgot Password</h3>
                    <form onSubmit={this.handleRegForm} id="forgotPassword-form" >
                        <div className="form-group">
                            <input type="email" placeholder=" Enter Email ID" id="email" name="email" value={this.state.email} onChange={this.handleUserInputReg} required />
                        </div>


                        <div className="sbm-sec">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                    <button type="button" id="regSec">Create a new account</button><br></br>
                    <button type="button" id="loginSec">Log In</button>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    //console.log('state.authReducer', state.authReducer)
    return {
        forgot_password_result: state.authReducer.forgot_password_result,
        //getError: state.authReducer.error,
        // listenRegister: state.authReducer.is_registered
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchForgotPassword: data => dispatch(forgotPassword(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
