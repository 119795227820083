import {
  GET_ROADMAP_ASSIGNS,
  GET_ROADMAP_ASSIGNS_SUCCESS,
  GET_ROADMAP_ASSIGNS_ERROR,
  UPDATE_ROADMAP_ASSIGNS,
  UPDATE_ROADMAP_ASSIGNS_SUCCESS,
  UPDATE_ROADMAP_ASSIGNS_ERROR,
  GET_ROADMAP_LESSONS,
  GET_ROADMAP_LESSONS_SUCCESS,
  GET_ROADMAP_LESSONS_ERROR,
} from "./actionTypes";

export const getRoadmapAssigns = (data) => {
  return {
    type: GET_ROADMAP_ASSIGNS,
    payload: data,
  };
};

export const getRoadmapAssignsSuccess = (data) => {
  return {
    type: GET_ROADMAP_ASSIGNS_SUCCESS,
    payload: data,
  };
};

export const getRoadmapAssignsError = (error) => {
  return {
    type: GET_ROADMAP_ASSIGNS_ERROR,
    payload: error,
  };
};

export const updateRoadmapAssigns = (data) => {
  return {
    type: UPDATE_ROADMAP_ASSIGNS,
    payload: data,
  };
};

export const updateRoadmapAssignsSuccess = (data) => {
  return {
    type: UPDATE_ROADMAP_ASSIGNS_SUCCESS,
    payload: data,
  };
};

export const updateRoadmapAssignsError = (error) => {
  return {
    type: UPDATE_ROADMAP_ASSIGNS_ERROR,
    payload: error,
  };
};

export const getRoadmapLessons = (data) => {
  return {
    type: GET_ROADMAP_LESSONS,
    payload: data,
  };
};

export const getRoadmapLessonsSuccess = (data) => {
  return {
    type: GET_ROADMAP_LESSONS_SUCCESS,
    payload: data,
  };
};

export const getRoadmapLessonsError = (error) => {
  return {
    type: GET_ROADMAP_LESSONS_ERROR,
    payload: error,
  };
};
