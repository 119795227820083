import React, { Component } from "react";

import Banner from "../../../layout/auth/Abanner";
import AuthHeader from "../../../layout/auth/Aheader";
// import AuthHeader from "../../../layout/gaust/newLayout/Header2";
import AuthFooter from "../../../layout/auth/Afooter";
//import Header from "../../../layout/gaust/Header";
import Header from "../../../layout/gaust/newLayout/Header";
import CourseCategory from "./courseCategory";
import Login from "../../gaust/login";
import { getCoursesByCategoryId } from "../../../store/protected/courses/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PopularTopics from "./PopularTopics";


class CoursesByCategoryId extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    // document.title = "Course-Category :: Maetrix";
    this.props.dispatchCoursesByCategoryId({
      id: this.props.match.params.name,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name !== this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      this.props.dispatchCoursesByCategoryId({
        id: this.props.match.params.name,
      });
    }
  }
  render() {
    return (
      <div>
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="course-main page-height ">
          <div className="container">
            <div className="cat-video-row">
              <div className="container">
                <div className="cat-video-head">
                  <h2>
                    {this.props?.coursesList?.category_name} <span>  
                            <Link
                            to={`/courses`}
                            className="back-button"
                            
                            >
                           Back{" "}
                            <i
                                className="fa fa-long-arrow-left"
                                aria-hidden="true"
                            ></i>
                            </Link> 
                                        
                                        </span>
                  </h2>
                </div>
                <div className="row">


                <div className="tab-pane fade show active" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <div className="card-slider-new my-5">
                        <div className="entrepreneurship row">
                        {this.props?.coursesList?.courses?.map((course, index) => (
                                <div className="col-lg-3 pr-5 padding-40" key={index}>
                                   <Link
                            to={`/course/${course.category_title_url}/${course.id}/${course.course_title_url}`}
                          >
                                    <div className="card w-100">
                                        <img className="card-img-top" src={course?.thumbnail_url} alt="Card image cap" />
                                        <div className="card-body">
                                            <h5 className="card-title">{course?.course_name}</h5>
                                            <p>{course?.no_of_lessons} Lessons</p>
                                            <a href="" className="btn-free"> {course.course_type === 0 &&
                                  (course.is_subscribed === true ||
                                    course.is_subscribed === false) &&
                                  (course.is_independent === true ||
                                    course.is_independent === false)
                                    ? "Free"
                                    : course.course_type === 1 &&
                                      course.is_independent === false &&
                                      course.is_subscribed === false
                                    ? `£ ${course.monthly_fee}`
                                    : course.course_type === 1 &&
                                      course.is_independent === true &&
                                      course.is_subscribed === false
                                    ? `£ ${course.course_fee}`
                                    : "View Course"}</a>
                                            <p className="mt-4">
                                              {[...Array(course.rating)].map((elementInArray, index1) => ( 
                                              <img src={"./../assets/new_layout/img/Path 9.png"} />
                                              ) 
                                              )}
                                              <small>({course.rating}.0)</small>
                                            </p>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {this.state.showLoginModel === true && <Login />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AuthFooter token={this.props.token} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    coursesList: state.coursesReducer?.coursesList?.data[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCoursesByCategoryId: (data) =>
      dispatch(getCoursesByCategoryId(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoursesByCategoryId));
