import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_USER_VIDEO,
  UPDATE_USER_VIDEO_SUCCESS,
  UPDATE_USER_VIDEO_ERROR,
} from "./actionTypes";

export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
};

export const updateUserError = (data) => {
  return {
    type: UPDATE_USER_ERROR,
    payload: data,
  };
};

export const updatePassword = (data) => {
  return {
    type: UPDATE_PASSWORD,
    payload: data,
  };
};
export const updatePasswordSuccess = (data) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const updatePasswordError = (data) => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    payload: data,
  };
};

export const updateUserVideo = (data) => {
  return {
    type: UPDATE_USER_VIDEO,
    payload: data,
  };
};

export const updateUserVideoSuccess = (data) => {
  return {
    type: UPDATE_USER_VIDEO_SUCCESS,
    payload: data,
  };
};

export const updateUserVideoError = (data) => {
  return {
    type: UPDATE_USER_VIDEO_ERROR,
    payload: data,
  };
};
