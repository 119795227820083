import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class NotFound extends Component {
  componentDidMount() {
    // document.title = "Page Not Found :: Maetrix";
  }

  render() {
    return (
      <React.Fragment>
        <div className="not-found-row">
          <div className="not-found-cnt">
            <Link to="/">
              {" "}
              <img
                alt="img"
                className="logo-img"
                src="/assets/images/maetrix-logo.png"
              />
            </Link>
            <img alt="img" src="/assets/images/404-error-img.png" />
          </div>
        </div>

        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotFound));
//export default NotFound
