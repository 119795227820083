import {
  GET_COURSE_BANNER,
  GET_COURSE_BANNER_SUCCESS,
  GET_COURSE_BANNER_ERROR,
} from "./actionTypes";

export const getCourseBanner = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_COURSE_BANNER,
    payload: data,
  };
};

export const getCourseBannerSuccess = (data) => {
  return {
    type: GET_COURSE_BANNER_SUCCESS,
    payload: data,
  };
};

export const getCourseBannerError = (error) => {
  return {
    type: GET_COURSE_BANNER_ERROR,
    payload: error,
  };
};
