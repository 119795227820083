import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { registerUser } from "../../../store/protected/auth/actions";
import { ToastContainer, toast } from "react-toastify";
class Register extends Component {
  state = {
    name: "",
    email: "",
    referral_code: "",
    password: "",
    confirm_password: "",
  };
  componentDidUpdate(prevProps) {
    //console.log('prevprops', prevProps.listenRegister, 'props', this.props.listenRegister);
    if (
      prevProps.listenRegister != this.props.listenRegister &&
      this.props.listenRegister === true
    ) {
      // toast.success('Thank you for the registration.Please check your email to activate the account', {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      // });

      document.getElementById("regForm").reset();
      this.setState({
        name: "",
        email: "",
        referral_code: "",
        password: "",
        confirm_password: "",
      });
    }
  }

  handleUserInputReg = (event) => {
    if (event.target.name === "name") {
      if (
        /^[a-zA-Z -]+$/.test(event.target.value) !== true &&
        event.target.value
      ) {
        return false;
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  Toggle = () => {
    let temp = document.getElementById("password-field-reg");
    let icon = document.getElementById("pass-reg");
    if (temp.className == "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  Toggle1 = () => {
    let temp = document.getElementById("password-field-reg1");
    let icon = document.getElementById("pass-reg1");
    if (temp.className == "active") {
      temp.setAttribute("type", "text");
      icon.className = "fa fa-eye";
      temp.className = "";
    } else {
      temp.setAttribute("type", "password");
      icon.className = "fa fa-eye-slash";
      temp.className = "active";
    }
  };

  handleRegForm = (event) => {
    //console.log('click button');
    event.preventDefault();
    let errors = {};
    if (!this.state.name) {
    }
    if (!this.state.email) {
      errors.name = "Email Is Required";
    }

    if (this.state.password != this.state.confirm_password) {
      errors.password = "Password And Confirm Password Does Not Match";
      toast.error("Password And Confirm Password Does Not Match", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const userData = {
        name: this.state.name,
        email: this.state.email,
      };

      this.props.dispatchRegister({
        name: userData.name,
        email: userData.email,
        referral_code: this.state.referral_code,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
      });
    }
  };
  render() {
    return (
      <div>
        <ToastContainer />
        <div className="reg-form">
          <h2>Maetrix</h2>
          <h3>Please Register</h3>
          <form onSubmit={this.handleRegForm} id="regForm">
            <div className="form-group">
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={this.state.name}
                onChange={this.handleUserInputReg}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleUserInputReg}
                required
              />
            </div>
            {/* <div className="form-group">
              <input
                type="text"
                placeholder="Referral Code"
                name="referral_code"
                value={this.state.referral_code}
                onChange={this.handleUserInputReg}
              />
            </div> */}
            <div className="form-group">
              <input
                type="password"
                className="active"
                id="password-field-reg"
                name="password"
                value={this.state.password}
                placeholder="Password"
                onChange={this.handleUserInputReg}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                required
              />
              <span className="show-pass">
                <i
                  id="pass-reg"
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.Toggle}
                ></i>
              </span>
            </div>

            <div className="form-group">
              <input
                type="password"
                className="active"
                id="password-field-reg1"
                name="confirm_password"
                value={this.state.confirm_password}
                placeholder="Confirm Password"
                onChange={this.handleUserInputReg}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                required
              />
              <span className="show-pass">
                <i
                  id="pass-reg1"
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.Toggle1}
                ></i>
              </span>
            </div>
            <div className="form-group">
              <ul>
                <li>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck">
                      Remember me
                    </label>
                  </div>
                </li>
                <li>
                  <button type="button" id="forgotsec">
                    Forgot Password
                  </button>
                </li>
              </ul>
            </div>
            <div className="sbm-sec">
              <button type="submit">Register</button>
            </div>
          </form>
          <button type="button" id="loginSec">
            Log In
          </button>
          <Link to="/terms-and-conditions">
            <p className="privacyPara">Terms & Conditions</p>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('state.authReducer', state.authReducer)
  return {
    getError: state.authReducer.error,
    listenRegister: state.authReducer.is_registered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchRegister: (data) => dispatch(registerUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Register));
