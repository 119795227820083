import {
  GET_DASHBOARD_BANNER,
  GET_DASHBOARD_BANNER_SUCCESS,
  GET_DASHBOARD_BANNER_ERROR,
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_ERROR,
  GET_LATEST_SERVICES,
  GET_LATEST_SERVICES_SUCCESS,
  GET_LATEST_SERVICES_ERROR,
  ADD_TRACKING_DATA,
  GET_DASHBOARD_AI_ANSWER,
  GET_DASHBOARD_AI_ANSWER_SUCCESS,
  GET_DASHBOARD_AI_ANSWER_ERROR,
  SAVE_DASHBOARD_AI_ANSWER,
  SAVE_DASHBOARD_AI_ANSWER_SUCCESS,
  SAVE_DASHBOARD_AI_ANSWER_ERROR,
  UPDATE_DASHBOARD_USER_GOAL,
  UPDATE_DASHBOARD_USER_GOAL_SUCCESS,
  UPDATE_DASHBOARD_USER_GOAL_ERROR,
  GET_DASHBOARD_USER_GOAL,
  GET_DASHBOARD_USER_GOAL_SUCCESS,
  GET_DASHBOARD_USER_GOAL_ERROR,
  GET_USER_DASHBOARD_AI,
  GET_USER_DASHBOARD_AI_SUCCESS,
  GET_USER_DASHBOARD_AI_ERROR,
  DELETE_USER_DASHBOARD_AI,
  DELETE_USER_DASHBOARD_AI_SUCCESS,
  DELETE_USER_DASHBOARD_AI_ERROR,
} from "./actionType";

export const getDashboardBanner = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_DASHBOARD_BANNER,
    payload: data,
  };
};

export const getDashboardBannerSuccess = (data) => {
  return {
    type: GET_DASHBOARD_BANNER_SUCCESS,
    payload: data,
  };
};

export const getDashboardBannerError = (error) => {
  return {
    type: GET_DASHBOARD_BANNER_ERROR,
    payload: error,
  };
};

export const getAllServices = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_ALL_SERVICES,
    payload: data,
  };
};

export const getAllServicesSuccess = (data) => {
  return {
    type: GET_ALL_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getAllServicesError = (error) => {
  return {
    type: GET_ALL_SERVICES_ERROR,
    payload: error,
  };
};

export const getLatestServices = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_LATEST_SERVICES,
    payload: data,
  };
};

export const getLatestServicesSuccess = (data) => {
  return {
    type: GET_LATEST_SERVICES_SUCCESS,
    payload: data,
  };
};

export const getLatestServicesError = (error) => {
  return {
    type: GET_LATEST_SERVICES_ERROR,
    payload: error,
  };
};

export const addTrackingData = (data) => {
  return {
    type: ADD_TRACKING_DATA,
    payload: data,
  };
};
export const getDashboardAIAnswer = (data) => {
  return {
    type: GET_DASHBOARD_AI_ANSWER,
    payload: data,
  };
};
export const getDashboardAIAnswerSuccess = (data) => {
  return {
    type: GET_DASHBOARD_AI_ANSWER_SUCCESS,
    payload: data,
  };
};

export const getDashboardAIAnswerError = (error) => {
  return {
    type: GET_DASHBOARD_AI_ANSWER_ERROR,
    payload: error,
  };
};

export const saveDashboardAIAnswer = (data) => {
  return {
    type: SAVE_DASHBOARD_AI_ANSWER,
    payload: data,
  };
};
export const saveDashboardAIAnswerSuccess = (data) => {
  return {
    type: SAVE_DASHBOARD_AI_ANSWER_SUCCESS,
    payload: data,
  };
};

export const saveDashboardAIAnswerError = (error) => {
  return {
    type: SAVE_DASHBOARD_AI_ANSWER_ERROR,
    payload: error,
  };
};

export const updateDashboardUserGoal = (data) => {
  return {
    type: UPDATE_DASHBOARD_USER_GOAL,
    payload: data,
  };
};
export const updateDashboardUserGoalSuccess = (data) => {
  return {
    type: UPDATE_DASHBOARD_USER_GOAL_SUCCESS,
    payload: data,
  };
};

export const updateDashboardUserGoalError = (error) => {
  return {
    type: UPDATE_DASHBOARD_USER_GOAL_ERROR,
    payload: error,
  };
};

export const getDashboardUserGoal = (data) => {
  return {
    type: GET_DASHBOARD_USER_GOAL,
    payload: data,
  };
};
export const getDashboardUserGoalSuccess = (data) => {
  return {
    type: GET_DASHBOARD_USER_GOAL_SUCCESS,
    payload: data,
  };
};

export const getDashboardUserGoalError = (error) => {
  return {
    type: GET_DASHBOARD_USER_GOAL_ERROR,
    payload: error,
  };
};

export const getUserDashboardAI = (data) => {
  return {
    type: GET_USER_DASHBOARD_AI,
    payload: data,
  };
};

export const getUserDashboardAISuccess = (data) => {
  return {
    type: GET_USER_DASHBOARD_AI_SUCCESS,
    payload: data,
  };
};

export const getUserDashboardAIError = (error) => {
  return {
    type: GET_USER_DASHBOARD_AI_ERROR,
    payload: error,
  };
};
export const deleteUserDashboardAI = (data) => {
  return {
    type: DELETE_USER_DASHBOARD_AI,
    payload: data,
  };
};

export const deleteUserDashboardAISuccess = (data) => {
  return {
    type: DELETE_USER_DASHBOARD_AI_SUCCESS,
    payload: data,
  };
};

export const deleteUserDashboardAIError = (error) => {
  return {
    type: DELETE_USER_DASHBOARD_AI_ERROR,
    payload: error,
  };
};
