import axios from "axios"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import styles from "./NewUser.module.css"

import { loginUser, newUserData } from "../../../store/actions"
import { useHistory, withRouter } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"

function NewUser(props, { loginUser }) {
	const [name, setName] = useState("")
	const [company, setCompany] = useState("")
	const [email, setEmail] = useState("")
	const [mobile, setMobile] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")
	const [city, setCity] = useState("")
	const [country, setCountry] = useState([])
	const [selectedContry, setSelectedCountry] = useState("United State of America")
	const [zip, setZip] = useState("")
	const [size, setSize] = useState("")
	const [color, setColor] = useState("")
	let urlParams = new URLSearchParams(window.location.search)

	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search)
		localStorage.setItem(
			"Checkout_Response",
			JSON.stringify({
				service_id: urlParams.get("service_id"),
				service_category: urlParams.get("service_category"),
				service_name: urlParams.get("service_name"),
			}),
			() => {
				console.log("Successfully saved")
			}
		)

		axios
			.get("https://restcountries.com/v2/all")
			.then((response) => {
				setCountry(response.data)
				let state = document.getElementById("inputState")
				state.options[239].selected = "selected"
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	const onPress = async (event) => {
		event.preventDefault()
		let urlParams = new URLSearchParams(window.location.search)

		if (password !== confirmPassword) {
			alert("Password and confirm password must be same")
		} else {
			await axios
				.post("https://admin.themaetrix.com/api/directPaymentForNewUser", {
					name,
					email,
					password,
					confirm_password: confirmPassword,
					service_id: urlParams.get("service_id"),
					service_category: urlParams.get("service_category"),
					service_name: urlParams.get("service_name"),
					company_name: company,
					dob: "04-11-1999",
					address1: address1,
					address2,
					city,
					country: selectedContry,
					mobile,
					post_code: zip,
					tshirt_size: size,
					tshirt_colour: color,
				})
				.then((response) => {
					//response.data?.session?.url
					localStorage.setItem("sessionId", response.data.result.session.id)
					localStorage.setItem("user_token", response.data.result.user_token)
					localStorage.setItem("service_id", urlParams.get("service_id"))
					localStorage.setItem("service_category", urlParams.get("service_category"))
					localStorage.setItem("service_name", urlParams.get("service_name"))
					window.open(response.data?.result.session.url)
				})
				.catch((error) => {
					console.log(error)
					alert(JSON.stringify(error.response.data.message))
				})
		}
	}

	return (
		<div className="">
			<ToastContainer />
			<div className="row p-5">
				<div className="col-md-7 m-auto">
					<div className="card">
						<div className="sign-up-form active card-body">
							<div className="text-center">
								<div className="row">
									<div className="col-md-5 m-auto">
										<img
											style={{ width: "100%" }}
											src="/assets/images/asethic-logo-1.png"
											alt=""
										></img>
									</div>
									<div className="col-md-12 m-auto">
										<p>
											We are so excited to have you here and to get you started with Aesthetic
											Entrepreneurs. Let's complete your registration now.
										</p>
									</div>
								</div>
							</div>

							<form onSubmit={onPress}>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label className="label">Full Name <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											type="input"
											placeholder="Full Name"
											value={name}
											onChange={(event) => {
												setName(event.target.value)
											}}
											required
										/>
									</div>

									<div className="form-group col-md-6 ">
										<label className="label">Company Name</label>
										<input
											className="input-update"
											type="input"
											placeholder="Company Name"
											value={company}
											onChange={(event) => setCompany(event.target.value)}
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Email <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											type="email"
											placeholder="@mail.com"
											value={email}
											onChange={(event) => setEmail(event.target.value)}
											required
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Mobile <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											type="number"
											placeholder="Mobile"
											value={mobile}
											minLength={10}
											onChange={(event) => setMobile(event.target.value)}
											required
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Password <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											placeholder="Password"
											name="password"
											id="password-field-reg"
											pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
											title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
											value={password}
											onChange={(event) => setPassword(event.target.value)}
											required
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Confirm Password <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											name="confirm_password"
											id="password-field-reg1"
											pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
											title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
											placeholder="Confirm Password"
											value={confirmPassword}
											onChange={(event) => setConfirmPassword(event.target.value)}
											required
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Address 1 <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											type="input"
											placeholder="Address 1"
											value={address1}
											onChange={(event) => setAddress1(event.target.value)}
										/>
									</div>
									<div className="form-group col-md-6">
										<label className="label">Address 2 <span style={{ color: "red" }}>*</span></label>
										<input
											className="input-update"
											type="input"
											placeholder="Address 2"
											value={address2}
											onChange={(event) => setAddress2(event.target.value)}
										/>
									</div>
								</div>

								<div className="form-row">
									<div className="form-group col-md-4">
										<label className="label" htmlFor="inputCity">
											City <span style={{ color: "red" }}>*</span>
										</label>
										<input
											className="input-update form-control"
											type="text"
											id="inputCity"
											value={city}
											onChange={(event) => setCity(event.target.value)}
										/>
									</div>
									<div className="form-group col-md-4">
										<label className="label" htmlFor="inputState">
											Country <span style={{ color: "red" }}>*</span>
										</label>
										<select
											id="inputState"
											name="inputState"
											className="form-control"
											value={selectedContry}
											onChange={(event) => setSelectedCountry(event.target.value)}
										>
											<option key="7868768768767867687687" value="United Kingdom">United Kingdom</option>
											{country.map((data) => (
												<option key={data.name} value={JSON.stringify(data.name)}>
													{data.name}
												</option>
											))}
										</select>
									</div>
									<div className="form-group col-md-4">
										<label className="label" htmlFor="inputZip">
											Postcode/Zip <span style={{ color: "red" }}>*</span>
										</label>
										<input
											className="input-update form-control"
											type="text"
											id="inputZip"
											value={zip}
											onChange={(event) => setZip(event.target.value)}
										/>
									</div>
								</div>

								<div className="form-row">
									<div className="form-group col-md-6">
										<label className="label" htmlFor="inputState">
											T-Shirt Size <span style={{ color: "red" }}>*</span>
										</label>
										<select
											id="inputState"
											className="form-control"
											value={size}
											onChange={(event) => setSize(event.target.value)}
										>
											<option defaultChecked>Choose...</option>
											<option value="S">Small</option>
											<option value="M">Medium</option>
											<option value="L">Large</option>
											<option value="XL">Extra Large</option>
											<option value="XXL">Double Extra Large</option>
										</select>
									</div>
									<div className="form-group col-md-6">
										<label className="label" htmlFor="inputState">
											Colour <span style={{ color: "red" }}>*</span>
										</label>
										<select
											id="inputState"
											className="form-control"
											value={color}
											onChange={(event) => setColor(event.target.value)}
										>
											<option defaultChecked>Choose...</option>
											<option value="red">Red</option>
											<option value="orange">Orange</option>
											<option value="white">White</option>
											<option value="black">Black</option>
											<option value="grey">Grey</option>
											<option value="pink">Pink</option>
											<option value="blue">Blue</option>
										</select>
									</div>
								</div>


								<div className="form-row">
									<div className="form-group col-md-12 m-auto pl-4">
										<span><h5 style={{ color: "red" }}>Instructions : </h5>	</span>
										<span><p><span style={{ color: "red" }}>1.</span> If you are an existing MAETRIX user then please log in  <a href={'https://themaetrix.com/direct-payment?service_category=' + urlParams.get("service_category") + '&service_id=' + urlParams.get("service_id") + '&service_name=' + urlParams.get("service_name")}> HERE </a>to purchase your course. </p> </span>
										<span><p><span style={{ color: "red" }}>2. </span> Field marked with <span style={{ color: "red" }}>` * `</span> are mandatory fields.</p>	</span>
										<span><p><span style={{ color: "red" }}>3 </span> Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.</p>	</span>
									</div>
								</div>

								<div className="form-row">
									<div className="form-group col-md-6 m-auto pl-4">
										<div className="form-group m-auto">
											<button className="mybutton">Proceed For Payment</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div >
		</div >
	)
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { loginUser, newUserData })(withRouter(NewUser))
