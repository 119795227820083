import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Header from "../../../layout/gaust/Header";
import VideoCategory from "./VideoCategory";
import Login from "../../gaust/login";
import VideoSlider from "./VideoSlider";
import { getVideosByCategoryId } from "../../../store/protected/videos/action";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class categoryDetails extends PureComponent {
  state = {
    showLoginModel: false,
  };

  responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  componentDidMount() {
    // document.title = "Videos Category :: The Maetrix";
    this.props.dispatchGetVideoCategory({
      category_name: this.props.match.params.name,
      token: this.props.token,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name !== this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      console.log("props token:  ", this.props.token);
      this.props.dispatchGetVideoCategory({
        category_name: this.props.match.params.name,
        token: this.props.token,
      });
    }
  }
  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  render() {
    return (
      <div>
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <VideoSlider />
        <div className="all-videos">
          <VideoCategory />

          {this.props?.videoListByCategory?.data?.map(
            (vidList, vidIndex) =>
              vidList?.videos?.length > 0 && (
                <React.Fragment key={vidIndex}>
                  <div className="video-row">
                    <div className="container">
                      <div className="video-row-header">
                        <h2>{vidList.category_name}</h2>
                      </div>

                      <div className="video-slider">
                        <div className="row">
                          {vidList?.videos?.map((videos, vList) => (
                            <div
                              key={vList}
                              className="col-lg-4 col-md-6 col-sm-6"
                            >
                              <div className="video-sld-box">
                                <div className="video-sld-img">
                                  <img alt="Banner" src={videos.banner_url} />
                                </div>
                                <div className="video-sld-cnt">
                                  {
                                    <Link
                                      to={`/video/${videos.category_title_url}/${videos.video_title_url}`}
                                    >
                                      <p className="right-date">
                                        {videos.created_at}
                                      </p>
                                      <div className="video-sld-cnt-mid">
                                        <img
                                          alt="play-btn"
                                          src="/assets/images/Play-btn.png"
                                        />
                                        <h4>
                                          <span>
                                            {videos.video_type === 0 &&
                                            (videos.is_subscribed === true ||
                                              videos.is_subscribed === false)
                                              ? "Free"
                                              : videos.video_type === 1 &&
                                                videos.is_subscribed === true
                                              ? "View Video"
                                              : `£ ${videos.fee}`}
                                          </span>
                                        </h4>
                                        <div className="video-sld-left">
                                          <h5>{videos.video_title}</h5>
                                        </div>
                                        <div className="video-sld-right">
                                          <p>{videos.duration}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  }
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
        </div>
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    videoListByCategory: state.videosReducer?.videoListByCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetVideoCategory: (data) => dispatch(getVideosByCategoryId(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(categoryDetails));
