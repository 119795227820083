import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSE_BY_ID_ERROR,
  GET_COURSES_BY_CATEGORY_ID,
  GET_COURSES_BY_CATEGORY_ID_SUCCESS,
  GET_COURSES_BY_CATEGORY_ID_ERROR,
  CLEAR_COURSE_AI_ANSWER,
  ENROLL_TO_SERVICE,
  ENROLL_TO_SERVICE_SUCCESS,
  ENROLL_TO_SERVICE_ERROR,
  SAVE_PAUSE_TIME,
  SAVE_PAUSE_TIME_SUCCESS,
  SAVE_PAUSE_TIME_ERROR,
  GET_PAUSE_TIME_BY_LESSION_ID,
  GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS,
  GET_PAUSE_TIME_BY_LESSION_ID_ERROR,
  GET_PROGRESS_BY_ID,
  GET_PROGRESS_BY_ID_SUCCESS,
  GET_PROGRESS_BY_ID_ERROR,
  ADD_TO_FAVOURITE,
  ADD_TO_FAVOURITE_SUCCESS,
  ADD_TO_FAVOURITE_ERROR,
  MARK_AS_COMPLETE,
  MARK_AS_COMPLETE_SUCCESS,
  MARK_AS_COMPLETE_ERROR,
  IS_LIKED,
  IS_LIKED_SUCCESS,
  IS_LIKED_ERROR,
  LATEST_SERVICE,
  LATEST_SERVICE_SUCCESS,
  LATEST_SERVICE_ERROR,
  GET_COURSE_AI_ANSWER,
  GET_COURSE_AI_ANSWER_SUCCESS,
  GET_COURSE_AI_ANSWER_ERROR,
  SAVE_COURSE_AI_ANSWER,
  SAVE_COURSE_AI_ANSWER_SUCCESS,
  SAVE_COURSE_AI_ANSWER_ERROR,
} from "./actionTypes";

export const getCourse = (data) => {
  //consolA.log('datadatadata', data)
  return {
    type: GET_COURSE,
    payload: data,
  };
};

export const getPopularCourse = (data) => {
  //consolA.log('datadatadata', data)
  return {
    type: GET_COURSE,
    payload: data,
  };
};

export const getCourseSuccess = (data) => {
  return {
    type: GET_COURSE_SUCCESS,
    payload: data,
  };
};

export const getCourseError = (error) => {
  return {
    type: GET_COURSE_ERROR,
    payload: error,
  };
};

export const getCourseById = (data) => {
  return {
    type: GET_COURSE_BY_ID,
    payload: data,
  };
};

export const getCourseByIdSuccess = (data) => {
  return {
    type: GET_COURSE_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getCourseByIdError = (error) => {
  return {
    type: GET_COURSE_BY_ID_ERROR,
    payload: error,
  };
};

export const getCourseAIAnswer = (data) => {
  return {
    type: GET_COURSE_AI_ANSWER,
    payload: data,
  };
};

export const getCourseAIAnswerSuccess = (data) => {
  return {
    type: GET_COURSE_AI_ANSWER_SUCCESS,
    payload: data,
  };
};

export const getCourseAIAnswerError = (error) => {
  return {
    type: GET_COURSE_AI_ANSWER_ERROR,
    payload: error,
  };
};

export const saveCourseAIAnswer = (data) => {
  return {
    type: SAVE_COURSE_AI_ANSWER,
    payload: data,
  };
};

export const saveCourseAIAnswerSuccess = (data) => {
  return {
    type: SAVE_COURSE_AI_ANSWER_SUCCESS,
    payload: data,
  };
};

export const saveCourseAIAnswerError = (error) => {
  return {
    type: SAVE_COURSE_AI_ANSWER_ERROR,
    payload: error,
  };
};
export const clearAIAnswer = () => {
  return {
    type: CLEAR_COURSE_AI_ANSWER,
    payload: null,
  };
};
export const getCoursesByCategoryId = (data) => {
  return {
    type: GET_COURSES_BY_CATEGORY_ID,
    payload: data,
  };
};

export const getCoursesByCategoryIdSuccess = (data) => {
  return {
    type: GET_COURSES_BY_CATEGORY_ID_SUCCESS,
    payload: data,
  };
};

export const getCoursesByCategoryIdError = (error) => {
  return {
    type: GET_COURSES_BY_CATEGORY_ID_ERROR,
    payload: error,
  };
};

export const enrollToService = (data) => {
  return {
    type: ENROLL_TO_SERVICE,
    payload: data,
  };
};

export const enrollToServiceSuccess = (data) => {
  return {
    type: ENROLL_TO_SERVICE_SUCCESS,
    payload: data,
  };
};

export const enrollToServiceError = (error) => {
  return {
    type: ENROLL_TO_SERVICE_ERROR,
    payload: error,
  };
};

export const savePauseTime = (data) => {
  return {
    type: SAVE_PAUSE_TIME,
    payload: data,
  };
};

export const savePauseTimeSuccess = (data) => {
  return {
    type: SAVE_PAUSE_TIME_SUCCESS,
    payload: data,
  };
};

export const savePauseTimeError = (error) => {
  return {
    type: SAVE_PAUSE_TIME_ERROR,
    payload: error,
  };
};

export const getPauseTimebyLessonId = (data) => {
  //console.log('akshay', data)
  return {
    type: GET_PAUSE_TIME_BY_LESSION_ID,
    payload: data,
  };
};

export const getPauseTimebyLessonIdSuccess = (data) => {
  return {
    type: GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS,
    payload: data,
  };
};

export const getPauseTimebyLessonIdError = (error) => {
  return {
    type: GET_PAUSE_TIME_BY_LESSION_ID_ERROR,
    payload: error,
  };
};

export const getProgressById = (data) => {
  return {
    type: GET_PROGRESS_BY_ID,
    payload: data,
  };
};

export const getProgressByIdSuccess = (data) => {
  return {
    type: GET_PROGRESS_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getProgressByIdError = (error) => {
  return {
    type: GET_PROGRESS_BY_ID_ERROR,
    payload: error,
  };
};

export const addToFavourite = (data) => {
  //console.log('datadatadata', data)
  return {
    type: ADD_TO_FAVOURITE,
    payload: data,
  };
};

export const addToFavouriteSuccess = (data) => {
  return {
    type: ADD_TO_FAVOURITE_SUCCESS,
    payload: data,
  };
};

export const addToFavouriteError = (error) => {
  return {
    type: ADD_TO_FAVOURITE_ERROR,
    payload: error,
  };
};

export const markAsComplete = (data) => {
  //console.log('datadatadata', data)
  return {
    type: MARK_AS_COMPLETE,
    payload: data,
  };
};

export const markAsCompleteSuccess = (data) => {
  return {
    type: MARK_AS_COMPLETE_SUCCESS,
    payload: data,
  };
};

export const markAsCompleteError = (error) => {
  return {
    type: MARK_AS_COMPLETE_ERROR,
    payload: error,
  };
};

export const isLike = (data) => {
  //console.log('datadatadata', data)
  return {
    type: IS_LIKED,
    payload: data,
  };
};

export const isLikeSuccess = (data) => {
  return {
    type: IS_LIKED_SUCCESS,
    payload: data,
  };
};

export const isLikeError = (error) => {
  return {
    type: IS_LIKED_ERROR,
    payload: error,
  };
};

export const isLatestSuccess = (data) => {
  //console.log('datadatadata', data)
  return {
    type: LATEST_SERVICE,
    payload: data,
  };
};

export const isLatestSuccessSuccess = (data) => {
  return {
    type: LATEST_SERVICE_SUCCESS,
    payload: data,
  };
};

export const isLatestSuccessError = (error) => {
  return {
    type: LATEST_SERVICE_ERROR,
    payload: error,
  };
};
