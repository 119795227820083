import React, { PureComponent } from "react";

import Login from "../../gaust/login";
import PodcastSubscriptionCheck from "../podcasts/PodcastSubscriptionCheck";
import EmbededPop from "../podcasts/embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactHtmlParser from "react-html-parser";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { isLatestSuccess } from "../../../store/protected/courses/action";

class LetestPodcast extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    playing: true,
    showLoginModel: false,
    SubscriptionName: "",
    podcastUrl: "",
    podcastData: null,
  };

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  onPlay = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  onPlayLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  podcastCondition = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  goToLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  notSubscribed = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  embeded = (data) => {
    this.setState({
      podcastData: data,
    });
    window.$("#embededPupUp").modal("show");
  };

  sharePop = (url) => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.podcast_category_url +
      "/" +
      url?.podcast_title_url+qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };

  playAudio = (service_id) => {
    this.props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: this.props.token,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props?.PodcastList?.length > 0 && (
          <>
            <EmbededPop data={this.state.podcastData} />
            <SharePopUpModel url={this.state.podcastUrl} />
            <PodcastSubscriptionCheck
              token={this.props.token}
              history={this.props.history}
              name={this.state?.SubscriptionName}
            />
            <div className="podcast-row">
              <div className="container">
                <div className="podcast-row-header">
                  <h2>Latest Podcasts</h2>
                  <Link to="/podcasts" className="right-link">
                    View All{" "}
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
                <div className="podcast-list">
                  {this.props?.PodcastList?.length > 0 && (
                    <OwlCarousel
                      className="owl-theme"
                      margin={10}
                      items={3}
                      responsive={this.state.responsive}
                    >
                      {this.props?.PodcastList.map((podcast, index) => (
                        <div className="podcast-list" key={index}>
                          <div className="item">
                            <div className="music-box">
                              <img
                                alt="img"
                                className="audio-img"
                                src={podcast?.banner_url}
                              />
                              {!this.props.token ? (
                                <div
                                  onClick={this.podcastCondition}
                                  className="demoAudio"
                                >
                                  {" "}
                                  <AudioPlayer
                                    src=""
                                    onPlayError={this.onPlayLogin}
                                  />{" "}
                                </div>
                              ) : podcast.is_subscribed == 1 ? (
                                <AudioPlayer
                                  src={podcast.podcast_url}
                                  onPlay={() => this.playAudio(podcast.id)}
                                  playing={false}
                                />
                              ) : (
                                <div
                                  onClick={() =>
                                    this.notSubscribed(podcast.tag_name)
                                  }
                                  className="demoAudio"
                                >
                                  <div
                                    onClick={this.onPlay}
                                    className="demoAudio"
                                  >
                                    <AudioPlayer
                                      src={podcast.podcast_url}
                                      playing={false}
                                      defaultDuration={podcast.duration}
                                      defaultCurrentTime="00:00"
                                      onPlayError={() =>
                                        this.onPlay(podcast.tag_name)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="audio-cnt">
                                <h2>{podcast?.podcast_title}</h2>
                                <div className="hide-audio-cnt">
                                  {ReactHtmlParser(podcast?.description)}
                                </div>
                                <h6 className="btm-brd-hide">
                                  <span></span>
                                </h6>
                              </div>
                              <div className="audio-action">
                                {!this.props.token ? (
                                  <button onClick={this.goToLogin}>
                                    <img
                                      alt="icon"
                                      src="/assets/images/download-icon.png"
                                    />
                                  </button>
                                ) : podcast.is_subscribed == 1 ? (
                                  <a href={podcast.podcast_url} download>
                                    <button>
                                      <img
                                        alt="icon"
                                        src="/assets/images/download-icon.png"
                                      />
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.notSubscribed(podcast.tag_name)
                                    }
                                  >
                                    <img
                                      alt="icon"
                                      src="/assets/images/download-icon.png"
                                    />
                                  </button>
                                )}

                                {!this.props.token ? (
                                  <button onClick={this.goToLogin}>
                                    <img
                                      alt="icon"
                                      src="/assets/images/share-icon.png"
                                    />
                                  </button>
                                ) : podcast.is_subscribed == 1 ? (
                                  <button
                                    onClick={() => this.sharePop(podcast)}
                                  >
                                    <img
                                      alt="icon"
                                      src="/assets/images/share-icon.png"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.notSubscribed(podcast.tag_name)
                                    }
                                  >
                                    <img
                                      alt="icon"
                                      src="/assets/images/share-icon.png"
                                    />
                                  </button>
                                )}

                                {!this.props.token ? (
                                  <button onClick={this.goToLogin}>
                                    <img
                                      alt="icon"
                                      src="/assets/images/coding-icon.png"
                                    />
                                  </button>
                                ) : podcast.is_subscribed == 1 ? (
                                  <button onClick={() => this.embeded(podcast)}>
                                    <img
                                      alt="icon"
                                      src="/assets/images/coding-icon.png"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.notSubscribed(podcast.tag_name)
                                    }
                                  >
                                    <img
                                      alt="icon"
                                      src="/assets/images/coding-icon.png"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </div>
            {this.state.showLoginModel === true && <Login />}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PodcastList: state?.dashboardReducer?.services?.podcasts,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LetestPodcast));
