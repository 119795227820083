import React, { PureComponent, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    getCoursesByCategoryId,
    getCourse,
  } from "../../../store/protected/courses/action";

//const PopularTopics = (props) => {
    class PopularTopics extends React.Component {
    state = {
        settings : {
            className: "center",
            dots: true,
            //centerMode: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 6,
            speed: 500,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
        },
        showLoginModel: false,
        selectedTab: 1
      };

      componentDidMount() {
        this.props.dispatchCoursesByCategoryId({
          id: this.props.match.params.name,
        });
    
        this.props.dispatchCourse({
          token: this.props?.token,
        });
      }
    
      componentDidUpdate(prevProps) {
        if (
          prevProps?.match?.params?.name != this.props?.match?.params?.name &&
          this.props?.match?.params?.name != null
        ) {
          this.props.dispatchCoursesByCategoryId({
            id: this.props.match.params.name,
          });
        }
      }

 render() {
        return (
        <section className="topics-popular my-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <h2>Popular Topics </h2>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="card-slider-new-1">
                    <Slider {...this.state.settings}>
                        {this.props?.getCourses?.data?.map((list, index) => (
                            <div className="col-lg-12 pr-5">
                                <div className="card w-100">
                                    <div className="card-body">
                                    <Link to={`/course/${list?.category_title_url}`}>
                                        <h5 className="card-title"><img alt="img" className="topics-image" src={list?.category_icon_url}/> {list?.category_name} </h5>
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}
}

const mapStateToProps = (state) => {
    return {
        services: state.dashboardReducer.services,
        getCourses: state.coursesReducer.courses,
        token: state.authReducer?.user?.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchServicesToken: (data) => dispatch(getAllServices(data)),
        dispatchCourse: (data) => dispatch(getCourse(data)),
        dispatchCoursesByCategoryId: (data) =>
       dispatch(getCoursesByCategoryId(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PopularTopics));
