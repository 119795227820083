import {
  GET_ALL_ACTIVITY,
  GET_ALL_ACTIVITY_SUCCESS,
  GET_ALL_ACTIVITY_ERROR,
} from "./actionType";
const initialState = {
  error: null,
  loading: null,
  allActivity: null,
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ACTIVITY:
      state = {
        error: null,
        loading: true,
        allActivity: null,
      };
      break;

    case GET_ALL_ACTIVITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        allActivity: action.payload,
      };
      break;

    case GET_ALL_ACTIVITY_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        allActivity: null,
      };
      break;

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default activityReducer;
