import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSE_BY_ID_ERROR,
  GET_COURSES_BY_CATEGORY_ID,
  GET_COURSES_BY_CATEGORY_ID_SUCCESS,
  GET_COURSES_BY_CATEGORY_ID_ERROR,
  ENROLL_TO_SERVICE,
  ENROLL_TO_SERVICE_SUCCESS,
  ENROLL_TO_SERVICE_ERROR,
  SAVE_PAUSE_TIME,
  SAVE_PAUSE_TIME_SUCCESS,
  SAVE_PAUSE_TIME_ERROR,
  GET_PAUSE_TIME_BY_LESSION_ID,
  GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS,
  GET_PAUSE_TIME_BY_LESSION_ID_ERROR,
  GET_PROGRESS_BY_ID,
  GET_PROGRESS_BY_ID_SUCCESS,
  GET_PROGRESS_BY_ID_ERROR,
  ADD_TO_FAVOURITE,
  ADD_TO_FAVOURITE_SUCCESS,
  ADD_TO_FAVOURITE_ERROR,
  MARK_AS_COMPLETE,
  MARK_AS_COMPLETE_SUCCESS,
  MARK_AS_COMPLETE_ERROR,
  IS_LIKED,
  IS_LIKED_SUCCESS,
  IS_LIKED_ERROR,
  LATEST_SERVICE,
  LATEST_SERVICE_SUCCESS,
  LATEST_SERVICE_ERROR,
  GET_COURSE_AI_ANSWER,
  GET_COURSE_AI_ANSWER_SUCCESS,
  GET_COURSE_AI_ANSWER_ERROR,
  SAVE_COURSE_AI_ANSWER,
  SAVE_COURSE_AI_ANSWER_SUCCESS,
  SAVE_COURSE_AI_ANSWER_ERROR,
  CLEAR_COURSE_AI_ANSWER,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: null,
  courses: null,
  courseDescription: null,
  coursesList: null,
  isTimeSave: false,
  getPauseData: null,
  getProgress: null,
  isFavourite: false,
  isComplete: false,
  isLike: null,
  isLetest: null,
  aiAnswer: null,
  loadingChat: false,
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE:
      state = {
        error: null,
        loading: true,
        courses: null,
      };
      break;
    case GET_COURSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        courses: action.payload,
      };
      break;
    case GET_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
        courses: null,
        error: action.payload,
      };
      break;
    case GET_COURSE_BY_ID:
      state = {
        ...state,
        loading: true,
        error: null,
        courseDescription: null,
      };
      break;
    case GET_COURSE_BY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        courseDescription: action.payload,
      };
      break;
    case GET_COURSE_BY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        courseDescription: null,
      };
      break;

    case GET_COURSES_BY_CATEGORY_ID:
      state = {
        ...state,
        loading: true,
        error: null,
        coursesList: null,
      };
      break;
    case GET_COURSES_BY_CATEGORY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        coursesList: action.payload,
      };
      break;
    case GET_COURSES_BY_CATEGORY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        coursesList: null,
      };
      break;
    case ENROLL_TO_SERVICE:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case ENROLL_TO_SERVICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;
    case ENROLL_TO_SERVICE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case SAVE_PAUSE_TIME:
      state = {
        ...state,
        loading: true,
        error: null,
        isTimeSave: false,
      };
      break;
    case SAVE_PAUSE_TIME_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isTimeSave: true,
      };
      break;
    case SAVE_PAUSE_TIME_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isTimeSave: false,
      };
      break;
    case GET_PAUSE_TIME_BY_LESSION_ID:
      state = {
        ...state,
        loading: true,
        error: null,
        getPauseData: null,
      };
      break;
    case GET_PAUSE_TIME_BY_LESSION_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        getPauseData: action.payload,
      };
      break;
    case GET_PAUSE_TIME_BY_LESSION_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        getPauseData: null,
      };
      break;
    case GET_PROGRESS_BY_ID:
      state = {
        ...state,
        loading: true,
        error: null,
        getProgress: null,
      };
      break;
    case GET_PROGRESS_BY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        getProgress: action.payload,
      };
      break;
    case GET_PROGRESS_BY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        getProgress: null,
      };
      break;
    case ADD_TO_FAVOURITE:
      state = {
        ...state,
        loading: true,
        error: null,
        isFavourite: false,
      };
      break;
    case ADD_TO_FAVOURITE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isFavourite: action.payload,
      };
      break;
    case ADD_TO_FAVOURITE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isFavourite: false,
      };
      break;
    case MARK_AS_COMPLETE:
      state = {
        ...state,
        loading: true,
        error: null,
        isComplete: false,
      };
      break;
    case MARK_AS_COMPLETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isComplete: action.payload,
      };
      break;
    case MARK_AS_COMPLETE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isComplete: false,
      };
      break;
    case IS_LIKED:
      state = {
        ...state,
        loading: true,
        error: null,
        isLike: null,
      };
      break;
    case IS_LIKED_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isLike: action.payload,
      };
      break;
    case IS_LIKED_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isLike: null,
      };
      break;
    case LATEST_SERVICE:
      state = {
        ...state,
        loading: true,
        error: null,
        isLetest: null,
      };
      break;

    case LATEST_SERVICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isLetest: action.payload,
      };
      break;

    case LATEST_SERVICE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isLetest: null,
      };
      break;
    case SAVE_COURSE_AI_ANSWER:
      state = {
        ...state,
        loadingChat: true,
        error: null,
        aiAnswer: null,
      };
      break;
    case SAVE_COURSE_AI_ANSWER_SUCCESS:
      state = {
        ...state,
        loadingChat: false,
        error: null,
        aiAnswer: {
          answer: action.payload.answer ? action.payload.answer : null,
          question: action.payload.question ? action.payload.question : null,
        },
      };
      break;

    case SAVE_COURSE_AI_ANSWER_ERROR:
      state = {
        ...state,
        loadingChat: false,
        error: action.payload,
        aiAnswer: null,
      };
      break;
    case CLEAR_COURSE_AI_ANSWER:
      state = {
        ...state,
        loadingChat: false,
        aiAnswer: null,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default coursesReducer;
