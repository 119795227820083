import React, { PureComponent, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//import { BASE_URL } from "../../helpers/Constants";

import { logout } from "../../../store/protected/auth/actions";

const Header = (props) => {
  const [stickyHeader, setStickyHeader] = useState({
    isSticky: false,
    offset: 0,
  });
  const [btnClicked, setBtnClicked] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };

    this.getNotification();
    this.getCartItems();
  }, []);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setStickyHeader({ sticky: { isSticky: true, offset: elHeight } });
    } else {
      setStickyHeader({ sticky: { isSticky: false, offset: 0 } });
    }
  };

  const loginBtnClicked = (event) => {
    //console.log('login button clicked in header.js');
    setBtnClicked(true);
    props.hitHome(true);
    /*
        this.setState(
          {
            btnClicked: true,
          },
          () => {
            if (this.props) {
              this.props.hitHome(this.state.btnClicked);
            }
          }
        );
        */
  };

  const onLogout = (event) => {
    props.dispatchLogout({
      token: props?.token,
      history: props.history,
    });
  };

  return (
    <div className="top-header-fixed">
      <div
        id="sticky-header"
        className={`${
          stickyHeader.isSticky
            ? "header-sec header-sticky"
            : "header-sec header-sticky"
        }`}
        ref={headerRef}
      >
        <nav className="navbar navbar-expand-lg" id="navbar1">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img
                src={"/assets/new_layout/img/logo.png"}
                className="img-fluid"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse me-auto"
              id="navbarSupportedContent"
            >
              <form className="d-flex">
                <img
                  src={"/assets/new_layout/img/search.png"}
                  className="mt-2 pr-1"
                />
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#">
                    <img src={"/assets/new_layout/img/notification.png"} />
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/cart" className="nav-link">
                    <img src={"/assets/new_layout/img/Cart.png"} />
                  </Link>
                </li>
                {/* <li className="nav-item">
                                    <a className="nav-link" href="#"><img src={"./assets/new_layout/img/profile.png"} /></a>
                                </li>
                                 
                                <li>
                                   
                                    <button type="button" onClick={loginBtnClicked} className="loginbutton">
                                        Log In / Register
                                    </button>
                                </li>*/}
              </ul>

              <div className="header-top header-top-new">
                <div className="header-top-right">
                  <ul>
                    <li className="profile">
                      <button
                        type="button"
                        className="dropdown-toggle dropdown-class"
                        data-toggle="dropdown"
                      >
                        <img
                          className="image-margin"
                          src={
                            props.getUpdateStatus?.updateUser?.data
                              ?.profile_image
                              ? this.props.getUpdateStatus?.updateUser?.data
                                  ?.profile_image
                              : "/assets/images/profile-img.jpg"
                          }
                          alt=""
                        ></img>
                      </button>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/user/myaccount">
                          My Account
                        </Link>
                        <Link className="dropdown-item" to="/user-settings">
                          Profile
                        </Link>
                        <Link className="dropdown-item" to="/subscriptions">
                          My Subscriptions
                        </Link>
                        <Link className="dropdown-item" to="/myactivity">
                          My Favourites
                        </Link>
                        <Link className="dropdown-item" to="/support">
                          Support & Feedback
                        </Link>
                        <a
                          className="dropdown-item"
                          onClick={onLogout}
                          style={{ borderBottom: "0px" }}
                        >
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item mx-2">
                  <Link
                    className={
                      window.location.pathname.includes("dashboard") ||
                      window.location.pathname.includes("dashboard")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item mx-2">
                  <Link
                    className={
                      window.location.pathname.includes("courses") ||
                      window.location.pathname.includes("courses")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/courses"
                  >
                    Programme
                  </Link>
                </li>
                <li className="nav-item mx-2">
                  <Link
                    className={
                      window.location.pathname.includes("calendar")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={"/calendar"}
                  >
                    Calendar
                  </Link>
                </li>

                <li className="nav-item mx-2">
                  <Link className="nav-link" to="/videos">
                    AE Shorts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/podcasts">
                    Podcasts
                  </Link>
                </li>
                <li className="nav-item mx-2">
                  <Link className="nav-link" to="/blogs">
                    Blogs
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/support">
                    Support & Feedback
                  </Link>
                </li> */}
                {/* <li className="nav-item ml-4">
                  <a
                    className={
                      window.location.pathname.includes("subscriptions") ||
                      window.location.pathname.includes("subscriptions")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="subscriptions"
                  >
                    Subscription
                  </a>
                </li> */}
                <li className="nav-item mx-2">
                  <Link
                    className={
                      window.location.pathname.includes("member") ||
                      window.location.pathname.includes("/member/")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/members"
                  >
                    Members
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    getUserDetail: state.authReducer?.user?.user,
    getUpdateStatus: state.settingReducer,
    cartItems: state?.cartReducer?.cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLogout: (data) => dispatch(logout(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
