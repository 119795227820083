import { BASE_URL } from "../../../helpers/Constants";

import {
  GET_ROADMAP_ASSIGNS,
  UPDATE_ROADMAP_ASSIGNS,
  GET_ROADMAP_LESSONS,
} from "./actionTypes";
import {
  getRoadmapAssignsSuccess,
  getRoadmapAssignsError,
  updateRoadmapAssignsSuccess,
  updateRoadmapAssignsError,
  getRoadmapLessonsSuccess,
  getRoadmapLessonsError,
} from "./actions";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getRoadmapLessons(data) {
  try {
    const response = yield fetch(BASE_URL + `api/roadmap/lessons`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(getRoadmapLessonsSuccess(response.result));
    } else {
      yield put(getRoadmapLessonsError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getRoadmapLessonsError(error.message));
  }
}
function* getRoadmapAssigns(data) {
  try {
    const response = yield fetch(BASE_URL + `api/roadmap/assigns`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(getRoadmapAssignsSuccess(response.result));
    } else {
      yield put(getRoadmapAssignsError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getRoadmapAssignsError(error.message));
  }
}

function* updateRoadmapAssigns(data) {
  try {
    let payload = {
      lesson_id: data?.payload?.lesson_id,
      status: data?.payload?.status,
    };
    const response = yield fetch(BASE_URL + `api/roadmap`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(updateRoadmapAssignsSuccess(response.result));
    } else {
      yield put(updateRoadmapAssignsError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(updateRoadmapAssignsError(error.message));
  }
}
export function* watchGetRoadmapLessons() {
  yield takeEvery(GET_ROADMAP_LESSONS, getRoadmapLessons);
}
export function* watchGetRoadmapAssigns() {
  yield takeEvery(GET_ROADMAP_ASSIGNS, getRoadmapAssigns);
}
export function* watchUpdateRoadmapAssigns() {
  yield takeEvery(UPDATE_ROADMAP_ASSIGNS, updateRoadmapAssigns);
}
function* roadmapSaga() {
  yield all([fork(watchGetRoadmapLessons)]);
  yield all([fork(watchGetRoadmapAssigns)]);
  yield all([fork(watchUpdateRoadmapAssigns)]);
}

export default roadmapSaga;
