import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  USER_UPDATE_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  NEW_USER_DATA,
} from "./actionTypes";

const initialState = {
  newUserData: null,
  error: null,
  loading: false,
  user: null,
  is_registered: false,
  login_Status: false,
  is_user_update: null,
  forgot_password_result: null,
  reset_password_status: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      state = {
        error: null,
        loading: true,
        login_Status: false,
        user: null,
        is_registered: false,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        login_Status: true,
        user: action.payload,
        is_registered: false,
      };
      break;
    case LOGIN_ERROR:
      state = {
        ...state,
        loading: false,
        login_Status: false,
        user: null,
        show_popup: false,
        error: action.payload,
      };
      break;
    case REGISTER:
      state = {
        error: null,
        loading: true,
        user: null,
        is_registered: false,
      };
      break;
    case REGISTER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: null,
        is_registered: true,
      };
      break;
    case REGISTER_ERROR:
      state = {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
        is_registered: false,
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        error: null,
        loading: true,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        ...state,
        loading: false,
        login_Status: false,
        show_popup: false,
        user: null,
      };
      break;
    case LOGOUT_ERROR:
      state = {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };
      break;

    case USER_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            ...action.payload,
          },
        },
        is_user_update: true,
      };
      break;
    case FORGOT_PASSWORD:
      state = {
        ...state,
        error: null,
        loading: true,
        forgot_password_result: null,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        forgot_password_result: action.payload,
      };
      break;
    case FORGOT_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
        forgot_password_result: null,
        error: action.payload,
      };
      break;

    case RESET_PASSWORD:
      state = {
        ...state,
        error: null,
        loading: true,
        reset_password_status: null,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        reset_password_status: action.payload,
      };
      break;
    case RESET_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
        reset_password_status: null,
        error: action.payload,
      };
      break;
    case NEW_USER_DATA:
      state = {
        ...state,
        newUserData: action.payload,
      };
      break;
    default:
      state = {
        ...state,
        // show_popup: false
      };
      break;
  }
  return state;
};

export default authReducer;
