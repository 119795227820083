import { combineReducers } from "redux";
import authReducer from "./protected/auth/reducer";
import coursesReducer from "./protected/courses/reducer";
import userReducer from "./protected/user-profile/reducer";
import settingReducer from "./protected/user-settings/reducer";
import subscriptionsListReducer from "./protected/subscription/reducer";
import cartReducer from "./protected/cart/reducer";
import bannerReducer from "./protected/banner/reducer";
import videosReducer from "./protected/videos/reducer";
import podcastReducer from "./protected/podcast/reducer";
import blogReducer from "./protected/blogs/reducer";
import dashboardReducer from "./protected/dashboard/reducer";
import memberReducer from "./protected/members/reducer";
import activityReducer from "./protected/my-activity/reducer";
import workBookReducer from "./protected/workbook/reducer";
import aiContentReducer from "./protected/ai-content/reducer";
import roadmapReducer from "./protected/roadmap/reducer";
// import forgetPasswordReducer from './data/forget-password/reducer';

const rootReducer = combineReducers({
  authReducer,
  coursesReducer,
  userReducer,
  settingReducer,
  subscriptionsListReducer,
  cartReducer,
  bannerReducer,
  videosReducer,
  podcastReducer,
  blogReducer,
  dashboardReducer,
  memberReducer,
  activityReducer,
  workBookReducer,
  aiContentReducer,
  roadmapReducer,
  // forgetPassword: forgetPasswordReducer
});

export default rootReducer;
