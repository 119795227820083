import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { subscriptionPayment } from "../../../store/protected/subscription/action";

import { ToastContainer, toast } from "react-toastify";

class CheckpoutPopUp extends Component {
  state = {
    services: [],
    card_number: "",
    cvc: "",
    exp_month: "",
    exp_year: "",
    name_on_card: "",
  };

  componentDidMount() {
    if (this.props?.cartItems?.cart_list) {
      this.setState({
        services:
          this.props?.cartItems?.cart_list?.length > 0
            ? this.props?.cartItems?.cart_list?.map((cart) => {
                return {
                  service_id: cart?.service_id,
                  service_category: cart?.service_category,
                  service_name: cart?.service_name,
                };
              })
            : [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.payment_done != this.props?.payment_done &&
      this.props.payment_done !== null &&
      this.props?.payment_done === true
    ) {
      toast.success("Payment Received Successfully ", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("checkoutForm").reset();
      window.$("#payModel").modal("hide");
      setTimeout(() => {
        this.props.history.push("/courses");
      }, 3000);
    }

    if (
      prevProps.isPaymentNotDone != this.props?.isPaymentNotDone &&
      this.props?.isPaymentNotDone !== null
    ) {
      toast.error(this.props?.isPaymentNotDone, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      document.getElementById("checkoutForm").reset();
      window.$("#payModel").modal("hide");
    }
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlePaymentForm = (event) => {
    event.preventDefault();
    this.props.dispatchSubscriptionPayment({
      token: this.props?.token,
      history: this.props.history,
      data: this?.state,
    });
  };

  render() {
    return (
      <div className="payment-pops">
        <ToastContainer />
        <div className="modal fade" id="payModel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pay-left-sec">
                      <div className="left-overlay"></div>
                      <div className="pay-left-cnt">
                        <h2>
                          Total Items: {this.props?.cartItems?.no_of_services}
                        </h2>
                        <ul>
                          <li className="popupli">
                            Total Fees: £ {this.props?.cartItems?.total_amount}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pay-right creditCardForm">
                      <h2>Payment Details</h2>
                      <form id="checkoutForm" onSubmit={this.handlePaymentForm}>
                        <div className="row">
                          <div className="col-sm-12">
                            <div
                              className="form-group cr-card"
                              id="card-number-field"
                            >
                              <label>Card Number</label>
                              <input
                                type="tel"
                                className="form-group"
                                placeholder="•••• •••• •••• ••••"
                                className="form-group"
                                title="Enter the 16 number credit card"
                                maxLength="18"
                                name="card_number"
                                onChange={this.handleChange}
                                required
                              />
                              <span className="card-icon">
                                <i
                                  className="fa fa-credit-card"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Exp Month</label>
                              <select
                                name="exp_month"
                                onChange={this.handleChange}
                                required
                              >
                                <option value="">Select</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Exp Year</label>
                              <select
                                name="exp_year"
                                onChange={this.handleChange}
                                required
                              >
                                <option value="">Select</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>CVV</label>
                              <input
                                type="tel"
                                className="form-group"
                                name="cvc"
                                maxLength="4"
                                placeholder="•••"
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Name on card</label>
                              <input
                                type="text"
                                name="name_on_card"
                                id="owner"
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sbm-btn">
                          <button type="submit">
                            Pay £ {this.props?.cartItems?.total_amount}{" "}
                          </button>
                          <button
                            type="button"
                            className="cancel"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPaymentDone: state.subscriptionsListReducer?.payment_done,
    isPaymentNotDone: state.subscriptionsListReducer?.error,
    cartItems: state.cartReducer?.cartItems,
    token: state.authReducer?.user?.token,
    payment_done: state.subscriptionsListReducer?.payment_done,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSubscriptionPayment: (data) => dispatch(subscriptionPayment(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckpoutPopUp));
//export default SubscriptionPop
