import firebase from "firebase";

// const config = {
//     apiKey: "AIzaSyAxgv8hiX8CIBEevQ8H1DWFcbKZLPUL_YI",
//     authDomain: "maetrix-chat.firebaseapp.com",
//     databaseURL: "https://maetrix-chat.firebaseio.com",
//     projectId: "maetrix-chat",
//     storageBucket: "maetrix-chat.appspot.com",
//     messagingSenderId: "29221068187",
//     appId: "1:29221068187:web:852f931e803b26525c953e",
// }

const config = {
  apiKey: "AIzaSyBlQlWyS-mn1MhHaJXE1Jei53Gpw8kcuLw",
  authDomain: "themaetrix-eda26.firebaseapp.com",
  databaseURL: "https://themaetrix-eda26.firebaseio.com",
  projectId: "themaetrix-eda26",
  storageBucket: "themaetrix-eda26.appspot.com",
  messagingSenderId: "645667848896",
  appId: "1:645667848896:web:b36e18910dcd24fd1b6325",
};

firebase.initializeApp(config);
export default firebase;
