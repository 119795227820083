import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

class CustomHeader extends Component {
  render() {
    return (
      <div className="custom-header">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <Link to="/">
                <img
                  alt="img"
                  src="/assets/new_layout/img/logo2.png"
                  className="logo-icon"
                />
              </Link>
            </div>
            <div className="col-6">
              <div className="custom-header-right">
                <Link to="/">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="custom-header">
      //   <div className="container">
      //     <Link to="/">
      //       <img
      //         alt="img"
      //         src="/assets/images/asethic-logo.png"
      //         className="logo-icon"
      //       />
      //     </Link>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomHeader));
//export default Customeheader
