import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import ReactPlayer from "react-player";
import screenfull from "screenfull";
import ReactHtmlParser from "react-html-parser";

import Header from "../../../layout/gaust/Header";
import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import SubscriptionPops from "../courses/subscriptionPops";
import {
  getCourseById,
  enrollToService,
  addToFavourite,
} from "../../../store/protected/courses/action";
import Login from "../../gaust/login";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SharePopUpModel from "../videos/SharedPopUpModel";

class CourseDescription extends Component {
  state = {
    url: this.props?.getCourseDescription?.course_preview_video,
    pip: false,
    playing: true,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    showSubscriptionModel: false,
    showLoginModel: false,
    enrollCheck: false,
    videoUrl: "",
    pageUrl: "",
  };

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  componentDidMount() {
    this.props.dispatchCourseById({
      id: this.props.match.params.id,
      token: this.props.token,
      history: this.props.history,
    });
    this.setState({
      ...this.state,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.getCourseDescription !== this.props?.getCourseDescription &&
      this.props?.getCourseDescription != null
    ) {
      this.setState({
        ...this.state,
        url: this.props?.getCourseDescription?.course_preview_video,
        getCourseById: this.props?.getCourseDescription,
      });
    }
    if (
      prevProps?.isFavourite !== this.props.isFavourite &&
      this.props.isFavourite != null
    ) {
      if (this.props.token) {
        this.props.dispatchCourseById({
          id: this.props.match.params.id,
          token: this.props.token,
          history: this.props.history,
        });
      } else {
        this.props.dispatchCourseById({
          id: this.props.match.params.id,
          history: this.props.history,
        });
      }
    }
  }

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    this.setState({ pip: false });
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleProgress = (state) => {
    if (state.playedSeconds >= 7 && this.state.playing === true) {
      this.handlePause();
      this.setState(
        {
          ...this.state,
          playing: false,
          controls: false,
          showSubscriptionModel: true,
        },
        () => {
          if (this.props?.token) {
            if (this.state?.getCourseById?.course_type === "1") {
              if (this.state?.getCourseById?.is_subscribed === 0) {
                window.$("#subsModel").modal("show");
              } else {
                alert(
                  "The preview ends here. Please Enroll to watch the course"
                );
              }
            } else {
              alert("The preview ends here. Please Enroll to watch the course");
            }
          } else {
            if (this.state?.getCourseById?.course_type === "1") {
              window.$("#subsModel").modal("show");
            } else {
              alert("The preview ends here. Please Enroll to watch the course");
            }
          }
        }
      );
    }
  };

  handleEnded = () => {
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  checkCondition = (event) => {
    if (this.props.token) {
      if (this.state?.getCourseById?.course_type === "0") {
        this.props.dispatchEnrollToService({
          id: this.state?.getCourseById?.id,
          name: this.state.getCourseById?.course_name,
          service_category: this.state?.getCourseById?.service_category,
          service_category_url: this.state?.getCourseById?.service_category_url,
          service_id: this.state?.getCourseById?.id,
          history: this.props.history,
          token: this.props.token,
        });
      } else {
        if (this.state?.getCourseById?.is_subscribed === 1) {
          this.props.dispatchEnrollToService({
            id: this.state?.getCourseById?.id,
            name: this.state.getCourseById?.course_name,
            service_category: this.state?.getCourseById?.service_category,
            service_id: this.state?.getCourseById?.id,
            history: this.props.history,
            token: this.props.token,
          });
        } else {
          this.setState(
            {
              ...this.state,
              showSubscriptionModel: true,
            },
            () => {
              window.$("#subsModel").modal("show");
            }
          );
        }
      }
    } else {
      if (this.state?.getCourseById?.course_type === "1") {
        this.setState(
          {
            ...this.state,
            showSubscriptionModel: true,
          },
          () => {
            window.$("#subsModel").modal("show");
          }
        );
      } else {
        this.setState(
          {
            showLoginModel: true,
          },
          () => {
            window.$("#login-model").modal("show");
          }
        );
      }
    }
  };

  ref = (player) => {
    this.player = player;
  };

  isFavourite = (id) => {
    if (this.props?.token) {
      this.props.dispatchIsFavourite({
        token: this.props.token,
        service_id: id,
        service_category: "course",
      });
    } else {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  goBuySubscription = (url) => {
    let str = "111" + this.props?.user?.user?.id + "111"; //userid=413
    let qrstr = "?q=" + str;
    this.setState({
      videoUrl: url,
      pageUrl: window.location.href + qrstr,
    });
    window.$("#shareModel").modal("show");
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <SharePopUpModel url={this.state.pageUrl} />
        {this.state.showSubscriptionModel === true && (
          <SubscriptionPops
            courseDescription={
              (this.state?.getCourseById !== null ||
                this.state?.getCourseById !== undefined) &&
              this.state?.getCourseById
            }
            token={this.props.token}
          />
        )}
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <div className="single-banner">
          <img alt="img" src={this.state?.getCourseById?.course_banner} />
          <div className="bnr-overlay"></div>
          <div className="container">
            <div className="banner-cap">
              <h1>
                {ReactHtmlParser(this.state?.getCourseById?.course_name)}{" "}
                {/* <span className="right-side">
                  <a href="#">
                    <img src={"/assets/new_layout/img/right-arrow.png"} /> Back
                  </a>
                </span> */}
              </h1>
              <p>
                {ReactHtmlParser(
                  this.state?.getCourseById?.short_description.substr(0, 200)
                )}
                ..<a href="#course-content">Read more</a>
              </p>
            </div>
          </div>
        </div>
        <div className="course-intro page-height">
          <div className="row">
            <div className="col-md-6">
              <div className="video-box">
                {/* <img alt="img" src={this.state?.getCourseById?.thumbnail_url}  /> */}

                {this.props?.getCourseDescription?.course_preview_video !=
                null ? (
                  <ReactPlayer
                    ref={this.ref}
                    className="react-player"
                    width="100%"
                    height="100%"
                    url={this.state.url}
                    pip={this.state.pip}
                    playing={this.state.playing}
                    controls={this.state.controls}
                    // light={this.state.light}
                    light={this.state?.getCourseById?.thumbnail_url}
                    loop={this.state.loop}
                    playbackRate={this.state.playbackRate}
                    volume={this.state.volume}
                    muted={this.state.muted}
                    onPlay={this.handlePlay}
                    onEnablePIP={this.handleEnablePIP}
                    onDisablePIP={this.handleDisablePIP}
                    onPause={this.handlePause}
                    onEnded={this.handleEnded}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                    config={{
                      file: { attributes: { controlsList: "no-download" } },
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : (
                  // this.state?.getCourseById?.thumbnail_url
                  <img
                    alt="img"
                    src={this.state?.getCourseById?.thumbnail_url}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="course-detail">
                <button
                  className={
                    this.state?.getCourseById?.is_favourite
                      ? "fav-btn2"
                      : "fav-btn"
                  }
                  onClick={() =>
                    this.isFavourite(this.state?.getCourseById?.id)
                  }
                >
                  <i className="fa fa-heart-o" aria-hidden="true"></i>
                </button>
                {this.state?.getCourseById?.is_enrolled === false && (
                  <h6>
                    {this.state?.getCourseById?.subscription_tag?.tag_name}
                  </h6>
                )}

                {this.state?.getCourseById?.is_enrolled === false &&
                  this.state?.getCourseById !== null && (
                    <h2>
                      {/* {this.state?.getCourseById?.course_type === 1 &&
                      this.state?.getCourseById?.is_independent === false &&
                      (this.state?.getCourseById?.is_subscribed === 0 ||
                        this.state?.getCourseById?.is_subscribed === 1)
                        ? ""
                        : this.state?.getCourseById?.is_subscribed === 0
                        ? ""
                        : "Free"} */}

                      {this.state?.getCourseById?.course_type === 1 &&
                      this.state?.getCourseById?.is_independent === false
                        ? `£${this.state?.getCourseById?.monthly_fee}`
                        : this.state?.getCourseById?.course_type === 1 &&
                          this.state?.getCourseById?.is_independent === true
                        ? `£${this.state?.getCourseById?.course_fee}`
                        : this.state?.getCourseById?.is_subscribed === 0
                        ? ""
                        : "Free"}
                    </h2>
                  )}

                {this.state?.getCourseById?.is_enrolled === true &&
                this.props.token ? (
                  <Link
                    to={`/course/${this.state.getCourseById?.course_category_url}/${this.state?.getCourseById?.id}/${this.state?.getCourseById?.course_title_url}/view-detail`}
                  >
                    <h5>Subscription</h5>
                    <h2>Free</h2>
                    <h3>
                      <span className="pointer" onClick={this.checkCondition}>
                        Let's Go!{" "}
                      </span>
                    </h3>{" "}
                  </Link>
                ) : (
                  <h3>
                    <button className="pointer" onClick={this.checkCondition}>
                      Let's Go!{" "}
                    </button>
                  </h3>
                )}
                <p className="rating1">
                  4.0{" "}
                  <img
                    src={"/assets/new_layout/img/rating.png"}
                    style={{ width: "100px !important" }}
                  />
                </p>
                <p id="course-content">
                  Duration : {this.state?.getCourseById?.course_duration}
                </p>
                <p>Last updated : {this.state?.getCourseById?.updated_at}</p>
                {/*<h4>About Course</h4>
                <p>
                  {ReactHtmlParser(
                    this.state?.getCourseById?.short_description
                  )}
                  </p>*/}
                {/*<p><span className="points1"><img src={"/assets/new_layout/img/Vector.png"} /> 116</span> <span className="points2">Loyalty points*</span></p>*/}
              </div>
            </div>
            {/* .single-video .vid-prw-row .video-view-cnt button.share */}
            <div className="col-md-12">
              <div className="inner-section-desc">
                <div class="single-video mb-2">
                  <div class="vid-prw-row">
                    <div class="video-view-cnt share-social">
                      {!this.props.token ? (
                        <button
                          className="share "
                          onClick={() =>
                            this.goBuySubscription(
                              this.state?.getCourseById?.course_title_url
                            )
                          }
                        >
                          Share{" "}
                          <i className="fa fa-share" aria-hidden="true"></i>
                        </button>
                      ) : (
                        <button
                          className="share "
                          onClick={() =>
                            this.goBuySubscription(
                              this.state?.getCourseById?.course_title_url
                            )
                          }
                        >
                          Share{" "}
                          <i className="fa fa-share" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <h4>About Course</h4>
                <p>
                  {ReactHtmlParser(
                    this.state?.getCourseById?.short_description
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="course-desc-list" id="course-list">
          {this.state?.getCourseById &&
            this.state?.getCourseById.chpaters.map((list, index) => (
              <span key={index}>
                <div className="course-cnt-box">
                  <div className="course-desc-head">
                    <h6>
                      <a
                        href={`#collapse${index}`}
                        data-toggle="collapse"
                        className="collapsible-link"
                      >
                        {list.chapter_name}
                      </a>
                    </h6>
                  </div>
                  <div id={`collapse${index}`} className="collapse">
                    <div className="course-desc-box">
                      <ul>
                        {list.lessons.map((course, index) => (
                          <span key={index}>
                            <li>
                              <h5 className="duration">{course?.duration}</h5>
                              <p>
                                {this.props.login_Status === true ? (
                                  <Link
                                    to={`/course/${this.state.getCourseById?.course_category_url}/${this.state?.getCourseById?.id}/${this.state?.getCourseById?.course_title_url}/view-detail?lessionId=${course.id}`}
                                  >
                                    <strong>
                                      Lesson-{course?.lesson_number} :{" "}
                                    </strong>
                                    {course?.lesson_name}
                                  </Link>
                                ) : (
                                  <p>
                                    <strong>
                                      Lesson-{course?.lesson_number} :{" "}
                                    </strong>
                                    {course?.lesson_name}
                                  </p>
                                )}
                                {course?.is_preview === 1 ? (
                                  <span className="prev-cnt">Preview </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </li>
                          </span>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            ))}
        </div>
        {this.state.showLoginModel === true && <Login />}
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getCourseDescription: state.coursesReducer.courseDescription,
    isFavourite: state?.coursesReducer?.isFavourite,
    login_Status: state.authReducer?.login_Status,
    user: state.authReducer?.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCourseById: (data) => dispatch(getCourseById(data)),
    dispatchEnrollToService: (data) => dispatch(enrollToService(data)),
    dispatchIsFavourite: (data) => dispatch(addToFavourite(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDescription));
