import React from "react";
import { Route } from "react-router-dom";

const AppRoute = ({
  isAuthProtected,
  props: Props,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={() => {
      if (Props.user !== null) {
        return (
          <main>
            <div id="wrapper">
              <Component token={Props.user.token} />
            </div>
          </main>
        );
      } else {
        return <Component />;
      }
    }}
  />
);

export default AppRoute;
