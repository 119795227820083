import { BASE_URL } from "../../../helpers/Constants";
import { GET_COURSE_BANNER } from "./actionTypes";
import { getCourseBannerSuccess, getCourseBannerError } from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAlLBanner(data) {
  //console.log('enter api function', data)
  try {
    const response = yield fetch(
      BASE_URL +
        "api/bannerList?banner_category=" +
        data.payload.banner_category,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getCourseBannerSuccess(response.result));
    } else {
      yield put(getCourseBannerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCourseBannerError(error.message));
  }
}
export function* watchGetAllBanner() {
  yield takeEvery(GET_COURSE_BANNER, getAlLBanner);
}

function* bannerSaga() {
  yield all([fork(watchGetAllBanner)]);
}
export default bannerSaga;
