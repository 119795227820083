import { BASE_URL } from "../../../helpers/Constants";

import {
  CREATE_USER_AI_CONTENT,
  SAVE_AI_CONTENT,
  GET_USER_AI_CONTENT,
  DELETE_USER_AI_CONTENT,
  ADD_FEEDBACK_AI_CONTENT,
} from "./actionTypes";
import {
  createAIContentSuccess,
  createAIContentError,
  saveAIContentSuccess,
  saveAIContentError,
  getAIContentSuccess,
  getAIContentError,
  deleteAIContentSuccess,
  deleteAIContentError,
  addAIContentFeedbackSuccess,
  addAIContentFeedbackError,
} from "./actions";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function* createAIContent(data) {
  try {
    let payload = {
      type: data?.payload?.type,
      question: data?.payload?.question,
    };
    const response = yield fetch(BASE_URL + `api/ai/content`, {
      method: "POST",
      timeout: 100 * 1000, // Set the timeout to 10 seconds (10000 milliseconds)
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(createAIContentSuccess(response.result));
    } else {
      yield put(createAIContentError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(createAIContentError(error.message));
  }
}
function* saveAIContent(data) {
  try {
    let payload = {
      type: data?.payload?.type,
      question: data?.payload?.question,
      answer: data?.payload?.answer,
    };
    const response = yield fetch(BASE_URL + `api/ai/content/save`, {
      method: "POST",
      timeout: 100 * 1000, // Set the timeout to 10 seconds (10000 milliseconds)
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(saveAIContentSuccess(response.result));
    } else {
      yield put(saveAIContentError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(saveAIContentError(error.message));
  }
}
function* getAIContent(data) {
  try {
    const response = yield fetch(BASE_URL + `api/ai/content`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(getAIContentSuccess(response.result));
    } else {
      yield put(getAIContentError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getAIContentError(error.message));
  }
}
function* deleteAIContent(data) {
  try {
    let payload = {
      type: data?.payload?.type,
      question: data?.payload?.question,
    };
    const response = yield fetch(
      BASE_URL + `api/ai/content/${data.payload.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(deleteAIContentSuccess(response.result));
    } else {
      yield put(deleteAIContentError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(deleteAIContentError(error.message));
  }
}
function* addAIContentFeedback(data) {
  try {
    let payload = {
      like: data?.payload?.like,
      feedback: data?.payload?.feedback,
    };
    const response = yield fetch(
      BASE_URL + `api/ai/content/feedback/${data.payload.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(addAIContentFeedbackSuccess(response.result));
    } else {
      yield put(addAIContentFeedbackError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(addAIContentFeedbackError(error.message));
  }
}

export function* watchCreateAIContent() {
  yield takeEvery(CREATE_USER_AI_CONTENT, createAIContent);
}
export function* watchSaveAIContent() {
  yield takeEvery(SAVE_AI_CONTENT, saveAIContent);
}
export function* watchGetAIContent() {
  yield takeEvery(GET_USER_AI_CONTENT, getAIContent);
}
export function* watchDeleteAIContent() {
  yield takeEvery(DELETE_USER_AI_CONTENT, deleteAIContent);
}
export function* watchAddAIContentFeedback() {
  yield takeEvery(ADD_FEEDBACK_AI_CONTENT, addAIContentFeedback);
}
function* aiContentSaga() {
  yield all([fork(watchCreateAIContent)]);
  yield all([fork(watchGetAIContent)]);
  yield all([fork(watchDeleteAIContent)]);
  yield all([fork(watchAddAIContentFeedback)]);
  yield all([fork(watchSaveAIContent)]);
}

export default aiContentSaga;
