import React, { PureComponent } from "react";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class LetestVideos extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  render() {
    return (
      this.props?.videoList?.length > 0 && (
        <>
          <h2>Latest Videos</h2>
          <Link to="/videos" className="right-link">
            View All{" "}
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </Link>

          <div className="video-slider">
            {this.props?.videoList?.length > 0 && (
              <OwlCarousel
                className="owl-theme"
                margin={10}
                items={2}
                nav={true}
                responsive={this.state.responsive}
              >
                {this.props?.videoList?.map((videos, vList) => (
                  <div className="item" key={vList}>
                    <div className="video-sld-box">
                      <div className="video-sld-img">
                        <img alt="img" src={videos.banner_url} />
                      </div>
                      <div className="video-sld-cnt">
                        {
                          <Link
                            to={`/video/${videos.video_category_url}/${videos.video_title_url}`}
                          >
                            <p className="right-date">{videos.created_at}</p>
                            <div className="video-sld-cnt-mid">
                              <img
                                alt="img"
                                src="/assets/images/Play-btn.png"
                              />
                              <h4>
                                <span>
                                  {videos.video_type == 0 &&
                                  (videos.is_subscribed === true ||
                                    videos.is_subscribed === false)
                                    ? "Free"
                                    : videos.video_type == 1 &&
                                      videos.is_subscribed === true
                                    ? "View Video"
                                    : `£ ${videos.fee}`}
                                </span>
                              </h4>
                              <div className="video-sld-left">
                                <h5>{videos.video_title}</h5>
                              </div>
                              <div className="video-sld-right">
                                <p>{videos.duration}</p>
                              </div>
                            </div>
                          </Link>
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}{" "}
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoList: state?.dashboardReducer?.services?.videos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LetestVideos));
