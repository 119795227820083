import React, { PureComponent } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/auth/Afooter";

import { getBlogDetailById } from "../../../store/protected/blogs/action";
import SharePopUpModel from "../videos/SharedPopUpModel";

import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class BlogDetail extends PureComponent {
  state = {
    url: "",
  };

  componentDidMount() {
    this.props.dispatchBlogId({
      blog_id: this.props.match.params.name,
      token: this.props.token,
      history: this.props.history,
    });
  }
  sharePop = () => {
    let str='111'+ this.props?.user?.user?.id +'111'; //userid=413
    let qrstr = '?q='+str;
    this.setState({
      url: window.location.href+qrstr,
    });
    window.$("#shareModel").modal("show");
  };

  render() {
    return (
      <React.Fragment>
        <SharePopUpModel url={this.state.url} />

        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <div className="blog-single page-height">
          <div className="container">
            <div className="bog-single-img">
              <img alt="img" src={this.props?.blogDetailById?.banner_url} />
            </div>
            <div className="bog-single-box">
              <div className="row">
                <div className="col-sm-6">
                  <div className="blog-title">
                    <img
                      alt="img"
                      src={
                        this.props?.blogDetailById?.author_image !== null
                          ? this.props?.blogDetailById?.author_image
                          : "/assets/images/course-slider-3.jpg"
                      }
                    />
                    <h3>
                      {this.props?.blogDetailById?.author_name !== null
                        ? this.props?.blogDetailById?.author_name
                        : "Richard"}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="blog-right">
                    <button onClick={this.sharePop} className="share">
                      <i className="fa fa-share" aria-hidden="true"></i>
                    </button>
                    <h6>{this.props?.blogDetailById?.updated_at}</h6>
                  </div>
                </div>
              </div>
              <h1>{this.props?.blogDetailById?.blog_title}</h1>
              {ReactHtmlParser(this.props?.blogDetailById?.description)}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blogDetailById: state.blogReducer.blogDetailById,
    user: state.authReducer?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchBlogId: (data) => dispatch(getBlogDetailById(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlogDetail));

//export default BlogDetail
