export const GET_DASHBOARD_BANNER = "GET_DASHBOARD_BANNER";
export const GET_DASHBOARD_BANNER_SUCCESS = "GET_DASHBOARD_BANNER_SUCCESS";
export const GET_DASHBOARD_BANNER_ERROR = "GET_DASHBOARD_BANNER_ERROR";

export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_ERROR = "GET_ALL_SERVICES_ERROR";

export const GET_LATEST_SERVICES = "GET_LATEST_SERVICES";
export const GET_LATEST_SERVICES_SUCCESS = "GET_LATEST_SERVICES_SUCCESS";
export const GET_LATEST_SERVICES_ERROR = "GET_LATEST_SERVICES_ERROR";

export const ADD_TRACKING_DATA = "ADD_TRACKING_DATA";

export const GET_DASHBOARD_AI_ANSWER = "GET_DASHBOARD_AI_ANSWER";
export const GET_DASHBOARD_AI_ANSWER_SUCCESS =
  "GET_DASHBOARD_AI_ANSWER_SUCCESS";
export const GET_DASHBOARD_AI_ANSWER_ERROR = "GET_DASHBOARD_AI_ANSWER_ERROR";

export const SAVE_DASHBOARD_AI_ANSWER = "SAVE_DASHBOARD_AI_ANSWER";
export const SAVE_DASHBOARD_AI_ANSWER_SUCCESS =
  "SAVE_DASHBOARD_AI_ANSWER_SUCCESS";
export const SAVE_DASHBOARD_AI_ANSWER_ERROR = "SAVE_DASHBOARD_AI_ANSWER_ERROR";

export const GET_DASHBOARD_USER_GOAL = "GET_DASHBOARD_USER_GOAL";
export const GET_DASHBOARD_USER_GOAL_SUCCESS =
  "GET_DASHBOARD_USER_GOAL_SUCCESS";
export const GET_DASHBOARD_USER_GOAL_ERROR = "GET_DASHBOARD_USER_GOAL_ERROR";

export const UPDATE_DASHBOARD_USER_GOAL = "UPDATE_DASHBOARD_USER_GOAL";
export const UPDATE_DASHBOARD_USER_GOAL_SUCCESS =
  "UPDATE_DASHBOARD_USER_GOAL_SUCCESS";
export const UPDATE_DASHBOARD_USER_GOAL_ERROR =
  "UPDATE_DASHBOARD_USER_GOAL_ERROR";

export const GET_USER_DASHBOARD_AI = "GET_USER_DASHBOARD_AI";
export const GET_USER_DASHBOARD_AI_SUCCESS = "GET_USER_DASHBOARD_AI_SUCCESS";
export const GET_USER_DASHBOARD_AI_ERROR = "GET_USER_DASHBOARD_AI_ERROR";

export const DELETE_USER_DASHBOARD_AI = "DELETE_USER_DASHBOARD_AI";
export const DELETE_USER_DASHBOARD_AI_SUCCESS =
  "DELETE_USER_DASHBOARD_AI_SUCCESS";
export const DELETE_USER_DASHBOARD_AI_ERROR = "DELETE_USER_DASHBOARD_AI_ERROR";
