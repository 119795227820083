export const GET_SUBSCRIPTIONS_LIST = "GET_SUBSCRIPTIONS_LIST";
export const GET_SUBSCRIPTIONS_LIST_SUCCESS = "GET_SUBSCRIPTIONS_LIST_SUCCESS";
export const GET_SUBSCRIPTIONS_LIST_ERROR = "GET_SUBSCRIPTIONS_LIST_ERROR";

export const SUBSCRIPTIONS_PAYMENT = "SUBSCRIPTIONS_PAYMENT";
export const SUBSCRIPTIONS_PAYMENT_SUCCESS = "SUBSCRIPTIONS_PAYMENT_SUCCESS";
export const SUBSCRIPTIONS_PAYMENT_ERROR = "SUBSCRIPTIONS_PAYMENT_ERROR";

export const ITEM_CHECKOUT = "ITEM_CHECKOUT";
export const ITEM_CHECKOUT_SUCCESS = "ITEM_CHECKOUT_SUCCESS";
export const ITEM_CHECKOUT_ERROR = "ITEM_CHECKOUT_ERROR";
