import React, { Component } from "react";

import Login from "../../gaust/login";

import { getCartItem, setCartItem } from "../../../store/protected/cart/action";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

class SubscriptionPops extends Component {
  state = {
    services: [],
    showLoginModel: false,
    subscription: ""
  };

  async componentDidMount() {
    // const url = `${BASE_URL}api/activePaymentOption`;
    const url = 'https://admin.themaetrix.com/api/activePaymentOption'
    axios.get(url).then(async (response) => {
      if (response.data.result.payment_option === "1") {
        await this.setState({ subscription: "1" });
      } else {
        await this.setState({ subscription: "2" });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  componentDidUpdate(prevProps) {
    //console.log('cartItemSaved', prevProps.cartItemSaved, 'this.props?.cartItemSaved', this.props?.cartItemSaved);
    if (
      prevProps?.cartItemSaved !== this.props?.cartItemSaved &&
      this.props?.cartItemSaved !== null
    ) {
      //console.log('cart item update');
      toast.success("Item Successfully Add To Cart", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.$("#subsModel").modal("hide");
      this.props.dispatchCart({
        token: this.props.token,
      });
    }
  }

  saveToCart = (event) => {
    if (this.props.token) {
      let service = {
        service_category: this.props?.courseDescription?.service_category,
        service_id: this.props?.courseDescription?.id,
      };
      this.setState(
        {
          services: this.state.services.concat(service),
        },
        () => {
          this.props.dispatchAddToCart({
            token: this.props.token,
            data: this.state.services,
          });
        }
      );
    } else {
      if (localStorage.getItem("courses")) {
        if (localStorage.getItem("courses").length > 0) {
          let data = JSON.parse(localStorage.getItem("courses"));
          let valid = true;
          for (let i = 0; i < data.length - 1; i++) {
            if (data[i].id == this.props?.courseDescription.id) {
              valid = false;
              break;
            }
          }
          if (valid === true) {
            data.push(this.props?.courseDescription);
            localStorage.setItem("courses", JSON.stringify(data));
            toast.success(
              <i className="fa fa-check" aria-hidden="true">
                {" "}
                Added To Cart
              </i>,
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
            window.$("#subsModel").modal("hide");
          } else {
            toast.error("Item already added in the cart", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            window.$("#subsModel").modal("hide");
          }
        }
      } else {
        let data = [];
        data.push(this.props?.courseDescription);
        localStorage.setItem("courses", JSON.stringify(data));
      }
    }
  };
  checkoutPopUp = async () => {
    const stripe = await stripePromise;
    //const url = `${BASE_URL}api/createSessionForPaymentV2Test`;
    const url = `${BASE_URL}api/createSessionForPaymentV2`;
    const response = await axios.post(
      url,
      {
        service_id: this.props?.courseDescription.id,
        service_category: this.props?.courseDescription?.service_category,
        service_name: this.props.courseDescription?.course_name,
      },
      {
        headers: {
          authorization: "Bearer" + this.props.token,
        },
      }
    );
    const apiResult = await response.data.result;
    localStorage.setItem("cart_Checkout", JSON.stringify(apiResult));
    const result = await stripe.redirectToCheckout({
      sessionId: apiResult?.session?.id,
    });
    if (result.error) {
      console.log("error", result.error.message);
    }
  };

  buyService = (event) => {
    if (this.props.token) {
      this.checkoutPopUp();
      // let service = {
      //     service_category: this.props?.courseDescription?.service_category,
      //     service_id: this.props?.courseDescription?.id
      // }
      // this.setState({
      //     services: this.state.services.concat(service)
      // }, () => {
      //     console.log('statedata', this.state.services);
      //     this.props.dispatchAddToCart({
      //         token: this.props.token,
      //         data: this.state.services

      //     });
      //     window.$('#subsModel').modal('hide')
      //     this.props.history.push('/cart');
      // });
    } else {
      this.goToLogin();
    }
  };

  goToLogin = () => {
    //console.log('gotologin')
    window.$("#subsModel").modal("hide");
    window.$("#login-model").modal("show");
  };

  subscriptionCheck = () => {
    window.$("#subsModel").modal("hide");
    this.props.history.push("/buy-subscriptions");
  };
  alreadyBuyService = () => {
    window.$("#subsModel").modal("hide");
    this.props.history.push("/cart");
  };
  render() {
    // console.log('propsss', this.props?.courseDescription);
    return (
      <div className="subscription-pops">
        <div className="modal fade" id="subsModel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="subs-cnt">
                
                  

                    {this.state.subscription === "2"
                      ?
                      <>
                      <h2>You need to buy this course</h2>
                      <div className="row">
                      <div className="col-sm-12">
                        <div className="subs-left-box">
                          <div className="white-overlay"></div>
                          <div className="inner-cnt">
                            <h3>Fee</h3>
                            <p>
                              <span>
                                £{" "}
                                {this.props?.courseDescription?.course_fee
                                  ? this.props?.courseDescription?.course_fee
                                  : 0}
                              </span>
                            </p>
                            {this.props?.added_to_cart === true &&
                              this.props?.cartItemSaved !== null ? (
                              <button
                                type="button"
                                onClick={this.alreadyBuyService}
                              >
                                Go To Cart
                              </button>
                            ) : (
                              <button type="button" onClick={this.buyService}>
                                Buy Now
                              </button>
                            )}

                            {this.props?.courseDescription?.added_to_cart ? (
                              <button type="button">Added To Cart</button>
                            ) : (
                              <button type="button" onClick={this.saveToCart}>
                                Add To Cart
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      </div>
                      </>
                      :
                      <>
                      <h2>Click below to subscribe!</h2>
                      <div className="row">
                      <div className="col-sm-12">
                        <div className="subs-right-box">
                          <h3>
                            {
                              this.props?.courseDescription?.subscription_tag
                                ?.tag_name
                            }
                            <br />
                            {/* £{" "}
                        {this.props?.courseDescription?.monthly_fee
                          ? this.props?.courseDescription?.monthly_fee
                          : 0} */}
                            {this.props?.courseDescription?.is_independent ===
                              true
                              ? `£${this.props?.courseDescription?.course_fee}`
                              : `£${this.props?.courseDescription?.monthly_fee}`}
                          </h3>
                          {/* <h3>
                        £{" "}
                        {this.props?.courseDescription?.monthly_fee
                          ? this.props?.courseDescription?.monthly_fee
                          : 0}
                      </h3> */}
                          {this.props?.token ? (
                            <button
                              type="button"
                              onClick={this.subscriptionCheck}npm 
                            >
                              Choose Plan
                            </button>
                          ) : (
                            <button type="button" onClick={this.goToLogin}>
                              Choose Plan
                            </button>
                          )}
                        </div>
                      </div>
                      </div>
                      </>
                      }
                  
                  {!this.props.token && (
                    <p className="footer-pops">
                      Already Have a membership ?{" "}
                      <button onClick={this.goToLogin} type="button">
                        Login
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Login />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartItemSaved: state.cartReducer?.addedResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAddToCart: (data) => dispatch(setCartItem(data)),
    dispatchCart: (data) => dispatch(getCartItem(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionPops));
