import React, { PureComponent, useRef, useState, useEffect } from "react";

const PageLoading = (props) => {
    return (
        <>
            {props.isLoading && props.isLoading == true ?
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 text-center mb-5 pb-5 page-loader-latest">
                            <div className="loade-sec">
                                <div className="loader"></div>
                                <h5>Loading...</h5>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </>
    )
}
export default PageLoading;
