import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PAYMENT_DETAIL,
  GET_USER_PAYMENT_DETAIL_SUCCESS,
  GET_USER_PAYMENT_DETAIL_ERROR,
} from "./actionType";

const initialState = {
  error: null,
  loading: null,
  user: null,
  userPaymentDetail: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      state = {
        ...state,
        error: null,
        loading: true,
        user: null,
        userPaymentDetail: null,
      };
      break;
    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: true,
        user: action.payload,
      };
      break;
    case GET_USER_ERROR:
      state = {
        ...state,
        loading: false,
        user: null,
        userPaymentDetail: null,
        error: action.payload,
      };
    case GET_USER_PAYMENT_DETAIL:
      state = {
        ...state,
        error: null,
        loading: true,
        userPaymentDetail: null,
      };
      break;
    case GET_USER_PAYMENT_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: true,
        userPaymentDetail: action.payload,
      };
      break;
    case GET_USER_PAYMENT_DETAIL_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        userPaymentDetail: null,
      };

    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default userReducer;
