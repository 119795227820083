import React, { Component } from "react";
import { BASE_URL } from "../../../helpers/Constants";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default class FacebookLoginComponent extends Component {
  state = {
    isLoggedIn: false,
    userID: "",
    name: "",
    email: "",
    picture: "",
  };

  responseFacebook = (response) => {
    if (response) {
      localStorage.setItem("Facebook_Response", JSON.stringify(response));
      let url = `${BASE_URL}api/updatePageAccessToken`;
      axios
        .post(
          url,
          {
            user_access_token: response?.accessToken,
          },
          {
            headers: { authorization: `Bearer ${this.props?.token}` },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data?.status === true) {
            let data = JSON.parse(localStorage.getItem("Facebook_Response"));
            this.setState({
              isLoggedIn: true,
              userID: data?.userID,
              name: data?.name,
              email: data?.email,
            });
            toast.success(
              response?.data?.message
                ? response?.data?.message
                : "facebook login successfull",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message
              ? error.response?.data?.message
              : "Login issue please check facebook page name again",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          //console.log(error.response);
        });
    }
  };
  componentClicked = () => {
    console.log("button clicked");
  };
  render() {
    let fbContent;
    let fb_data = JSON.parse(localStorage.getItem("Facebook_Response"));
    if (fb_data && fb_data.accessToken) {
      fbContent = (<ToastContainer />)(
        <>
          {/* <p style={{'color': 'green'}}>* Before do facebook login please update facebook page name</p> */}
          <div className="fb-user-dtl">
            {/* <img src={this.state?.picture} class="className" /> */}
            <h3>Welcome {fb_data?.name}</h3>
            <h5>{fb_data?.email}</h5>
          </div>
        </>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId="1131040847344118"
          autoLoad={false}
          scope="public_profile,email,pages_show_list,pages_read_user_content"
          fields="name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
        />
      );
    }

    return <div>{fbContent}</div>;
  }
}
