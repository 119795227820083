import {
  GET_VIDEO,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_ERROR,
  GET_VIDEOS_BY_CATEGORY_ID,
  GET_VIDEOS_BY_CATEGORY_ID_SUCCESS,
  GET_VIDEOS_BY_CATEGORY_ID_ERROR,
  SET_PAUSE_TIME,
  SET_PAUSE_TIME_SUCCESS,
  SET_PAUSE_TIME_ERROR,
  GET_PAUSE_TIME,
  GET_PAUSE_TIME_SUCCESS,
  GET_PAUSE_TIME_ERROR,
} from "./actionType";

const initialState = {
  error: null,
  loading: null,
  videos: null,
  videoDetail: null,
  videoList: null,
  isTimeSave: false,
  getPauseTime: null,
  videoBanner: null,
  videoListByCategory: null,
};

const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO:
      state = {
        ...state,
        error: null,
        loading: true,
        videos: null,
      };
      break;
    case GET_VIDEO_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        videos: action.payload,
      };
      break;
    case GET_VIDEO_ERROR:
      state = {
        ...state,
        loading: false,
        videos: null,
        error: action.payload,
      };
      break;
    case GET_BANNER:
      state = {
        ...state,
        error: null,
        loading: true,
        videoBanner: null,
      };
      break;
    case GET_BANNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        videoBanner: action.payload,
      };
      break;
    case GET_BANNER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        videoBanner: null,
      };
      break;
    case GET_VIDEO_DETAIL:
      state = {
        ...state,
        error: null,
        loading: true,
        videoDetail: null,
      };
      break;
    case GET_VIDEO_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        videoDetail: action.payload,
      };
      break;
    case GET_VIDEO_DETAIL_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        videoDetail: null,
      };
      break;
    case GET_VIDEOS_BY_CATEGORY_ID:
      state = {
        ...state,
        error: null,
        loading: true,
        videoListByCategory: null,
      };
      break;
    case GET_VIDEOS_BY_CATEGORY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        videoListByCategory: action.payload,
      };
      break;
    case GET_VIDEOS_BY_CATEGORY_ID_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        videoListByCategory: null,
      };
      break;
    case SET_PAUSE_TIME:
      state = {
        ...state,
        loading: true,
        error: null,
        isTimeSave: false,
      };
      break;
    case SET_PAUSE_TIME_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        isTimeSave: true,
      };
      break;
    case SET_PAUSE_TIME_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isTimeSave: false,
      };
      break;
    case GET_PAUSE_TIME:
      state = {
        ...state,
        loading: true,
        error: null,
        getPauseTime: null,
      };
      break;
    case GET_PAUSE_TIME_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        getPauseTime: action.payload,
      };
      break;
    case GET_PAUSE_TIME_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        getPauseTime: null,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};

export default videosReducer;
