import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../helpers/Constants';
import axios from 'axios'

function FunQuestionAnswer({ token }) {

    const [answer, setAnswer] = useState('');
    const [SendQuestion, setSendQuestion] = useState('');
    const [qId, setQid] = useState('')
    const [question, setQuestion] = useState([])

    const [result, setResult] = useState(null)

    useEffect(() => {
        let url = `${BASE_URL}api/profile/detail`
        axios.get(url, {
            headers: { authorization: `Bearer ${token}` }

        }).then(res => {

            setQuestion(res.data?.result?.fun_question)
        }
        )
    }, [result])


    const submit = (e) => {
        e.preventDefault();
        let url = `${BASE_URL}api/answerTheQuestion`
        axios.post(url, {
            question_id: qId.id,
            answer: answer
        }, {
            headers: { authorization: `Bearer ${token}` }
        })
            .then(function (response) {
                setResult(response);
                window.$("#myModal").modal("hide")
                setAnswer('')
            })
    }


    const handleModal = (list) => {
        setAnswer('')
        window.$("#myModal").modal("show")
        if (list.question) {
            setSendQuestion(list.question)
        }

        if (list.answer) {
            setAnswer(list.answer);

        }
        setQid(list)
    }

    const update = e => {
        e.preventDefault();
        let url = `${BASE_URL}api/editAnswer`
        axios.post(url, {
            answer_id: qId.answer_id,
            answer
        }, {
            headers: { authorization: `Bearer ${token}` }

        }).then(res => {
            setResult(res);
            window.$("#myModal").modal("hide");
            setAnswer('')
        }
        )
    }

    const deleteAnswer = (e, list) => {
        e.preventDefault();
        let url = `${BASE_URL}api/deleteAnswer`
        axios.post(url, {
            answer_id: list.answer_id
        }, {
            headers: { authorization: `Bearer ${token}` }

        }).then(res => {
            setResult(res);

        }
        )
    }

    return (
        <div className="account-footer-sec page-height">
            <h2>Fun Question & Answer</h2>
            {question && question.map((list, key) => (
                <div key={key} className="qustion-box">
                    <h3>{key + 1}. {list.question}</h3>
                    <form>
                        <div className="row answerLabel" onClick={() => handleModal(list)}>
                            {list.answer ? <label className="txtlable" > {list.answer} </label> : <label className="txtans">'Click here for write your answer..'</label>}

                            {list.answer ? (<button type="button" onClick={e => deleteAnswer(e, list)} className="delete-btn" ><i className="fa fa-trash-o" aria-hidden="true"></i></button>) : null}
                        </div>
                    </form>
                </div>
            ))}
            <div className="updt-faq">
                <div className="modal fade" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title">Modal Heading</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div> */}
                            <div className="modal-body">
                                <h3>{SendQuestion}</h3>
                                <div className="form-group">
                                    <textarea className="form-control" value={answer} onChange={e => setAnswer(e.target.value)} required></textarea>
                                </div>
                                <div className="sbm-btn">
                                    {!qId.answer && <button type="button" onClick={submit}>save</button>}
                                    {qId.answer && <button type="button" onClick={update}>update</button>}
                                    <button type="button" className="cancel" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FunQuestionAnswer
