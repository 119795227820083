import React, { PureComponent, useRef, useState, useEffect } from "react";

import AuthHeader from "../../../layout/auth/Aheader";
import Login from "../../../components/gaust/login";
import Header from "../../../layout/gaust/newLayout/Header";
import Header2 from "../../../layout/gaust/newLayout/Header2";
import Footer from "../../../layout/gaust/newLayout/Footer";

import HomeSlider from "../../../layout/gaust/newLayout/HomeSlider";
import LatestLearning from "../../../components/auth/dashboard/NewComponent/LatestLearning";
import NewsFeeds from "../../../components/auth/dashboard/NewComponent/NewsFeeds";
import LatestEpisodes from "../../../components/auth/dashboard/NewComponent/LatestEpisodes";
import LatestBoxsets from "../../../components/auth/dashboard/NewComponent/LatestBoxsets";
import LatestPodcast from "../../../components/auth/dashboard/NewComponent/LatestPodcast";
import LatestBlogs from "../../../components/auth/dashboard/NewComponent/LatestBlogs";
import AllMembers from "../../../components/auth/dashboard/NewComponent/AllMembers";
import GroupChat from "../../../components/auth/dashboard/GroupChat";
//import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAllServices,
  getLatestServices,
} from "../../../store/protected/dashboard/action";
import PageLoading from "../../common/PageLoading";

const NewHomePage = (props) => {
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  const loginModel = (value) => {
    if (value === true) {
      setShowLoginModel(true);
      window.$("#login-model").modal("show");
    }
  };
  useEffect(() => {
    props.dispatchServicesToken({
      token: props.token,
    });
    props.dispatchLetestService({
      token: props.token,
    });
    var elem = document.getElementById("calendar");
    elem.addEventListener("onload", myFunc());
    //this.myFunction = this.myFunction.bind(this);
  }, []);
  const myFunc = (e) => {
    e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = "https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js";
    e.className = "ae-emd-script";
    document.getElementsByTagName("body")[0].appendChild(e);
  }
  return (
    <React.Fragment>
      <div className="matrix_new_layout">
        <AuthHeader token={props?.token} hitHome={loginModel} />
        {/* {loading && loading == true ? (
          <PageLoading isLoading={loading} />
        ) : ( */}
        <>
            <HomeSlider />
            <LatestLearning userDetail={props.userName} token={props?.token} />
            <div className="container">
                <div
                    id="calendar"
                    className="col-md-12 text-center ae-emd-cal"
                    data-calendar="sR520112"
                    data-configure="false"
                >
                </div>
            </div>      
            <NewsFeeds />
          {/* <LatestEpisodes /> */}
          {/* <LatestBoxsets /> */}
          {/* <LatestPodcast token={props?.token} /> */}
          {/* <LatestBlogs token={props.token} /> */}
            <AllMembers />
            <Footer userDetail={props.userName} token={props.token} />
        </>
        {/* )} */}
        {showLoginModel === true && <Login />}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
    userName: state.authReducer?.user?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServicesToken: (data) => dispatch(getAllServices(data)),
    dispatchLetestService: (data) => dispatch(getLatestServices(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewHomePage));
