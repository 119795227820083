import { BASE_URL } from "../../../helpers/Constants";
import {
  SET_CART_ITEM,
  GET_CART_ITEM,
  DELETE_CART_ITEM,
  CHECKOUT_PAYMENT,
} from "./actionTypes";
import {
  setCartItemSuccess,
  setCartItemError,
  getCartItemSuccess,
  getCartItemError,
  deleteCartItemSuccess,
  deleteCartItemError,
  checkoutPaymentSuccess,
  checkoutPaymentError,
} from "./action";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getCartItemByUser(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(BASE_URL + "api/getCartList", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getCartItemSuccess(response.result));
    } else {
      yield put(getCartItemError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCartItemError(error.message));
  }
}

function* saveToCart(data) {
  try {
    let payload = {
      services: data.payload.data,
    };
    const response = yield fetch(BASE_URL + "api/addToCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(setCartItemSuccess(response.status));
      localStorage.removeItem("courses");
    } else {
      yield put(setCartItemError(response.message));
    }
  } catch (error) {
    yield put(setCartItemError(error.message));
  }
}

function* removeFromCart(data) {
  try {
    let payload = {
      cart_id: data.payload.cart_id,
    };

    const response = yield fetch(BASE_URL + "api/removeFromCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(deleteCartItemSuccess(response.status));
    } else {
      yield put(deleteCartItemError(response.message));
    }
  } catch (error) {
    yield put(deleteCartItemError(error.message));
  }
}

function* cartCheckoutPayment(data) {
  try {
    let payload = {
      services: data.payload.data.services,
      name_on_card: data.payload.data.name_on_card,
      card_number: data.payload.data.card_number,
      cvc: parseInt(data.payload.data.cvc),
      exp_month: parseInt(data.payload.data.exp_month),
      exp_year: parseInt(data.payload.data.exp_year),
    };
    const response = yield fetch(BASE_URL + "api/buyServices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(checkoutPaymentSuccess(response));
    } else {
      yield put(
        checkoutPaymentError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    yield put(checkoutPaymentError("Something went wrong"));
  }
}

export function* watchAddToCart() {
  yield takeEvery(SET_CART_ITEM, saveToCart);
}
export function* watchCartCheckout() {
  yield takeEvery(CHECKOUT_PAYMENT, cartCheckoutPayment);
}
export function* watchRemoveFromCart() {
  yield takeEvery(DELETE_CART_ITEM, removeFromCart);
}

export function* watchgetCart() {
  yield takeEvery(GET_CART_ITEM, getCartItemByUser);
}

function* cartSaga() {
  yield all([
    fork(watchgetCart),
    fork(watchAddToCart),
    fork(watchCartCheckout),
    fork(watchRemoveFromCart),
  ]);
}

export default cartSaga;
