import { BASE_URL } from "../../../helpers/Constants";
import { DELETE_AI_WORKBOOK, GET_AI_WORKBOOK } from "./actionType";

import {
  getAIWorkBookSuccess,
  getAIWorkBookError,
  deleteAIWorkBookSuccess,
  deleteAIWorkBookError,
} from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAIWorkBook(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(BASE_URL + "api/ai/workbook", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(getAIWorkBookSuccess(response.result));
    } else {
      yield put(getAIWorkBookError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getAIWorkBookError(error.message));
  }
}

function* deleteAIWorkBook(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(
      BASE_URL + `api/ai/workbook/${data.payload.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));
    if (response.status === true) {
      yield put(deleteAIWorkBookSuccess(response.result));
    } else {
      yield put(deleteAIWorkBookError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(deleteAIWorkBookError(error.message));
  }
}

export function* watchgetAIWorkBook() {
  yield takeEvery(GET_AI_WORKBOOK, getAIWorkBook);
}

export function* watchdeleteAIWorkBook() {
  yield takeEvery(DELETE_AI_WORKBOOK, deleteAIWorkBook);
}

function* workBookSaga() {
  yield all([fork(watchgetAIWorkBook)]);
  yield all([fork(watchdeleteAIWorkBook)]);
}

export default workBookSaga;
