import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class VideoResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: false,
    };
  }
  render() {
    return (
      <React.Fragment>
        {this.props?.videoList?.length > 0 && (
          <div className="video-row page-height">
            <div className="container">
              <div className="video-row-header">
                <h2>Videos Result</h2>
              </div>

              <div className="video-slider">
                <div className="row">
                  {this.props?.videoList?.map((videos, vList) => (
                    <div className="col-lg-4 col-md-6" key={vList}>
                      <div className="video-sld-box">
                        <div className="video-sld-img">
                          <img alt="img" src={videos.banner_url} />
                        </div>
                        <div className="video-sld-cnt">
                          {
                            <Link
                              to={`/video/${videos.category_name}/${videos.video_title_url}`}
                            >
                              <p className="right-date">{videos.created_at}</p>
                              <div className="video-sld-cnt-mid">
                                <img
                                  alt="img"
                                  src="/assets/images/Play-btn.png"
                                />
                                <h4>
                                  <span>
                                    {videos.video_type == 0 &&
                                    (videos.is_subscribed == 1 ||
                                      videos.is_subscribed == 0)
                                      ? "Free"
                                      : videos.video_type == 1 &&
                                        videos.is_subscribed == 1
                                      ? "View Video"
                                      : `£ ${videos.fee}`}
                                  </span>
                                </h4>
                                <div className="video-sld-left">
                                  <h5>{videos.video_title}</h5>
                                  {ReactHtmlParser(videos.description)}
                                </div>
                                <div className="video-sld-right">
                                  <p>{videos.duration}</p>
                                </div>
                              </div>
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoResult));
