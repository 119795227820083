import { BASE_URL } from "../../../helpers/Constants";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import {
  GET_COURSE,
  GET_COURSE_BY_ID,
  GET_COURSE_AI_ANSWER,
  SAVE_COURSE_AI_ANSWER,
  GET_COURSES_BY_CATEGORY_ID,
  ENROLL_TO_SERVICE,
  SAVE_PAUSE_TIME,
  GET_PAUSE_TIME_BY_LESSION_ID,
  GET_PROGRESS_BY_ID,
  ADD_TO_FAVOURITE,
  MARK_AS_COMPLETE,
  IS_LIKED,
  LATEST_SERVICE,
} from "./actionTypes";

import {
  getCourseSuccess,
  getCourseError,
  getCourseByIdSuccess,
  getCourseByIdError,
  getCourseAIAnswerSuccess,
  getCourseAIAnswerError,
  saveCourseAIAnswerSuccess,
  saveCourseAIAnswerError,
  getCoursesByCategoryIdSuccess,
  getCoursesByCategoryIdError,
  enrollToServiceSuccess,
  enrollToServiceError,
  savePauseTimeSuccess,
  savePauseTimeError,
  getPauseTimebyLessonIdSuccess,
  getPauseTimebyLessonIdError,
  getProgressByIdSuccess,
  getProgressByIdError,
  addToFavouriteSuccess,
  addToFavouriteError,
  markAsCompleteSuccess,
  markAsCompleteError,
  isLikeSuccess,
  isLikeError,
  isLatestSuccessSuccess,
  isLatestSuccessError,
} from "./action";

import { takeEvery, fork, put, all } from "redux-saga/effects";

function stringifyQueryParams(obj) {
  let queryStr = "";
  Object.keys(obj).map((key) => {
    let objKey = obj[key];
    if (Array.isArray(objKey)) {
      objKey = objKey.join(",");
    }
    if (objKey !== false && objKey !== 0 && !objKey) {
      return false;
    } // return if ObjectKey is falsy
    try {
      const decodeUri = decodeURIComponent(objKey);
      queryStr += `${key}=${encodeURIComponent(decodeUri)}&`;
    } catch (e) {
      queryStr += `${key}=${objKey}&`;
    }
    return queryStr;
  });
  return queryStr.length ? `?${queryStr.substr(0, queryStr.length - 1)}` : "";
}

const addParamToUrl = (relativeUrl, queryParam, isFetch) => {
  const kvp = relativeUrl.split("?");
  let existing = {};
  if (kvp.length > 1) {
    // decoder option for parse to override decoding of properties and values
    existing = queryString.parse(kvp[1]);
  }
  if (isFetch) {
    existing = { ...queryParam, ...existing };
  } else {
    existing = { ...existing, ...queryParam };
  }
  return `${kvp[0]}${stringifyQueryParams(existing)}`;
};

function* getAllCourses(data) {
  //console.log('enter api function')
  try {
    if (data.payload.token) {
      const response = yield fetch(
        BASE_URL + "api/course/categories?token=" + data.payload.token,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + data.payload.token,
          },
        }
      )
        .then((res) => res.json())
        .catch((error) => ({
          status: "error",
          errors: { message: "Server not responding" },
        }));

      if (response.status === true) {
        //console.log('respone', response);
        yield put(getCourseSuccess(response.result));
      } else {
        yield put(getCourseError(response.message));
      }
    } else {
      const response = yield fetch(BASE_URL + "api/course/categories", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      })
        .then((res) => res.json())
        .catch((error) => ({
          status: "error",
          errors: { message: "Server not responding" },
        }));

      if (response.status === true) {
        //console.log('respone', response);
        yield put(getCourseSuccess(response.result));
      } else {
        yield put(getCourseError(response.message));
      }
    }

    // {"status":false,"result":null,"message":"Invalid Crediantials"}
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCourseError(error.message));
  }
}

function* getCourseById(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      course_id: data?.payload?.id,
      token: data?.payload?.token,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/course/detail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + data.payload.token
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('responedadadadadadad', response);
      yield put(getCourseByIdSuccess(response.result));
      // if(response?.result?.is_redirect == true){
      //     console.log('hello saga');
      //     // data.payload.history.push(`/course/course-description/${response?.result?.id}/course-detail/${response?.result?.course_title_url}`)
      // }
    } else {
      //console.log('response error', response.message);
      yield put(getCourseByIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCourseByIdError(error.message));
  }
}

function* getCourseAIAnswer(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      course_id: data?.payload?.course_id,
      lesson_id: data?.payload?.lession_id,
      question: data?.payload?.question,
    };
    const response = yield fetch(BASE_URL + "api/ai/course", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getCourseAIAnswerSuccess(response.result));
    } else {
      yield put(getCourseAIAnswerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCourseAIAnswerError(error.message));
  }
}

function* saveCourseAIAnswer(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      course_id: data?.payload?.course_id,
      lesson_id: data?.payload?.lession_id,
      question: data?.payload?.question,
      answer: data?.payload?.answer,
    };
    const response = yield fetch(BASE_URL + "api/ai/course", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(saveCourseAIAnswerSuccess(response.result));
    } else {
      yield put(saveCourseAIAnswerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(saveCourseAIAnswerError(error.message));
  }
}
function* getCoursesByCategoryId(data) {
  try {
    const response = yield fetch(
      BASE_URL + "api/course/categories?category_name=" + data.payload.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getCoursesByCategoryIdSuccess(response.result));
    } else {
      yield put(getCoursesByCategoryIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getCoursesByCategoryIdError(error.message));
  }
}

function* enrollToService(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      service_category: data?.payload?.service_category,
      service_id: data?.payload?.service_id,
    };
    console.log("JSON", JSON.stringify(payload));
    const response = yield fetch(BASE_URL + "api/enrollToService", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('responedadadadadadad', response);
      yield put(enrollToServiceSuccess(response.result));
      data.payload.history.push(
        "/course/" +
          data.payload.service_category_url +
          "/" +
          data.payload.id +
          "/" +
          data.payload.name +
          "/view-detail"
      );
    } else {
      yield put(enrollToServiceError(response.message));
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(enrollToServiceError(error.message));
  }
}

function* savePauseTime(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      service_category: data?.payload?.service_category,
      service_id: data?.payload?.service_id,
      playble_item_id: data?.payload?.playble_item_id,
      resume_at: data?.payload?.resume_at,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/addOrUpdatePlaylist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(savePauseTimeSuccess(response.result));
      //data.payload.history.push('/course/course-description/' + data.payload.id + '/course-detail');
    } else {
      yield put(savePauseTimeError(response.message));
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(savePauseTimeError(error.message));
  }
}

function* getPauseTimeById(data) {
  try {
    const url = addParamToUrl(BASE_URL + "api/getResumeTime", {
      ...data.payload.qs,
    });
    const response = yield fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getPauseTimebyLessonIdSuccess(response.result));
    } else {
      yield put(getPauseTimebyLessonIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getPauseTimebyLessonIdError(error.message));
  }
}
function* getProgress(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      course_id: data.payload.course_id,
    };
    const response = yield fetch(BASE_URL + "api/courseCompletePercentage", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getProgressByIdSuccess(response.result));
    } else {
      yield put(getProgressByIdError(response.message));
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(getProgressByIdError(error.message));
  }
}
function* addToFavourite(data) {
  console.log(data);
  try {
    let payload = {
      service_id: data.payload.service_id,
      service_category: data.payload.service_category,
    };
    const response = yield fetch(BASE_URL + "api/addToFavourite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(addToFavouriteSuccess(response.result));
      if (response?.result && response?.result?.is_favourite == true) {
        toast.success("Course Successfully Added To Favourite", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response?.result && response.result.is_favourite != true) {
        toast.success("Course Successfully Removed From Favourite", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      yield put(addToFavouriteError(response.message));
      toast.error("Not Added to Favourite", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(addToFavouriteError(error.message));
  }
}

function* markAsComplete(data) {
  try {
    let payload = {
      service_id: data.payload.service_id,
      service_category: data.payload.service_category,
    };
    const response = yield fetch(BASE_URL + "api/completeService", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(markAsCompleteSuccess(response.result));
    } else {
      yield put(markAsCompleteError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(markAsCompleteError(error.message));
  }
}
function* isLike(data) {
  console.log("enter ap", data);
  try {
    let payload = {
      service_category: data.payload.service_category,
      service_id: data?.payload?.id,
      //token: data?.payload?.token
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/checkIsFavourite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('responedadadadadadad', response);
      yield put(isLikeSuccess(response.result));
    } else {
      yield put(isLikeError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(isLikeError(error.message));
  }
}

function* getLatestServices(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      service_category: data?.payload?.category,
      service_id: data?.payload?.id,
      token: data?.payload?.token,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/addUserActivity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('responedadadadadadad', response);
      yield put(isLatestSuccessSuccess(response.result));
    } else {
      yield put(isLatestSuccessError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(isLatestSuccessError(error.message));
  }
}

export function* watchgetAllCourses() {
  yield takeEvery(GET_COURSE, getAllCourses);
}

export function* watchgetCourseById() {
  yield takeEvery(GET_COURSE_BY_ID, getCourseById);
}

export function* watchgetCoursesByCategoryId() {
  yield takeEvery(GET_COURSES_BY_CATEGORY_ID, getCoursesByCategoryId);
}

export function* watchgetEnrollToService() {
  yield takeEvery(ENROLL_TO_SERVICE, enrollToService);
}

export function* watchgetSavePauseTime() {
  yield takeEvery(SAVE_PAUSE_TIME, savePauseTime);
}
export function* watchgetSavePauseTimeById() {
  yield takeEvery(GET_PAUSE_TIME_BY_LESSION_ID, getPauseTimeById);
}
export function* watchgetProgressById() {
  yield takeEvery(GET_PROGRESS_BY_ID, getProgress);
}
export function* watchgetaddToFavourite() {
  yield takeEvery(ADD_TO_FAVOURITE, addToFavourite);
}
export function* watchgetMarkAsComplete() {
  yield takeEvery(MARK_AS_COMPLETE, markAsComplete);
}
export function* watchgetIsLike() {
  yield takeEvery(IS_LIKED, isLike);
}
export function* watchgetIsLatest() {
  yield takeEvery(LATEST_SERVICE, getLatestServices);
}
export function* watchgetAIAnswer() {
  yield takeEvery(GET_COURSE_AI_ANSWER, getCourseAIAnswer);
}
export function* watchsaveAIAnswer() {
  yield takeEvery(SAVE_COURSE_AI_ANSWER, saveCourseAIAnswer);
}
function* coursesSaga() {
  yield all([
    fork(watchgetAllCourses),
    fork(watchgetCourseById),
    fork(watchgetCoursesByCategoryId),
    fork(watchgetEnrollToService),
    fork(watchgetSavePauseTime),
    fork(watchgetSavePauseTimeById),
    fork(watchgetProgressById),
    fork(watchgetaddToFavourite),
    fork(watchgetMarkAsComplete),
    fork(watchgetIsLike),
    fork(watchgetIsLatest),
    fork(watchgetAIAnswer),
    fork(watchsaveAIAnswer),
  ]);
}

export default coursesSaga;
