import { BASE_URL } from "../../../helpers/Constants";
import { GET_MEMBERS, GET_MEMBER_DETAIL, GET_MEMBER_LOYALITY_DETAIL } from "./actionType";
import {
  getMembersSuccess,
  getMembersError,
  getMemberDetailError,
  getMemberDetailSuccess,
  getMemberLoyalityDetailSuccess,
  getMemberLoyalityDetailError,
} from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAllMembers(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(
      BASE_URL + "api/allMembers?page=" + data.payload.pageNo,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getMembersSuccess(response.result));
    } else {
      yield put(getMembersError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getMembersError(error.message));
  }
}

function* getMemberById(data) {
  try {
    const response = yield fetch(
      BASE_URL + "api/memeberDetailByIdV2?member_id=" + data.payload.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getMemberDetailSuccess(response.result));
    } else {
      yield put(getMemberDetailError(response.message));
    }
  } catch (error) {
    yield put(getMemberDetailError(error.message));
  }
}

function* getLoyalityMemberById(data) {
  try {
    const response = yield fetch(
      BASE_URL + "api/loyaltyList",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + data.payload.token,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(getMemberLoyalityDetailSuccess(response.result));
    } else {
      yield put(getMemberLoyalityDetailError(response.message));
    }
  } catch (error) {
    yield put(getMemberLoyalityDetailError(error.message));
  }
}

export function* watchgetAllMembers() {
  yield takeEvery(GET_MEMBERS, getAllMembers);
}
export function* watchgetMembersById() {
  yield takeEvery(GET_MEMBER_DETAIL, getMemberById);
}
export function* watchgetLoyalityMembersById() {
  yield takeEvery(GET_MEMBER_LOYALITY_DETAIL, getLoyalityMemberById);
}

function* membersSaga() {
  yield all([fork(watchgetAllMembers), fork(watchgetMembersById), fork(watchgetLoyalityMembersById)]);
}

export default membersSaga;
