import React, { Component } from "react";
import AuthHeader from "../../../layout/auth/Aheader";
import Banner from "../../../layout/auth/Abanner";
import Header from "../../../layout/gaust/Header";
import Login from "../../../components/gaust/login";

import Footer from "../../../layout/auth/Afooter";
import Courses from "./index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class AuthDashboard extends Component {
  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    //console.log('value', value);
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
  }

  render() {
    return (
      <div>
        {this.props.token ? (
          <AuthHeader token={this.props.token} history={this.props.history} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <Banner token={this.props.token} />
        <Courses token={this.props.token} />
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthDashboard));
