export const GET_VIDEO = "GET_VIDEO";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";

export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";

export const GET_VIDEO_DETAIL = "GET_VIDEO_DETAIL";
export const GET_VIDEO_DETAIL_SUCCESS = "GET_VIDEO_DETAIL_SUCCESS";
export const GET_VIDEO_DETAIL_ERROR = "GET_VIDEO_DETAIL_ERROR";

export const GET_VIDEOS_BY_CATEGORY_ID = "GET_VIDEOS_BY_CATEGORY_ID";
export const GET_VIDEOS_BY_CATEGORY_ID_SUCCESS =
  "GET_VIDEOS_BY_CATEGORY_ID_SUCCESS";
export const GET_VIDEOS_BY_CATEGORY_ID_ERROR =
  "GET_VIDEOS_BY_CATEGORY_ID_ERROR";

export const GET_PAUSE_TIME = "GET_PAUSE_TIME";
export const GET_PAUSE_TIME_SUCCESS = "GET_PAUSE_TIME_SUCCESS";
export const GET_PAUSE_TIME_ERROR = "GET_PAUSE_TIME_ERROR";

export const SET_PAUSE_TIME = "SET_PAUSE_TIME";
export const SET_PAUSE_TIME_SUCCESS = "SET_PAUSE_TIME_SUCCESS";
export const SET_PAUSE_TIME_ERROR = "SET_PAUSE_TIME_ERROR";
