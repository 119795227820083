import React, { Component } from "react";

import Login from "../../gaust/login";
import SubscriptionCheck from "../blogs/subscriptionCheck";
import SharePopUpModel from "../videos/SharedPopUpModel";

import ReactHtmlParser from "react-html-parser";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class BlogResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionModel: false,
      showLoginModel: false,
      tagName: "",
    };
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  goBlog = (blog) => {
    this.props.history.push(
      `/blog/${blog.blog_category_url}/${blog.blog_title_url}`
    );
  };

  goBuySubscription = (tag) => {
    window.$("#subsModel1").modal("show");
    this.setState({
      tagName: tag,
    });
  };

  goLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props?.BlogList?.length > 0 && (
          <React.Fragment>
            <SubscriptionCheck
              tag={this.state.tagName}
              history={this.props.history}
            />

            <div className="blog-row page-height">
              <div className="container">
                <div className="blog-row-header">
                  <h2>Blogs Result</h2>
                </div>
                <div className="blog-slider">
                  <div className="row">
                    {this.props?.BlogList?.map((blog, index) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                        <div className="blog-sld-box">
                          <div className="blog-sld-img">
                            <img alt="Banner" src={blog.banner_url} />
                          </div>
                          <div className="blog-sld-cnt">
                            <div className="blog-sld-top">
                              <h2 className="title">{blog.blog_title}</h2>
                              <div className="blogDesc">
                                {ReactHtmlParser(blog.preview_description)}
                              </div>
                            </div>
                            <div className="blog-sld-footer">
                              <img
                                alt="img"
                                src="/assets/images/course-slider-3.jpg"
                              />
                              <h5>Richard</h5>
                              <p>{blog.created_at}</p>
                            </div>
                            {!this.props.token ? (
                              <button
                                onClick={this.goLogin}
                                className="dlt-blg"
                              >
                                Read More
                              </button>
                            ) : blog.is_subscribed == 1 ? (
                              <button
                                onClick={() => this.goBlog(blog)}
                                className="dlt-blg"
                              >
                                Read More
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  this.goBuySubscription(blog.tag_name)
                                }
                                className="dlt-blg"
                              >
                                Read More
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {this.state.showLoginModel === true && <Login />}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlogResult));
