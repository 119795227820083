import {
  GET_VIDEO,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  GET_BANNER,
  GET_BANNER_SUCCESS,
  GET_BANNER_ERROR,
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_ERROR,
  GET_VIDEOS_BY_CATEGORY_ID,
  GET_VIDEOS_BY_CATEGORY_ID_SUCCESS,
  GET_VIDEOS_BY_CATEGORY_ID_ERROR,
  SET_PAUSE_TIME,
  SET_PAUSE_TIME_SUCCESS,
  SET_PAUSE_TIME_ERROR,
  GET_PAUSE_TIME,
  GET_PAUSE_TIME_SUCCESS,
  GET_PAUSE_TIME_ERROR,
} from "./actionType";

export const getVideo = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_VIDEO,
    payload: data,
  };
};

export const getVideoSuccess = (data) => {
  return {
    type: GET_VIDEO_SUCCESS,
    payload: data,
  };
};

export const getVideoError = (error) => {
  return {
    type: GET_VIDEO_ERROR,
    payload: error,
  };
};

export const getBanner = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_BANNER,
    payload: data,
  };
};

export const getBannerSuccess = (data) => {
  return {
    type: GET_BANNER_SUCCESS,
    payload: data,
  };
};

export const getBannerError = (error) => {
  return {
    type: GET_BANNER_ERROR,
    payload: error,
  };
};

export const getVideoDetail = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_VIDEO_DETAIL,
    payload: data,
  };
};

export const getVideoDetailSuccess = (data) => {
  return {
    type: GET_VIDEO_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getVideoDetailError = (error) => {
  return {
    type: GET_VIDEO_DETAIL_ERROR,
    payload: error,
  };
};

export const getVideosByCategoryId = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_VIDEOS_BY_CATEGORY_ID,
    payload: data,
  };
};

export const getVideosByCategoryIdSuccess = (data) => {
  return {
    type: GET_VIDEOS_BY_CATEGORY_ID_SUCCESS,
    payload: data,
  };
};

export const getVideosByCategoryIdError = (error) => {
  return {
    type: GET_VIDEOS_BY_CATEGORY_ID_ERROR,
    payload: error,
  };
};

export const savePauseTime = (data) => {
  return {
    type: SET_PAUSE_TIME,
    payload: data,
  };
};

export const savePauseTimeSuccess = (data) => {
  return {
    type: SET_PAUSE_TIME_SUCCESS,
    payload: data,
  };
};

export const savePauseTimeError = (error) => {
  return {
    type: SET_PAUSE_TIME_ERROR,
    payload: error,
  };
};

export const getPauseTime = (data) => {
  //console.log('akshay', data)
  return {
    type: GET_PAUSE_TIME,
    payload: data,
  };
};

export const getPauseTimeSuccess = (data) => {
  return {
    type: GET_PAUSE_TIME_SUCCESS,
    payload: data,
  };
};

export const getPauseTimeError = (error) => {
  return {
    type: GET_PAUSE_TIME_ERROR,
    payload: error,
  };
};
