import {
  GET_ALL_ACTIVITY,
  GET_ALL_ACTIVITY_SUCCESS,
  GET_ALL_ACTIVITY_ERROR,
} from "./actionType";

export const getAllActivity = (data) => {
  //console.log('datadatadata', data)
  return {
    type: GET_ALL_ACTIVITY,
    payload: data,
  };
};

export const getAllActivitySuccess = (data) => {
  return {
    type: GET_ALL_ACTIVITY_SUCCESS,
    payload: data,
  };
};

export const getAllActivityError = (error) => {
  return {
    type: GET_ALL_ACTIVITY_ERROR,
    payload: error,
  };
};
