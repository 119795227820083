import {
	GET_SUBSCRIPTIONS_LIST,
	GET_SUBSCRIPTIONS_LIST_SUCCESS,
	GET_SUBSCRIPTIONS_LIST_ERROR,
	SUBSCRIPTIONS_PAYMENT,
	SUBSCRIPTIONS_PAYMENT_SUCCESS,
	SUBSCRIPTIONS_PAYMENT_ERROR,
	ITEM_CHECKOUT,
	ITEM_CHECKOUT_SUCCESS,
	ITEM_CHECKOUT_ERROR,
} from "./actionTypes"

export const getSubnscriptionList = (data) => {
	//console.log('datadatadata', data)
	return {
		type: GET_SUBSCRIPTIONS_LIST,
		payload: data,
	}
}

export const getSubnscriptionListSuccess = (data) => {
	return {
		type: GET_SUBSCRIPTIONS_LIST_SUCCESS,
		payload: data,
	}
}

export const getSubnscriptionListError = (error) => {
	return {
		type: GET_SUBSCRIPTIONS_LIST_ERROR,
		payload: error,
	}
}

export const subscriptionPayment = (data) => {
	return {
		type: SUBSCRIPTIONS_PAYMENT,
		payload: data,
	}
}

export const subscriptionPaymentSuccess = (data) => {
	return {
		type: SUBSCRIPTIONS_PAYMENT_SUCCESS,
		payload: data,
	}
}

export const subscriptionPaymentError = (error) => {
	return {
		type: SUBSCRIPTIONS_PAYMENT_ERROR,
		payload: error,
	}
}

export const itemCheckout = (data) => {
	return {
		type: ITEM_CHECKOUT,
		payload: data,
	}
}

export const itemCheckoutSuccess = (data) => {
	return {
		type: ITEM_CHECKOUT_SUCCESS,
		payload: data,
	}
}

export const itemCheckoutError = (error) => {
	return {
		type: ITEM_CHECKOUT_ERROR,
		payload: error,
	}
}
