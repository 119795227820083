import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthHeader from "../../../layout/auth/Aheader";
import AuthFooter from "../../../layout/auth/Afooter";
import { BASE_URL, stripePromise } from "../../../helpers/Constants";
import axios from "axios";
import Header2 from "../../../layout/gaust/newLayout/Header2";
import { getMemberLoyalityDetail } from "../../../store/protected/members/action";
import * as clipboard from "clipboard-polyfill";
import { getSubnscriptionList } from "../../../store/protected/subscription/action";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import "./affiliate.css";
const APP_URL = process.env.REACT_APP_URL || "https://themaetrix.com/";
class LoyalityPoints extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    this.props.dispatchSubscription({
      token: this.props.token,
    });
    this.props.getMemberLoyalityDetail({
      token: this.props.token,
    });
    this.setState({
      link: `${APP_URL}referral?code=${this.props.userName.referral_code}`,
    });
  }
  onClickCopy = (url) => {
    clipboard.writeText(url).then(
      () => {
        toast.success("Copied!", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      () => {
        toast.error("Failed to copy", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };
  render() {
    console.log(this.props);
    return (
      <div className="affiliate">
        <ToastContainer />
        <AuthHeader token={this.props.token} />
        <section className="container">
          <img src={"/assets/images/affiliate.png"} />
          <div className="affiliate-body container">
            <div className="row d-flex">
              <div className="col-md-4 left-panel">
                <p>
                  Link clicks ={" "}
                  {this.props?.memberLoyalityDetail?.link_clicks
                    ? this.props?.memberLoyalityDetail?.link_clicks
                    : 0}
                </p>
                <p>
                  Clients Active ={" "}
                  {this.props?.memberLoyalityDetail?.clients_active
                    ? this.props?.memberLoyalityDetail?.clients_active
                    : 0}
                </p>
                <p>
                  Points Earned ={" "}
                  {this.props?.memberLoyalityDetail?.total_points
                    ? this.props?.memberLoyalityDetail?.total_points
                    : 0}
                </p>
              </div>
              <div className="col-md-8 right-panel">
                <p>Your affiliate link</p>
                <span>YOUR OFFER</span>
                <ul className="affiliate-offers">
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>
                    Earn 10% on all subscription income{" "}
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>
                    Lifetime commissions*{" "}
                  </li>
                </ul>
                <div className="d-flex items-center">
                  <input value={this.state.link}></input>
                  <button
                    className="btn"
                    onClick={() => this.onClickCopy(this.state.link)}
                  >
                    Copy
                  </button>
                </div>
                <div className="social-buttons">
                  <p>Share this link with</p>
                  <div>
                    <FacebookShareButton url={this.state.link}>
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TelegramShareButton url={this.state.link}>
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <TwitterShareButton url={this.state.link}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={this.state.link}>
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptionsList: state.subscriptionsListReducer.subscriptionsList,
    userName: state.authReducer?.user?.user,
    memberLoyalityDetail: state.memberReducer?.memberLoyalityDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSubscription: (data) => dispatch(getSubnscriptionList(data)),
    getMemberLoyalityDetail: (data) => dispatch(getMemberLoyalityDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoyalityPoints));
