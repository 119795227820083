import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class LastViewService extends PureComponent {
  render() {
    //console.log('loggggg', this.props.latestServices);
    return (
      <React.Fragment>
        <div className="col-lg-4 col-md-5">
          <div className="dashboard-prof">
            <div className="prof-img">
              <img
                alt="img"
                src={this.props?.latestServices?.profile_image}
                className="profile"
              />
              <h2>
                Welcome !<span>{this.props?.latestServices?.user_name}</span>
              </h2>
            </div>
            {this.props?.latestServices?.service_category == "course" ? (
              <Link
                to={`/course/${this.props?.latestServices?.service_category_url}/${this.props?.latestServices?.service_id}/${this.props?.latestServices?.service_title_url}`}
              >
                <div className="prof-video">
                  <div className="video-play-sec">
                    <button type="button" className="ply-btn">
                      <img alt="img" src="/assets/images/Play-btn.png" />
                    </button>
                  </div>
                  <img
                    alt="img"
                    src={this.props?.latestServices?.service_thumbnail}
                  ></img>
                </div>
              </Link>
            ) : (
              <Link
                to={`/video/${this.props?.latestServices?.service_category_url}/${this.props?.latestServices?.service_title_url}`}
              >
                <div className="prof-video">
                  <div className="video-play-sec">
                    <button type="button" className="ply-btn">
                      <img alt="img" src="/assets/images/Play-btn.png" />
                    </button>
                  </div>
                  <img
                    alt="img"
                    src={this.props?.latestServices?.service_thumbnail}
                  ></img>
                </div>
              </Link>
            )}
            <div className="prof-footer">
              <h2>{this.props?.latestServices?.service_name}</h2>
              <h3 className="status">
                {this.props?.latestServices?.complete_percentage}% Completed
              </h3>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    latestServices: state.dashboardReducer.latestServices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LastViewService));
