import React, { Component } from "react";
import AuthHeader from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";
import Header from "../../../layout/gaust/Header";
import PodcastSubscriptionCheck from "./PodcastSubscriptionCheck";
import { BASE_URL } from "../../../helpers/Constants";
import { isLatestSuccess } from "../../../store/protected/courses/action";
import EmbededPop from "./embededPop";
import SharePopUpModel from "../videos/SharedPopUpModel";
import AudioPlayer from "react-h5-audio-player";
import ReactHtmlParser from "react-html-parser";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class PodcastDetail extends Component {

  state = {
    playing: true,
    showLoginModel: false,
    podcastUrl: "",
    podcastData: {},
    SubscriptionName: "",
  };

  componentDidMount() {
    axios
      .post(BASE_URL + "api/podcastDetail", {
        token: this.props.token,
        podcast_title_url: this.props.match.params.name,
      })
      .then((response) => {
        console.log(response);
        this.setState(
          {
            podcastData: response?.data?.result,
          },
          () => {
            console.log("podcast data", this.state.podcastData);
          }
        );
      });
  }

  openHeaderContentPopup = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  onPlay = (name) => {
    this.setState({
      SubscriptionName: name,
    });
    window.$("#subsModel").modal("show");
  };

  onPlayLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  podcastCondition = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  goToLogin = () => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };

  playAudio = (service_id) => {
    console.log("play ausio", service_id);
    this.props.dispatchLatestService({
      id: service_id,
      category: "podcast",
      token: this.props.token,
    });
  };

  sharePop = (url) => {
    let str = '111' + this.props?.user?.user?.id + '111'; //userid=413
    let qrstr = '?q=' + str;
    const URL = window.location.origin;
    const podcast_url =
      URL +
      "/podcast/" +
      url?.category_title_url +
      "/" +
      url?.podcast_title_url + qrstr;
    this.setState({
      podcastUrl: podcast_url,
    });
    window.$("#shareModel").modal("show");
  };

  embeded = (data) => {
    window.$("#embededPupUp").modal("show");
  };

  render() {
    console.log("duration", this.state?.podcastData?.duration);
    let duration = this.state?.podcastData?.duration;
    return (
      <React.Fragment>
        <PodcastSubscriptionCheck
          token={this.props.token}
          history={this.props.history}
          name={this.state?.SubscriptionName}
        />
        <EmbededPop data={this.state.podcastData} />
        <SharePopUpModel url={this.state.podcastUrl} />
        {this.props?.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.openHeaderContentPopup} />
        )}
        <div className="podcast-row page-height">
          <div className="container podcast">
            <div className="podcast-list">
              {Object.keys(this.state?.podcastData).length > 0 && (
                <div className="offset-3 col-md-6 mb-3">
                  <div className="card w-100">
                    <img className="img-top" style={{ width: "150px" }} src={this?.state?.podcastData?.banner_url} loading="lazy" alt="Card image cap" />
                    <div className="card-body">
                      <h5 className="card-title">{this?.state?.podcastData?.duration}</h5>
                      <p>
                        {!this.props.token ? (
                          <div
                            onClick={this.podcastCondition}
                            className="demoAudio"
                          >
                            {" "}
                            <AudioPlayer
                              src=""
                              onPlayError={this.onPlayLogin}
                            />{" "}
                          </div>
                        ) : this?.state?.podcastData?.is_subscribed == 1 ? (
                          <AudioPlayer
                            src={this?.state?.podcastData?.podcast_url}
                            onPlay={() => this.playAudio(this?.state?.podcastData?.id)}
                            playing={false}
                          />
                        ) : (
                          <div
                            onClick={() =>
                              this.notSubscribed(this?.state?.podcastData?.tag_name)
                            }
                            className="demoAudio"
                          >
                            <div
                              onClick={this.onPlay}
                              className="demoAudio"
                            >
                              <AudioPlayer
                                src={this?.state?.podcastData?.podcast_url}
                                playing={false}
                                defaultDuration={this?.state?.podcastData?.duration}
                                defaultCurrentTime="00:00"
                                onPlayError={() =>
                                  this.onPlay(this?.state?.podcastData?.tag_name)
                                }
                              />
                            </div>
                          </div>
                        )}
                        {/* <span><a href="" className="btn-1"><img className="podcast_icon_image" src={"./../assets/new_layout/img/Path 3048.png"} /></a></span>
                                              <span><a href="" className="btn-2"><img className="podcast_icon_image" src={"./../assets/new_layout/img/play.png"} /></a></span>
                                              <span><a href="" className="btn-3"><img className="podcast_icon_image" src={"./../assets/new_layout/img/Path 3049.png"} /></a></span> */}
                      </p>
                      <hr />
                      <h4>{this?.state?.podcastData?.podcast_title} </h4>
                      <p className=""> {ReactHtmlParser(this?.state?.podcastData?.description)}</p>
                      <div className="side-div">
                        <p>
                          {!this.props.token ? (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/download (1).png"}
                              onClick={() =>
                                this.goToLogin()
                              }
                              className="link_cursor"
                            />
                          ) : this?.state?.podcastData?.is_subscribed == 1 ? (
                            <a href={this?.state?.podcastData?.podcast_url} download className="link_cursor">
                              <img
                                alt="icon"
                                src={"./../../assets/new_layout/img/download (1).png"}
                              />
                            </a>
                          ) : (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/download (1).png"}
                              onClick={() =>
                                this.notSubscribed(this?.state?.podcastData?.tag_name)
                              }
                              className="link_cursor"
                            />
                          )}<br />

                          {!this.props.token ? (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/share (1).png"}
                              onClick={() =>
                                this.goToLogin()
                              }
                              className="link_cursor"
                            />
                          ) : this?.state?.podcastData?.is_subscribed == 1 ? (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/share (1).png"}
                              onClick={() =>
                                this.sharePop(this?.state?.podcastData)
                              }
                              className="link_cursor"
                            />
                          ) : (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/share (1).png"}
                              onClick={() =>
                                this.notSubscribed(this?.state?.podcastData?.tag_name)
                              }
                              className="link_cursor"
                            />
                          )}<br />

                          {!this.props.token ? (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/coding.png"}
                              onClick={() =>
                                this.goToLogin()
                              }
                              className="link_cursor"
                            />
                          ) : this?.state?.podcastData?.is_subscribed == 1 ? (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/coding.png"}
                              onClick={() =>
                                this.embeded(this?.state?.podcastData)
                              }
                              className="link_cursor"
                            />
                          ) : (
                            <img
                              alt="icon"
                              src={"./../../assets/new_layout/img/coding.png"}
                              onClick={() =>
                                this.notSubscribed(this?.state?.podcastData.tag_name)
                              }
                              className="link_cursor"
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                // <div className="pdcst-box">
                //   <div className="music-box">
                //     <img
                //       alt="img"
                //       className="audio-img"
                //       src={this?.state?.podcastData?.banner_url}
                //     />
                //     {!this.props.token ? (
                //       <div
                //         onClick={this.podcastCondition}
                //         className="demoAudio"
                //       >
                //         <AudioPlayer
                //           src={this?.state?.podcastData?.podcast_url}
                //           playing={false}
                //           defaultDuration={this?.state?.podcastData?.duration}
                //           defaultCurrentTime="00:00"
                //           onPlayError={() => this.goToLogin()}
                //         />{" "}
                //       </div>
                //     ) : this?.state?.podcastData.is_subscribed == 1 ? (
                //       <AudioPlayer
                //         src={this?.state?.podcastData.podcast_url}
                //         onPlay={() =>
                //           this.playAudio(this?.state?.podcastData.id)
                //         }
                //         playing={false}
                //       />
                //     ) : (
                //       <div onClick={this.onPlay} className="demoAudio">
                //         <AudioPlayer
                //           src={this?.state?.podcastData?.podcast_url}
                //           playing={false}
                //           defaultDuration={this.state?.podcastData?.duration}
                //           defaultCurrentTime="00:00"
                //           onPlayError={() =>
                //             this.onPlay(this?.state?.podcastData.tag_name)
                //           }
                //         />
                //       </div>
                //     )}
                //     <div className="audio-cnt">
                //       <h2>{this?.state?.podcastData?.podcast_title}</h2>
                //       {ReactHtmlParser(this.state?.podcastData?.description)}
                //     </div>
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PodcastDetail));
