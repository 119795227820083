import React, { Component, Suspense } from "react";
import { connect } from "react-redux";

import { Switch, BrowserRouter, Route } from "react-router-dom";

// import { BrowserRouter as Router, Route, Switch } from "react-router";

import { authProtectedRoutes } from "./routes/auth-routes";
import { publicRoutes } from "./routes/public-routes";
import AestheticRoute from "./routes/route";
import Error404 from "../src/components/NotFound/NotFound";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import firebase from "./firebase";

// import NotFound from "../src/components/NotFound/NotFound";
// import NotFound from "./components/NotFound/NotFound";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

class Aesthetic extends Component {
  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          localStorage.setItem("device_token", token);
        })
        .catch((error) => {
          console.log("Please allow Notification");
        });
    }
  }

  render() {
    return (
      <>
        <BrowserRouter forceRefresh={true}>
          {/* <Router forceRefresh={true}> */}
          <Route component={ScrollToTop} />

          <Suspense
            fallback={
              <div className="spinner-border text-danger" role="status">
                {" "}
                <span className="sr-only">Loading...</span>
              </div>
            }
          >
            <Switch>
              {!this.props?.user || this.props?.user === null
                ? publicRoutes.map((route, idx) => (
                    <AestheticRoute
                      props={this.props}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                      key={idx}
                      isAuthProtected={false}
                    />
                  ))
                : null}
              {this.props?.user
                ? authProtectedRoutes.map((route, idx) => (
                    <AestheticRoute
                      props={this.props}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                      key={idx}
                      isAuthProtected={true}
                    />
                  ))
                : null}
              <Route component={Error404} />
              {/* <Route component={NotFound} /> */}

              {/* <Route path="*">
                <NotFound />
              </Route> */}
            </Switch>
          </Suspense>
          {/* </Router> */}
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  };
};

export default connect(mapStateToProps, null)(Aesthetic);
