import React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import {
  getCoursesByCategoryId,
  getCourse,
} from "../../../store/protected/courses/action";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

class courseCategory extends React.Component {
  responsive = {
    0: {
      items: 2,
    },
    450: {
      items: 3,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
  };

  componentDidMount() {
    this.props.dispatchCoursesByCategoryId({
      id: this.props.match.params.name,
    });

    this.props.dispatchCourse({
      token: this.props?.token,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.name != this.props?.match?.params?.name &&
      this.props?.match?.params?.name != null
    ) {
      this.props.dispatchCoursesByCategoryId({
        id: this.props.match.params.name,
      });
    }
  }
  render() {
    const galleryItems = this.props?.getCourses?.data?.map((list, index) => (
      <div className="item" keys={list.id}>
        {this.props?.token ? (
          <Link to={`/course/${list?.category_title_url}`}>
            <img alt="img" src={list?.category_icon_url} />{" "}
            {list?.category_name}
          </Link>
        ) : (
          <Link to={`/course/${list?.category_title_url}`}>
            <img alt="img" src={list?.category_icon_url} />{" "}
            {list?.category_name}
          </Link>
        )}
      </div>
    ));
    return (
      <div className="course-main-top">
        <AliceCarousel
          items={galleryItems}
          responsive={this.responsive}
          buttonsDisabled={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCourses: state.coursesReducer.courses,
    token: state.authReducer?.user?.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCourse: (data) => dispatch(getCourse(data)),
    dispatchCoursesByCategoryId: (data) =>
      dispatch(getCoursesByCategoryId(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(courseCategory));
