import React, { Component } from "react";
import LiveStreamingVideo from "./LiveStreamingVideo";
import LiveStreamingChat from "./LiveStreamingChat";
import CustomeHeader from "../../../layout/auth/customHeader";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../helpers/Constants";

class Main extends Component {
  state = {
    showLoginModel: false,
  };

  componentDidMount = async () => {
    // document.title = "Maetrix Live :: The Maetrix";
    const url = `${BASE_URL}api/streamData`;
    axios
      .post(
        url,
        {},
        {
          headers: { authorization: `Bearer ${this.props.token}` },
        }
      )
      .then((response) => {
        this.setState({
          response: response?.data?.result,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  openLogin = (event) => {
    this.setState(
      {
        showLoginModel: true,
      },
      () => {
        window.$("#login-model").modal("show");
      }
    );
  };
  render() {
    return (
      <React.Fragment>
        <CustomeHeader />
        {!this.props?.token ? (
          <>
            <div class="container page-height">
              <div class="vid-strm-close">
                <div class="vid-overlay"></div>
                <div class="vid-cnt">
                  <img
                    alt="Maetrix logo"
                    src="/assets/new_layout/img/logo.png"
                    class="strm-logo"
                  />
                  <h3>Please login and continue</h3>
                  <button onClick={this.openLogin}>Login</button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="live-strm-row">
            {this?.state?.response?.stream_status === true &&
              this.state?.response?.is_subscribed === true && (
                <div className="container">
                  <div className="row">
                    <LiveStreamingVideo response={this?.state?.response} />
                    <LiveStreamingChat
                      userDetail={this.props.userName}
                      response={this?.state?.response}
                      token={this.props?.token}
                    />
                  </div>
                </div>
              )}
            {this.state?.response?.is_subscribed === false && (
              <>
                <div class="container page-height">
                  <div class="vid-strm-close">
                    <div class="vid-overlay"></div>
                    <div class="vid-cnt">
                      <img
                        alt="img"
                        src="/assets/new_layout/img/logo.png"
                        class="strm-logo"
                      />
                      <h3>{`Choose the Subscription "${this.state?.response?.subscription_name}" to watch this Live Streaming`}</h3>
                      <a href="/buy-subscriptions">
                        <button>Buy Subscription</button>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
            {this.state?.response?.stream_status === false &&
              this.state?.response?.is_subscribed === true && (
                <div class="container">
                  <img alt="img" src="assets/images/strm-img-auth.jpg" />
                </div>
              )}
          </div>
        )}
        <Footer />
        {this.state.showLoginModel === true && <Login />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userName: state.authReducer?.user?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
