import {
  GET_AI_WORKBOOK,
  GET_AI_WORKBOOK_SUCCESS,
  GET_AI_WORKBOOK_ERROR,
  DELETE_AI_WORKBOOK,
  DELETE_AI_WORKBOOK_SUCCESS,
  DELETE_AI_WORKBOOK_ERROR,
} from "./actionType";
const initialState = {
  error: null,
  loading: null,
  allAIWorkBook: [],
};

const workBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AI_WORKBOOK:
      state = {
        error: null,
        loading: true,
        allAIWorkBook: [],
      };
      break;

    case GET_AI_WORKBOOK_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        allAIWorkBook: action.payload,
      };
      break;

    case GET_AI_WORKBOOK_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        allAIWorkBook: [],
      };
      break;
    case DELETE_AI_WORKBOOK:
      state = {
        ...state,
        error: null,
        loading: true,
      };
      break;

    case DELETE_AI_WORKBOOK_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        allAIWorkBook: state.allAIWorkBook
          .filter((item) => item.id != action.payload)
          .concat([]),
      };
      break;

    case DELETE_AI_WORKBOOK_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
};
export default workBookReducer;
