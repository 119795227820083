import React, { PureComponent } from "react";
import { BASE_URL } from "../../../helpers/Constants";

import AuthHeader from "../../../layout/auth/Aheader";
import Header from "../../../layout/gaust/Header";
import Footer from "../../../layout/auth/Afooter";
import Login from "../../gaust/login/index";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SupportFeedback extends PureComponent {
  state = {
    showLoginModel: false,
    faq: null,
    value: "",
    submitResponse: "",
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.token) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    } else {
      if (this.state.value == "") {
        alert("Please Enter Question");
      } else {
        let url = `${BASE_URL}api/userFaq`;
        axios
          .post(
            url,
            {
              feedback: this.state.value,
            },
            {
              headers: { authorization: `Bearer ${this.props?.token}` },
            }
          )
          .then((res) => {
            this.setState(
              {
                submitResponse: res.data.message,
                value: "",
              },
              () => {
                if (
                  this.state.submitResponse !== "" &&
                  this.state.submitResponse != null
                ) {
                  toast.success(
                    this.state.submitResponse
                      ? this.state.submitResponse
                      : "Your Review Submit Successfully!!",
                    {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }
              }
            );
          });
      }
    }
  };

  myFunction = (value) => {
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };

  async getFAQ() {
    const url = `${BASE_URL}api/faqList`;
    const response = await axios.get(url, {});
    this.setState({
      faq: response?.data?.result?.faqs,
    });
  }

  componentDidMount() {
    // document.title = "Support And Feedback :: The Maetrix";
    this.getFAQ();
  }

  render() {
    return (
      <div>
        <ToastContainer />
        {this.props.token ? (
          <AuthHeader token={this.props.token} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <div className="container page-height">
          <div className="support-row">
            <h1>Support & Feedback</h1>
            <div className="course-desc-list" id="course-list">
              <h2>FAQs</h2>
              {this.state.faq &&
                this.state?.faq?.map((list, index) => (
                  <div className="course-cnt-box" key={index}>
                    <div className="course-desc-head">
                      <h6>
                        <a
                          href={`#collapseOne${index}`}
                          data-toggle="collapse"
                          className="collapsible-link"
                        >
                          {list.question}
                        </a>
                      </h6>
                    </div>
                    <div id={`collapseOne${index}`} className="collapse">
                      <div className="course-desc-box">
                        <p>{list.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="feedback-form">
              <form onSubmit={this.handleSubmit}>
                <h2>Did not get what you want, let us know ?</h2>
                <textarea
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder="Write something here..."
                ></textarea>
                <button className="sbm">Send Us</button>
              </form>
            </div>
          </div>
        </div>
        <Footer token={this.props.token} />
        {this.state.showLoginModel === true && <Login />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SupportFeedback));
