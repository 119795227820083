import React, {
  Component,
  PureComponent,
  useRef,
  useState,
  useEffect,
} from "react";

import Login from "../../../components/gaust/login";
import AuthHeader from "../../../layout/auth/Aheader";
// import AuthHeader from "../../../layout/gaust/newLayout/Header2";
import Header from "../../../layout/gaust/newLayout/Header";
import Footer from "../../../layout/gaust/newLayout/Footer";
import BoxsetsSlider from "./BoxsetsSlider";
import Entrepreneurship from "./Entrepreneurship";
import NewEntrepreneurship from "./NewEntrepreneurship";
import Goal from "./Goal";
import Growth from "./Growth";
import Opportunity from "./Opportunity";
import Plan from "./Plan";
import PopularTabs from "./PopularTabs";
import NewPopularTabs from "./NewPopularTabs";
import PopularTopics from "./PopularTopics";
import { getAllServices } from "../../../store/protected/dashboard/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Courses from "./index";

class BoxsetsMain extends Component {
  /*
//const BoxsetsMain = (props) => {
    const [showLoginModel, setShowLoginModel] = useState(false);

    const loginModel = (value) => {
        if (value === true) {
            setShowLoginModel(true);
            window.$("#login-model").modal("show");
        }
    }

    useEffect(() => {
        props.dispatchServicesToken({});
    }, []);

    const myFunction = (value) => {
        setShowLoginModel(true);
        window.$("#login-model").modal("show");
    };
    */

  state = {
    showLoginModel: false,
  };

  myFunction = (value) => {
    //console.log('value', value);
    if (value === true) {
      this.setState(
        {
          showLoginModel: true,
        },
        () => {
          window.$("#login-model").modal("show");
        }
      );
    }
  };
  componentDidMount() {
    // document.title = "Courses :: The Maetrix";
  }

  render() {
    return (
      <div>
        {this.props.token ? (
          <AuthHeader token={this.props.token} history={this.props.history} />
        ) : (
          <Header hitHome={this.myFunction} />
        )}
        <BoxsetsSlider />
        {/* <PopularTabs /> */}
        <NewPopularTabs />
        {/* <PopularTopics /> */}
        {/* <Entrepreneurship /> */}
        <NewEntrepreneurship
          token={this.props.token}
          history={this.props.history}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    services: state.dashboardReducer.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BoxsetsMain));
