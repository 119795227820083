import { BASE_URL } from "../../../helpers/Constants";

import {
  GET_SUBSCRIPTIONS_LIST,
  SUBSCRIPTIONS_PAYMENT,
  ITEM_CHECKOUT,
} from "./actionTypes";

import {
  getSubnscriptionListSuccess,
  getSubnscriptionListError,
  subscriptionPaymentSuccess,
  subscriptionPaymentError,
  itemCheckoutSuccess,
  itemCheckoutError,
} from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAllSubscriptionList(data) {
  //console.log('enter api function', data)
  try {
    const response = yield fetch(
      BASE_URL + "api/subscriptionList?token=" + data.payload.token,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          //"Authorization": "Bearer " + data.payload.token
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getSubnscriptionListSuccess(response.result));
    } else {
      yield put(getSubnscriptionListError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getSubnscriptionListError(error.message));
  }
}

function* postSuscriptionPayment(data) {
  console.log("saga data", data);

  try {
    let payload = {
      services: data.payload.data.services,
      name_on_card: data.payload.data.name_on_card,
      card_number: data.payload.data.card_number,
      cvc: parseInt(data.payload.data.cvc),
      exp_month: parseInt(data.payload.data.exp_month),
      exp_year: parseInt(data.payload.data.exp_year),
    };
    const response = yield fetch(BASE_URL + "api/buyServices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(subscriptionPaymentSuccess(response));
    } else {
      yield put(
        subscriptionPaymentError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(subscriptionPaymentError("Something went wrong"));
  }
}

function* postCheckoutPayment(data) {
  // console.log('saga data', data);

  try {
    let payload = {
      services: data.payload.data.services,
    };
    //const response = yield fetch(BASE_URL + "api/createSessionForPaymentTest", {
    const response = yield fetch(BASE_URL + "api/createSessionForPayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(itemCheckoutSuccess(response));
    } else {
      yield put(
        itemCheckoutError(
          response.message ? response.message : "Something went wrong"
        )
      );
      // toast(response.message ? response.message : 'Registration successfull');
    }
  } catch (error) {
    console.log("catch error", error);
    yield put(itemCheckoutError("Something went wrong"));
  }
}

export function* watchgetAllSubscriptionList() {
  yield takeEvery(GET_SUBSCRIPTIONS_LIST, getAllSubscriptionList);
}

export function* watchgetPostSubscriptionPayment() {
  yield takeEvery(SUBSCRIPTIONS_PAYMENT, postSuscriptionPayment);
}

export function* watchgetPostItemCheckoutPayment() {
  yield takeEvery(ITEM_CHECKOUT, postCheckoutPayment);
}

function* subscriptionsListSaga() {
  yield all([
    fork(watchgetAllSubscriptionList),
    fork(watchgetPostSubscriptionPayment),
    fork(watchgetPostItemCheckoutPayment),
  ]);
}

export default subscriptionsListSaga;
