import { BASE_URL } from "../../../helpers/Constants";
import firebase from "firebase";
import { ToastContainer, toast } from "react-toastify";
import {
  LOGIN,
  REGISTER,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "./actionTypes";
import {
  loginSuccess,
  registerUserSuccess,
  registerUserError,
  logoutSuccess,
  logoutError,
  loginError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

import { takeEvery, takeLatest, fork, put, all } from "redux-saga/effects";

function* login(data) {
  try {
    let formData = new FormData();
    formData.append("email", data.payload.email);
    formData.append("password", data.payload.password);
    formData.append("device_token", data.payload.device_token);
    const response = yield fetch(BASE_URL + "api/login", {
      method: "POST",

      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      const userData = {
        email: data.payload.email,
        password: data.payload.password,
      };
      // firebase.auth().signInWithEmailAndPassword(userData.email, userData.password).then((ref) => {
      //   let user = ref?.user;
      //   let starCountRef = firebase.database().ref("/users/" + user.uid);
      //   starCountRef.on("value", function (snapshot) {
      //     var user = firebase.auth().currentUser;

      //     if (user != null) {
      //       firebase
      //         .database()
      //         .ref("/active/" + user.uid)
      //         .set({
      //           name: snapshot?.val()?.userName,
      //           profilePicture: snapshot?.val()?.profilePicture,
      //           timestamp: firebase.database.ServerValue.TIMESTAMP,
      //         })
      //         .then(() => {
      //           console.log("Active user created");
      //         });
      //     }
      //   });
      // })
      //   .catch((error) => {
      //     console.log("error message", error.message);
      //   });

      yield put(loginSuccess(response.result));
      if (response.result) {
        const key = localStorage.getItem("url_params");
        data.payload.url === "/home" || data.payload.url === "/"
          ? data.payload.history.push("/dashboard")
          : data.payload.history.push({
              pathname: data.payload.url,
              search: key,
            });

        // setTimeout(() => {
        //   data.payload.url === "/home" || data.payload.url === "/"
        //      data.payload.history.push("/dashboard")
        //     : data.payload.history.push(data.payload.url);
        // }, 5000);
      }
    } else {
      yield put(loginError(response.message));
    }
  } catch (error) {
    console.log("catch error", error);
  }
}

function* register(data) {
  try {
    let formData = new FormData();
    formData.append("name", data.payload.name);
    formData.append("email", data.payload.email);
    formData.append("referral_code", data.payload.referral_code);
    formData.append("password", data.payload.password);
    formData.append("confirm_password", data.payload.confirm_password);

    const response = yield fetch(BASE_URL + "api/register", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      toast.success(
        "Thank you for the registration.Please check your email to activate the account",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      yield put(registerUserSuccess(response.result));
    } else {
      yield put(
        registerUserError(
          response.message ? response.message : "Something went wrong"
        )
      );
    }
  } catch (error) {
    console.log("catch error", error);
  }
}

function* logoutUser(data) {
  console.log("data", data.payload);
  try {
    const response = yield fetch(BASE_URL + "api/logout", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + data.payload.token,
      },
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(logoutSuccess(response.result));
      localStorage.clear();
      sessionStorage.clear();
      data.payload.history.push("/");
    } else {
      yield put(
        logoutError(
          response.message ? response.message : "Something went wrong"
        )
      );
      localStorage.clear();
      data.payload.history.push("/");
    }
  } catch (error) {
    console.log("catch error", error);
  }
}

function* forgotPassword(data) {
  try {
    let formData = new FormData();

    formData.append("email", data.payload.email);
    const response = yield fetch(BASE_URL + "api/forgotPassword", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      toast.success("Please check your email to change password", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(forgotPasswordSuccess(response));
    } else {
      yield put(
        forgotPasswordError(
          response.message ? response.message : "Something went wrong"
        )
      );
    }
  } catch (error) {
    console.log("catch error", error);
  }
}

function* resetPassword(data) {
  try {
    let formData = new FormData();
    formData.append("token", data.payload.token);
    formData.append("new_password", data.payload.new_password);
    formData.append("confirm_password", data.payload.confirm_password);
    const response = yield fetch(BASE_URL + "api/changePassword", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      yield put(resetPasswordSuccess(response));
      data.payload.history.push("/");
    } else {
      yield put(
        resetPasswordError(
          response.message ? response.message : "Something went wrong"
        )
      );
    }
  } catch (error) {
    console.log("catch error", error);
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN, login);
}

export function* watchRegister() {
  yield takeLatest(REGISTER, register);
}

export function* watchLogout() {
  yield takeEvery(LOGOUT, logoutUser);
}

export function* watchforgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchRegister),
    fork(watchLogout),
    fork(watchforgotPassword),
    fork(watchResetPassword),
  ]);
}

export default authSaga;
