import React, { PureComponent } from "react";

import Header from "../../../layout/auth/Aheader";
import Footer from "../../../layout/auth/Afooter";
import {
  getAIContent,
  deleteAIContent,
} from "../../../store/protected/ai-content/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DataLoading from "../../common/DataLoading";
import DateFormat from "../../../helpers/DateHelper";
import ReactMarkdown from "react-markdown";
import html2pdf from "html2pdf.js";
import "./ContentGenerator.css";
import htmlDocx from 'html-docx-js';
import { saveAs } from 'file-saver';

class ContentGenerator extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    downloadType: "DOC"
  };
  componentDidMount() {
    this.props.dispatchGetAIContent({
      token: this.props.token,
    });
  }
  deleteAI = async (item) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
    if (confirmation)
      this.props.dispatchDeleteAIContent({
        id: item.id,
        token: this.props.token,
      });
  };
  handleChangeSaveType = (type) => {
    console.log(type.target.value)
    this.setState({
      downloadType: type.target.value
    })
  }
  download = () => {
    if (this.state.downloadType == "PDF") {
      this.downloadPDF()
    } else {
      this.downloadDoc()
    }
  }
  downloadPDF = async () => {
    const element = document.getElementById("content");
    const options = {
      filename: "Insight-Engine.pdf",
      image: { type: "jpeg", quality: 1 }, // Optional
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      html2canvas: { scale: 3, letterRendering: true }, // Optional
      jsPDF: {
        unit: "in",
        format: "A2",
        orientation: "portrait",
      }, // Optional
    };

    html2pdf().from(element).set(options).save();
  };
  downloadDoc = async () => {
    var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + document.getElementById("content").innerHTML + footer;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'Insight-Engine.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };
  TypeFormat = (type) => {
    switch (type) {
      case "video":
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/video.png"></img>
            <p>Video Script</p>
          </div>
        );
      case "blog":
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/blog.png"></img>
            <p>Blog Post</p>
          </div>
        );
      case "social":
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/social.png"></img>
            <p>Social Media Post</p>
          </div>
        );
      case "campaign":
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/campaign.png"></img>
            <p>Campaign</p>
          </div>
        );
      case "design_brief":
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/brief.png"></img>
            <p>Design Brief</p>
          </div>
        );
      default:
        return (
          <div className="type">
            <img src="/assets/images/ai_icons/video.png"></img>
            <p>Video Script</p>
          </div>
        );
    }
  };
  render() {
    return (
      <React.Fragment>
        <Header token={this.props.token} />
        <ToastContainer />
        <div className="container page-height">
          <div className="my-questions-row">
            {/* <h3>Question and Answers</h3> */}
            {this.props.loadingAIContent == false ? (
              <>
                {this.props.aiContentList &&
                  this.props.aiContentList.length > 0 && (
                    <div className="save-as">
                      Save as
                      <select
                        name="exp_month"
                        onChange={this.handleChangeSaveType}
                        required
                      >
                        <option value="DOC">DOC</option>
                        <option value="PDF">PDF</option>

                      </select>
                      <button
                        className="btn btn-primary"
                        onClick={this.download}
                      >
                        Save
                      </button>
                    </div>
                  )}
                <div id="content" className="ai-qa-list">
                  {this.props.aiContentList &&
                    this.props.aiContentList.map((q, index) => (
                      <div key={index} className="qa-row">
                        <i
                          className="delete fa fa-times"
                          aria-hidden="true"
                          onClick={(e) => this.deleteAI(q)}
                        ></i>
                        <h3>{q.lesson_name}</h3>
                        <div className="question">
                          <h4>
                            <i
                              className="fa fa-align-left"
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            {q.question}
                          </h4>
                          <div className="meta-tag">
                            {this.TypeFormat(q.type)}
                            <p>
                              <i className="fa fa-calendar"></i>
                              {DateFormat(q.created_at)}
                            </p>
                          </div>
                        </div>
                        <ReactMarkdown>{q.answer}</ReactMarkdown>
                      </div>
                    ))}
                </div>
                {this.props.aiContentList &&
                  this.props.aiContentList.length > 0 && (
                    // <button
                    //   className="btn btn-primary"
                    //   onClick={this.downloadPDF}
                    // >
                    //   Download PDF
                    // </button>
                    <div className="save-as">
                      Save as
                      <select
                        name="exp_month"
                        onChange={this.handleChangeSaveType}
                        required
                      >
                        <option value="DOC">DOC</option>
                        <option value="PDF">PDF</option>

                      </select>
                      <button
                        className="btn btn-primary"
                        onClick={this.download}
                      >
                        Save
                      </button>
                    </div>
                  )}
              </>
            ) : (
              <DataLoading />
            )}
          </div>
        </div>

        <Footer token={this.props.token} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    aiContentList: state.aiContentReducer.aiContentList,
    loadingAIContent: state.aiContentReducer.loadingAIContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetAIContent: (data) => dispatch(getAIContent(data)),
    dispatchDeleteAIContent: (data) => dispatch(deleteAIContent(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentGenerator));
