import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";

class LetestCourses extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  render() {
    return (
      <React.Fragment>
        {this.props?.courseList?.length > 0 && (
          <div className="course-row">
            <div className="container">
              <div className="course-row-header">
                <h2>Latest Courses</h2>
                <Link to="/courses" className="right-link">
                  View All{" "}
                  <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                </Link>
              </div>
              <div className="all-course-slider">
                {this.props?.courseList?.length > 0 && (
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    items={4}
                    nav={true}
                    responsive={this.state.responsive}
                  >
                    {this.props?.courseList?.map((course, id) => (
                      <div className="item" key={id}>
                        <div className="course-sld-box">
                          <div className="course-sld-img">
                            <img
                              alt="Course-Thumbnail"
                              src={course.thumbnail_url}
                            />
                          </div>

                          <Link
                            to={`/course/${course.course_category_url}/${course.id}/${course.course_title_url}`}
                          >
                            <div className="course-sld-cnt">
                              <h3>
                                {course?.course_name?.length <= 50
                                  ? course?.course_name
                                  : course?.course_name?.substring(0, 50) +
                                    "..."}
                              </h3>
                              <h4>Total Chapters-{course.no_of_chapters}</h4>
                              <h5>
                                <span>
                                  {course.course_type === 0 &&
                                  (course.is_subscribed === true ||
                                    course.is_subscribed === false) &&
                                  (course.is_independent === true ||
                                    course.is_independent === false)
                                    ? "Free"
                                    : course.course_type === 1 &&
                                      course.is_independent === false
                                    ? `£ ${course.monthly_fee}`
                                    : course.course_type === 1 &&
                                      course.is_independent === true
                                    ? `£ ${course.course_fee}`
                                    : "View Course"}
                                </span>
                              </h5>
                              <p>
                                <span>
                                  {course.rating === 1 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                    </>
                                  ) : course.rating === 2 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : course.rating === 3 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : course.rating === 4 ? (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-o "
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </>
                                  )}
                                </span>
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    courseList: state?.dashboardReducer?.services?.courses,
  };
};

export default connect(mapStateToProps)(withRouter(LetestCourses));
