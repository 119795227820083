import { BASE_URL } from "../../../helpers/Constants";
import {
  GET_BLOG,
  GET_BANNER,
  GET_BLOG_DETAIL,
  GET_BLOG_BY_CATEGORY_ID,
} from "./actionType";
import {
  getBlogSuccess,
  getBlogError,
  getBannerSuccess,
  getBannerError,
  getBlogDetailByIdSuccess,
  getBlogDetailByIdError,
  getBlogByCategoryIdSuccess,
  getBlogByCategoryIdError,
} from "./action";
import { takeEvery, fork, put, all } from "redux-saga/effects";

function* getAllBlog(data) {
  try {
    if (data.payload.token) {
      //console.log('if saga');
      const response = yield fetch(
        BASE_URL + "api/blogListBycategory?token=" + data.payload.token,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      )
        // {"status":false,"result":null,"message":"Invalid Crediantials"}
        .then((res) => res.json())
        .catch((error) => ({
          status: "error",
          errors: { message: "Server not responding" },
        }));

      if (response.status === true) {
        //console.log('respone', response);
        yield put(getBlogSuccess(response.result));
      } else {
        yield put(getBlogError(response.message));
      }
    } else {
      //console.log('else saga');
      const response = yield fetch(BASE_URL + "api/blogListBycategory", {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        // {"status":false,"result":null,"message":"Invalid Crediantials"}
        .then((res) => res.json())
        .catch((error) => ({
          status: "error",
          errors: { message: "Server not responding" },
        }));

      if (response.status === true) {
        //console.log('respone', response);
        yield put(getBlogSuccess(response.result));
      } else {
        yield put(getBlogError(response.message));
      }
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getBlogError(error.message));
  }
}

function* getBlogBanner(data) {
  //console.log('enter api function')
  try {
    const response = yield fetch(
      BASE_URL +
        "api/bannerList?banner_category=" +
        data.payload.bannerCategory,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // "Authorization": "Bearer " + data.payload.token
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getBannerSuccess(response.result));
    } else {
      yield put(getBannerError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getBannerError(error.message));
  }
}

function* getBlogDetailById(data) {
  //console.log('enter api function', data);
  try {
    let payload = {
      blog_title: data?.payload?.blog_id,
      token: data?.payload?.token,
    };
    //console.log("JSON", JSON.stringify(payload))
    const response = yield fetch(BASE_URL + "api/blogDetail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + data.payload.token
      },

      body: JSON.stringify(payload),
    })
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      if (response.result.is_redirect == true) {
        data.payload.history.push("/blogs");
      }
      yield put(getBlogDetailByIdSuccess(response.result));
    } else {
      yield put(getBlogDetailByIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getBlogDetailByIdError(error.message));
  }
}

function* getBlogByCategory(data) {
  try {
    const response = yield fetch(
      BASE_URL +
        "api/blogListBycategory?category_name=" +
        data.payload.category_id +
        "&token=" +
        data.payload.token,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      // {"status":false,"result":null,"message":"Invalid Crediantials"}
      .then((res) => res.json())
      .catch((error) => ({
        status: "error",
        errors: { message: "Server not responding" },
      }));

    if (response.status === true) {
      //console.log('respone', response);
      yield put(getBlogByCategoryIdSuccess(response.result));
    } else {
      yield put(getBlogByCategoryIdError(response.message));
    }
  } catch (error) {
    // console.log('catch error', error);
    yield put(getBlogByCategoryIdError(error.message));
  }
}

export function* watchgetBanner() {
  yield takeEvery(GET_BANNER, getBlogBanner);
}
export function* watchgetBlog() {
  yield takeEvery(GET_BLOG, getAllBlog);
}
export function* watchgetBlogDetailById() {
  yield takeEvery(GET_BLOG_DETAIL, getBlogDetailById);
}
export function* watchgetBlogByCategoryId() {
  yield takeEvery(GET_BLOG_BY_CATEGORY_ID, getBlogByCategory);
}
function* blogSaga() {
  yield all([
    fork(watchgetBanner),
    fork(watchgetBlog),
    fork(watchgetBlogDetailById),
    fork(watchgetBlogByCategoryId),
  ]);
}

export default blogSaga;
