import React, { Component } from "react";

class ErrorPage extends Component {
  componentDidMount() {
    // document.title = "Payment Fail :: The Maetrix";
  }
  render() {
    return (
      <div className="success-main">
        <div className="success-box">
          <img alt="img" className="tick" src="/assets/images/fail.png" />
          <img alt="img" src="/assets/images/success-img.jpg" />
          <h3 style={{ color: "red" }}>Payment Fail</h3>
          <p>Payment Error - Please check your card details and try again.</p>
          <a href="/">
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Home
          </a>
        </div>
      </div>
    );
  }
}
export default ErrorPage;
