export const GET_PODCAST = 'GET_PODCAST';
export const GET_PODCAST_SUCCESS = 'GET_PODCAST_SUCCESS';
export const GET_PODCAST_ERROR = 'GET_PODCAST_ERROR'

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export const GET_PODCAST_BY_CATEGORY_ID = 'GET_PODCAST_BY_CATEGORY_ID';
export const GET_PODCAST_BY_CATEGORY_ID_SUCCESS = 'GET_PODCAST_BY_CATEGORY_ID_SUCCESS'
export const GET_PODCAST_BY_CATEGORY_ID_ERROR = 'GET_PODCAST_BY_CATEGORY_ID_ERROR';