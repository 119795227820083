import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  FacebookShareCount,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
  InstapaperIcon,
} from "react-share";

class SharePopUpModel extends PureComponent {
  copyFunction = (event) => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("Copied the text: " + copyText.value);
  };

  render() {
    const title = "The Maetrix";
    return (
      <div className="share-pops">
        <div className="modal fade" id="shareModel">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="subs-cnt">
                  <button
                    type="button"
                    className="close btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="Demo__some-network">
                    <FacebookShareButton
                      url={this.props.url}
                      quote={title}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <div>
                      <FacebookShareCount
                        url={this.props.url}
                        className="Demo__some-network__share-count"
                      >
                        {(count) => count}
                      </FacebookShareCount>
                    </div>
                  </div>
                  <div className="Demo__some-network">
                    <TwitterShareButton
                      url={this.props.url}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>

                    <div className="Demo__some-network__share-count">
                      &nbsp;
                    </div>
                  </div>
                  <div className="Demo__some-network">
                    <WhatsappShareButton
                      url={this.props.url}
                      title={title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    <div className="Demo__some-network__share-count">
                      &nbsp;
                    </div>
                  </div>
                  <div className="Demo__some-network">
                    <LinkedinShareButton
                      url={this.props.url}
                      className="Demo__some-network__share-button"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>

                  <div className="Demo__some-network">
                    <EmailShareButton
                      url={this.props.url}
                      subject={title}
                      body="body"
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                  <div className="Demo__some-network">
                    <InstapaperShareButton
                      url={this.props.url}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <InstapaperIcon size={32} round />
                    </InstapaperShareButton>
                  </div>
                  <div className="linkcop">
                    <input
                      type="text"
                      value={this.props.url}
                      id="myInput"
                    />

                    <button onClick={this.copyFunction}>
                      <i className="fa fa-chain-broken" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharePopUpModel));
