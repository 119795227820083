import React, { useState, useEffect } from "react";

function ActivateAccount() {
  const [value, setValue] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setValue(params.get("status"));
  }, []);

  return (
    <div className="succes-main">
      {console.log("value", value)}

      <div className="success-box">
        {value === "true" ? (
          <>
            <img
              src="/assets/images/user-success-icon.png"
              className="user-success"
              alt=""
            />
            <img
              src="/assets/images/asethic-logo-1.png"
              className="logo-img"
              alt=""
            />
            <h4>Account activated successfully.</h4>
            <a href="/">Back to login</a>
          </>
        ) : (
          <>
            <img
              src="/assets/images/user-success-error.png"
              className="user-success"
              alt=""
            />
            <img
              src="/assets/images/asethic-logo-1.png"
              className="logo-img"
              alt=""
            />
            <h4 style={{ color: "red" }}>
              Account not activated successfully.
            </h4>
            <a href="/">Back to login</a>
          </>
        )}
      </div>
    </div>
  );
}
export default ActivateAccount;
