import React, { PureComponent } from "react";
import RatingPopUp from "./RatingPopUp";

import OwlCarousel from "react-owl-carousel-loop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class CourseActivity extends PureComponent {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    courseDetail: null,
  };

  rating = (data) => {
    this.setState({
      courseDetail: data,
    });
  };

  render() {
    const { courseDetail } = this.state;
    return (
      <React.Fragment>
        {this.props?.courseList?.length > 0 && (
          <React.Fragment>
            <div className="course-row">
              <div className="course-row-header">
                <h2>Courses</h2>
              </div>
              <div className="course-slider">
                {this.props?.courseList?.length > 0 && (
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    items={4}
                    nav
                    responsive={this.state.responsive}
                  >
                    {this.props?.courseList?.map((course, id) => (
                      <div className="item" key={id}>
                        <div className="course-sld-box">
                          <div className="course-sld-img">
                            <img
                              alt="course-thumbnail"
                              src={course.thumbnail_url}
                            />
                          </div>
                          <div className="course-sld-cnt">
                            <h4>{course.course_name}</h4>
                            <h5>
                              {course.no_of_lessons == 1
                                ? course.no_of_lessons + " Lesson"
                                : course.no_of_lessons + " Lessons"}
                            </h5>
                            <div className="prgrs-box">
                              <div className="prgs-row">
                                <span
                                  className="prgs-cnt"
                                  style={{
                                    width: course.complete_precentage + "%",
                                  }}
                                ></span>
                              </div>
                              <span className="percent">
                                {course.complete_precentage}% Completed
                              </span>
                            </div>
                            <div className="btn-sec">
                              <a
                                href={`/course/${course.course_category_url}/${course?.service_id}/${course.course_title_url}`}
                              >
                                <button>View</button>
                              </a>
                              <button
                                className="rate"
                                data-toggle="modal"
                                data-target="#rating"
                                onClick={() => {
                                  this.rating(course);
                                }}
                              >
                                Rate Us
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
            <RatingPopUp data={courseDetail} token={this.props.token} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    courseList: state?.activityReducer?.allActivity?.courses,
  };
};

export default connect(mapStateToProps)(withRouter(CourseActivity));
