import React, { PureComponent } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  getCourseById,
  savePauseTime,
  getPauseTimebyLessonId,
  getProgressById,
  markAsComplete,
  isLatestSuccess,
  getCourseAIAnswer,
  saveCourseAIAnswer,
  clearAIAnswer,
} from "../../../store/protected/courses/action";

import get from "lodash/get";
import isEqual from "lodash/isEqual";
import ReactHtmlParser from "react-html-parser";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomHeader from "../../../layout/auth/customHeader";
import PublicCustomHeader from "../../../layout/gaust/publicCustomHeader";
import AuthFooter from "../../../layout/auth/Afooter";
import ResponsivePlayer from "../video-player/responsiveplayer";
import { ToastContainer, toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import { BASE_URL } from "../../../helpers/Constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";

var Spinner = require("react-spinkit");
class CourseDetail extends PureComponent {
  textareaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      currentLesson: 0,
      activeTab: "",
      currentLessionId: "",
      question: "",
      aiAnswer: "",
      aiQuestion: "",
      answering: false,
    };
    this.duration = 0;
    this.video = React.createRef();
    this.abort = null;
  }

  componentDidMount() {
    // document.title = "Course Detail :: The Maetrix";
    let params = queryString.parse(this.props.location.search);
    if (params && params.lessionId && params.lessionId != "") {
      this.setState({
        currentLessionId: params.lessionId,
      });

      this.props.dispatchSavePauseTime({
        service_id: this.props.match.params.id,
        service_category: "course",
        playble_item_id: params.lessionId,
        resume_at: "00:00:00",
        token: this.props.token,
      });

      this.props.dispatchGetPauseVideoTime({
        qs: {
          service_category: "course",
          service_id: this.props.match.params.id,
          playble_item_id: params.lessionId,
        },

        token: this.props.token,
      });
    }

    this.props.dispatchCourseById({
      id: this.props.match.params.id,
      token: this.props.token,
      history: this.props.history,
    });

    this.props.dispatchGetProgress({
      course_id: this.props.match.params.id,
      token: this.props.token,
    });
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.aiAnswer?.answer != "" &&
      newProps.aiAnswer?.answer != this.state.aiAnswer &&
      this.state.question != ""
    ) {
      this.setState({
        aiAnswer: newProps.aiAnswer?.answer,
        aiQuestion: newProps.aiAnswer?.question,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = get(prevProps, "getCourseDescription", {});
    const currentData = get(this, "props.getCourseDescription", {});
    if (!isEqual(prevData, currentData) && currentData) {
      const chpaters = currentData.chpaters || [];
      let lessonList = chpaters.reduce((acc, crr) => {
        const curretnLessons = crr.lessons || [];
        return acc.concat(curretnLessons);
      }, []);
      this.setState(
        {
          lessons: lessonList,
          currentLesson: 0,
          activeTab:
            lessonList[0]?.lesson_video_url &&
            lessonList[0]?.lesson_video_url != ""
              ? "video"
              : lessonList[0]?.lesson_content_url &&
                lessonList[0]?.lesson_content_url != ""
              ? "content"
              : "downloadUrl",
        },
        () => {
          const service_id = lessonList[0].course_id;
          const playble_item_id = lessonList[0].id;
          this.props.dispatchGetPauseVideoTime({
            qs: {
              service_category: "course",
              service_id,
            },
            token: this.props.token,
          });
        }
      );
    }

    if (prevState.currentLesson !== this.state.currentLesson) {
      const currentLesson = get(
        prevState,
        `lessons[${prevState.currentLesson}]`
      );
      var date = new Date(0);
      date.setSeconds(Math.floor(Math.floor(this.duration)).toFixed());
      var timeString = date.toISOString().substr(11, 8);
      const resume_at = timeString;
      const service_id = currentLesson.course_id;
      const service_category = "course";
      const playble_item_id = currentLesson.id;
      this.props.dispatchSavePauseTime({
        service_id: service_id,
        service_category: service_category,
        playble_item_id: playble_item_id,
        resume_at: resume_at,
        token: this.props.token,
      });
      this.props.dispatchGetProgress({
        course_id: this.props.match.params.id,
        token: this.props.token,
      });
    }

    if (
      !isEqual(prevProps?.getPauseData, this.props?.getPauseData) &&
      this.props?.getPauseData
    ) {
      const pauseData = this.props.getPauseData;
      var hms = pauseData.resume_at; // your input string
      const lessonId = pauseData.playble_item_id;
      const lessonIndex = this.state.lessons.findIndex((i) => i.id == lessonId);
      let indexToSend = lessonIndex;
      if (lessonIndex <= 0 || !lessonIndex) {
        indexToSend = 0;
      }
      var a = hms.split(":"); // split it at the colons

      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

      this.setState(
        {
          currentLesson: indexToSend,
        },
        () => {
          if (this.video.current) {
            this.video.current.seekTo(seconds);
          }
        }
      );
      this.props.dispatchGetProgress({
        course_id: this.props.match.params.id,
        token: this.props.token,
      });
    }

    if (
      !isEqual(prevProps?.isComplete, this.props?.isComplete) &&
      this.props?.isComplete
    ) {
      this.props.dispatchGetProgress({
        course_id: this.props.match.params.id,
        token: this.props.token,
      });
    }
  }

  handleWatchComplete = ({ playedSeconds }) => {
    this.duration = playedSeconds;
  };
  onChangeQuestion = (event) => {
    this.setState({ question: event.target.value });
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  onKeyDownQuestion = async (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      this.setState({ question: this.state.question + "\n" });
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + 30 + "px";
    } else if (event.key === "Enter") {
      event.preventDefault();
      try {
        this.getAIAnswer();
      } catch (error) {
        console.error(error);
      }
    }
  };
  stopAIAnswer = () => {
    this.abort.abort();
    this.setState({ answering: false });
    this.props.dispatchSaveAIAnswer({
      course_id: this.props.match.params.id,
      lession_id: this.state.currentLessionId,
      token: this.props.token,
      question: this.state.question,
      answer: this.state.aiAnswer,
    });
  };
  getAIAnswer = async () => {
    const pThis = this;
    this.abort = new AbortController();
    this.setState({
      answering: true,
    });
    this.setState(
      {
        currentLessionId: this.state.lessons[this.state.currentLesson]?.id,
        aiAnswer: "",
      },
      async () => {
        const currentLessionId = this.state.currentLessionId
          ? this.state.currentLessionId
          : this.state.lessons[this.state.currentLesson]?.id;
        try {
          await fetchEventSource(`${BASE_URL}api/ai/course`, {
            method: "POST",
            signal: this.abort.signal,
            headers: {
              Accept: "text/event-stream",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.props.token,
            },
            body: JSON.stringify({
              course_id: this.props.match.params.id,
              lesson_id: currentLessionId,
              question: this.state.question,
            }),
            onopen(res) {
              if (res.ok && res.status === 200) {
                console.log("Connection made ", res);
              } else if (
                res.status >= 400 &&
                res.status < 500 &&
                res.status !== 429
              ) {
                console.log("Client side error ", res);
              }
            },
            onmessage(event) {
              try {
                if (event.data != "[DONE]") {
                  const parsedData = JSON.parse(event.data);
                  if (
                    parsedData &&
                    parsedData.choices &&
                    parsedData.choices.length > 0 &&
                    parsedData.choices[0]?.delta &&
                    parsedData.choices[0]?.delta.content
                  ) {
                    setTimeout(() => {
                      pThis.setState({
                        aiAnswer:
                          pThis.state.aiAnswer +
                          parsedData.choices[0]?.delta.content,
                      });
                    }, 1);
                  }
                } else if (event.data == "[DONE]") {
                  console.log("finished");
                  pThis.setState({ answering: false });
                  pThis.props.dispatchSaveAIAnswer({
                    course_id: pThis.props.match.params.id,
                    lession_id: currentLessionId,
                    token: pThis.props.token,
                    question: pThis.state.question,
                    answer: pThis.state.aiAnswer,
                  });
                }
              } catch (e) {}
            },
            onclose() {
              console.log("Connection closed by the server");
            },
            onerror(err) {
              console.log("There was an error from server", err);
            },
          });
        } catch (e) {
          this.abort.abort();
        }
      }
    );

    /** Method2 */
    // this.setState(
    //   {
    //     currentLessionId: this.state.lessons[this.state.currentLesson]?.id,
    //   },
    //   () => {
    //     const currentLessionId = this.state.currentLessionId
    //       ? this.state.currentLessionId
    //       : this.state.lessons[this.state.currentLesson]?.id;
    //     if (this.state.question && this.state.question != "") {
    //       this.props.dispatchGetAIAnswer({
    //         course_id: this.props.match.params.id,
    //         lession_id: currentLessionId,
    //         token: this.props.token,
    //         question: this.state.question,
    //       });
    //     } else {
    //       toast.error("Please input your question.");
    //     }
    //   }
    // );
  };
  completeCourse = () => {
    this.props.dispatchCompleteCourse({
      service_id: this.props?.getCourseDescription?.id,
      service_category: "course",
      token: this.props?.token,
    });
  };
  previous = () => {
    if (
      this.state.currentLesson > 0 &&
      this.state.currentLesson <= this.state.lessons.length - 1
    ) {
      this.props.dispatchClearAIAnswer();
      this.setState(
        {
          currentLesson: this.state.currentLesson - 1,
          currentLessionId:
            this.state.lessons[this.state.currentLesson - 1]?.id,
          aiAnswer: null,
          aiQuestion: null,
          question: "",
        },
        () => {
          if (
            this.state?.lessons[this.state?.currentLesson].lesson_video_url !==
              null &&
            this.state?.lessons[this.state?.currentLesson].lesson_video_url !=
              ""
          ) {
            this.setState({
              activeTab: "video",
            });
          } else if (
            this.state?.lessons[this.state?.currentLesson]
              ?.lesson_content_url !== null &&
            this.state?.lessons[this.state?.currentLesson]
              ?.lesson_content_url != ""
          ) {
            this.setState({
              activeTab: "content",
            });
          } else if (
            this.state?.lessons[this.state?.currentLesson]
              ?.downloadable_content_url !== null &&
            this.state?.lessons[this.state?.currentLesson]
              ?.downloadable_content_urll != ""
          ) {
            this.setState({
              activeTab: "downloadUrl",
            });
          } else {
            this.setState({
              activeTab: "video",
            });
          }
        }
      );
    }
  };
  next = () => {
    if (
      this.state.currentLesson < this.state.lessons.length - 1 &&
      this.state.currentLesson >= 0
    ) {
      this.props.dispatchClearAIAnswer();
      this.setState(
        {
          currentLesson: this.state.currentLesson + 1,
          currentLessionId:
            this.state.lessons[this.state.currentLesson + 1]?.id,
          aiAnswer: null,
          aiQuestion: null,
          question: "",
        },
        () => {
          if (
            this.state?.lessons[this.state?.currentLesson].lesson_video_url !==
              null &&
            this.state?.lessons[this.state?.currentLesson].lesson_video_url !=
              ""
          ) {
            this.setState({
              activeTab: "video",
            });
          } else if (
            this.state?.lessons[this.state?.currentLesson]
              ?.lesson_content_url !== null &&
            this.state?.lessons[this.state?.currentLesson]
              ?.lesson_content_url != ""
          ) {
            this.setState({
              activeTab: "content",
            });
          } else if (
            this.state?.lessons[this.state?.currentLesson]
              ?.downloadable_content_url !== null &&
            this.state?.lessons[this.state?.currentLesson]
              ?.downloadable_content_urll != ""
          ) {
            this.setState({
              activeTab: "downloadUrl",
            });
          } else {
            this.setState({
              activeTab: "video",
            });
          }
        }
      );
    }
  };

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let indexTo = 0;
    const html = this.state.lessons[this.state.currentLesson]
      ?.lesson_content_url
      ? this.state.lessons[this.state.currentLesson]?.lesson_content_url
      : "No Content";
    if (!this.state.lessons.length) return null;
    return (
      <React.Fragment>
        <ToastContainer />
        <div>
          {this.props.token ? <CustomHeader /> : <PublicCustomHeader />}

          <div className="enroll-single">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-lg-9 col-md-7 pl-0 pb-3 pr-0"
                  style={{ borderBottom: "2px solid #E7E7E7" }}
                >
                  <div className="enroll-video">
                    {/* <span className="right-side">
                      <a href="#">
                        <img src={"/assets/new_layout/img/right-arrow.png"} />{" "}
                        Back
                      </a>
                    </span> */}
                    <div className="button-class">
                      <button className="fav-btn2">
                        <i className="fa fa-heart-o" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="enroll-slider">
                      <Slider {...settings}>
                        {this.state.lessons[this.state.currentLesson]
                          ?.lesson_video_url !== null &&
                        this.state.lessons[this.state.currentLesson]
                          ?.lesson_video_url !== "" ? (
                          <div className="video-box">
                            <ResponsivePlayer
                              url={
                                this.state.lessons[this.state.currentLesson]
                                  ?.lesson_video_url
                                  ? this.state.lessons[this.state.currentLesson]
                                      .lesson_video_url
                                  : ""
                              }
                              refToSet={this.video}
                              cbs={{
                                onProgress: this.handleWatchComplete,
                                onStart: () => {
                                  this.duration = 0;
                                  this.props.dispatchLatestService({
                                    id: this.props.match.params.id,
                                    category: "course",
                                    token: this.props.token,
                                  });

                                  this.props.dispatchSavePauseTime({
                                    service_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].course_id,
                                    service_category: "course",
                                    playble_item_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].id,
                                    resume_at: "00:00:00",
                                    token: this.props.token,
                                  });
                                },
                                onPause: () => {
                                  var date = new Date(0);
                                  date.setSeconds(
                                    Math.floor(
                                      Math.floor(this.duration)
                                    ).toFixed()
                                  );
                                  var timeString = date
                                    .toISOString()
                                    .substr(11, 8);
                                  const pauseTime = timeString;

                                  this.props.dispatchSavePauseTime({
                                    service_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].course_id,
                                    service_category: "course",
                                    playble_item_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].id,
                                    resume_at: pauseTime,
                                    token: this.props.token,
                                  });
                                },

                                onEnded: () => {
                                  var date = new Date(0);
                                  date.setSeconds(
                                    Math.floor(
                                      Math.floor(this.duration)
                                    ).toFixed()
                                  );
                                  var timeString = date
                                    .toISOString()
                                    .substr(11, 8);
                                  const pauseTime1 = timeString;

                                  this.props.dispatchSavePauseTime({
                                    service_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].course_id,
                                    service_category: "course",
                                    playble_item_id:
                                      this.state.lessons[
                                        this.state.currentLesson
                                      ].id,
                                    resume_at: pauseTime1,
                                    token: this.props.token,
                                  });

                                  if (
                                    this.state.currentLesson <
                                    this.state.lessons.length - 1
                                  )
                                    this.setState({
                                      currentLesson:
                                        this.state.currentLesson + 1,
                                    });
                                },
                              }}
                            />
                          </div>
                        ) : null}

                        {this.state.lessons[this.state.currentLesson]
                          ?.lesson_content_url !== null &&
                        this.state.lessons[this.state.currentLesson]
                          ?.lesson_content_url !== "" ? (
                          <div className="content-box">
                            {ReactHtmlParser(html)}
                          </div>
                        ) : null}

                        {this.state.lessons[this.state.currentLesson]
                          ?.downloadable_content_url !== null &&
                        this.state.lessons[this.state.currentLesson]
                          ?.downloadable_content_url !== "" ? (
                          <div className="content-download">
                            <a
                              href={
                                this.state.lessons[this.state.currentLesson]
                                  ?.downloadable_content_url
                              }
                              target="_blank"
                            >
                              <button className="download">
                                <span>
                                  <i
                                    className="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                Download
                              </button>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </Slider>
                    </div>
                    <div className="nxt-btn">
                      <button className="btn btn-info" onClick={this.previous}>
                        Prev Lesson
                      </button>
                      <button onClick={this.next} className="btn btn-info">
                        Next Lesson
                      </button>

                      {this.state.lessons[this.state.currentLesson]
                        ?.downloadable_content_url !== null &&
                        this.state.lessons[this.state.currentLesson]
                          ?.downloadable_content_url !== "" && (
                          <button className="btn btn-info">
                            <a
                              href={
                                this.state.lessons[this.state.currentLesson]
                                  ?.downloadable_content_url
                              }
                              target="_blank"
                              style={{ color: "#fff" }}
                            >
                              {" "}
                              <span>
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              Download{" "}
                            </a>
                          </button>
                        )}
                    </div>
                    {/* <div className="slider-bottom">
                    <h4 stylee={{"fontWeight":"bold"}}>
                          {
                              this.state.lessons[this.state.currentLesson]
                                ?.lesson_name
                            }
                      
                    </h4>
                  </div> */}
                    <div
                      className="slider-bottom"
                      style={{ marginLeft: "20px" }}
                    >
                      <button
                        onClick={this.completeCourse}
                        className="continue"
                      >
                        Complete & Continue
                      </button>
                      <h3>Your Progress</h3>
                      <div className="progress-row">
                        <div className="bg-gray"></div>
                        <div
                          className="bg-prgs"
                          style={{
                            width:
                              this.props?.getProgress?.percentage.toString() +
                              "%",
                          }}
                        >
                          <span>
                            {this.props?.getProgress?.percentage > 30
                              ? this.props?.getProgress?.percentage.toString() +
                                "% Completed"
                              : this.props?.getProgress?.percentage.toString() +
                                "%"}
                          </span>
                        </div>
                      </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 course-boxset-lesson-content"
                  style={{ borderBottom: "2px solid #E7E7E7"}}
                            >
                 {ReactHtmlParser(html)}
                </div>
                    <div className="ai-container">
                      <div className="ai-answer py-12">
                        <div className="box-set-ai-answer">
                          {
                            this.state.lessons[this.state.currentLesson]
                              .question
                          }
                        </div>
                      </div>
                      <div className="border-2 border-n-3 rounded-xl border-solid course-boxset-question relative flex items-bottom ">
                        <textarea
                          className={`${
                            !this.state.answering &&
                            this.state.aiAnswer != "" &&
                            this.props.aiAnswer?.question == this.state.question
                              ? "small"
                              : "normal"
                          }`}
                          placeholder="Ask CLARA"
                          value={this.state.question}
                          onChange={this.onChangeQuestion}
                          onKeyDown={this.onKeyDownQuestion}
                        />
                        {this.state.answering && (
                          <button
                            className="btn btn-send"
                            onClick={() => this.stopAIAnswer()}
                          >
                            Stop
                          </button>
                        )}
                        {!this.state.answering && (
                          <button
                            className={`btn btn-send ${
                              this.state.aiAnswer != "" &&
                              this.props.aiAnswer?.question ==
                                this.state.question
                                ? ""
                                : "small"
                            }`}
                            onClick={() => this.getAIAnswer()}
                          >
                            {this.state.aiAnswer != "" &&
                            this.props.aiAnswer?.question ==
                              this.state.question ? (
                              <i className="fa fa-refresh" aria-hidden="true">
                                &nbsp; Regenerate
                              </i>
                            ) : (
                              <img src="/assets/images/ai_icons/bot-icon.png" />
                            )}
                          </button>
                        )}
                      </div>

                      <div className="ai-answer py-12">
                        <h5>
                          {this.state.answering ? (
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="far"
                              data-icon="circle-half-stroke"
                              className="svg-inline--fa fa-circle-half-stroke fa-fw animate-spin"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M464 256c0-114.9-93.1-208-208-208V464c114.9 0 208-93.1 208-208zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                              ></path>
                            </svg>
                          ) : (
                            <i
                              className="fa fa-align-left"
                              aria-hidden="true"
                            ></i>
                          )}
                          &nbsp; Answer
                        </h5>
                        {this.state.aiAnswer && (
                          <ReactMarkdown>{this.state.aiAnswer}</ReactMarkdown>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-5 px-0">
                  <div className="enroll-right-header">
                    {/* <h2>
                    Chapter:{" "}
                    {this.props?.getCourseDescription?.no_of_chapters}
                  </h2> */}
                    <h2>
                      Chapter{" "}
                      <span>
                        {this.props?.getCourseDescription?.no_of_chapters}
                      </span>
                    </h2>
                  </div>
                  {/* <div className="enroll-right" id="course-list">
                  {this.props?.getCourseDescription &&
                    this.props?.getCourseDescription.chpaters.map(
                      (list, index) => (
                        <span key={index}>
                          <div className="course-cnt-box">
                            <div className="course-desc-head">
                              <h6>
                                <a
                                  href={`#collapse${index}`}
                                  data-toggle="collapse"
                                  className="collapsible-link"
                                >
                                  {list.chapter_name}
                                </a>
                              </h6>
                            </div>
                            <div
                              id={`collapse${index}`}
                              className="collapse show"
                            >
                              <div className="course-desc-box">
                                <ul>
                                  {list?.lessons.map((lesson, j) => {
                                    let indexTo =
                                      this.state.lessons.findIndex((l) => {
                                        return l.id == lesson.id;
                                      }) || 0;
                                    return (
                                      <span key={j}>
                                        <div
                                          onClick={() => {
                                            const ind =
                                              this.state.lessons.findIndex(
                                                (l) => {
                                                  return l.id == lesson.id;
                                                }
                                              ) || 0;
                                            if (
                                              this.state.lessons[ind]
                                                ?.lesson_video_url &&
                                              this.state?.lessons[ind]
                                                ?.lesson_video_url != ""
                                            ) {
                                              this.setState(
                                                {
                                                  activeTab: "video",
                                                },
                                                () => {
                                                  this.setState({
                                                    currentLesson: ind,
                                                    activeTab:
                                                      this.state?.activeTab,
                                                  });
                                                }
                                              );
                                            } else if (
                                              !this.state?.lessons[ind]
                                                ?.lesson_video_url ||
                                              (this.state?.lessons[ind]
                                                ?.lesson_video_url == "" &&
                                                this.state?.lessons[ind]
                                                  ?.lesson_overview &&
                                                this.state?.lessons[ind]
                                                  ?.lesson_overview != "")
                                            ) {
                                              this.setState(
                                                {
                                                  activeTab: "content",
                                                },
                                                () => {
                                                  this.setState({
                                                    currentLesson: ind,
                                                    activeTab:
                                                      this.state?.activeTab,
                                                  });
                                                }
                                              );
                                            } else if (
                                              (!this.state.lessons[ind]
                                                ?.lesson_video_url ||
                                                this.state?.lessons[ind]
                                                  ?.lesson_video_url == "") &&
                                              (!this.state.lessons[ind]
                                                ?.lesson_overview ||
                                                this.state.lessons[ind]
                                                  ?.lesson_overview == "") &&
                                              this.state.lessons[ind]
                                                ?.downloadable_content_url &&
                                              this.state?.lessons[ind]
                                                ?.downloadable_content_url != ""
                                            ) {
                                              this.setState(
                                                {
                                                  activeTab: "downloadUrl",
                                                },
                                                () => {
                                                  this.setState({
                                                    currentLesson: ind,
                                                    activeTab:
                                                      this.state?.activeTab,
                                                  });
                                                }
                                              );
                                            } else {
                                              this.setState(
                                                {
                                                  activeTab: "video",
                                                },
                                                () => {
                                                  this.setState({
                                                    currentLesson: ind,
                                                    activeTab:
                                                      this.state?.activeTab,
                                                  });
                                                }
                                              );
                                            }
                                            this.props.dispatchGetPauseVideoTime(
                                              {
                                                qs: {
                                                  service_category: "course",
                                                  service_id: lesson.course_id,
                                                  playble_item_id: lesson.id,
                                                },

                                                token: this.props.token,
                                              }
                                            );
                                          }}
                                          id={lesson.id}
                                          className={
                                            lesson?.id ==
                                              this.state?.lessons[
                                                this.state?.currentLesson
                                              ]?.id
                                              ? "lsn-box green"
                                              : "lsn-box black"
                                          }
                                        >
                                          <h6>
                                            {" "}
                                            {j + 1}: {lesson.lesson_name}
                                          </h6>
                                          <div className="lsn-cnt">
                                            <div className="row">
                                              <div className="col-5">
                                                <p>
                                                  <span className="active-vdo">
                                                    {lesson?.duration != 0 && (
                                                      <i
                                                        className="fa fa-play-circle-o"
                                                        aria-hidden="true"
                                                      ></i>
                                                    )}
                                                  </span>{" "}
                                                  {lesson.duration}
                                                </p>
                                              </div>
                                              <div className="col-7">
                                                <p className="rt-icon">
                                                  {lesson?.lesson_content_url && (
                                                    <i
                                                      className="fa fa-file-text-o"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                  {lesson?.downloadable_content_url && (
                                                    <a
                                                      href={
                                                        lesson?.downloadable_content_url
                                                      }
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                    >
                                                      <i
                                                        className="fa fa-download"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </a>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </span>
                      )
                    )}
                </div> */}
                  <div className="enroll-right" id="course-list">
                    <div className="wrapper">
                      <ul
                        className="StepProgress"
                        style={{ paddingTop: "10px" }}
                      >
                        {this.props?.getCourseDescription &&
                          this.props?.getCourseDescription.chpaters.map(
                            (list, index) => (
                              <div key={index}>
                                <li
                                  className={
                                    this.state?.lessons[
                                      this.state?.currentLesson
                                    ]?.chapter_id > list.id
                                      ? "StepProgress-item is-done"
                                      : this.state?.lessons[
                                          this.state?.currentLesson
                                        ]?.chapter_id == list.id
                                      ? "StepProgress-item current"
                                      : "StepProgress-item"
                                  }
                                >
                                  <strong
                                    data-toggle="collapse"
                                    href={`#collapse-${index}`}
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls={`collapse-${index}`}
                                  >
                                    {list.chapter_name}
                                    <span>
                                      <img
                                        src={
                                          "/assets/new_layout/img/dropdown.png"
                                        }
                                      />
                                    </span>
                                  </strong>
                                </li>
                                <div
                                  className="collapse show"
                                  id={`collapse-${index}`}
                                >
                                  <div className="sub-section mb-4">
                                    {list?.lessons.map((lesson, j) => {
                                      let indexTo =
                                        this.state.lessons.findIndex((l) => {
                                          return l.id == lesson.id;
                                        }) || 0;
                                      return (
                                        <li
                                          key={j}
                                          className={
                                            lesson?.id ==
                                              this.state?.lessons[
                                                this.state?.currentLesson
                                              ]?.id &&
                                            lesson?.lesson_number ==
                                              this.state?.lessons[
                                                this.state?.currentLesson
                                              ]?.lesson_number
                                              ? "current StepProgress-item"
                                              : this.state?.lessons[
                                                  this.state?.currentLesson
                                                ]?.id == lesson?.id &&
                                                this.state?.lessons[
                                                  this.state?.currentLesson
                                                ]?.lesson_number >
                                                  lesson?.lesson_number
                                              ? "is-done StepProgress-item"
                                              : this.state?.lessons.findIndex(
                                                  (obj) => obj.id === lesson?.id
                                                ) < this.state?.currentLesson
                                              ? "is-done StepProgress-item"
                                              : "StepProgress-item"
                                          }
                                          style={{ fontSize: "12px" }}
                                        >
                                          <strong
                                            onClick={() => {
                                              this.props.dispatchClearAIAnswer();
                                              const ind =
                                                this.state.lessons.findIndex(
                                                  (l) => {
                                                    return l.id == lesson.id;
                                                  }
                                                ) || 0;
                                              if (
                                                this.state.lessons[ind]
                                                  ?.lesson_video_url &&
                                                this.state?.lessons[ind]
                                                  ?.lesson_video_url != ""
                                              ) {
                                                this.setState(
                                                  {
                                                    activeTab: "video",
                                                    question: "",
                                                  },
                                                  () => {
                                                    this.setState({
                                                      currentLesson: ind,
                                                      activeTab:
                                                        this.state?.activeTab,
                                                    });
                                                  }
                                                );
                                              } else if (
                                                !this.state?.lessons[ind]
                                                  ?.lesson_video_url ||
                                                (this.state?.lessons[ind]
                                                  ?.lesson_video_url == "" &&
                                                  this.state?.lessons[ind]
                                                    ?.lesson_overview &&
                                                  this.state?.lessons[ind]
                                                    ?.lesson_overview != "")
                                              ) {
                                                this.setState(
                                                  {
                                                    activeTab: "content",
                                                  },
                                                  () => {
                                                    this.setState({
                                                      currentLesson: ind,
                                                      activeTab:
                                                        this.state?.activeTab,
                                                    });
                                                  }
                                                );
                                              } else if (
                                                (!this.state.lessons[ind]
                                                  ?.lesson_video_url ||
                                                  this.state?.lessons[ind]
                                                    ?.lesson_video_url == "") &&
                                                (!this.state.lessons[ind]
                                                  ?.lesson_overview ||
                                                  this.state.lessons[ind]
                                                    ?.lesson_overview == "") &&
                                                this.state.lessons[ind]
                                                  ?.downloadable_content_url &&
                                                this.state?.lessons[ind]
                                                  ?.downloadable_content_url !=
                                                  ""
                                              ) {
                                                this.setState(
                                                  {
                                                    activeTab: "downloadUrl",
                                                  },
                                                  () => {
                                                    this.setState({
                                                      currentLesson: ind,
                                                      activeTab:
                                                        this.state?.activeTab,
                                                    });
                                                  }
                                                );
                                              } else {
                                                this.setState(
                                                  {
                                                    activeTab: "video",
                                                  },
                                                  () => {
                                                    this.setState({
                                                      currentLesson: ind,
                                                      activeTab:
                                                        this.state?.activeTab,
                                                    });
                                                  }
                                                );
                                              }
                                              this.props.dispatchGetPauseVideoTime(
                                                {
                                                  qs: {
                                                    service_category: "course",
                                                    service_id:
                                                      lesson.course_id,
                                                    playble_item_id: lesson.id,
                                                  },

                                                  token: this.props.token,
                                                }
                                              );
                                            }}
                                            id={lesson.id}
                                            className={
                                              lesson?.id ==
                                              this.state?.lessons[
                                                this.state?.currentLesson
                                              ]?.id
                                                ? "lsn-box green"
                                                : "lsn-box black"
                                            }
                                          >
                                            <img
                                              src={
                                                "/assets/new_layout/img/sub-section (1).png"
                                              }
                                            />{" "}
                                            Lesson-{j + 1} :{" "}
                                            {lesson.lesson_name}
                                          </strong>
                                        </li>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        {/* <li className="StepProgress-item is-done"><strong>2. Goal-setting</strong></li>
                      <li className="StepProgress-item current"><strong>3. Planning & Strategy</strong></li>
                      <li className="StepProgress-item"><strong>4. Culture</strong></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
               
                {/* <div
                  className="col-lg-8 col-md-7"
                  style={{ borderBottom: "2px solid #E7E7E7" }}
                >
                  <div className="about-course">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link  active"
                          href="#aboutCourse"
                          role="tab"
                          data-toggle="tab"
                          aria-selected="true"
                        >
                          About this course
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#aboutChapter"
                          role="tab"
                          data-toggle="tab"
                        >
                          About this chapter
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="aboutCourse">
                        <div className="about-cnt">
                          {ReactHtmlParser(
                            this.props?.getCourseDescription?.course_description
                          )}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="aboutChapter">
                        <div className="about-cnt">
                          {this.props?.getCourseDescription ? (
                            <div>
                              {this.props?.getCourseDescription?.chpaters?.map(
                                (list, index) => (
                                  <span key={index}>
                                    {list?.lessons.map((lesson, num) => (
                                      <span key={num}>
                                        <h3>{lesson?.lesson_name}</h3>
                                        <p>{lesson.lesson_overview}</p>
                                      </span>
                                    ))}
                                  </span>
                                )
                              )}
                            </div>
                          ) : (
                            console.log("nothing found")
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div
                  className="col-lg-8 col-md-7 py-3"
                  style={{ borderBottom: "2px solid #E7E7E7" }}
                >
                  <div className="course-boxset-number">
                    <div className="row">
                      <div className="col-md-2">
                        <h4>By the number</h4>
                      </div>
                      <div className="col-md-10">
                        <p>Skill level: Beginner Level</p>
                        <p>Lessons : 3</p>
                        <p>Languages : English</p>
                        <p>Episodes : 2.5 total hour</p>
                        <p>Downloadable content : 1</p>
                        <p>Text document : 1</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-7 py-3">
                  <div className="discription-boxset-section">
                    <h4>Description</h4>
                    <div className="course-desc-list" id="course-list">
                      {this.props?.getCourseDescription &&
                        this.props?.getCourseDescription.chpaters.map(
                          (list, index) => (
                            <span key={index}>
                              <div className="course-cnt-box">
                                <div className="course-desc-head">
                                  <h6>
                                    <a
                                      href={`#collapseDetails-${index}`}
                                      data-toggle="collapse"
                                      aria-controls={`collapseDetails-${index}`}
                                      className="collapsible-link"
                                    >
                                      {index + 1 + " " + list.chapter_name}
                                    </a>
                                  </h6>
                                </div>
                                <div
                                  id={`collapseDetails-${index}`}
                                  className="collapse"
                                >
                                  <div className="course-desc-box">
                                    <ul>
                                      <span>
                                        {list?.lessons.map((lesson, j) => {
                                          let indexTo =
                                            this.state.lessons.findIndex(
                                              (l) => {
                                                return l.id == lesson.id;
                                              }
                                            ) || 0;
                                          return (
                                            <li>
                                              <h5 className="duration">
                                                {lesson.duration}
                                              </h5>
                                              <p>
                                                <a
                                                  onClick={() => {
                                                    const ind =
                                                      this.state.lessons.findIndex(
                                                        (l) => {
                                                          return (
                                                            l.id == lesson.id
                                                          );
                                                        }
                                                      ) || 0;
                                                    if (
                                                      this.state.lessons[ind]
                                                        ?.lesson_video_url &&
                                                      this.state?.lessons[ind]
                                                        ?.lesson_video_url != ""
                                                    ) {
                                                      this.setState(
                                                        {
                                                          activeTab: "video",
                                                        },
                                                        () => {
                                                          this.setState({
                                                            currentLesson: ind,
                                                            activeTab:
                                                              this.state
                                                                ?.activeTab,
                                                          });
                                                        }
                                                      );
                                                    } else if (
                                                      !this.state?.lessons[ind]
                                                        ?.lesson_video_url ||
                                                      (this.state?.lessons[ind]
                                                        ?.lesson_video_url ==
                                                        "" &&
                                                        this.state?.lessons[ind]
                                                          ?.lesson_overview &&
                                                        this.state?.lessons[ind]
                                                          ?.lesson_overview !=
                                                          "")
                                                    ) {
                                                      this.setState(
                                                        {
                                                          activeTab: "content",
                                                        },
                                                        () => {
                                                          this.setState({
                                                            currentLesson: ind,
                                                            activeTab:
                                                              this.state
                                                                ?.activeTab,
                                                          });
                                                        }
                                                      );
                                                    } else if (
                                                      (!this.state.lessons[ind]
                                                        ?.lesson_video_url ||
                                                        this.state?.lessons[ind]
                                                          ?.lesson_video_url ==
                                                          "") &&
                                                      (!this.state.lessons[ind]
                                                        ?.lesson_overview ||
                                                        this.state.lessons[ind]
                                                          ?.lesson_overview ==
                                                          "") &&
                                                      this.state.lessons[ind]
                                                        ?.downloadable_content_url &&
                                                      this.state?.lessons[ind]
                                                        ?.downloadable_content_url !=
                                                        ""
                                                    ) {
                                                      this.setState(
                                                        {
                                                          activeTab:
                                                            "downloadUrl",
                                                        },
                                                        () => {
                                                          this.setState({
                                                            currentLesson: ind,
                                                            activeTab:
                                                              this.state
                                                                ?.activeTab,
                                                          });
                                                        }
                                                      );
                                                    } else {
                                                      this.setState(
                                                        {
                                                          activeTab: "video",
                                                        },
                                                        () => {
                                                          this.setState({
                                                            currentLesson: ind,
                                                            activeTab:
                                                              this.state
                                                                ?.activeTab,
                                                          });
                                                        }
                                                      );
                                                    }
                                                    this.props.dispatchGetPauseVideoTime(
                                                      {
                                                        qs: {
                                                          service_category:
                                                            "course",
                                                          service_id:
                                                            lesson.course_id,
                                                          playble_item_id:
                                                            lesson.id,
                                                        },

                                                        token: this.props.token,
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <strong>
                                                    Lesson-{j + 1} :{" "}
                                                  </strong>
                                                  {lesson.lesson_name}
                                                </a>
                                              </p>
                                            </li>
                                          );
                                        })}
                                      </span>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </span>
                          )
                        )}

                      {/* <span>
                      <div className="course-cnt-box">
                        <div className="course-desc-head">
                          <h6>
                            <a href="#collapse1" data-toggle="collapse" className="collapsible-link">2 Goal-setting</a>
                          </h6>
                        </div>
                        <div id="collapse1" className="collapse">
                          <div className="course-desc-box">
                            <ul>
                              <span>
                                <li>
                                  <h5 className="duration">00:00</h5>
                                  <p>
                                    <a href="/course/entrepreneurship/90/test-course/view-detail?lessionId=645"><strong>Lesson-1 : </strong>Testing</a>
                                  </p>
                                </li>
                              </span>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AuthFooter />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getCourseDescription: state.coursesReducer.courseDescription,
    getPauseData: state.coursesReducer.getPauseData,
    getProgress: state.coursesReducer.getProgress,
    isComplete: state.coursesReducer.isComplete,
    loadingChat: state.coursesReducer.loadingChat,
    aiAnswer: state.coursesReducer.aiAnswer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchCourseById: (data) => dispatch(getCourseById(data)),
    dispatchSavePauseTime: (data) => dispatch(savePauseTime(data)),
    dispatchGetPauseVideoTime: (data) => dispatch(getPauseTimebyLessonId(data)),
    dispatchGetProgress: (data) => dispatch(getProgressById(data)),
    dispatchCompleteCourse: (data) => dispatch(markAsComplete(data)),
    dispatchLatestService: (data) => dispatch(isLatestSuccess(data)),
    dispatchGetAIAnswer: (data) => dispatch(getCourseAIAnswer(data)),
    dispatchSaveAIAnswer: (data) => dispatch(saveCourseAIAnswer(data)),
    dispatchClearAIAnswer: () => dispatch(clearAIAnswer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDetail));
